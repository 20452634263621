import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, Page} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type AllConnectionApiReturnType = ApiResponse<Page>;
type AllConnectionPageArgs = { page: number };

export interface AllConnectionPageState extends Loadable {
    allConnection: Page;
}

const initialAllConnectionState: AllConnectionPageState = {
    allConnection: {} as Page,
    isLoading: false,
};

export const fetchAllConnectionPage = createAsyncThunk<AllConnectionApiReturnType,
    AllConnectionPageArgs,
    ThunkApiArgs<AllConnectionApiReturnType>>("AllConnectionPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/info-connection/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allConnectionPageSlice = createSlice({
    name: "allConnectionSlice",
    initialState: initialAllConnectionState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllConnectionPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllConnectionPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allConnection = payload.content;
            })
            .addCase(fetchAllConnectionPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********
