import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {fetchNbPackBuyPage, NbPackBuyPageState,} from "./membership-reducer";

const SubscriptionStatistics = () => {
    const dispatch = useDispatch();

    const {nbPackBuy} = useSelector<AppState, NbPackBuyPageState>(state => state.nbPackBuyed)

    useEffect(() => {
        dispatch(fetchNbPackBuyPage({}));
    }, []);

    return (
        <div className="start-page">
            <header className="page-header page-header-dark">
                <h1>Statistiques d'abonnement mensuel</h1>
            </header>
            <div>
                <table className="table">
                    <thead>
                    <tr>
                        <td>Date</td>
                        <td>Nb Pro</td>
                        <td>Nb Semi-Pro</td>
                        <td>Nb Discovery</td>
                        <td>Total</td>
                    </tr>
                    </thead>
                    <tbody>
                    {nbPackBuy && nbPackBuy.map((month) => {
                        return (
                            <tr>
                                <td>{month.month + " " + month.year}</td>
                                <td>{month.nbPro}</td>
                                <td>{month.nbSemi}</td>
                                <td>{month.nbDisc}</td>
                                <td>{month.nbMembership}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SubscriptionStatistics;