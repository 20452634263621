import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const pageOfMatchsActions = generateApiActions("get_all_match");

export const actionGetAllMatchs = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/matchs/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfMatchsActions.all())
        .build();

export const updateMatchResultActions = generateApiActions("update_match_result");

export const actionUpdateMatchResult = (updateMatchRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/matchs/`)
        .withBody(updateMatchRequest)
        .verb(HTTP_VERBS.POST)
        .types(updateMatchResultActions.all())
        .build();


export const getMatchPeriodActions = generateApiActions("get_match_period");

export const actionGetMatchPeriod = (matchId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/matchs/${matchId}`)
        .types(getMatchPeriodActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...pageOfMatchsActions.all())]: {
            next: (state, {payload}) => (payload ? {page: {...payload.content}} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...updateMatchResultActions.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },

        [combineActions(...getMatchPeriodActions.all())]: {
            next: (state, {payload}) => ({...state, period: payload ? payload.content : null}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;