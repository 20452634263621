import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Table} from "react-bootstrap";
import Popin from "../../uicomponents/modal/modal";

import PopinDelete from "../../uicomponents/modal/modalDelete";
import AutoComplete from "../../components/AutoComplete";
import {
    AllProductConfigurationPageState,
    AllUsersPageState,
    fetchAddProductConfigurationPage,
    fetchAllProductConfigurationPage,
    fetchAllUsersPage,
    fetchDeleteProductConfigurationPage
} from "./membership-reducer";
import {AppState} from "../../store/reducers";

const ProductConfiguration = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showModalDeletion, setShowModalDeletion] = useState(false);
    const [productOnEdition, setProductOnEdition] = useState<any>({});

    const {users} = useSelector<AppState, AllUsersPageState>(state => state.users);
    const {allProductConfiguration} = useSelector<AppState, AllProductConfigurationPageState>(state => state.allProductConfiguration)

    useEffect(() => {
        dispatch(fetchAllProductConfigurationPage({}));
        dispatch(fetchAllUsersPage({searchText: "", page: 0}));
    }, []);

    const addOrUpdateProduct = () => {
        setShowModal(false);
        dispatch(fetchAddProductConfigurationPage({product: productOnEdition}));
        dispatch(fetchAllUsersPage({searchText: "", page: 0}));
        window.location.reload();
    };


    const deleteProduct = () => {
        setShowModalDeletion(false);
        dispatch(fetchDeleteProductConfigurationPage({id: productOnEdition.id}));
        setProductOnEdition({});
        dispatch(fetchAllUsersPage({searchText: "", page: 0}));
        window.location.reload();
    };

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setProductOnEdition({...productOnEdition, [name]: value});
    };

    const handleUsersSelected = selected => {
        const businessContributorId = selected[0].id
        setProductOnEdition({...productOnEdition, ["businessContributorId"]: businessContributorId});
    };

    const onAddNewProduct = () => {
        setProductOnEdition({...productOnEdition, id: null});
        setShowModal(true);
    }

    const onFillProductInformation = (product) => {
        setProductOnEdition(product);
        setShowModal(true);
    }

    const onDeleteProduct = (product) => {
        setProductOnEdition(product);
        setShowModalDeletion(true);
    }

    const renderModal = () => {
        return (
            <Popin title={productOnEdition.id != -1 ? `Edition du produit #${productOnEdition.id}` : `Nouveau produit`}
                   showModal={showModal}
                   cancelAction={() => setShowModal(false)}
                   saveAction={addOrUpdateProduct}>

                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Niveau de membership</Form.Label>
                        <Form.Control type="text" placeholder="Niveau de membership"
                                      onChange={(event) => handleChange(event)}
                                      name="level"
                                      value={productOnEdition.level ? productOnEdition.level : ""}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nom du produit google</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le nom du produit google ..."
                                      onChange={(event) => handleChange(event)}
                                      name="googlePlayProductId"
                                      value={productOnEdition.googlePlayProductId}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nom du produit apple</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le nom du produit apple ..."
                                      onChange={(event) => handleChange(event)}
                                      name="appleStoreProductId"
                                      value={productOnEdition.appleStoreProductId ? productOnEdition.appleStoreProductId : ""}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Durée de l'abonnement</Form.Label>
                        <Form.Control type="text" placeholder="Entrer la durée de l'abonnement ..."
                                      onChange={(event) => handleChange(event)}
                                      name="period"
                                      value={productOnEdition.period ? productOnEdition.period : ""}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Code promo</Form.Label>
                        <Form.Control type="text" placeholder="Modificer le code promo ..."
                                      onChange={(event) => handleChange(event)}
                                      name="promoCode"
                                      value={productOnEdition.promoCode ? productOnEdition.promoCode : ""}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Prix</Form.Label>
                        <Form.Control type="number" placeholder="Modifier le prix..."
                                      onChange={(event) => handleChange(event)}
                                      name="amountInCent"
                                      value={productOnEdition.amountInCent ? productOnEdition.amountInCent : ""}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Influenceur Id</Form.Label>

                        <AutoComplete users={users.content} labelKey="nickname"
                                      filter={["firstname", "lastname", "email"]} multiple={false}
                                      placeHolderText="Choississez l'utilisateur"
                                      handleUsersSelected={(event) => handleUsersSelected(event)}/>
                    </Form.Group>

                </Form>
            </Popin>
        );
    }

    const renderModalDeletion = () => {

        return (
            <PopinDelete title="Voulez vous supprimer cet produit"
                         showModal={showModalDeletion}
                         cancelAction={() => setShowModalDeletion(false)}
                         saveAction={deleteProduct}>
            </PopinDelete>
        );
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Configuration des produits</h1>

            <div className="alert alert-info">
                Cet écran permet de configurer les produits disponibles
            </div>
            {productOnEdition && renderModal()}
            {productOnEdition && renderModalDeletion()}
            <div>
                <Button variant="primary" size="lg" onClick={() => onAddNewProduct()}>
                    Ajouter un produit
                </Button>
            </div>
            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Niveau membership</th>
                        <th>Google produit id</th>
                        <th>Apple produit id</th>
                        <th>Period</th>
                        <th>Code promo</th>
                        <th>Prix en cents</th>
                        <th>Influenceur Id</th>
                        <th><i className="fas fa-cog"></i></th>
                        <th><i className="fas fa-cog"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allProductConfiguration && allProductConfiguration.map((product, index) =>
                        <tr key={index}>
                            <td>{product.id}</td>
                            <td>{product.level}</td>
                            <td>{product.googlePlayProductId}</td>
                            <td>{product.appleStoreProductId}</td>
                            <td>{product.period}</td>
                            <td>{product.promoCode}</td>
                            <td>{product.amountInCent}</td>
                            <td>{product.businessContributorId}</td>
                            <td>
                                <Button variant="primary" onClick={() => onFillProductInformation(product)}>
                                    <i className="fas fa-pencil-alt"></i>
                                </Button>
                            </td>

                            <td>
                                <Button variant="primary" onClick={() => onDeleteProduct(product)}>
                                    <i className="fas fas fa-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default ProductConfiguration;