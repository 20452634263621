import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const allSocialStatistics = generateApiActions("bo_get_social_statistics");

export const actionGetAllSocialStatistics = (nbDays: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/social/statistics/?nbDays=${nbDays}`)
        .verb(HTTP_VERBS.GET)
        .types(allSocialStatistics.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...allSocialStatistics.all())]: {
            next: (state, {payload}) => (payload ? {socialStatistics: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;