import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    AddMembershipLevelInUserConfigurationPageState,
    AllPackLevelsPageState,
    AllUsersPageState,
    fetchAddMembershipLevelInUserConfigurationPage,
    fetchAllPackLevelsPage,
    fetchAllUsersPage
} from "./membership-reducer";
import Pagination from "../../uicomponents/pagination/pagination";
import moment from "moment";
import FormControl from "react-bootstrap/FormControl";
import {User} from "../../typings/api";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";

const LevelConfiguration = () => {

    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [showEdition, setShowEdition] = useState(false);
    const [pack, setPack] = useState("");
    const [date, setDate] = useState("");
    const [user, setUser] = useState<User>({} as User);

    const {levels} = useSelector<AppState, AllPackLevelsPageState>(state => state.packLevels);
    const {users} = useSelector<AppState, AllUsersPageState>(state => state.users);
    const {isLoading} = useSelector<AppState, AddMembershipLevelInUserConfigurationPageState>(state => state.addMembershipLevelInUserConfiguration)

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: "", page: 0}))
        dispatch(fetchAllPackLevelsPage({}))
    }, []);

    useEffect(() => {
        if (!showEdition)
            setUser({} as User);
    }, [showEdition]);

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: searchText, page: currentPage}))
    }, [currentPage]);

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: searchText, page: 0}))
    }, [searchText]);

    useEffect(() => {
        if (!isLoading)
            dispatch(fetchAllUsersPage({searchText: searchText, page: currentPage}))
    }, [isLoading]);

    const handlePackEdition = () => {
        dispatch(fetchAddMembershipLevelInUserConfigurationPage({
            sendRequest: {
                allUsers: !user.nickname,
                expirationDate: date,
                levelChoice: pack,
                userIds: user.nickname ? [user.id] : []
            }
        }));
        setShowEdition(false);
    };

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        setCurrentPage(0);
    };

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Attribution des packs</h1>
        </header>
        <div className="mb-4 row">
            <div className="col-md-4">
                <FormControl
                    placeholder="Entrez le pseudo d'un utilisateur"
                    aria-label="Username"
                    aria-describedby="basic-addon"
                    size="lg"
                    onChange={handleSearchTextChange}
                />
            </div>
            <div className="col-md-4">
                <button className="btn btn-primary btn-lg" onClick={() => setShowEdition(true)}>
                    <i className="fas fa-pen"/> Modifier tous les utilisateurs
                </button>
            </div>
        </div>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <Pagination page={users} action={setCurrentPage}/>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <td>#</td>
                    <td>Pseudo</td>
                    <td>Email</td>
                    <td>Pack</td>
                    <td>Expiration</td>
                    <td>Modifier</td>
                </tr>
                </thead>
                <tbody>
                {
                    users.content && users.content.map((user, index) =>
                        <tr key={index}>
                            <td>{user.id}</td>
                            <td>{user.nickname}</td>
                            <td>{user.email}</td>
                            <td>{user.userMembershipAttribut.level}</td>
                            <td>{moment(user.userMembershipAttribut.expirationDate).format("DD/MM/YY")}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => {
                                    setShowEdition(true);
                                    setUser(user)
                                }}>
                                    <i className="fas fa-pen"/>
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
        {
            showEdition &&
            <Modal size="lg" show={showEdition}
                   onHide={() => setShowEdition(false)}>
                <Modal.Header closeButton className="alert alert-primary">
                    <Modal.Title>
                        Modification du pack de {user.nickname ? user.nickname : "tous les utilisateurs"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select className="custom-select mb-2" defaultValue="PACK"
                            onChange={event => setPack(event.target.value)}>
                        <option value="PACK" disabled={true}>PACK</option>
                        {
                            levels.map(level =>
                                <option value={level}>{level}</option>
                            )
                        }
                    </select>
                    Date d'expiration : <input className="border rounded" type="date"
                                               onChange={e => setDate(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlePackEdition}
                            disabled={date === "" || pack === ""}>
                        Appliquer
                    </Button>
                </Modal.Footer>
            </Modal>
        }
    </div>
}

export default LevelConfiguration;