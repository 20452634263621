import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllTeams = generateApiActions("bo_get_all_page_team");

export const actionGetAllTeams = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllTeams.all())
        .build();

export const searchTeamsByName = generateApiActions("bo_search_teamr_by_name");

export const actionSearchTeamsByName = (nickName: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/search/${nickName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchTeamsByName.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getAllTeams.all())]: {
            next: (state, {payload}) => (payload ? {teams: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...searchTeamsByName.all())]: {
            next: (state, {payload}) => (payload ? {teams: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;