import {ApiResponse, Sport, User} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";
import {HTTP_VERBS} from "../../../services/rsaa-builder";

type AllAdminsApiReturnType = ApiResponse<User[]>;
type AllAdminsPageArgs = {};

export interface AllAdminsPageState extends Loadable {
    allAdmins: User[];
}

const initialAllAdminsState: AllAdminsPageState = {
    allAdmins: [] as User[],
    isLoading: false,
};

export const fetchAllAdminsPage = createAsyncThunk<AllAdminsApiReturnType,
    AllAdminsPageArgs,
    ThunkApiArgs<AllAdminsApiReturnType>>("AllAdminsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/admins`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allAdminsPageSlice = createSlice({
    name: "allAdminsSlice",
    initialState: initialAllAdminsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllAdminsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllAdminsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allAdmins = payload.content;
            })
            .addCase(fetchAllAdminsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

type SendMessageApiReturnType = ApiResponse<any>;
type SendMessagePageArgs = {
    message: {
        channel: string;
        userId: number;
        message: string;
        documentUrl: string;
    };
};

export interface SendMessagePageState extends Loadable {
}

const initialSendMessageState: SendMessagePageState = {
    isLoading: false,
};

export const fetchSendMessagePage = createAsyncThunk<SendMessageApiReturnType,
    SendMessagePageArgs,
    ThunkApiArgs<SendMessageApiReturnType>>("SendMessagePage/fetch", async ({message}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/send-feed`)
        .verb(HTTP_VERBS.POST)
        .withBody(message)
        .build()
);

export const sendMessagePageSlice = createSlice({
    name: "sendMessageSlice",
    initialState: initialSendMessageState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSendMessagePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSendMessagePage.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSendMessagePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllFeedsForSendFeedApiReturnType = ApiResponse<Sport[]>;
type FetchAllFeedsForSendFeedPageArgs = {};

export interface AllFeedsForSendFeedPageState extends Loadable {
    allFeeds: Sport[]
}

const initialAllFeedsForSendFeedState: AllFeedsForSendFeedPageState = {
    allFeeds: [],
    isLoading: false,
};

export const fetchAllFeedsForSendFeedPage = createAsyncThunk<AllFeedsForSendFeedApiReturnType,
    FetchAllFeedsForSendFeedPageArgs,
    ThunkApiArgs<AllFeedsForSendFeedApiReturnType>>("AllFeedsForSendFeedPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/sports/`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allFeedsForSendFeedPageSlice = createSlice({
    name: "allFeedsForSendFeedSlice",
    initialState: initialAllFeedsForSendFeedState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllFeedsForSendFeedPage.pending, state => {
                state.isLoading = true
            })
            .addCase(fetchAllFeedsForSendFeedPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allFeeds = payload.content;
                state.allFeeds.push({
                    id: -1,
                    code: "GENERAL",
                    name: "Général",
                    version: 0,
                    active: true,
                    activeForProno: true,
                    sportOrder: 100,
                });
            })
            .addCase(fetchAllFeedsForSendFeedPage.rejected, state => {
                state.isLoading = false
            })
    }
});

//*******