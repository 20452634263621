import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {compose} from "redux";
import {Table} from "react-bootstrap";
import {formatPrecisionMin} from "../../services/moment/momentUtil";
import Pagination from "../../uicomponents/pagination/pagination";
import {actionGetAllConnection} from "../../store/reducers/info-connection";
import {AppState} from "../../store/reducers";
import {AllConnectionPageState, fetchAllConnectionPage} from "./info-connection-reducer";

const InfoConnection = () => {

    const dispatch = useDispatch();
    const {allConnection} = useSelector<AppState, AllConnectionPageState>(state => state.allConnection);

    useEffect(() => {
        dispatch(fetchAllConnectionPage({page: 0}));
    }, []);

    const paginationChange = (event, pageClicked) => {
        dispatch(fetchAllConnectionPage({page: pageClicked}));
    };

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Connexions</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {allConnection &&
                        <Pagination page={allConnection} action={paginationChange}/>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>User id#</th>
                        <th>Mobile</th>
                        <th>Device type</th>
                        <th>Device OS</th>
                        <th>Version de l'application</th>
                        <th>Langue</th>
                        <th>Pays</th>
                        <th>Market Key</th>
                        <th>Date de connexion</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allConnection && allConnection.content && allConnection.content.map((connection, index) =>
                        <tr key={index}>
                            <td>{connection.id}</td>
                            <td>{connection.userId}</td>
                            <td>{connection.mobileType}</td>
                            <td>{connection.deviceType}</td>
                            <td>{connection.osVersion}</td>
                            <td>{connection.appVersion}</td>
                            <td>{connection.languageCode}</td>
                            <td>{connection.countryCode}</td>
                            <td>{connection.marketKeyCode}</td>
                            <td>{formatPrecisionMin(connection.createdAt)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    connectionReducersState: state.reducerConnection
});

const mapDispatchToProps = {
    getAllConnection: actionGetAllConnection,
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(InfoConnection);
