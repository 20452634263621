/* tslint:disable */

/* eslint-disable */

export interface InfluencersStatistics extends BaseEntityWithAudit {
    appStoreLink: string;
    googlePlayLink: string;
    influencerName: string;
    nbClickAppStore: number;
    nbClickGooglePlay: number;
}

export interface CampaignRequest {
    fromUserId: number;
    notificationTitle: string;
    message: string;
    toUserIds: number[];
    allUsers: boolean;
}

export interface CampaignMessage extends BaseEntityWithAudit {
    userId: number;
    notificationTitle: string;
    message: string;
    publishedAt: Date;
}

export interface GenerationOddVariationAlert extends BaseEntityWithAudit {
    matchName: string;
    matchDate: Date;
    championship: string;
    oldOdd: number;
    newOdd: number;
    betName: string;
    betChoiceName: string;
    message: string
    sportType: string;
}

export interface News extends BaseEntityWithAudit {
    title: string;
    content: string;
    imageUrl: string;
    status: string;
    action: string;
}

export interface MembershipMappingFeaturesByLevel extends BaseEntity {
    level: string;
    feature: string;
}

export interface MembershipPackResponse {
    id: number;
    priceAmountInCents: number;
    level: string;
}

export interface MembershipPackPurchasedResponse {
    month: string;
    nbDisc: number;
    nbPro: number;
    nbSemi: number;
    nbMembership: number;
    year: string;
}

export interface MembershipRenewResponse {
    id: string;
    nickname: string;
    userMembershipAttribut: {
        level: string;
        expirationDate: string;
    };
}


export interface ProductByPromoCode extends BaseEntityWithAudit {
    amountInCent: number;
    period: string;
    appleStoreProductId: string;
    googlePlayProductId: string;
    level: string;
    promoCode: string;
    businessContributorId: string;
}

export interface InfoConnection extends BaseEntityWithAudit {
    userId: string;
    mobileType: string;
    osVersion: string;
    deviceType: string;
    marketKeyCode: string;
    appVersion: string;
    languageCode: string;
    countryCode: string;
}

export interface MembershipBasicMetricsReportResponse {
    nbSemiPro: number;
    nbPro: number;
}

export interface TeamBasicMetricsReportResponse {
    nbTeams: number;
    nbTeamsPublic: number;
    nbTeamsPrivate: number;
    nbTeamsSecret: number;
    nbTeamsPublications: number;
    nbTeamsUsers: number;
}

export interface BasicMetricsReportResponse {
    nbUsers: number;
    nbUsersSinceYesterday: number;
    nbUsersSinceOneWeek: number;
    nbUsersSinceOneMonth: number;
    nbReportedUsers: number;
    nbPublications: number;
    nbPublicationComments: number;
    nbPublicationLikes: number;
    nbPublicationCommentLikes: number;
    nbPendingProno: number;
    nbTotalsProno: number;
    teamBasicMetricsReportResponse: TeamBasicMetricsReportResponse;
    membershipBasicMetricsReportResponse: MembershipBasicMetricsReportResponse;
}

export interface FixResultMatchTeamPlayRequest {
    betSobetRef: string;
    winnerChoiceSobetRef: string;
    postResultMessage: string;
}

export interface BetChoice extends BaseEntityWithAudit {
    name: ContributionI18N;
    odd: number;
    sobetRef: string;
}

export interface Bet extends BaseEntityWithAudit {
    name: ContributionI18N;
    choices: BetChoice[];
    bookmakerId: number;
    matchId: number
    oddType: string;
    sobetRef: string;
    winnerChoiceId: number;
    postResultMessage: string;
}

export interface BookmakerV2 extends BaseEntityWithAudit {
    name: ContributionI18N;
    code: string;
    iconUrl: string;
    bookmakerUrl: string;
    bannerUrl: string;
    priority: number;
}

export interface Matchs extends BaseEntityWithAudit {
    eventId: number;
    name: string;
    sobetRef: string;
    dateTime: Date;
}

export interface PlayersTeam {
    name: ContributionI18N;
    userIds: number[];
}

export interface TeamPlayAddMatchRequest {
    dateTime: Date;
    name: string;
    type: string;
    teams: PlayersTeam[];
}

export interface TeamPlayAddEventRequest {
    eventName: string;
    sportCategoryId: number;
}

export interface ContributionI18NItem {
    lang: string;
    trad: string;
}

export interface ContributionI18N {
    items: ContributionI18NItem[];
}

export interface Event extends BaseEntityWithAudit {
    id: number;
    name: ContributionI18N;
    sportCategoryId: number;
    priority: number;
}

export interface SportCategory extends BaseEntityWithAudit {
    id: number;
    name: ContributionI18N;
    iconUrl: string;
    codeLinkWithModelv1: string;
    priority: number;
}

export interface Team {
    id: number;
    name: string;
    teamPhotoUrl: string;
    members: User[];
}

export interface ValidatePhoneWithTokenForm {
    phoneNumber: string;
    token: string;
}

export interface UserUpdateForm {
    userId: number;
    firstname: string;
    lastname: string;
    email: string;
    storeEvaluation: boolean;
    instagramAccount: string;
    followingOnInstagram: boolean;
    facebookAccount: string;
    followingOnFacebook: boolean;
    nbGodson: number;
}

export interface RankingItem {
    userId: number;
    rank: number;
    value: number;
}

export interface RankingItemV2 {
    userId: number;
    rank: number;
    value: number;
    nickname: string;
    valid: boolean;
}

export interface RankingItemContent {
    content: RankingItem[];
}

export interface RankingItemContentV2 {
    items: RankingItemV2[];
}

export interface UserSummaryView {
    id: number;
    nickname: string;
    profilPhotoUrl: string;
    follow: boolean;
    hasPredictionInPending: boolean;
    isExpert: boolean;
    isSemiExpert: boolean;
    membershipLevel: string;
}

export interface RankingResponse {
    sport: string;
    period: string;
    periodFirstDay: Date;
    rankingType: string;
    challengeId: number;
    teamId: number;
    rankingItemsPage: RankingItemContent;
    userRanking: RankingItem;
    userSummaryViews: UserSummaryView[];
}

export interface RankingV2 extends BaseEntityWithAudit {
    sport: string;
    period: string;
    periodFirstDay: Date;
    rankingType: string;
    challengeId: number;
    teamId: number;
    ranking: RankingItemContentV2;
}

export interface ChallengeParticipant {
    userId: number;
    challengeId: number;
}

export interface customDate {
    startDate: string;
    hours: number;
    min: number;
}

export interface Challenge {
    id: number;
    title: string;
    urlImage: string;
    presentation: string;
    shortDescription: string;
    nbMinPredictions: number;
    packInformation: string;
    rules: string;
    startDate: string;
    expirationDate: string;
    expirationDisplayDate: string;
    googlePlayProductName: string;
    lots: string[];
    annoncesLots: string;
    advertising: string;
    version: number;
    webUrl: string | null;
    createdAt;
    updatedAt;
    sports: number[];
    event: number[];
    matchs: number[];
    bookmakers: number[];
    oddTypes: string[];
    nbParticipants: number;
    priceCents: number;
    paid: boolean;
    activeSubscriptionsCount: number;
    totalPredictions: number;
    otherCount: number;
}

export interface SupportMessage {
    id: number;
    user: {
        id: number;
        civility: string;
        country: string;
        nickname: string;
        email: string;
        firstname: string;
        lastname: string;
        roles: {
            name: string;
        }[];
        userMembershipAttribut: {
            level: string;
            expirationDate: string;
        };
        userSpecialAttribut: {
            expert: boolean;
            semiExpert: boolean;
        };
        phoneNumber: string;
    };
    documentUrls: string[];
    message: string;
    publishedAt: string;
}

export interface UserStatusForSubscription {
    firstnameIsCompleted: boolean;
    lastnameIsCompleted: boolean;
    emailIsCompleted: boolean;
    phoneNumberIsCompleted: boolean;
    storeEvaluationIsCompleted: boolean;
    instagramAccountIsCompleted: boolean;
    followingOnInstagramIsCompleted: boolean;
    facebookAccountIsCompleted: boolean;
    followingOnFacebookIsCompleted: boolean;
    nbGodsonIsEnough: boolean;
    allConditionsCompleted: boolean;
    nbGodson: number;
}

export interface Bookmaker {
    id: number;
    code: string;
    name: string;
}

export interface BookmakerResponse {
    id: number;
    code: string;
    name: string;
}

export interface Match {
    id: number;
    eventId: number;
    name: string;
    dateTime: Date;
    sobetRef: string;
}

export interface MatchScore {
    id: number;

    matchId: number;

    awayScore: number;

    pending: boolean;
    homeScore: number;
}

export interface MatchScoreResponse {
    match: Match;

    matchScore: MatchScore

    event: Event;
}

export interface Trophy {
    id: number;
    name: string;
    description: string;
    durationType: DurationType;
    iconEmoji: string;
}

export interface ActiveTrophy {
    id: number;
    userId: number;
    trophyId: number;
    activeUntil: string;
}

export interface DetDescription {
    bet: Bet;
    betChoice: BetChoice;
    match: Match;
    event: Event;
    bookmaker: BookmakerV2;
    sportCategory: SportCategory;
}

export interface PredictionItem extends BaseEntity {
    betDescription: DetDescription;
    nickname: String;
    resultType: string;
}

export interface Page {
    content: any;
}


export interface Competition extends BaseEntityWithAudit {
    name: string;
    providerOddsId: number;
}

export interface Sport extends BaseEntity {
    active: boolean;
    activeForProno: boolean;
    sportOrder: number;
    code: string;
    name: string;
}

export interface Prognosis {
    bookmakerId: number;
    analysis: string;
    gradeAdvise: number;
    items: PrognosisItem[];
    userId: number;
}

export interface PrognosisItem {
    competitionId: number;
    matchId: number;
    label: string;
    oddChoice: OddChoice;
}

export interface OddChoice {
    code: string;
    label: string;
    value: number;
}

export interface CreateContactForm {
    userId: number;
    message: string;
    documentUrls: [string];
}

export interface ContactUs extends BaseEntityWithAudit {
    user: User;
    message: string;
    documentUrls: [];
}

export interface Revenus {
    semiPro: RevenusAndLevel;
    pro: RevenusAndLevel;
    totalInCents: number;
}

export interface SubscribersInfo {
    nbSubscriberPro: number;
    nbSubscriberSemiPro: number;
    totalSubscriber: number;
}

export interface SubscriberGrowth {
    nbNewSubscriber: number;
    nbOutSubscriber: number;
    nbCroissance: number;
}

export interface RevenusAndLevel {
    level: string;
    revenue: number;
}

export interface AuthSigninRequest {
    email: string;
    password: string;
}

export interface AuthSigninForm {
    nicknameOrEmail: string;
    password: string;
}

export interface AuthSigninResponse {
    jwt: string;
    user: User;
}

export interface BaseEntity {
    id: number;
    version: number;
}

export interface BaseEntityWithAudit extends BaseEntity {
    createdAt: Date;
    updatedAt: Date;
}

export interface BasketDeliveryLine extends BaseEntityWithAudit {
    addressId: number;
    quantity: number;
}

export interface BasketLine extends BaseEntityWithAudit {
    deliveryLines: BasketDeliveryLine[];
    offer: Offer;
    quantity: number;
}

export interface CatalogView extends BaseEntityWithAudit {
    description: string;
    name: string;
    status: CatalogViewStatus;
}

export interface Category extends BaseEntityWithAudit {
    code: string;
    label: string;
    level: number;
    parentCategory: Category;
}

export interface CategoryTreeView {
    childrenCategories: CategoryTreeView[];
    label: string;
}

export interface CreateCatalogView {
    customerAccountsIds: number[];
    customerOrganisationsIds: number[];
    customerSubOrganisationsIds: number[];
    customerUsersIds: number[];
    description: string;
    name: string;
    productsIds: number[];
    status: CatalogViewStatus;
}

export interface CreateUserRequest {
    civilityType: CivilityType;
    email: string;
    firstname: string;
    lastname: string;
    profiles: ProfileType[];
    status: UserStatus;
}

export interface CustomerOrganisation extends BaseEntityWithAudit {
    mainContacts: User[];
    name: string;
    status: CustomerOrganisationStatus;
    subOrganisations: CustomerSubOrganisation[];
}

export interface CustomerSubOrganisation extends BaseEntity {
    name: string;
}

export interface DeleteBasketLineRequest {
    offerId: number;
}

export interface ApiError extends RuntimeException {
    code: string;
    detail: string;
}

export interface ApiResponse<T> {
    content: T;
    errors: ApiError[];
    warnings: ApiWarning[];
}

export interface ApiResponseError extends RuntimeException {
    response: ApiResponse<any>;
}

export interface ApiWarning {
    code: string;
    field: string;
    message: string;
}

export interface Exception extends Throwable {
}

export interface Job extends BaseEntityWithAudit {
    endDate: Date;
    jobStatus: JobStatus;
    jobType: JobType;
    startDate: Date;
}

export interface JobLastUpdate extends BaseEntityWithAudit {
    jobType: JobType;
    lastUpdate: Date;
}

export interface Offer extends BaseEntityWithAudit, SystemSourceAssociatable {
    active: boolean;
    currency: string;
    djustOfferId: string;
    listPriceExcVat: number;
    originListPriceExcVat: number;
    product: Product;
    quantity: number;
    supplier: Supplier;
}

export interface OrderLogisticHistoryStatus extends BaseEntity {
    histories: OrderLogisticHistoryStatusItem[];
    orderLogisticId: number;
}

export interface OrderLogisticHistoryStatusItem extends BaseEntityWithAudit {
    newStatus: OrderLogisticStatusType;
    oldStatus: OrderLogisticStatusType;
    userIdWhoDoAction: number;
}

export interface OrderLogisticItem extends BaseEntity {
    offer: Offer;
    quantity: number;
}

export interface Permission extends BaseEntity {
    name: PermissionName;
    permissionGroup: PermissionGroup;
}

export interface Product extends BaseEntityWithAudit {
    catalogViews: CatalogView[];
    djustProductId: string;
    externalProductId: string;
    productInfo: ProductInfo;
    productStatus: ProductStatus;
    properties: string;
}

export interface ProductDetailledView {
    offers: Offer[];
    product: Product;
}

export interface ProductInfo {
    brand: string;
    category: Category;
    description: string;
    ean: string;
    mainImageUrl: string;
    mpn: string;
    name: string;
    subImageUrls: string;
}

export interface ProductInfoOfferView {
    name: string;
}

export interface ProductOfferView {
    djustProductId: string;
    productInfo: ProductInfoOfferView;
}

export interface Role extends BaseEntity {
    name: RoleType;
}

export interface RuntimeException extends Exception {
}

export interface Serializable {
}

export interface SimpleCatalogView {
    createdAt: Date;
    description: string;
    id: number;
    name: string;
    status: CatalogViewStatus;
    updatedAt: Date;
    version: number;
}

export interface SimpleOfferView {
    active: boolean;
    createdAt: Date;
    currency: string;
    djustOfferId: string;
    id: number;
    listPriceExcVat: number;
    product: ProductOfferView;
    quantity: number;
    supplier: SupplierOfferView;
    systemSourceId: string;
    updatedAt: Date;
    version: number;
}

export interface SimpleProductInfoView {
    brand: string;
    category: Category;
    ean: string;
    mainImageUrl: string;
    mpn: string;
    name: string;
}

export interface SimpleProductView {
    catalogViews: SimpleCatalogView[];
    createdAt: Date;
    djustProductId: string;
    externalProductId: string;
    id: number;
    productInfo: SimpleProductInfoView;
    productStatus: ProductStatus;
    updatedAt: Date;
    version: number;
}

export interface SimpleUserView {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
}

export interface StackTraceElement extends Serializable {
    classLoaderName: string;
    className: string;
    fileName: string;
    lineNumber: number;
    methodName: string;
    moduleName: string;
    moduleVersion: string;
    nativeMethod: boolean;
}

export interface Supplier extends BaseEntityWithAudit {
    createdDate: Date;
    djustSupplierId: string;
    externalSupplierId: string;
    name: string;
    supplierStatus: SupplierStatus;
    totalOffers: number;
    totalOrders: number;
    totalSales: number;
}

export interface SupplierOfferView {
    name: string;
}

export interface SystemSourceAssociatable {
    systemSourceId: string;
    systemSourceType: SystemSourceType;
}

export interface SystemSourceOffer extends BaseEntityWithAudit, SystemSourceAssociatable {
    offer: any;
}

export interface Throwable extends Serializable {
    cause: Throwable;
    localizedMessage: string;
    message: string;
    stackTrace: StackTraceElement[];
    suppressed: Throwable[];
}

export interface User extends BaseEntityWithAudit {
    civility: CivilityType;
    email: string;
    firstname: string;
    lastname: string;
    parentId: number;
    country: string;
    permissions: Permission[];
    roles: Role[];
    status: UserStatus;
    phoneNumber: string;
    userProfiles: UserProfile[];
    nickname: string;
    userSpecialAttribut: {
        expert: boolean;
        semiExpert: boolean;
    };
    userMembershipAttribut: {
        level: string;
        expirationDate: string;
    };
}

export interface UserProfile extends BaseEntity {
    permissions: Permission[];
    type: ProfileType;
}

export interface UserToken extends BaseEntity {
    createdDate: Date;
    token: string;
    user: User;
}

export enum AddressType {
    SHIPPING = "SHIPPING",
    BILLING = "BILLING",
}

export enum CatalogViewStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum CivilityType {
    MR = "MR",
    MRS = "MRS",
    MISS = "MISS",
}

export enum CustomerAccountStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum CustomerOrganisationStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum JobStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

export enum JobType {
    IMPORT_PRODUCTS = "IMPORT_PRODUCTS",
    IMPORT_OFFERS = "IMPORT_OFFERS",
    IMPORT_SUPPLIERS = "IMPORT_SUPPLIERS",
    IMPORT_ORDERS_STATUS = "IMPORT_ORDERS_STATUS",
}

export enum OrderLogisticStatusType {
    CREATING = "CREATING",
    WAITING_APPROVAL = "WAITING_APPROVAL",
    APPROVED = "APPROVED",
    DECLINED_BY_CUSTOMER = "DECLINED_BY_CUSTOMER",
    WAITING_FULFILLMENT_CONFIRMATION = "WAITING_FULFILLMENT_CONFIRMATION",
    DECLINED_BY_SUPPLIER = "DECLINED_BY_SUPPLIER",
    WAITING_SHIPMENT = "WAITING_SHIPMENT",
    PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED",
    SHIPPED = "SHIPPED",
    COMPLETED = "COMPLETED",
    CANCELLED_BY_CUSTOMER = "CANCELLED_BY_CUSTOMER",
}

export enum PaymentMethod {
    THRUST = "THRUST",
}

export enum PermissionGroup {
    DASHBOARD = "DASHBOARD",
    PRODUCT_CATALOG = "PRODUCT_CATALOG",
    OFFERS = "OFFERS",
    SUPPLIERS = "SUPPLIERS",
    ORDERS = "ORDERS",
    CLAIM_MANAGEMENT = "CLAIM_MANAGEMENT",
    CUSTOMERS = "CUSTOMERS",
    SETTINGS = "SETTINGS",
}

export enum PermissionName {
    ACCESS_SALES_DASHBOARD = "ACCESS_SALES_DASHBOARD",
    ACCESS_WEB_ANALYTICS_DASHBOARD = "ACCESS_WEB_ANALYTICS_DASHBOARD",
    ACCESS_CUSTOMER_SERVICE_DASHBOARD = "ACCESS_CUSTOMER_SERVICE_DASHBOARD",
    ACCESS_PRODUCT_LIST = "ACCESS_PRODUCT_LIST",
    CHANGE_PRODUCT_STATUS = "CHANGE_PRODUCT_STATUS",
    ATTACH_CUSTOMER_VIEWS = "ATTACH_CUSTOMER_VIEWS",
    ACCESS_OFFER_LIST = "ACCESS_OFFER_LIST",
    ACCESS_SUPPLIER_LIST = "ACCESS_SUPPLIER_LIST",
    ACCESS_ORDER_LIST = "ACCESS_ORDER_LIST",
    ACCESS_ORDER_DETAIL = "ACCESS_ORDER_DETAIL",
    CANCEL_ORDER = "CANCEL_ORDER",
    ACCESS_CLAIM_LIST = "ACCESS_CLAIM_LIST",
    ACCESS_CLAIM_DETAIL = "ACCESS_CLAIM_DETAIL",
    CREATE_CLAIM = "CREATE_CLAIM",
    TRIGGER_REFUNDS = "TRIGGER_REFUNDS",
    ACCESS_ACCOUNT_LIST = "ACCESS_ACCOUNT_LIST",
    CREATE_ACCOUNT = "CREATE_ACCOUNT",
    MODIFY_ACCOUNT_INFORMATION = "MODIFY_ACCOUNT_INFORMATION",
    ACCESS_ORGANISATION_LIST = "ACCESS_ORGANISATION_LIST",
    ACCESS_CUSTOMER_USER_LIST = "ACCESS_CUSTOMER_USER_LIST",
    CREATE_ORGANISATION = "CREATE_ORGANISATION",
    CREATE_USER = "CREATE_USER",
    MODIFY_CUSTOMER_USER_INFORMATION = "MODIFY_CUSTOMER_USER_INFORMATION",
    MODIFY_ORGANISATION_INFORMATION = "MODIFY_ORGANISATION_INFORMATION",
    MANAGE_USERS = "MANAGE_USERS",
    MANAGE_ROLES = "MANAGE_ROLES",
    MANAGE_EMAIL_TEMPLATE = "MANAGE_EMAIL_TEMPLATE",
    MANAGE_CURRENCIES_SET_UP = "MANAGE_CURRENCIES_SET_UP",
    MANAGE_PAYMENT_SET_UP = "MANAGE_PAYMENT_SET_UP",
    MANAGE_SHIPPING_CONFIGURATION = "MANAGE_SHIPPING_CONFIGURATION",
    CONFIGURE_GOOGLE_AMP_ANALYTICS = "CONFIGURE_GOOGLE_AMP_ANALYTICS",
    BATCH_DASHBOARD = "BATCH_DASHBOARD",
    MANAGE_BATCH = "MANAGE_BATCH",
}

export enum ProductStatus {
    NEW = "NEW",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum ProfileType {
    ADMIN = "ADMIN",
    COMMERCE_MANAGER = "COMMERCE_MANAGER",
    ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
    CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
}

export enum RoleType {
    USER = "USER",
    CUSTOMER = "CUSTOMER",
}

export enum DurationType {
    WEEK = "WEEK",
    MONTH = "MONTH",
}

export enum SupplierStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    SUSPENDED = "SUSPENDED",
    UNKNOWN = "UNKNOWN",
}

export enum SystemSourceType {
    MIRAKL = "MIRAKL",
}

export enum UserStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum ModelPronoType {
    SPORT = "SPORT",
    EVENT = "EVENT",
    MATCH = "MATCH",
    TEAM = "TEAM",
    BET = "BET",
    BETCHOICE = "BETCHOICE",
}

export interface BookDATAJson {
    competitionName: string;
    matchName: string;
}

export interface LinkBetweenBookAndSobetModel extends BaseEntity {
    sobetRef: string;
    modelPronoType: ModelPronoType;
    bookReferenceKey: string;
    bookData: BookDATAJson;
}

export interface Publication extends BaseEntityWithAudit {
    user: User;
    sportType: string;
    message: string;
    publishedAt: string;
    pin: boolean;
}
