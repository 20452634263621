import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {
    ExpertStatState,
    ExpertSubscriptionStatState,
    fetchExpertStat,
    fetchExpertSubscriptionStat
} from "./expert-statistics-reducer";
import {AppState} from "../../store/reducers";

const ExpertStatistics = () => {
    const dispatch = useDispatch();

    const {expertStatResponse} = useSelector<AppState, ExpertStatState>(state => state.expertStatResponse);
    const {expertSubscriptionStatResponse} = useSelector<AppState, ExpertSubscriptionStatState>(state => state.expertSubscriptionStatResponse);

    useEffect(() => {
        dispatch(fetchExpertSubscriptionStat({}));
        dispatch(fetchExpertStat({}));
    }, []);

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Statistiques des experts</h1>

            <div className="row">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pseudo</th>
                        <th>Email</th>
                        <th>Numéro</th>
                        <th>Nombre de user qui suit cet expert</th>
                        <th>nombre de user Pro</th>
                        <th>nombre de user semi - Pro</th>


                    </tr>
                    </thead>
                    <tbody>
                    {expertSubscriptionStatResponse.map((item, index) =>
                        <tr key={index}>
                            <td>{item.expert.id}</td>
                            <td>{item.expert.nickname}</td>
                            <td>{item.expert.email}</td>
                            <td>{item.expert.phoneNumber}</td>
                            <td>{item.countAllSubscribers}</td>
                            <td>{item.countProSubscribers}</td>
                            <td>{item.countAllSubscribers - item.countProSubscribers}</td>


                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            <div className="alert alert-info">
                <p>Attention la requête est assez couteuse en ressource/performance donc ne pas trop solliciter</p>
                <p>Il faut attendre qu'elle s'affiche (au moins 30 secondes)</p>
            </div>

            <div className="row">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pseudo</th>
                        <th>Email</th>
                        <th>Numéro</th>
                        <th>Nb pronos</th>
                        <th>Stat ROI</th>
                        <th>Classement ROI</th>
                        <th>Stat réussite</th>
                        <th>Classement réussite</th>
                        <th>Stat gain</th>
                        <th>Classement gain</th>
                    </tr>
                    </thead>
                    <tbody>
                    {expertStatResponse.map((user, index) =>
                        <tr key={index}>
                            <td>{user.id}</td>
                            <td>{user.nickname}</td>
                            <td>{user.email}</td>
                            <td>{user.phoneNumber == undefined ? "N/A" : user.phoneNumber}</td>
                            <td>{user.totalPrognosis}</td>
                            <td>{user.statRoi}</td>
                            <td>{user.rankingRoi}</td>
                            <td>{user.statSucess}</td>
                            <td>{user.rankingSuccess}</td>
                            <td>{user.statGain}</td>
                            <td>{user.rankingGain}</td>
                        </tr>
                    )}
                    </tbody>

                </Table>
            </div>


        </div>
    );
}


export default ExpertStatistics;