import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const pageOfPublicationActions = generateApiActions("bo_get_page_user_publications");

export const actionGetAllUserPublications = (userId: number, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/publications/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfPublicationActions.all())
        .build();

export const pageOfCommentActions = generateApiActions("bo_get_page_user_comments");

export const actionGetAllUserComments = (userId: number, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/comments/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfCommentActions.all())
        .build();

export const pageOfAnalysisActions = generateApiActions("bo_get_page_user_analysis");

export const actionGetAllUserAnalysis = (userId: number, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/analysis/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfAnalysisActions.all())
        .build();

export const preventUserActions = generateApiActions("bo_prevent_user");

export const actionPreventUser = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/prevent/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(preventUserActions.all())
        .build();

export const disPreventUserActions = generateApiActions("bo_dis_prevent_user");

export const actionDisPreventUser = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/dis-prevent/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(disPreventUserActions.all())
        .build();


export const updateUserPasswordActions = generateApiActions("bo_update_user_password");

export const actionUpdateUserPassword = (form) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/change-password`)
        .verb(HTTP_VERBS.POST)
        .withBody(form)
        .types(updateUserPasswordActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...pageOfPublicationActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, publications: payload.content, user: {}} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...pageOfCommentActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, comments: payload.content, user: {}} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...pageOfAnalysisActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, analysis: payload.content, user: {}} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...preventUserActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, user: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...disPreventUserActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, user: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...updateUserPasswordActions.all())]: {
            next: (state, {payload}) => (payload ? {...state, isUpdated: true} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;