import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Table} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";
import {
    AllNewsPageState,
    DeleteNewsPageState,
    EditNewsPageState,
    fetchAllNewsPage,
    fetchDeleteNewsPage,
    fetchEditNewsPage,
    fetchUploadImageNewsPage,
    UploadImageNewsPageState
} from "./news-reducer";
import {AppState} from "../../store/reducers";
import {News} from "../../typings/api";
import Pagination from "../../uicomponents/pagination/pagination";
import {fetchAllCampaignsPage} from "../publication/campaign/campaign-reducer";

const AllNews = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [news, setNews] = useState<News>({} as News);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const {allNews} = useSelector<AppState, AllNewsPageState>(state => state.allNews);
    const deleteNews = useSelector<AppState, DeleteNewsPageState>(state => state.deleteNews);
    const editNews = useSelector<AppState, EditNewsPageState>(state => state.editNews);
    const {urlNewsImage} = useSelector<AppState, UploadImageNewsPageState>(state => state.urlNewsImage);

    useEffect(() => {
        dispatch(fetchAllNewsPage({page: 0}));
    }, []);

    useEffect(() => {
        if (!deleteNews.isLoading)
            dispatch(fetchAllNewsPage({page: currentPage}));
    }, [deleteNews.isLoading]);

    useEffect(() => {
        if (!editNews.isLoading)
            dispatch(fetchAllNewsPage({page: currentPage}));
    }, [editNews.isLoading]);

    useEffect(() => {
        setNews({...news, ["imageUrl"]: urlNewsImage});
    }, [urlNewsImage]);

    const handleImage = async (event) => {
        const tmp = new FormData();
        tmp.append("file", event.target.files[0]);
        await dispatch(fetchUploadImageNewsPage({data: tmp}));
    }

    const handleChangeNews = (field, value) => {
        setNews({...news, [field]: value});
    }

    const handleDeleteNews = () => {
        dispatch(fetchDeleteNewsPage({newsId: news.id}));
        setShowDelete(false);
    }

    const handleEditNews = () => {
        dispatch(fetchEditNewsPage({news: news}));
        setShowEdit(false);
    };

    const onDeleteNews = (news) => {
        setNews(news);
        setShowDelete(true);
    };

    const onEditNews = (news) => {
        setNews(news);
        setShowEdit(true);
    };

    const handleClickAdd = () => {
        history.push("/create-news");
    };

    const paginationChange = (event, pageClicked) => {
        setCurrentPage(pageClicked);
        dispatch(fetchAllCampaignsPage({page: pageClicked}));
    };

    console.log(allNews);

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">
                News <Button variant="primary" size="lg" onClick={() => handleClickAdd()}>
                <i className="fas fa-plus"/>
            </Button></h1>
            <div className="card mb-4">
                <div className="card-header">
                    {
                        allNews &&
                        <Pagination page={allNews} action={paginationChange}/>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Contenu</th>
                        <th>Image (URL)</th>
                        <th>Date de création</th>
                        <th>Statut</th>
                        <th>Action</th>
                        <th>Modifier</th>
                        <th>Supprimer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        allNews.content && allNews.content.map((news, index) => (
                            <tr key={index}>
                                <td>{news.id}</td>
                                <td>{news.title}</td>
                                <td>{news.content}</td>
                                <td>{news.imageUrl}</td>
                                <td>{news.createdAt}</td>
                                <td>{news.status}</td>
                                <td>{news.action}</td>
                                <td>
                                    <Button variant="primary" onClick={() => onEditNews(news)}>
                                        <i className="fas fa-pen"/>
                                    </Button>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => onDeleteNews(news)}>
                                        <i className="fas fa-trash"/>
                                    </Button>
                                </td>
                            </tr>)
                        )
                    }
                    </tbody>
                </Table>
            </div>
            {
                showDelete &&
                <Modal size="lg" show={showDelete}
                       onHide={() => setShowDelete(false)}>
                    <Modal.Header closeButton className="alert alert-danger">
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Voulez-vous vraiment supprimer cette news ?</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => handleDeleteNews()}>
                            Confirmer
                        </button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showEdit &&
                <Modal size="lg" show={showEdit} onHide={() => setShowEdit(false)}>
                    <Modal.Header closeButton className="alert alert-primary">
                        <Modal.Title>Edition de {news.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m-1">
                            <h5>Image :</h5>
                            <input className="border rounded" type="file" onChange={e => handleImage(e)}/>
                        </div>
                        <div className="m-1">
                            <h5>Titre :</h5>
                            <input className="border rounded" type="text" placeholder="Titre" value={news.title}
                                   onChange={e => handleChangeNews("title", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Contenu :</h5>
                            <textarea className="border rounded" placeholder="Contenu"
                                      value={news.content}
                                      onChange={e => handleChangeNews("content", e.target.value)}/>
                        </div>
                        <br/>
                        <div className="m-1">
                            Statut :
                            <select onChange={e => handleChangeNews("status", e.target.value)}
                                    defaultValue={news.status}>
                                <option value={"PUBLISHED"}>Publiée</option>
                                <option value={"DRAFT"}>Brouillon</option>
                                <option value={"OBSOLETE"}>Obsolète</option>
                            </select>
                        </div>
                        <br/>
                        <div className="m-1">
                            Action :
                            <select onChange={e => handleChangeNews("action", e.target.value)}
                                    defaultValue={news.action}>
                                <option value={"NO_ACTION"}>Pas d'action</option>
                                <option value={"ACTION_TAKE_SUBSCRIPTION"}>Prendre un abonnement</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => handleEditNews()}>
                            Modifier
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
}

export default AllNews;
