import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllChallenges = generateApiActions("bo_get_all_page_challenge");

export const actionGetAllChallenges = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/webapp/challenge/next?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllChallenges.all())
        .build();

export const searchChallengeByTitle = generateApiActions("bo_search_challenge_by_title");

export const actionSearchChallengeByTitle = (title: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/webapp/challenge/challenge/${title}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchChallengeByTitle.all())
        .build();

export const deleteChallenge = generateApiActions("bo_delete_challenge");

export const actionDeleteChallenge = (challengeId: number, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/webapp/challenge/${challengeId}?page=${page}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteChallenge.all())
        .build();

export const updateChallenge = generateApiActions("bo_update_challenge");

export const actionUpdateChallenge = (challenge, challengeId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/webapp/challenge/update-challenge/${challengeId}`)
        .withBody(challenge)
        .verb(HTTP_VERBS.PUT)
        .types(updateChallenge.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getAllChallenges.all())]: {
            next: (state, {payload}) => (payload ? {challenges: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...searchChallengeByTitle.all())]: {
            next: (state, {payload}) => (payload ? {challenges: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...deleteChallenge.all())]: {
            next: (state, {payload}) => (payload ? {challengeDeleted: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...updateChallenge.all())]: {
            next: (state, {payload}) => (payload ? {challengeUpdated: payload.content, isUpdated: true} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;