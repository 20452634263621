import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import Menu from "../menu/menu";

import {push} from "connected-react-router";

class BarHandler extends React.Component {
    constructor(props) {
        super(props);
        this.onChangePage = this.onChangePage.bind(this);
    }

    onChangePage(url) {
        push(url);
    }

    render() {
        return (
            <div className="start-page">
                <Menu onchangePage={this.onChangePage}/>
                <div id="content-wrapper" className="d-flex flex-column">

                </div>
            </div>
        );
    }

}


const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(BarHandler);
