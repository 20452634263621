import { Page } from "../../typings/api";
import { Loadable } from "../../typings/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiBuilder, ThunkApiArgs } from "../../services/fetch-api";
import { HTTP_VERBS } from "../../services/rsaa-builder";

// Utilisez l'importation existante de ApiResponse
import { ApiResponse } from "../../typings/api";

type DeleteUserApiReturnType = ApiResponse<any>;
type FetchDeleteUserPageArgs = { userId: number };

export interface DeleteUserPageState extends Loadable {
    isUserDeleted: boolean;
}

const initialDeleteUserState: DeleteUserPageState = {
    isLoading: false,
    isUserDeleted: false,
};

export const fetchDeleteUserPage = createAsyncThunk<DeleteUserApiReturnType,
    FetchDeleteUserPageArgs,
    ThunkApiArgs<DeleteUserApiReturnType>>("DeleteUserPage/fetch", async ({ userId }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/users/${userId}/delete`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteUserPageSlice = createSlice({
    name: "deleteUserSlice",
    initialState: initialDeleteUserState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteUserPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteUserPage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isUserDeleted = true;
            })
            .addCase(fetchDeleteUserPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

type FetchAllUsersForModerationPageArgs = { page: number, nickname: string, reported: boolean };

export interface AllUsersForModerationPageState extends Loadable {
    allUsers: Page;
}

const initialState: AllUsersForModerationPageState = {
    allUsers: {} as Page,
    isLoading: false,
};

export const fetchAllUsersForModerationPage = createAsyncThunk<ApiResponse<Page>,
    FetchAllUsersForModerationPageArgs,
    ThunkApiArgs<ApiResponse<Page>>>("AllUsersForModerationPage/fetch", async ({
                                                                                   page,
                                                                                   nickname,
                                                                                   reported
                                                                               }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/search${reported ? "-reported" : ""}/${nickname}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForModerationPageSlice = createSlice({
    name: "allUsersForModerationSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersForModerationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersForModerationPage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.allUsers = payload.content;
            })
            .addCase(fetchAllUsersForModerationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

export interface DailyUserCountState extends Loadable {
    data: Array<{ date: string, count: number }>;
}

const initialDailyUserCountState: DailyUserCountState = {
    isLoading: false,
    data: [],
};

type FetchDailyUserCountArgs = { start: string, end: string };

export const fetchDailyUserCount = createAsyncThunk<ApiResponse<Array<[string, number]>>,
    FetchDailyUserCountArgs,
    ThunkApiArgs<ApiResponse<Array<[string, number]>>>>(
    "DailyUserCount/fetch",
    async ({ start, end }, thunkAPI) => {
        return ApiBuilder.getInstance(thunkAPI)
            .apiEndpoint(`/v1/users/daily-count?start=${start}&end=${end}`)
            .verb(HTTP_VERBS.GET)
            .build();
    }
);

export const dailyUserCountSlice = createSlice({
    name: "dailyUserCountSlice",
    initialState: initialDailyUserCountState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDailyUserCount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDailyUserCount.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload.content.map(([date, count]) => ({ date, count }));
            })
            .addCase(fetchDailyUserCount.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export default dailyUserCountSlice.reducer;
