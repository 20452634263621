import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {AllSupportMessagesPageState, fetchAllSupportMessagesPage} from "./all-messages-reducer";
import moment from "moment";

const AllMessages = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const {allMessages} = useSelector<AppState, AllSupportMessagesPageState>(state => state.allSupportMessages);

    useEffect(() => {
        dispatch(fetchAllSupportMessagesPage({}));
    }, []);

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Demandes de support</h1>
        </header>
        <div className="card">
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Login</th>
                    <th>Message</th>
                    <th>Email</th>
                    <th>Fichier(s)</th>
                    <th>Details</th>
                    <th>Statut (Unavailable)</th>
                </tr>
                </thead>
                <tbody>
                {
                    allMessages && allMessages.map((message, index) =>
                        <tr key={index}>
                            <td>{message.id}</td>
                            <td>{moment(message.publishedAt).format("DD/MM/YY HH:mm:ss")}</td>
                            <td>{message.user.nickname}</td>
                            <td>{message.message}</td>
                            <td>{message.user.email}</td>
                            <td>
                                <h3>
                                    <span className={message.documentUrls.length ?
                                        "badge badge-success" :
                                        "badge badge-dark"}>
                                        {message.documentUrls.length}</span>
                                </h3>
                            </td>
                            <td>
                                <button className="btn btn-primary" type="button"
                                        onClick={() => history.push("/support/message-details/" + message.id)}>
                                    <i className="fas fa-arrow-right"/>
                                </button>
                            </td>
                            <td>
                                <h3>
                                    <span
                                        className="badge badge-danger">Non résolu</span>
                                </h3>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    </div>;
};

export default AllMessages;