import {routerMiddleware} from "connected-react-router";
import {applyMiddleware, compose, createStore} from "redux";
import {apiMiddleware} from "redux-api-middleware";
import {responsiveStoreEnhancer} from "redux-responsive";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";
import {authenticationMiddleware} from "../middlewares/authentication";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const configureStore = (initialState, history) => {
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: "SOBET",
                trace: true,
                traceLimit: 25,
            })
            : compose;
    /* eslint-enable no-underscore-dangle */

    const middleware = [thunk, apiMiddleware, authenticationMiddleware, routerMiddleware(history)];

    const enhancer = composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware));

    return createStore(createRootReducer, initialState, enhancer);
};

export default configureStore;

