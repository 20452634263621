import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, GenerationOddVariationAlert} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api"

type AllGeneratedAlertsApiReturnType = ApiResponse<GenerationOddVariationAlert[]>;
type AllGeneratedAlertsPageArgs = {};

export interface AllGeneratedAlertsPageState extends Loadable {
    allGeneratedAlerts: GenerationOddVariationAlert[];
}

const initialAllGeneratedAlertsState: AllGeneratedAlertsPageState = {
    allGeneratedAlerts: [] as GenerationOddVariationAlert[],
    isLoading: false,
};

export const fetchAllGeneratedAlertsPage = createAsyncThunk<AllGeneratedAlertsApiReturnType,
    AllGeneratedAlertsPageArgs,
    ThunkApiArgs<AllGeneratedAlertsApiReturnType>>("AllGeneratedAlertsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint('/v1/bo/odd-variation/get-all-generated-alerts')
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allGeneratedAlertsPageSlice = createSlice({
    name: "allGeneratedAlertsSlice",
    initialState: initialAllGeneratedAlertsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllGeneratedAlertsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllGeneratedAlertsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allGeneratedAlerts = payload.content;
            })
            .addCase(fetchAllGeneratedAlertsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********
