import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    BlockPageState,
    fetchBlockPage,
    fetchGetUserAnalysisPage,
    fetchGetUserCommentsPage,
    fetchGetUserPage,
    fetchGetUserPublicationsPage,
    fetchModifyUserPasswordPage,
    fetchUnBlockPage,
    GetUserAnalysisPageState,
    GetUserCommentsPageState,
    GetUserPageState,
    GetUserPublicationsPageState,
    UnBlockPageState
} from "./user-details-reducer";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

const UserDetails = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    let id: any;
    ({id} = useParams());

    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(-1);
    const [showBlockConfirm, setShowBlockConfirm] = useState(false);
    const [showPasswordModify, setShowPasswordModify] = useState(false);
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/;

    const {user, isLoading} = useSelector<AppState, GetUserPageState>(state => state.userInfos);
    const {publications} = useSelector<AppState, GetUserPublicationsPageState>(state => state.userDetailsPublications);
    const {comments} = useSelector<AppState, GetUserCommentsPageState>(state => state.userDetailsComments);
    const {analysis} = useSelector<AppState, GetUserAnalysisPageState>(state => state.userDetailsAnalysis);
    const block = useSelector<AppState, BlockPageState>(state => state.blockUser);
    const unblock = useSelector<AppState, UnBlockPageState>(state => state.unBlockUser);

    useEffect(() => {
        dispatch(fetchGetUserPage({userId: id}));
        dispatch(fetchGetUserPublicationsPage({userId: id, page: 0}));
        dispatch(fetchGetUserCommentsPage({userId: id, page: 0}));
        dispatch(fetchGetUserAnalysisPage({userId: id, page: 0}));
    }, []);

    useEffect(() => {
        if (loading && !isLoading && user.roles) {
            setLoading(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (!block.isLoading) {
            dispatch(fetchGetUserPage({userId: id}));
        }
    }, [block.isLoading]);

    useEffect(() => {
        if (!unblock.isLoading) {
            dispatch(fetchGetUserPage({userId: id}));
        }
    }, [unblock.isLoading]);

    const handleBlockButton = () => {
        if (user.roles.length) {
            dispatch(fetchBlockPage({userId: id}));
        } else {
            dispatch(fetchUnBlockPage({userId: id}));
        }
        setShowBlockConfirm(false);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const onConfirmChange = (event) => {
        setConfirm(event.target.value);
    };

    const handleModifyPassword = () => {
        if (confirm == password && regex.test(password)) {
            dispatch(fetchModifyUserPasswordPage({userId: id, password: password}));
            setShowPasswordModify(false);
            setPassword("");
            setConfirm("");
        }
    };

    if (loading)
        return (
            <div className="start-page">
                <header className="page-header page-header-dark">
                    <h1>Loading, please wait...</h1>
                </header>
            </div>
        );

    return <div className="start-page">
        <div className="page-header page-header-dark row">
            <button className="btn btn-danger ml-3 mr-4 mb-3" onClick={() => history.goBack()}>
                Retour
            </button>
            <h1>Moderation de {user.nickname}</h1>
        </div>
        <div className="card m-1">
            <div className="card-header h5">
                Utilisateur {
                user.userSpecialAttribut.expert &&
                <span className={"badge badge-primary"}>expert</span>
            } {
                user.userSpecialAttribut.semiExpert &&
                <span className={"badge badge-primary"}>semi-expert</span>
            }
            </div>
            <div className="card-body">
                <div className="mb-4">
                    Identité
                    : {user.civility}. {user.firstname} “{user.nickname}“ {user.lastname}
                </div>
                <div className="mb-4">
                    Pays : {user.country}
                </div>
                <div className="mb-4">
                    Email : {user.email}
                </div>
                <div className="mb-4">
                    Numéro : {user.phoneNumber}
                </div>
                <div className="mb-4">
                    Role(s) : {user.roles.map(role => role.name).join(', ')}
                </div>
                <div className="mb-4">
                    Titre
                    : {user.userMembershipAttribut.level} {
                    user.userMembershipAttribut.level === "PRO" && "jusqu'au " +
                    moment(user.userMembershipAttribut.expirationDate).format("DD/MM/YY HH:mm:ss")}
                </div>
            </div>
        </div>
        <div className="card m-1">
            <div className="card-header">Actions</div>
            <div className="card-body row m-0">
                <button className={"btn btn-" + (user.roles.length ? "danger" : "success") + " mr-2"}
                        onClick={() => setShowBlockConfirm(true)}>
                    {user.roles.length ? "Bloquer" : "Debloquer"} l'utilisateur
                </button>
                <button className="btn btn-primary" onClick={() => setShowPasswordModify(true)}>
                    Modifier le mot de passe
                </button>
            </div>
        </div>
        <div className="card m-1">
            <div className="card-header row m-0">
                <button className={"btn btn-primary mr-2"}
                        onClick={() => setTab(0)} disabled={tab == 0}>
                    Publications
                </button>
                <button className={"btn btn-primary mr-2"}
                        onClick={() => setTab(1)} disabled={tab == 1}>
                    Commentaires
                </button>
                <button className={"btn btn-primary"}
                        onClick={() => setTab(2)} disabled={tab == 2}>
                    Analyses
                </button>
            </div>
            {
                tab == 0 &&
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Type de publication</th>
                        <th>Feed</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        publications && publications.content && publications.content.map((publication, index) =>
                            <tr key={index}>
                                <td>{publication.id}</td>
                                <td>{moment(publication.publishedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>{publication.message}</td>
                                <td>{publication.type}</td>
                                <td>{publication.sportType}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            }
            {
                tab == 1 &&
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Commentaire</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        comments && comments.content && comments.content.map((comment, index) =>
                            <tr key={index}>
                                <td>{comment.id}</td>
                                <td>{moment(comment.publishedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>{comment.comment}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            }
            {
                tab == 2 &&
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Analyse</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        comments && comments.content && analysis.content.map((analysis, index) =>
                            <tr key={index}>
                                <td>{analysis.id}</td>
                                <td>{moment(analysis.publishedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>{analysis.analysis}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            }
        </div>
        {
            showBlockConfirm &&
            <Modal size="lg" show={showBlockConfirm} onHide={() => setShowBlockConfirm(false)}>
                <Modal.Header closeButton className="alert alert-danger">
                    <Modal.Title>Mise a jour de l'utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirmez-vous le {user.roles.length ? "blocage" : "deblocage"} de {user.nickname} ?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleBlockButton}>
                        Confirmer la modification
                    </button>
                </Modal.Footer>
            </Modal>
        }
        {
            showPasswordModify &&
            <Modal size="lg" show={showPasswordModify} onHide={() => setShowPasswordModify(false)}>
                <Modal.Header closeButton className="alert alert-danger">
                    <Modal.Title>Modification de mot de passe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-danger">
                        Ne modifiez pas de mot de passe sans l'autorisation explicite de l'utilisateur.
                    </div>
                    <div className="m-1">
                        Nouveau mot de passe : <input type="password" onChange={onPasswordChange}/>
                    </div>
                    <div className="m-1">
                        Confirmez nouveau mot de passe : <input type="password" onChange={onConfirmChange}/>
                    </div>
                    <div className="m-1">
                    <span className={"badge badge-" + (regex.test(password) ? "success" : "danger")}>
                        validite
                    </span> <span className={"badge badge-" + (password == confirm ? "success" : "danger")}>
                        correspondance
                    </span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleModifyPassword}
                            disabled={confirm != password || !regex.test(password)}>
                        Confirmer la modification
                    </button>
                </Modal.Footer>
            </Modal>
        }
    </div>
};

export default UserDetails;