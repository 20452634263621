import React, {useEffect, useState} from 'react';
import './rankinghistory.scss';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    fetchChallengeRankPage,
    fetchRankWeeklyHistoryPage,
    RankChallPageState,
    RankHistoryPageState
} from "./ranking-reducer";
import {AllChallengesPageState, fetchAllChallengesPage} from "../challenge/all-challenges/all-challenges-reducer";
import {RankingItemV2} from "../../typings/api";

const RankingHistory = () => {
    const dispatch = useDispatch();
    const [selectedWeek, setSelectedWeek] = useState<RankingItemV2[]>();

    const {rank} = useSelector<AppState, RankHistoryPageState>(state => state.rankingHistory)
    const {chall} = useSelector<AppState, RankChallPageState>(state => state.challHistory)
    const {allChallenges} = useSelector<AppState, AllChallengesPageState>(state => state.allChallenges);

    useEffect(() => {
        dispatch(fetchRankWeeklyHistoryPage({rankingType: "ROI", challengeId: null}));
        dispatch(fetchAllChallengesPage({page: 0}));
    }, []);

    useEffect(() => {
    }, [selectedWeek]);

    useEffect(() => {
        if (rank.length > 0) handleRankingWeek(rank[1].id);
    }, [rank]);

    useEffect(() => {
        if (allChallenges.content && allChallenges.content.length > 0) handleSelectedChall(allChallenges.content[0].id);
    }, [allChallenges]);


    const handleRankingWeek = (id) => {
        if (rank) {
            const semaine = rank.filter((sem) => sem.id == id)[0].ranking.items;
            setSelectedWeek(semaine)
        }
    }

    const handleSelectedChall = (nbChall) => {
        dispatch(fetchChallengeRankPage({rankingType: "ODD", challengeId: nbChall}));
    }


    return (
        <div className={"rankingHContainer"}>
            <div className={"rankingWeek"}>
                <h5>Classement Hebdomadaire</h5>
                <select onChange={(e) => {
                    handleRankingWeek(e.target.value)
                }}>
                    {rank && rank.map((semaine, index) => {
                        if (index != 0) {
                            return (
                                <option key={index}
                                        value={semaine.id}>{semaine.createdAt.toString().slice(0, 10)}</option>
                            )
                        }
                    })}
                </select>
                <div className={"rankingWeekCont"}>
                    {selectedWeek && selectedWeek.map((rank, index) => {
                        return (
                            <div className={"ranking_row"}>
                                <h5>{index + 1}</h5>
                                <p key={rank.userId}>{rank.nickname}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={"border"}></div>
            <div className={"rankingChall"}>
                <h5>Challenges</h5>
                <select onChange={(e) => {
                    handleSelectedChall(parseInt(e.target.value))
                }}>
                    {allChallenges.content && allChallenges.content.map((chal, index) => {
                        return (
                            <option key={index} value={chal.id}>{chal.title}</option>
                        )
                    })}
                </select>
                <div className={"rankingChallCont"}>
                    {chall && chall.map((chl) => {
                        return chl.ranking.items.map((ranki, index) => {
                            return (
                                <div className={ranki.valid ? "valid" : "not-valid"}>
                                    <h5>{index + 1}</h5>
                                    <p key={ranki.userId}>{ranki.nickname}</p>
                                    {ranki.valid ? <i className="fas fa-check"/> : <i className="fas fa-crosshairs"/>}
                                </div>
                            )
                        })
                    })}
                </div>

            </div>

        </div>
    );
};

export default RankingHistory;
