import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const pageOfCompetitionsActions = generateApiActions("bo_get_page_competition");

export const actionGetAllCompetitions = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/competitions/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfCompetitionsActions.all())
        .build();

export const updateCompetitionActions = generateApiActions("bo_update_competition");

export const actionUpdateCompetition = (updateCompetitionRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/competitions/")
        .withBody(updateCompetitionRequest)
        .verb(HTTP_VERBS.POST)
        .types(updateCompetitionActions.all())
        .build();


export const searchCompetitionByNameActions = generateApiActions("bo_search_competition_by_name");

export const actionSearchCompetitionByName = (name: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/competitions/search/${name}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchCompetitionByNameActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...pageOfCompetitionsActions.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...searchCompetitionByNameActions.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    {
        [combineActions(...updateCompetitionActions.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

