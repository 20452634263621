import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Table} from "react-bootstrap";
import Pagination from "../../../uicomponents/pagination/pagination";
import ScoreV2 from "../../../components/score/ScoreV2";
import {formatPrecisionMin} from "../../../services/moment/momentUtil";
import {AppState} from "../../../store/reducers";
import {AllMatchsPageState, fetchAllMatchsPage, fetchUpdateMatchPage} from "./match-reducer";

const AllMatchs = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [result, setResult] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [matchOnEdition, setMatchOnEdition] = useState<any>({});
    const {allMatchs} = useSelector<AppState, AllMatchsPageState>(state => state.allMatch);

    useEffect(() => {
        dispatch(fetchAllMatchsPage({page: 0}));
    }, []);

    const updateMatchResult = (matchId) => {
        setShowModal(false);
        const request = {
            cancel: isCancel,
            prediction: matchOnEdition.id,
            result: result,
        };
        dispatch(fetchUpdateMatchPage({updateMatchRequest: request}));
        // TODO : améliorer mis dans un timer pour attendre que le back se rafraichissent
        setIsCancel(false);
        setTimeout(() => { //Start the timer
            dispatch(fetchAllMatchsPage({page: 0}));
        }, 1000);
    };

    const handleIsCancel = (event) => {
        setIsCancel(event.target.checked);
    }

    const handleInputTextChange = (result) => {
        setResult(result);
    };

    const handleClickEdition = (match, period) => {
        setMatchOnEdition(match);
        setResult(false);
        setShowModal(true);
    };

    const paginationChange = (event, pageClicked) => {
        dispatch(fetchAllMatchsPage({page: pageClicked}));
    };

    const renderModal = () => {
        return (
            <Modal size="lg" show={showModal}
                   onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edition du match
                        #{matchOnEdition.betDescription.match.name + " | " + formatPrecisionMin(matchOnEdition.betDescription.match.dateTime)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        < ScoreV2 handleInputTextChange={(event) => handleInputTextChange(event)}
                                  matchOnEdition={matchOnEdition}
                                  handleIsCancel={(event) => handleIsCancel(event)}/>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => updateMatchResult(matchOnEdition.id)}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="start-page">
            {matchOnEdition && matchOnEdition.betDescription && matchOnEdition.betDescription.match && renderModal()}

            <h1 className="h3 mb-2 text-gray-800">Matchs</h1>

            <div className="alert alert-info">
                Pour l'instant on gère la mise à jour du résultat pour le Football, Basketball et rugby pour (V/N/D
                ; V/N/D -
                HT ; V/N/D - 2HT)
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {allMatchs && allMatchs.content &&
                        <div>
                            <Pagination page={allMatchs} action={paginationChange}/>
                            {"Il y a " + allMatchs.content.length + " pronostic(s) en attente de resultat."}
                        </div>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>L'heure</th>
                        <th>Match</th>
                        <th>Compétition</th>
                        <th>Type</th>
                        <th>Resultat</th>
                        <th><i className="fas fa-cog"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allMatchs && allMatchs.content && allMatchs.content.map((item, index) =>
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.betDescription.match.dateTime}</td>
                            <td>{item.betDescription.match.name}</td>
                            <td>{item.betDescription.event.name.items[0].trad}</td>
                            <td>{item.betDescription.bet.oddType}</td>
                            <td>{item.resultType}</td>
                            <td>
                                <Button variant="primary"
                                        onClick={() => handleClickEdition(item, item.betDescription.bet.oddType)}>
                                    <i className="fas fa-pencil-alt"/>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}


export default AllMatchs;
