import React from "react";
import {Button, Modal} from "react-bootstrap";

interface ModalProps {
    title: string,
    showModal: boolean,

    cancelAction(): void,

    saveAction(): void
}

export default class PopinDelete extends React.Component<ModalProps> {

    render() {
        const {title, showModal, cancelAction, saveAction} = this.props;

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={showModal} onHide={() => cancelAction()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-md-center">
                        <div className="col-md-2">
                            <Button variant="secondary" onClick={(e) => cancelAction()}>
                                Annuler
                            </Button>
                        </div>
                        <div>
                            <Button variant="primary" onClick={(e) => saveAction()}>
                                Supprimer
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}