import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const getBookmakersActionsV2 = generateApiActions("bo_get_bookmaker_v2");

export const actionGetAllBookmakersV2 = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint('/bo/bookmaker/referentials/')
        .verb(HTTP_VERBS.GET)
        .types(getBookmakersActionsV2.all())
        .build();

export const updateBookmakerActionsV2 = generateApiActions("bo_update_bookmaker_v2");

export const actionUpdateBookmakerV2 = (updateBookmakerRequest) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/bo/bookmaker")
        .withBody(updateBookmakerRequest)
        .verb(HTTP_VERBS.POST)
        .types(updateBookmakerActionsV2.all())
        .build();


const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getBookmakersActionsV2.all())]: {
            next: (state, {payload}) => (payload ? {bookmakers: payload.content} : {...state}),
            throw: () => defaultState,
        },


        [combineActions(...updateBookmakerActionsV2.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

