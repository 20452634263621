import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {PredictionItem} from "../../../typings/api";
import Pagination from "../../../uicomponents/pagination/pagination";
import {
    AllMatchesWithResultPageState,
    fetchAllMatchesWithResultPage,
    fetchUpdateMatchResultPage,
    UpdateMatchResultPageState
} from "./modify-result-reducer";
import moment from "moment";

const ModifyResult = () => {

    const dispatch = useDispatch();

    const [showModify, setShowModify] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [currentMatch, setCurrentMatch] = useState<PredictionItem>({} as PredictionItem);
    const {allMatches} = useSelector<AppState, AllMatchesWithResultPageState>(state => state.allMatchesWithResult);
    const {isLoading} = useSelector<AppState, UpdateMatchResultPageState>(state => state.updateMatchResult);

    useEffect(() => {
        dispatch(fetchAllMatchesWithResultPage({search: "", page: 0}));
    }, []);

    useEffect(() => {
        if (!isLoading)
            dispatch(fetchAllMatchesWithResultPage({search: search, page: currentPage}));
    }, [isLoading]);

    const handleSearchTextChange = text => {
        setSearch(text.target.value);
        dispatch(fetchAllMatchesWithResultPage({search: text.target.value, page: currentPage}));
    };

    const paginationChange = (event, page) => {
        setCurrentPage(page);
        dispatch(fetchAllMatchesWithResultPage({search: search, page: page}));
    };

    const handleClickEdition = match => {
        setCurrentMatch(match);
        setIsCancel(match.betDescription.betChoice.odd === 1 && match.resultType === 'WIN');
        setShowModify(true);
    };

    const handleEditClick = () => {
        dispatch(fetchUpdateMatchResultPage({
            request: {
                cancel: isCancel,
                prediction: currentMatch.id,
                result: currentMatch.resultType === 'WIN'
            }
        }));
        setCurrentMatch({} as PredictionItem);
        setShowModify(false);
    };

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Correction de résultats</h1>
        </header>
        <div className="mb-2">
            <input
                className="form-control"
                type="search"
                placeholder="Rechercher"
                aria-label="Rechercher"
                onChange={e => handleSearchTextChange(e)}
            />
        </div>
        <div className="card">
            <div className="card-header">
                <Pagination page={allMatches} action={paginationChange}/>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Competition</th>
                    <th>Match</th>
                    <th>Heure</th>
                    <th>Prediction</th>
                    <th>Resultat</th>
                    <th>Modifier</th>
                </tr>
                </thead>
                <tbody>
                {
                    allMatches && allMatches.content && allMatches.content.map((match, index) =>
                        <tr key={index}>
                            <td>{match.id}</td>
                            <td>{match.betDescription.event.name.items[0].trad}</td>
                            <td>{match.betDescription.match.name}</td>
                            <td>{moment(match.betDescription.match.dateTime).format("DD/MM/YY HH:mm")}</td>
                            <td>{match.betDescription.betChoice.name.items[0].trad}</td>
                            <td>{match.betDescription.betChoice.odd === 1 && match.resultType === 'WIN' ? 'ABAN' : match.resultType}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => handleClickEdition(match)}
                                        disabled={match.betDescription.betChoice.odd === 1 && match.resultType === 'WIN'}>
                                    <i className="fas fa-cog"/>
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        {
            showModify &&
            <Modal show={showModify} onHide={() => setShowModify(false)}>
                <Modal.Header closeButton className="alert alert-primary">
                    <Modal.Title>Modification d'un résultat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            {currentMatch.betDescription.event.name.items[0].trad + " | "
                                + currentMatch.betDescription.match.name}
                        </div>
                        <div className="card-header">
                            {currentMatch.betDescription.bet.name.items[0].trad + " | "
                                + currentMatch.betDescription.betChoice.name.items[0].trad}
                        </div>
                        <BootstrapSwitchButton checked={currentMatch.resultType === 'WIN'} onlabel='WIN'
                                               onstyle='success' offlabel='LOSE' offstyle='danger' style='w-100'
                                               onChange={event => setCurrentMatch({
                                                   ...currentMatch,
                                                   resultType: event ? 'WIN' : 'LOSE'
                                               })}/>
                        <div className="card-footer">
                            <input className="custom-checkbox m-3" type="checkbox" checked={isCancel}
                                   onChange={event => setIsCancel(event.target.checked)}/>Abandon
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleEditClick()}>
                        Confirmer la modification
                    </button>
                </Modal.Footer>
            </Modal>
        }
    </div>;
};

export default ModifyResult;
