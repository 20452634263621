import {push} from "connected-react-router";

import {authenticateActions} from "../store/reducers/authentication";

const AUTHENTICATED_USER_STORAGE_KEY = "sobet-authenticated-user";

export const authenticationMiddleware = store => next => action => {
    const result = next(action);
    const {
        authUser: {jwt},
    } = store.getState();
    const {type, payload} = action;
    if (type.startsWith("REQUEST_") && !jwt) {
        localStorage.removeItem(AUTHENTICATED_USER_STORAGE_KEY);
        store.dispatch(push("/login"));
    }
    if (type.startsWith("FAILURE_") && payload) {
        const {status} = action.payload;
        if (status === 401) {
            localStorage.removeItem(AUTHENTICATED_USER_STORAGE_KEY);
            store.dispatch(push("/login"));
        }
    }

    if (type === authenticateActions.SUCCESS) {
        const {content} = payload;
        if (content && content.jwt) {
            localStorage.setItem(AUTHENTICATED_USER_STORAGE_KEY, JSON.stringify(content));
            store.dispatch(push("/"));
        } else {
            throw new Error("Authentification réussi mais aucun token JWT trouvé.");
        }
    }
    return result;
};

export default authenticationMiddleware;
