import {ApiResponse, SupportMessage} from "../../../typings/api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Loadable} from "../../../typings/store";
import {ApiBuilder, HTTP_VERBS, ThunkApiArgs} from "../../../services/fetch-api";

type GetSupportMessageApiReturnType = ApiResponse<SupportMessage>
type FetchGetSupportMessagePageArgs = {
    id: number;
};

export interface GetSupportMessagePageState extends Loadable {
    message: SupportMessage;
}

const initialGetSupportMessageState: GetSupportMessagePageState = {
    message: {} as SupportMessage,
    isLoading: false,
}

export const fetchGetSupportMessagePage = createAsyncThunk<GetSupportMessageApiReturnType,
    FetchGetSupportMessagePageArgs,
    ThunkApiArgs<GetSupportMessageApiReturnType>>("GetSupportMessagePage/fetch", async ({id}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/contact/${id}`)
        .verb(HTTP_VERBS.GET)
        .build()
)

export const getSupportMessagePageSlice = createSlice({
    name: "getSupportMessageSlice",
    initialState: initialGetSupportMessageState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGetSupportMessagePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGetSupportMessagePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.message = payload.content;
            })
            .addCase(fetchGetSupportMessagePage.rejected, state => {
                state.isLoading = false;
            })
    }
})