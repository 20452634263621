import {createBrowserHistory} from "history";

import configureStore from "./configure-store";

const basename = "/";
export const history = createBrowserHistory({basename});

const store = configureStore({}, history);

export type AppDispatch = typeof store.dispatch;

export default store;
