import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getOddTypes = generateApiActions("v2_get_all_odd_types");

export const actionGetOddTypes = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/referentials/odd-types")
        .verb(HTTP_VERBS.GET)
        .types(getOddTypes.all())
        .build();


const defaultState = {}


const reducerOddTypes = handleActions(
    {
        [combineActions(...getOddTypes.all())]: {
            next: (state, {payload}) => (payload ? {oddTypes: payload.content} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducerOddTypes;