import React from 'react';
import {Button, Modal} from "react-bootstrap";

interface ModalProps {
    title: string,
    showModal: boolean,

    cancelAction(): void,

    saveAction(): void
}

export default class Popin extends React.Component<ModalProps> {

    render() {
        const {title, showModal, cancelAction, saveAction} = this.props;

        return (
            <Modal size="lg" show={showModal} onHide={() => cancelAction()}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => cancelAction()}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={(e) => saveAction()}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}