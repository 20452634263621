import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, Event, LinkBetweenBookAndSobetModel, Match} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type UnlinkSobetModelApiReturnType = ApiResponse<LinkBetweenBookAndSobetModel[]>;
type unlinkSobetModelPageArgs = { modelPronoType: string };

export interface UnlinkSobetPageState extends Loadable {
    unlinkSobetObject: LinkBetweenBookAndSobetModel[];
}

const initialUnlinkSobetState: UnlinkSobetPageState = {
    unlinkSobetObject: [] as LinkBetweenBookAndSobetModel[],
    isLoading: false,
};

export const fetchUnLinkSobetModelPage = createAsyncThunk<UnlinkSobetModelApiReturnType,
    unlinkSobetModelPageArgs,
    ThunkApiArgs<UnlinkSobetModelApiReturnType>>("UnlinkSobetPage/fetch", async ({modelPronoType}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/link-book-to-sobet/unlink-with-sobet`)
        .withQueryParams({modelPronoType})
        .verb(HTTP_VERBS.GET)
        .build()
);

export const unlinkSobetPageSlice = createSlice({
    name: "unlinkSobetSlice",
    initialState: initialUnlinkSobetState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUnLinkSobetModelPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUnLinkSobetModelPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.unlinkSobetObject = payload.content;
            })
            .addCase(fetchUnLinkSobetModelPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//**********

type MatchToLinkWithSobetApiReturnType = ApiResponse<Match>;
type MatchToLinkWithSobetPageArgs = { sobetRef: string, linkBetweenBookAndSobetModelId: number };

export interface MatchToLinkWithSobetPageState extends Loadable {
    match: Match;
    isFound: boolean;
}

const initialMatchToLinkWithSobetState: MatchToLinkWithSobetPageState = {
    match: {} as Match,
    isFound: false,
    isLoading: false,
};

export const fetchMatchToLinkWithSobetPage = createAsyncThunk<MatchToLinkWithSobetApiReturnType,
    MatchToLinkWithSobetPageArgs,
    ThunkApiArgs<MatchToLinkWithSobetApiReturnType>>("MatchToLinkWithSobetPage/fetch", async ({
                                                                                                  sobetRef,
                                                                                                  linkBetweenBookAndSobetModelId
                                                                                              }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/link-book-to-sobet/match-to-link-with-sobet`)
        .withQueryParams({sobetRef, linkBetweenBookAndSobetModelId})
        .verb(HTTP_VERBS.POST)
        .build()
);

export const matchToLinkWithSobetPageSlice = createSlice({
    name: "matchToLinkWithSobetSlice",
    initialState: initialMatchToLinkWithSobetState,
    reducers: {
        setIsFound: (state, action) => {
            state.isFound = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchMatchToLinkWithSobetPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchMatchToLinkWithSobetPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.match = payload.content;
                state.isFound = true;
            })
            .addCase(fetchMatchToLinkWithSobetPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllEventsBySportApiReturnType = ApiResponse<Event[]>;
type FetchAllEventsBySportPageArgs = { sportId: number };

export interface AllEventsBySportPageState extends Loadable {
    allEventsBySport: Event[];
}

const initialAllEventsBySportState: AllEventsBySportPageState = {
    allEventsBySport: [] as Event[],
    isLoading: false,
};

export const fetchAllEventsBySportPage = createAsyncThunk<AllEventsBySportApiReturnType,
    FetchAllEventsBySportPageArgs,
    ThunkApiArgs<AllEventsBySportApiReturnType>>("AllEventsBySportPage/fetch", async ({sportId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/event/sport/${sportId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allEventsBySportPageSlice = createSlice({
    name: "allEventsBySportSlice",
    initialState: initialAllEventsBySportState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllEventsBySportPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllEventsBySportPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allEventsBySport = payload.content;
            })
            .addCase(fetchAllEventsBySportPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllMatchByEventApiReturnType = ApiResponse<Match[]>;
type AllMatchByEventPageArgs = { eventId: number };

export interface AllMatchByEventPageState extends Loadable {
    allMatchByEvent: Match[];
}

const initialAllMatchByEventState: AllMatchByEventPageState = {
    allMatchByEvent: [] as Match[],
    isLoading: false,
};

export const fetchAllMatchByEventPage = createAsyncThunk<AllMatchByEventApiReturnType,
    AllMatchByEventPageArgs,
    ThunkApiArgs<AllMatchByEventApiReturnType>>("AllMatchByEventPage/fetch", async ({eventId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/pronos/matchs/${eventId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allMatchByEventPageSlice = createSlice({
    name: "allMatchByEventSlice",
    initialState: initialAllMatchByEventState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllMatchByEventPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatchByEventPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMatchByEvent = payload.content;
            })
            .addCase(fetchAllMatchByEventPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//**********

type DeleteMatchToLinkWithSobetApiReturnType = ApiResponse<Match>;
type DeleteMatchToLinkWithSobetPageArgs = { linkBetweenBookAndSobetModelId: number };

export const deleteMatchToLinkWithSobetPage = createAsyncThunk<DeleteMatchToLinkWithSobetApiReturnType,
    DeleteMatchToLinkWithSobetPageArgs,
    ThunkApiArgs<DeleteMatchToLinkWithSobetApiReturnType>>("MatchToLinkWithSobetPage/fetch", async ({linkBetweenBookAndSobetModelId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/link-book-to-sobet/delete-match-to-link-with-sobet`)
        .withQueryParams({linkBetweenBookAndSobetModelId})
        .verb(HTTP_VERBS.DELETE)
        .build()
);