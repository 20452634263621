import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum MessageToFeedActionType {
    RESET = "MSG2FEED_RESET"
}


export const allPublicationsActions = generateApiActions("bo_get_publication_message");

export const actionGetAllPublications = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/publications/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(allPublicationsActions.all())
        .build();

export const searchPublicationActions = generateApiActions("bo_search_publication_by_expression");

export const actionSearchPublicationByExpression = (expression: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/search-publications/${expression}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchPublicationActions.all())
        .build();


export const deletePublicationActions = generateApiActions("bo_delete_publication");

export const actionDeletePublicationByExpression = (userId: number, publication: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/publication/${userId}/${publication}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deletePublicationActions.all())
        .build();

export const sendMessageToFeed = generateApiActions("bo_send_message_to_feed");

export const actionSendMessageToFeed = (adminId: number, channel: string, userExpert: string, message: string, documentUrl: string) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/message/publication/${adminId}?channel=${channel}&userExpert=${userExpert}&message=${message}&documentUrl=${documentUrl}`)
        .verb(HTTP_VERBS.POST)
        .types(sendMessageToFeed.all())
        .build();

export const actionReset = createAction(MessageToFeedActionType.RESET, () => {

});
const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...allPublicationsActions.all())]: {
            next: (state, {payload}) => (payload ? {publications: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...searchPublicationActions.all())]: {
            next: (state, {payload}) => (payload ? {publications: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...sendMessageToFeed.all())]: {
            next: (state, {payload}) => {
                return {...state, messageIsSend: payload ? true : false};
            },
            throw: () => (state, {payload}) => (payload ? {errors: payload.errors} : defaultState)
        },
        [combineActions(MessageToFeedActionType.RESET)]: {
            next: (state) => ({...state, errors: [], messageIsSend: false}),
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducer;