import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import CardInfo from "../../uicomponents/cardInfo/cardInfo";
import {BasicMetricsReportPageState, fetchBasicMetricsReportPage} from "./dashboard-reducer";
import {AppState} from "../../store/reducers";

const Dashboard = () => {

    const dispatch = useDispatch();
    const {basicMetricsReport} = useSelector<AppState, BasicMetricsReportPageState>(state => state.basicMetricsReport);

    useEffect(() => {
        dispatch(fetchBasicMetricsReportPage({}));
    }, []);

    const renderOneLine = (data) => (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h5 mb-0 text-gray-800">{data.title}</h1>
            </div>
            <div className="row">
                {data.elements.map((element, index) =>
                    <div className="col-xl-3 col-md-4 mb-4">
                        <CardInfo title={element.name}
                                  value={element.value}
                                  iconName={element.iconName}/>
                    </div>
                )}
            </div>
        </>
    )

    if (basicMetricsReport) {
        return (
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>

                {renderOneLine({
                    title: 'Utilisateurs',
                    elements: [
                        {
                            name: 'Nombre d\'utilisateurs créés',
                            value: basicMetricsReport.nbUsers,
                            iconName: "fa-user"
                        },
                        {
                            name: 'Nombre d\'utilisateurs créés depuis 1 jour',
                            value: basicMetricsReport.nbUsersSinceYesterday,
                            iconName: "fa-user"
                        },
                        {
                            name: 'Nombre d\'utilisateurs créés depuis 7 jours',
                            value: basicMetricsReport.nbUsersSinceOneWeek,
                            iconName: "fa-user"
                        },
                        {
                            name: 'Nombre d\'utilisateurs créés depuis 30 jours',
                            value: basicMetricsReport.nbUsersSinceOneMonth,
                            iconName: "fa-user"
                        },
                        {
                            name: 'Nombre d\'utilisateurs reportés',
                            value: basicMetricsReport.nbReportedUsers,
                            iconName: "fa-user-times"
                        },
                    ]
                })}

                {basicMetricsReport.membershipBasicMetricsReportResponse && renderOneLine({
                    title: 'Membership',
                    elements: [
                        {
                            name: 'Nombre de membres en semi-pro',
                            value: basicMetricsReport.membershipBasicMetricsReportResponse.nbSemiPro,
                            iconName: "fas fa-users"
                        },
                        {
                            name: 'Nombre de membres en pro',
                            value: basicMetricsReport.membershipBasicMetricsReportResponse.nbPro,
                            iconName: "fas fa-users"
                        },
                    ]
                })}

                {renderOneLine({
                    title: 'Réseaux sociaux',
                    elements: [
                        {
                            name: 'Nombre de publications',
                            value: basicMetricsReport.nbPublications,
                            iconName: "fa-user"
                        },
                        {
                            name: 'Nombre de commentaires',
                            value: basicMetricsReport.nbPublicationComments,
                            iconName: "fa-comments"
                        },
                        {
                            name: 'Nombre de likes sur les publications',
                            value: basicMetricsReport.nbPublicationLikes,
                            iconName: "fa-heart"
                        },
                        {
                            name: 'Nombre de likes sur les commentaires',
                            value: basicMetricsReport.nbPublicationCommentLikes,
                            iconName: "fa-thumbs-up"
                        },
                    ]
                })}

                {basicMetricsReport.teamBasicMetricsReportResponse && renderOneLine({
                    title: 'Groupes',
                    elements: [
                        {
                            name: 'Nombre de groupes',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeams,
                            iconName: "fas fa-users"
                        },
                        {
                            name: 'Nombre de groupes publics',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeamsPublic,
                            iconName: "fas fa-users"
                        },
                        {
                            name: 'Nombre de groupes privés',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeamsPrivate,
                            iconName: "fas fa-user-lock"
                        },
                        {
                            name: 'Nombre de groupes secrets',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeamsSecret,
                            iconName: "fas fa-user-secret"
                        },
                        {
                            name: 'Nombre publications dans les groupes',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeamsPublications,
                            iconName: "fa-comment"
                        },
                        {
                            name: 'Nombre d\'utilisateur dans au moins un groupe',
                            value: basicMetricsReport.teamBasicMetricsReportResponse.nbTeamsUsers,
                            iconName: "fa-user"
                        },
                    ]
                })}

                {renderOneLine({
                    title: 'Pronostics',
                    elements: [
                        {
                            name: 'Nombre de paris totals',
                            value: basicMetricsReport.nbTotalsProno,
                            iconName: "fas fa-users"
                        },
                        {
                            name: 'Nombre de paris en cours',
                            value: basicMetricsReport.nbPendingProno,
                            iconName: "fas fa-users"
                        },
                    ]
                })}

            </div>
        );
    } else {
        return (<></>);
    }
}

export default Dashboard;
