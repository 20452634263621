import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllDeviceInformation = generateApiActions("bo_get_all_device_information");

export const actionGetAllDeviceInformation = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/last-device-information/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllDeviceInformation.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getAllDeviceInformation.all())]: {
            next: (state, {payload}) => (payload ? {pageDeviceInformation: payload.content.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;