import React from "react";
import {EnvBadge} from "../envBadge/envBadge";
import {Button} from "react-bootstrap";
import {compose} from "redux";
import {connect} from "react-redux";
import {actionDisconnect} from "../../store/reducers/authentication";

interface NavProps {
    user: any,

    disconnect(): void
}

class Nav extends React.Component<NavProps, any> {

    deconnect = () => {
        this.props.disconnect();
        if (this.context.router) {
            this.context.router.history.push('/');
            window.location.reload();
        }
    }

    render() {
        const {user} = this.props;
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <ul className="navbar-nav ml-auto">


                    <div className="topbar-divider d-none d-sm-block"></div>
                    {user &&
                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span
                                    className="mr-2 d-none d-lg-inline text-gray-600 small">{user.firstname + ' ' + user.lastname}</span>
                                <img className="img-profile rounded-circle"
                                     src={user.profilPhotoUrl} alt=""/>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                 aria-labelledby="userDropdown">
                                <Button className="dropdown-item" onClick={this.deconnect}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Déconnexion
                                </Button>
                            </div>
                        </li>
                    }

                    <li className="nav-item dropdown no-arrow">
                        <h1 className="h4 nav-link"><EnvBadge/></h1>
                    </li>

                </ul>

            </nav>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    disconnect: actionDisconnect,
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Nav);