import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getMatchs = generateApiActions("v2_get_all_match_in_event");

export const actionGetMatchs = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/bo/matchs/")
        .verb(HTTP_VERBS.GET)
        .types(getMatchs.all())
        .build();

const defaultState = {}

const reducerMatchs = handleActions(
    {
        [combineActions(...getMatchs.all())]: {
            next: (state, {payload}) => (payload ? {matchs: payload.content.content} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducerMatchs;