import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getSports = generateApiActions("pronos_get_all_sports");

export const actionGetSports = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/pronos/sports")
        .verb(HTTP_VERBS.GET)
        .types(getSports.all())
        .build();


export const createChallenge = generateApiActions("create_post_challenge");

export const actionCreateChallenge = (challenge) => {
    return RsaaBuilder.getInstance()
        .apiEndpoint("/webapp/challenge/")
        .withBody(challenge)
        .verb(HTTP_VERBS.POST)
        .types(createChallenge.all())
        .build();
}

export const uploadImageChallengeActions = generateApiActions("upload_image_challenge");

export const actionUploadImageChallenge = (data) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .types(uploadImageChallengeActions.all())
        .build(true);

export const uploadImageChallengeAdActions = generateApiActions("upload_image_challenge_ad");

export const actionUploadImageChallengeAd = (data) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .types(uploadImageChallengeAdActions.all())
        .build(true);


const defaultState = {err: "error"}

const reducerChallenge = handleActions(
    {
        [combineActions(...getSports.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },

        [combineActions(...createChallenge.all())]: {
            next: (state, {payload}) => (payload ? {res: payload.content, isCreated: true} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducerChallenge;

export const reducerImageForChallenge = handleActions(
    {
        [combineActions(uploadImageChallengeActions.FAILURE, uploadImageChallengeActions.SUCCESS)]: {
            next: (state, {payload}) => (payload ? {challengeImageUrl: payload.content, isLoading: false} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(uploadImageChallengeActions.REQUEST)]: {
            next: (state, {payload}) => ({...state, isLoading: true}),
            throw: () => defaultState,
        },

        [combineActions(uploadImageChallengeAdActions.FAILURE, uploadImageChallengeAdActions.SUCCESS)]: {
            next: (state, {payload}) => (payload ? {ImageUrlAd: payload.content, isLoading: false} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(uploadImageChallengeAdActions.REQUEST)]: {
            next: (state, {payload}) => ({...state, isLoading: true}),
            throw: () => defaultState,
        },
    },
    defaultState
);