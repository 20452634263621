import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const getTeamAllPublications = generateApiActions("bo_get_team_publications");


export const actionGetTeamAllPublications = (publicationChannel: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/publications/${publicationChannel}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getTeamAllPublications.all())
        .build();


export const getTeamAllComents = generateApiActions("bo_get_team_comments");


export const actionGetTeamAllComments = (publicationChannel: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/comments/${publicationChannel}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getTeamAllComents.all())
        .build();

export const deleteTeam = generateApiActions("bo_delete_team");


export const actionDeleteTeam = (teamId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/delete/${teamId}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteTeam.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getTeamAllPublications.all())]: {
            next: (state, {payload}) => (payload ? {...state, publications: payload.content,} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...getTeamAllComents.all())]: {
            next: (state, {payload}) => (payload ? {...state, comments: payload.content,} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;