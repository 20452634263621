import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {
    actionCreateOrUpdateWatchingSponsorships,
    actionGetAllWatchingSponsorships
} from "../../../store/reducers/sponsorship";
import {Form, Modal, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";

interface UsersProps {
    watchingSponsorshipReducersState: any;

    getAllWatchingSponsorships(): void;

    createOrUpdateWatchingSponsorships(email: string, sponsorshipCode: string): void;
}

const Sponsorship = ({
                         getAllWatchingSponsorships,
                         createOrUpdateWatchingSponsorships,
                         watchingSponsorshipReducersState
                     }: UsersProps) => {

    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState("");
    const [sponsorshipCode, setSponsorshipCode] = useState("");
    const [watchingSponsorships, setWatchingSponsorships] = useState<any>({});

    useEffect(() => {
        getAllWatchingSponsorships();
    }, []);

    const handleClickEdition = (watchingSponsorships) => {
        setEmail(watchingSponsorships ? watchingSponsorships.emailGodfather : "");
        setSponsorshipCode(watchingSponsorships ? watchingSponsorships.boWatchingSponsorship.sponsorshipCode : "")
        setShowModal(true);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setSponsorshipCode(value);
    }

    const handleGodsonsClick = (godfather) => {
        history.push("/godsons/" + godfather.godfatherId + "/" + godfather.nickname)
    }

    const handleChangeEmail = (event) => {
        const value = event.target.value;
        setEmail(value);
    }

    const handleCreateOrUpdate = () => {
        setShowModal(false);
        createOrUpdateWatchingSponsorships(email, sponsorshipCode);

        setTimeout(() => { //Start the timer
            getAllWatchingSponsorships();
        }, 500);
    }

    const renderModal = () => {
        const title = watchingSponsorships ? "Edition du parrainage" : "Création de code de parrainage";

        return (
            <Modal size="lg" show={showModal}
                   onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>email</Form.Label>
                                <Form.Control type="input" placeholder="Entrer l'email de l'utilisateur ..."
                                              name="email"
                                              onChange={(event) => handleChangeEmail(event)}
                                              value={email}/>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>code de parrainnage</Form.Label>
                                <Form.Control type="input" placeholder="Entrer le code de parrainage ..."
                                              onChange={(event) => handleChange(event)}
                                              name="sponsorshipCode"
                                              value={sponsorshipCode}/>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => handleCreateOrUpdate()}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="start-page">
            {renderModal()}
            <h1 className="h3 mb-2 text-gray-800">Utilisateurs</h1>
            <br/>
            <Button variant="primary" size="lg" onClick={() => handleClickEdition(null)}>
                Ajouter <i className="fas fa-plus"></i>
            </Button>
            <br/>
            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Utilisateur</th>
                        <th>Code parrainage</th>
                        <th>Email parrain</th>
                        <th>Nombre de filleuls</th>
                        <th>Modifier</th>
                        <th>Filleuls</th>
                    </tr>
                    </thead>
                    <tbody>
                    {watchingSponsorshipReducersState.allWatchingSponsorships && watchingSponsorshipReducersState.allWatchingSponsorships.map((watchingSponsorships, index) =>
                        <tr key={index}>
                            <td>{watchingSponsorships.godfatherId}</td>
                            <td>{watchingSponsorships.nickname}</td>
                            <td>{watchingSponsorships.boWatchingSponsorship.sponsorshipCode}</td>
                            <td>{watchingSponsorships.emailGodfather}</td>
                            <td>{watchingSponsorships.nbGodson}</td>
                            <td>
                                <Button variant="primary"
                                        onClick={() => handleClickEdition(watchingSponsorships)}>
                                    <i className="fas fa-pencil-alt"></i>
                                </Button>
                            </td>
                            <td>
                                <Button variant="primary"
                                        onClick={() => handleGodsonsClick(watchingSponsorships)}>
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    watchingSponsorshipReducersState: state.watchingSponsorshipReducers
});

const mapDispatchToProps = {
    getAllWatchingSponsorships: actionGetAllWatchingSponsorships,
    createOrUpdateWatchingSponsorships: actionCreateOrUpdateWatchingSponsorships
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Sponsorship);
