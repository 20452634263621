import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, News, Page} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type CreateNewsApiReturnType = ApiResponse<News>;
type CreateNewsPageArgs = { news: News };

export interface CreateNewsPageState extends Loadable {
    news: News;
    isCreated: boolean;
    error: boolean;
}

const initialCreateNewsState: CreateNewsPageState = {
    news: {} as News,
    isCreated: false,
    isLoading: false,
    error: false,
};

export const fetchCreateNewsPage = createAsyncThunk<CreateNewsApiReturnType,
    CreateNewsPageArgs,
    ThunkApiArgs<CreateNewsApiReturnType>>("CreateNewsPage/fetch", async ({news}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/news/`)
        .withBody(news)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const createNewsPageSlice = createSlice({
    name: "createNewsSlice",
    initialState: initialCreateNewsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCreateNewsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateNewsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.news = payload.content;
                state.isCreated = true;
            })
            .addCase(fetchCreateNewsPage.rejected, state => {
                state.isLoading = false;
                state.error = true;
            });
    },
});

//*********


type AllNewsApiReturnType = ApiResponse<Page>;
type AllNewsPageArgs = { page: number };

export interface AllNewsPageState extends Loadable {
    allNews: Page;
}

const initialAllNewsState: AllNewsPageState = {
    allNews: {} as Page,
    isLoading: false,
};

export const fetchAllNewsPage = createAsyncThunk<AllNewsApiReturnType,
    AllNewsPageArgs,
    ThunkApiArgs<AllNewsApiReturnType>>("AllNewsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/news/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allNewsPageSlice = createSlice({
    name: "allNewsSlice",
    initialState: initialAllNewsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllNewsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllNewsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allNews = payload.content;
            })
            .addCase(fetchAllNewsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type DeleteNewsApiReturnType = ApiResponse<Page>;
type DeleteNewsPageArgs = { newsId: number };

export interface DeleteNewsPageState extends Loadable {
}

const initialDeleteNewsState: DeleteNewsPageState = {
    isLoading: false,
};

export const fetchDeleteNewsPage = createAsyncThunk<DeleteNewsApiReturnType,
    DeleteNewsPageArgs,
    ThunkApiArgs<DeleteNewsApiReturnType>>("DeleteNewsPage/fetch", async ({newsId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/news/${newsId}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteNewsPageSlice = createSlice({
    name: "deleteNewsSlice",
    initialState: initialDeleteNewsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteNewsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteNewsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteNewsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type EditNewsApiReturnType = ApiResponse<Page>;
type EditNewsPageArgs = { news: News };

export interface EditNewsPageState extends Loadable {
}

const initialEditNewsState: EditNewsPageState = {
    isLoading: false,
};

export const fetchEditNewsPage = createAsyncThunk<EditNewsApiReturnType,
    EditNewsPageArgs,
    ThunkApiArgs<EditNewsApiReturnType>>("EditNewsPage/fetch", async ({news}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/news/${news.id}`)
        .withBody(news)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const editNewsPageSlice = createSlice({
    name: "editNewsSlice",
    initialState: initialEditNewsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchEditNewsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchEditNewsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchEditNewsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UpdateNewsStatusApiReturnType = ApiResponse<News>;
type UpdateNewsStatusPageArgs = { newsId: number, status: string };

export interface UpdateNewsStatusPageState extends Loadable {
    newsUpdated: News;
}

const initialUpdateNewsStatusState: UpdateNewsStatusPageState = {
    newsUpdated: {} as News,
    isLoading: false,
};

export const fetchUpdateNewsStatusPage = createAsyncThunk<UpdateNewsStatusApiReturnType,
    UpdateNewsStatusPageArgs,
    ThunkApiArgs<UpdateNewsStatusApiReturnType>>("UpdateNewsStatusPage/fetch", async ({newsId, status}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/news/${newsId}/change-status/${status}`)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const updateNewsStatusPageSlice = createSlice({
    name: "updateNewsStatusSlice",
    initialState: initialUpdateNewsStatusState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateNewsStatusPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateNewsStatusPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.newsUpdated = payload.content;
            })
            .addCase(fetchUpdateNewsStatusPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UploadImageNewsApiReturnType = ApiResponse<string>;
type FetchUploadImageNewsPageArgs = { data: any };

export interface UploadImageNewsPageState extends Loadable {
    urlNewsImage: string;
}

const initialUploadImageNewsState: UploadImageNewsPageState = {
    urlNewsImage: "",
    isLoading: false,
};

export const fetchUploadImageNewsPage = createAsyncThunk<UploadImageNewsApiReturnType,
    FetchUploadImageNewsPageArgs,
    ThunkApiArgs<UploadImageNewsApiReturnType>>("UploadImageNewsPage/fetch", async ({data}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const uploadImageNewsPageSlice = createSlice({
    name: "uploadImageNewsSlice",
    initialState: initialUploadImageNewsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUploadImageNewsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUploadImageNewsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.urlNewsImage = payload.content;
            })
            .addCase(fetchUploadImageNewsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});
