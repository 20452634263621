import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import LoginForm from "./login-form";
import {actionAuthenticate, actionReset} from "../../store/reducers/authentication";
import {EnvBadge} from "../../uicomponents/envBadge/envBadge";
import Toast from "react-bootstrap/Toast";
import Loader from "../../components/loader/loader";

interface LoginProps {
    authUserState: any,
    reset: any

    authenticate(object): void;
}

const Login = ({authenticate, authUserState, reset}: LoginProps) => {

    const [showToast, setShowToast] = useState(false);

    const onLogin = values => {
        authenticate(values);
        setShowToast(true);
    };

    return (
        <Loader>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-7 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-5">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h2"><EnvBadge/></h1>
                                                <h1 className="h4 text-gray-900 mb-4">Back Office Sobet</h1>

                                            </div>
                                            <LoginForm onSubmit={onLogin}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={4000} autohide
                           transition={false}>
                        <Toast.Header>
                            <strong className="mr-auto"> Sobet</strong>
                        </Toast.Header>
                        {showToast && authUserState.errors && authUserState.errors.length > 0 && authUserState.errors.map((error, index) =>
                            <Toast.Body key={index}>
                                {error.code === "P0013"
                                    ? "Vos identifiants sont incorrects ou " +
                                    "vous n'avez pas les droits nécessaires pour acceder au back office."
                                    : "Erreur inconnue, veuillez contacter l'administrateur."}
                            </Toast.Body>
                        )}

                    </Toast>
                </div>
            </div>
        </Loader>
    );

}

const mapStateToProps = state => ({
    authUserState: state.authUser
});

const mapDispatchToProps = {
    authenticate: actionAuthenticate,
    reset: actionReset
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Login);
