// @ts-ignore
import {getJSON, RSAA} from "redux-api-middleware";
import {getConfig} from "./config";

export enum HTTP_VERBS {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

const REQUEST_HEADERS = {
    "content-type": "application/json",
};

const API_PREFIX = `${getConfig().urlHostApi}/sobet-api/v1`;
const API_V2_PREFIX = `${getConfig().urlHostApi}/sobet-api/v2`;

export default class RsaaBuilder {
    private _endpoint: string | undefined;
    private _verb: HTTP_VERBS;
    private _types: string[] | undefined;
    private _headers: { "content-type": string; Authorization?: string };
    private _body: object | undefined;
    private _options: object | undefined;

    constructor() {
        this._endpoint = undefined;
        this._verb = HTTP_VERBS.GET;
        this._types = undefined;
        this._headers = REQUEST_HEADERS;
        this._body = undefined;
        this._options = undefined;

        this.addCredential();
    }

    static getInstance() {
        return new RsaaBuilder();
    }

    endpoint(endpoint: string) {
        this._endpoint = endpoint;
        return this;
    }

    apiEndpoint(endpoint: string) {
        this.endpoint(`${API_PREFIX}${endpoint}`);
        return this;
    }

    apiV2Endpoint(endpoint: string) {
        this.endpoint(`${API_V2_PREFIX}${endpoint}`);

        return this;
    }

    verb(verb: HTTP_VERBS) {
        this._verb = verb;
        return this;
    }

    types(types: string[]) {
        this._types = types;
        return this;
    }

    withBody(body: object) {
        this._body = body;
        return this;
    }

    withOptions(options: object) {
        this._options = options;
        return this;
    }

    build(isImg = false) {
        if (!this._types) {
            throw new Error("types from RsaaBuilder can't be undefined.");
        }

        const additionnalProps: any = {};
        if (this._options) {
            additionnalProps.options = this._options;
        }
        if (this._body) {
            additionnalProps.body = !isImg ? JSON.stringify(this._body) : this._body;
        }
        if (isImg) {
            delete (this._headers as any)["content-type"];
        }


        return {
            [RSAA]: {
                endpoint: this._endpoint,
                method: this._verb,
                types: this._types.map(type => {
                    if (!type.startsWith("FAILURE_")) {
                        return type;
                    }
                    return {
                        type,
                        payload: (action, state, res) => getJSON(res),
                    };
                }),
                headers: this._headers,
                ...additionnalProps,
            },
        };
    }

    private addCredential() {
        const {authUser}: any = require("../store").default.getState();

        if (authUser && authUser.jwt) {
            this._headers = {
                ...this._headers,
                Authorization: `Bearer ${authUser.jwt}`,
            };
        }
    }
}
