import React, {Component} from "react";
import {Typeahead} from "react-bootstrap-typeahead";

interface AutoCompleteProps {
    handleUsersSelected: any;
    users: any;
    labelKey: any;
    filter: any;
    placeHolderText: string
    multiple: boolean
}

interface AutoCompleteState {
}

class AutoComplete extends Component<AutoCompleteProps, AutoCompleteState> {

    render() {
        const {filter, multiple, users, placeHolderText, handleUsersSelected} = this.props;
        return (
            <Typeahead
                labelKey={option => option.nickname}
                filterBy={filter}
                users
                multiple={multiple}
                id="basic-example"
                onChange={selected => handleUsersSelected(selected)}
                options={users ? users : []}
                placeholder={placeHolderText}
                bsSize="large"
                renderMenuItemChildren={(option) => (
                    <div>
                        <div className="font-weight-bold">
                            {option.nickname}
                        </div>
                        <div>
                            <small>nom: {option.lastname}</small>
                        </div>
                        <div>
                            <small>prénom: {option.firstname}</small>
                        </div>
                        <div>
                            <small>email: {option.email}</small>
                        </div>
                    </div>
                )}
            />
        );
    }

}

export default AutoComplete;