import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    AddMembershipLevelInUserConfigurationPageState,
    AllUsersPageState,
    fetchAllUsersPage
} from "./membership-reducer";
import Pagination from "../../uicomponents/pagination/pagination";
import FormControl from "react-bootstrap/FormControl";
import {User} from "../../typings/api";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {AllExpertsState, fetchAllExpertsRequest} from "../../components/experts/all-experts-table-reducer";
import {
    ExpertFollowedByUserState,
    fetchExpertFollowedByUserRequest,
    FollowUserState,
    postFollowUserRequest
} from "./subscription-reducer";

const Subscription = () => {

    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [showEdition, setShowEdition] = useState(false);
    const [user, setUser] = useState<User>({} as User);

    const {users} = useSelector<AppState, AllUsersPageState>(state => state.users);
    const {isLoading} = useSelector<AppState, AddMembershipLevelInUserConfigurationPageState>(state => state.addMembershipLevelInUserConfiguration)
    const {experts} = useSelector<AppState, AllExpertsState>(state => state.allExpertsResponse);
    const {followedExpertsByUser} = useSelector<AppState, ExpertFollowedByUserState>(state => state.followedExpertsByUserResponse);
    const {newFollowForUser} = useSelector<AppState, FollowUserState>(state => state.followUserResponse);

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: "", page: 0}));
        dispatch(fetchAllExpertsRequest({}));
    }, []);

    useEffect(() => {
        if (user.id && newFollowForUser) {
            dispatch(fetchExpertFollowedByUserRequest({userId: user.id}));
        }
    }, [newFollowForUser])

    useEffect(() => {
        if (!showEdition) {
            setUser({} as User);
        }
    }, [showEdition]);

    useEffect(() => {
        if (user.id) {
            dispatch(fetchExpertFollowedByUserRequest({userId: user.id}));
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: searchText, page: currentPage}))
    }, [currentPage]);

    useEffect(() => {
        dispatch(fetchAllUsersPage({searchText: searchText, page: 0}))
    }, [searchText]);

    useEffect(() => {
        if (!isLoading) {
            dispatch(fetchAllUsersPage({searchText: searchText, page: currentPage}))
        }
    }, [isLoading]);

    const handleAddExpertToUser = () => {
        setShowEdition(false);
    };

    const handleUnfollow = (expertId: number) => {
        dispatch(postFollowUserRequest({userId: user.id, action: "no-follow", expertId}));
    }

    const handleFollow = (expertId: number) => {
        dispatch(postFollowUserRequest({userId: user.id, action: "follow", expertId}));
    }

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        setCurrentPage(0);
    };

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Faire suivre à un utilisateur un expert</h1>
        </header>
        <div className="mb-4 row">
            <div className="col-md-4">
                <FormControl
                    placeholder="Entrez le pseudo d'un utilisateur"
                    aria-label="Username"
                    aria-describedby="basic-addon"
                    size="lg"
                    onChange={handleSearchTextChange}
                />
            </div>
        </div>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <Pagination page={users} action={setCurrentPage}/>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <td>#</td>
                    <td>Pseudo</td>
                    <td>Email</td>
                    <td>Modifier</td>
                </tr>
                </thead>
                <tbody>
                {
                    users.content && users.content.map((user, index) =>
                        <tr key={index}>
                            <td>{user.id}</td>
                            <td>{user.nickname}</td>
                            <td>{user.email}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => {
                                    setShowEdition(true);
                                    setUser(user)
                                }}>
                                    <i className="fas fa-pen"/>
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
        {
            showEdition &&
            <Modal size="lg" show={showEdition}
                   onHide={() => setShowEdition(false)}>
                <Modal.Header closeButton className="alert alert-primary">
                    <Modal.Title>
                        Ajout d'un expert à l'utilisateur {user.nickname}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table">
                        <thead>
                        <tr>
                            <td>#</td>
                            <td>Nickname</td>
                        </tr>
                        </thead>
                        <tbody>
                        {followedExpertsByUser && experts && experts.map((expert, index) => (
                            <tr key={index}>
                                <td>{expert.id}</td>
                                <td>{expert.nickname}</td>
                                <td>
                                    {followedExpertsByUser.filter(e => e.followedExpertUserId === expert.id).length > 0 && (
                                        <button onClick={e => handleUnfollow(expert.id)}>UnFollow</button>
                                    )}
                                    {followedExpertsByUser.filter(e => e.followedExpertUserId === expert.id).length == 0 && (
                                        <button onClick={e => handleFollow(expert.id)}>Follow</button>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddExpertToUser}>
                        Appliquer
                    </Button>
                </Modal.Footer>
            </Modal>
        }
    </div>
}

export default Subscription;