import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";
import {
    CreateNewsPageState,
    fetchCreateNewsPage,
    fetchUploadImageNewsPage,
    UploadImageNewsPageState
} from "../news-reducer";
import {AppState} from "../../../store/reducers";

const CreateNews = () => {

    const history = useHistory();
    const disptach = useDispatch();
    const [news, setNews] = useState<any>({
        imageUrl: "undefined",
        title: "undefined",
        content: "Presentation",
        status: "undefined",
    });
    const [showModal, setShowModal] = useState(false);
    const {isCreated, error} = useSelector<AppState, CreateNewsPageState>(state => state.createNews);
    const {urlNewsImage} = useSelector<AppState, UploadImageNewsPageState>(state => state.urlNewsImage);

    const handleChangeNewsItem = (key, event) => {
        setNews({...news, [key]: event.target.value});
    }

    const handleImage = async (event) => {
        const tmp = new FormData();
        tmp.append("file", event.target.files[0]);
        await disptach(fetchUploadImageNewsPage({data: tmp}));
    }

    useEffect(() => {
        setNews({...news, imageUrl: urlNewsImage});
    }, [urlNewsImage]);

    const handleCreateChallenge = () => {
        setShowModal(true);
        disptach(fetchCreateNewsPage({news: news}));
    }

    const handleNewsCreated = () => {
        history.push('/all-news');
    }

    return (
        <div style={{maxWidth: '600px'}}>
            <h1>Créer une news</h1>

            <div className="alert alert-info">
                Cet écran permet de créer des news
            </div>

            <div>
                <hr/>

                <div>
                    <label>Choisir une image</label>
                    <br/>
                    <input type="file" name="myImage" onChange={(event) => handleImage(event)}
                           alt={"Image illustrant la news"}/>
                </div>

                <Form.Group controlId="exampleForm.ControlTextarea2" style={{marginTop: '20px'}}>
                    <Form.Label>Titre de la news</Form.Label>
                    <Form.Control as="input" rows="3" onChange={(event) => handleChangeNewsItem("title", event)}/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea3" style={{marginTop: '20px'}}>
                    <Form.Label>Contenu</Form.Label>
                    <Form.Control as="textarea" rows="3" onChange={(event) => handleChangeNewsItem("content", event)}/>
                </Form.Group>

                <div>
                    <select onChange={(event) => handleChangeNewsItem("status", event)}
                            defaultValue={"Selectionne un statut"}>
                        <option disabled>Selectionne un statut</option>
                        <option value={"PUBLISHED"}>Publiée</option>
                        <option value={"DRAFT"}>Brouillon</option>
                        <option value={"OBSOLETE"}>Obsolète</option>
                    </select>
                </div>

                <Button variant="primary" onClick={() => handleCreateChallenge()}
                        style={{marginTop: '50px', marginBottom: '70px'}}>
                    Submit
                </Button>
                {
                    error &&
                    <Modal size="lg" show={showModal}
                           onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton className="alert alert-danger">
                            <Modal.Title>Erreur</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>Une erreur est survenue lors de la creation de votre news</Form.Label>
                        </Modal.Body>
                    </Modal>
                }

                {
                    isCreated &&
                    <Modal size="lg" show={showModal}
                           onHide={() => setShowModal(false)}>
                        <Modal.Header className="alert alert-info">
                            <Modal.Title>Succès</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>Votre news est créée avec succès</Form.Label>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => handleNewsCreated()}>
                                OK!
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

            </div>
        </div>
    )
}

export default CreateNews;
