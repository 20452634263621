import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type CreateInfluencerApiReturnType = ApiResponse<any>;
type FetchCreateInfluencerPageArgs = { influencerName: String };

export interface CreateInfluencerPageState extends Loadable {
    isCreated: boolean;
    error: boolean;
}

const initialCreateInfluencerState: CreateInfluencerPageState = {
    isCreated: false,
    error: false,
    isLoading: false,
};

export const fetchCreateInfluencerPage = createAsyncThunk<CreateInfluencerApiReturnType,
    FetchCreateInfluencerPageArgs,
    ThunkApiArgs<CreateInfluencerApiReturnType>>("CreateInfluencerPage/fetch", async ({influencerName}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/influencers-statistics/")
        .withQueryParams({influencerName})
        .verb(HTTP_VERBS.POST)
        .build()
);

export const createInfluencerPageSlice = createSlice({
    name: "createInfluencerSlice",
    initialState: initialCreateInfluencerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCreateInfluencerPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateInfluencerPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.isCreated = true;
            })
            .addCase(fetchCreateInfluencerPage.rejected, state => {
                state.isLoading = false;
                state.error = true;
            });
    },
});