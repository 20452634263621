import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllConnection = generateApiActions("get_all_connection");

export const actionGetAllConnection = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/info-connection/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllConnection.all())
        .build();

const defaultState = {};

const reducerConnection = handleActions(
    {
        [combineActions(...getAllConnection.all())]: {
            next: (state, {payload}) => (payload ? {connections: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducerConnection;