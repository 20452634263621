import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, BookmakerV2} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllBookmakersApiReturnType = ApiResponse<BookmakerV2[]>;
type FetchAllBookmakersPageArgs = {};

export interface AllBookmakersPageState extends Loadable {
    allBookmakers: BookmakerV2[];
}

const initialAllBookmakersState: AllBookmakersPageState = {
    allBookmakers: [] as BookmakerV2[],
    isLoading: false,
};

export const fetchAllBookmakersPage = createAsyncThunk<AllBookmakersApiReturnType,
    FetchAllBookmakersPageArgs,
    ThunkApiArgs<AllBookmakersApiReturnType>>("AllBookmakersPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/bookmaker/referentials/`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allBookmakersPageSlice = createSlice({
    name: "allBookmakersSlice",
    initialState: initialAllBookmakersState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllBookmakersPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllBookmakersPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allBookmakers = payload.content;
            })
            .addCase(fetchAllBookmakersPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type FetchUpdateBookmakerPageArgs = { updateBookmakerRequest: any };

export interface UpdateBookmakerPageState extends Loadable {
    bookmakers: BookmakerV2[];
}

const initialState: UpdateBookmakerPageState = {
    bookmakers: [] as BookmakerV2[],
    isLoading: false,
};

export const fetchUpdateBookmakerPage = createAsyncThunk<AllBookmakersApiReturnType,
    FetchUpdateBookmakerPageArgs,
    ThunkApiArgs<AllBookmakersApiReturnType>>("UpdateBookmakerPage/fetch", async ({updateBookmakerRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/bookmaker`)
        .withBody(updateBookmakerRequest)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const updateBookmakerPageSlice = createSlice({
    name: "updateBookmakerSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateBookmakerPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateBookmakerPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.bookmakers = payload.content;
            })
            .addCase(fetchUpdateBookmakerPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UploadBookmakerImageApiReturnType = ApiResponse<string>;
type FetchUploadBookmakerImagePageArgs = { data: any };

export interface UploadBookmakerImagePageState extends Loadable {
    urlBookmakerImage: string;
}

const initialUploadBookmakerImageState: UploadBookmakerImagePageState = {
    urlBookmakerImage: "",
    isLoading: false,
};

export const fetchUploadBookmakerImagePage = createAsyncThunk<UploadBookmakerImageApiReturnType,
    FetchUploadBookmakerImagePageArgs,
    ThunkApiArgs<UploadBookmakerImageApiReturnType>>("UploadBookmakerImagePage/fetch", async ({data}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/documents/`)
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const uploadBookmakerImagePageSlice = createSlice({
    name: "uploadBookmakerImageSlice",
    initialState: initialUploadBookmakerImageState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUploadBookmakerImagePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUploadBookmakerImagePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.urlBookmakerImage = payload.content;
            })
            .addCase(fetchUploadBookmakerImagePage.rejected, state => {
                state.isLoading = false;
            });
    },
});



