import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    AllPackInformationPageState,
    AllProductConfigurationPageState,
    fetchAddProductConfigurationPage,
    fetchAllPackInformationPage,
    fetchAllProductConfigurationPage,
    fetchUpdatePackInformationPage,
    UpdatePackInformationPageState
} from "./membership-reducer";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const PackInformation = () => {

    const dispatch = useDispatch();

    const [currentPackValue, setCurrentPackValue] = useState("");
    const [currentPack, setCurrentPack] = useState<any>({});
    const [unsaved, setUnsaved] = useState(false);
    const [saved, setSaved] = useState(false);
    const [productOnEdition, setProductOnEdition] = useState<any>({});

    const {allProductConfiguration} = useSelector<AppState, AllProductConfigurationPageState>(state => state.allProductConfiguration)
    const {allPackInformation} = useSelector<AppState, AllPackInformationPageState>(state => state.allPackInformation);
    const {isLoading} = useSelector<AppState, UpdatePackInformationPageState>(state => state.updatePackInformation);

    useEffect(() => {
        dispatch(fetchAllProductConfigurationPage({}));
        dispatch(fetchAllPackInformationPage({}))
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (unsaved) {
                setSaved(true);
                setUnsaved(false);
            }
            dispatch(fetchAllPackInformationPage({}))
        }
    }, [isLoading]);

    useEffect(() => {
        setSaved(false);
        setUnsaved(false);
        setCurrentPack(allPackInformation.find(pack => pack.level === currentPackValue));
    }, [currentPackValue]);

    useEffect(() => {
        if (currentPackValue !== "")
            setCurrentPack(allPackInformation.find(pack => pack.level === currentPackValue));
    }, [allPackInformation]);

    const onPackChange = (label, value) => {
        setSaved(false);
        setUnsaved(true);
        setCurrentPack({...currentPack, [label]: value});
    };

    const replaceInArray = (array, label, index, value) => {
        return [...array.slice(0, index), {...array[index], [label]: value}, ...array.slice(index + 1, array.length)];
    };

    const deleteInArray = (array, index) => {
        return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
    };

    const onUpdatePackInformation = () => {
        dispatch(fetchUpdatePackInformationPage({id: currentPack.id, features: currentPack}));
        dispatch(fetchAddProductConfigurationPage({product: productOnEdition}));
    };

    const setPrice = (event) => {
        onPackChange("priceAmountInCents", event.target.value);
        setProductOnEdition({...productOnEdition, amountInCent: parseInt(event.target.value)});
    }

    const selectPack = (event) => {
        setCurrentPackValue(event.target.value);
        setProductOnEdition(allProductConfiguration.find(product => product.level === event.target.value && product.promoCode === "default"));
    }

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Informations des packs</h1>
        </header>
        <div className="card mb-4">
            <div className="card-header">
                <select className="custom-select" defaultValue="PACK"
                        onChange={event => selectPack(event)}>
                    <option key={-1} value="PACK" disabled={true}>PACK</option>
                    {
                        allPackInformation.map((pack, index) =>
                            <option key={index} value={pack.level}>{pack.level}</option>
                        )
                    }
                </select>
            </div>
            {
                currentPack && currentPack.level &&
                <>
                    <div className="card-body">
                        <div className="m-1 ml-0">
                            <h6>Titre :</h6>
                            <input className="border rounded" type="text" placeholder="Titre..."
                                   value={currentPack.features.title}
                                   onChange={event => onPackChange("features", {
                                       ...currentPack.features,
                                       title: event.target.value
                                   })}/>
                        </div>
                        <div className="m-1 ml-0">
                            <h6>Description :</h6>
                            <textarea className="border rounded w-100" placeholder="Description..."
                                      value={currentPack.features.description}
                                      onChange={event => onPackChange("features", {
                                          ...currentPack.features,
                                          description: event.target.value
                                      })}/>
                        </div>
                        <div className="m-1 ml-0">
                            <h6>Prix (en centimes) :</h6>
                            <input className="border rounded" type="number" placeholder="Prix..."
                                   value={currentPack.priceAmountInCents}
                                   onChange={event => setPrice(event)}/>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="card">
                            <div className="card-header">
                                <button className="btn btn-primary mr-2"
                                        onClick={() => onPackChange("features", {
                                            ...currentPack.features,
                                            privileges: [
                                                {
                                                    title: "",
                                                    description: "",
                                                    accessible: false
                                                },
                                                ...currentPack.features.privileges
                                            ]
                                        })}>
                                    <i className="fas fa-plus"/>
                                </button>
                                Privileges
                            </div>
                            <div className="card-body">
                                {
                                    currentPack.features.privileges.map((privilege, index) =>
                                        <div className={"card" + (index === 0 ? "" : " mt-4")}>
                                            <div className="card-header">
                                                <button className="btn btn-danger mr-2"
                                                        onClick={() => onPackChange("features", {
                                                            ...currentPack.features,
                                                            privileges: deleteInArray(currentPack.features.privileges, index)
                                                        })}>
                                                    <i className="fas fa-minus"/>
                                                </button>
                                                <input type="text" className="border-0 rounded" placeholder="Titre..."
                                                       value={privilege.title}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           privileges: replaceInArray(currentPack.features.privileges, "title", index, event.target.value)
                                                       })}/>
                                            </div>
                                            <div className="card-body">
                                                <div className="m-1 ml-0">
                                                    <h6>Description :</h6>
                                                    <textarea className="border rounded  w-100"
                                                              placeholder="Description..."
                                                              value={privilege.description}
                                                              onChange={event => onPackChange("features", {
                                                                  ...currentPack.features,
                                                                  privileges: replaceInArray(currentPack.features.privileges, "description", index, event.target.value)
                                                              })}/>
                                                </div>
                                                <div className="m-1 ml-0">
                                                    <h6>Accessibilite :</h6>
                                                    <BootstrapSwitchButton
                                                        checked={privilege.accessible}
                                                        onlabel='I'
                                                        offlabel='O'
                                                        onChange={event => onPackChange("features", {
                                                            ...currentPack.features,
                                                            privileges: replaceInArray(currentPack.features.privileges, "accessible", index, event.valueOf())
                                                        })}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                <button className="btn btn-primary mr-2"
                                        onClick={() => onPackChange("features", {
                                            ...currentPack.features,
                                            advantages: [
                                                {
                                                    title: "",
                                                    description: "",
                                                    accessible: false
                                                },
                                                ...currentPack.features.advantages
                                            ]
                                        })}>
                                    <i className="fas fa-plus"/>
                                </button>
                                Avantages
                            </div>
                            <div className="card-body">
                                {
                                    currentPack.features.advantages.map((advantage, index) =>
                                        <div className={"card" + (index === 0 ? "" : " mt-4")}>
                                            <div className="card-header">
                                                <button className="btn btn-danger mr-2"
                                                        onClick={() => onPackChange("features", {
                                                            ...currentPack.features,
                                                            advantages: deleteInArray(currentPack.features.advantages, index)
                                                        })}>
                                                    <i className="fas fa-minus"/>
                                                </button>
                                                <input type="text" className="border-0 rounded" placeholder="Titre..."
                                                       value={advantage.title}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           advantages: replaceInArray(currentPack.features.advantages, "title", index, event.target.value)
                                                       })}/>
                                            </div>
                                            <div className="card-body">
                                                <div className="m-1 ml-0">
                                                    <h6>Description :</h6>
                                                    <textarea className="border rounded  w-100"
                                                              placeholder="Description..."
                                                              value={advantage.description}
                                                              onChange={event => onPackChange("features", {
                                                                  ...currentPack.features,
                                                                  advantages: replaceInArray(currentPack.features.advantages, "description", index, event.target.value)
                                                              })}/>
                                                </div>
                                                <div className="m-1 ml-0">
                                                    <h6>Accessibilite :</h6>
                                                    <BootstrapSwitchButton
                                                        checked={advantage.accessible}
                                                        onlabel='I'
                                                        offlabel='O'
                                                        onChange={event => onPackChange("features", {
                                                            ...currentPack.features,
                                                            advantages: replaceInArray(currentPack.features.advantages, "accessible", index, event.valueOf())
                                                        })}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                Parrainage
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <td>Condition</td>
                                            <td>Presentation</td>
                                            <td>Offre</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Parrainage SEMI-PRO
                                            </td>
                                            <td>
                                                <input type="text" className="border rounded w-100"
                                                       placeholder="Description..."
                                                       value={currentPack.features.getDescriptionOfSemiProSponsorship}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           getDescriptionOfSemiProSponsorship: event.target.value
                                                       })}/>
                                            </td>
                                            <td>
                                                <input type="text" className="border rounded"
                                                       placeholder="Description..."
                                                       value={currentPack.features.titleOfSemiProSponsorship}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           titleOfSemiProSponsorship: event.target.value
                                                       })}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Parrainage PRO
                                            </td>
                                            <td>
                                                <input type="text" className="border rounded w-100"
                                                       placeholder="Description..."
                                                       value={currentPack.features.getDescriptionOfProSponsorship}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           getDescriptionOfProSponsorship: event.target.value
                                                       })}/>
                                            </td>
                                            <td>
                                                <input type="text" className="border rounded"
                                                       placeholder="Description..."
                                                       value={currentPack.features.titleOfProSponsorship}
                                                       onChange={event => onPackChange("features", {
                                                           ...currentPack.features,
                                                           titleOfProSponsorship: event.target.value
                                                       })}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary" onClick={onUpdatePackInformation}>Sauvegarder</button>
                            {
                                saved &&
                                <span className="badge badge-success text-lg ml-4">
                                    Enregistrement réussi
                                </span>
                            }
                            {
                                unsaved &&
                                <span className="badge badge-danger text-lg ml-4">
                                    Modifications detectées !
                                </span>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    </div>
};

export default PackInformation;