import React, {Component} from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

interface CustomInputProps {
    onInputTextchange: any;
    matchOnEdition: any;
}

interface CustomInputState {
    items: Array<any>;
    results: Array<any>;
    homeScore: any,
    awayScore: any,
}


class CustomMatchInputV2 extends Component<CustomInputProps, CustomInputState> {

    render() {
        const {onInputTextchange} = this.props;

        return (
            <div>
                <BootstrapSwitchButton
                    checked={false}
                    onlabel='WIN'
                    onstyle='success'
                    offlabel='LOSE'
                    offstyle='danger'
                    style='w-25 mx-3'
                    onChange={onInputTextchange}
                />
            </div>
        )
    }

}

export default CustomMatchInputV2;

/**
 <div className="form-row justify-content-center">
 <div className="form-group col-md-2">
 <Button variant="primary"  onClick={(event) => this.handleValidResult(event)}>
 Valider <i className="fas fa-check"></i>
 </Button>
 </div>
 </div>
 **/