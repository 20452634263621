import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Table} from "react-bootstrap";
import I18nField from "../../../uicomponents/i18nfield/I18nField";
import Pagination from "../../../uicomponents/pagination/pagination";
import Popin from "../../../uicomponents/modal/modal";
import FormControl from "react-bootstrap/FormControl";
import {
    AllEventsPageState,
    fetchAllEventsPage,
    fetchSearchEventByNamePage,
    fetchUpdateEventPage,
    SearchEventByNamePageState
} from "./competition-reducer";
import {AppState} from "../../../store/reducers";

const ContributionCompetitionV2 = () => {
    const dispatch = useDispatch();
    const [competitionOnEdition, setCompetitionOnEdition] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [events, setEvents] = useState<any>({});
    const {allEvents} = useSelector<AppState, AllEventsPageState>(state => state.allEvents);
    const {eventsByName} = useSelector<AppState, SearchEventByNamePageState>(state => state.searchEventByName);

    useEffect(() => {
        dispatch(fetchAllEventsPage({page: 0}));
    }, []);

    console.log(allEvents)

    useEffect(() => {
        setEvents(allEvents);
    }, [allEvents]);

    useEffect(() => {
        setEvents(eventsByName);
    }, [eventsByName]);

    // TODO: avoir la technique avec redux form pour ne plus faire cette méthode
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        setCompetitionOnEdition({...competitionOnEdition, [name]: value})
    };

    const updateCompetition = () => {
        setShowModal(false)
        dispatch(fetchUpdateEventPage({updateCompetitionRequest: competitionOnEdition}));
        // TODO : améliorer mis dans un timer pour attendre que le back se rafraichissent
        setTimeout(() => { //Start the timer
            dispatch(fetchAllEventsPage({page: 0}));
        }, 1000);
    };

    const onContributionChange = (items) => {
        setCompetitionOnEdition({...competitionOnEdition, name: {items}});
        return items;
    };

    const hasContribution = (competition) => {
        return competition.name && competition.name.items;
    };

    const paginationChange = (event, pageClicked) => {
        dispatch(fetchAllEventsPage({page: pageClicked}));
    };

    const handleInputTextChange = (event) => {
        const target = event.target;
        const value = target.value;
        if (value !== "") {
            dispatch(fetchSearchEventByNamePage({name: value}));
        }
    };

    const onSelectCompetition = (competition) => {
        setCompetitionOnEdition(competition);
        setShowModal(true);
    }

    return (
        <div className="start-page">

            {competitionOnEdition && competitionOnEdition.name &&
                <Popin
                    title={`Edition de la compétition #${competitionOnEdition.id} - ${competitionOnEdition.name.items[0].trad}`}
                    showModal={showModal}
                    cancelAction={() => setShowModal(false)}
                    saveAction={() => updateCompetition()}>
                    <I18nField onChange={(items) => onContributionChange(items)}
                               contributionItems={hasContribution(competitionOnEdition) ? competitionOnEdition.name.items : null}/>

                    <Form.Group>
                        <Form.Label>Priorité d'affichage</Form.Label>
                        <Form.Control type="number" placeholder="Entrer la priorité d'affichage ..."
                                      onChange={(event) => handleChange(event)}
                                      name="priority"
                                      value={competitionOnEdition.priority}/>
                    </Form.Group>
                </Popin>}

            <h1 className="h3 mb-2 text-gray-800">Contribution - Compétitions</h1>
            <p className="mb-4">Documentation ...</p>

            <div className="col-md-4">

                <FormControl
                    placeholder="Entrez le nom d'une compétition"
                    aria-label="Usernamed"
                    aria-describedby="basic-addon"
                    size="lg"
                    onChange={(event) => handleInputTextChange(event)}
                />
            </div>
            <br/>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {events &&
                        <Pagination page={events}
                                    action={paginationChange}/>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>SportType</th>
                        <th>Nom</th>
                        <th>Priorité d'affichage</th>
                        <th><i className="fas fa-cog"/></th>

                    </tr>
                    </thead>
                    <tbody>
                    {events && events.content && events.content.map((competition, index) =>
                        <tr key={index}>
                            <td>{competition.id}</td>
                            <td>{competition.sportCategoryId}</td>
                            <td>
                                {hasContribution(competition) && (
                                    <span>{competition.name.items[0].trad} ({competition.name.items[0].lang})</span>
                                )}
                            </td>
                            <td>{competition.priority}</td>
                            <td>
                                <Button variant="primary" onClick={() => onSelectCompetition(competition)}>
                                    <i className="fas fa-pencil-alt"/>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

        </div>
    );
}

export default ContributionCompetitionV2;
