export enum apiPrefix {
    REQUEST = "REQUEST",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}

interface ApiActions {
    REQUEST: string;
    SUCCESS: string;
    FAILURE: string;

    all(): string[];
}

export const generateApiActions = (actionName: string): ApiActions => {
    const actionNameUpper = actionName.toUpperCase();
    const REQUEST = `${apiPrefix.REQUEST}_${actionNameUpper}`;
    const SUCCESS = `${apiPrefix.SUCCESS}_${actionNameUpper}`;
    const FAILURE = `${apiPrefix.FAILURE}_${actionNameUpper}`;
    return {
        REQUEST,
        SUCCESS,
        FAILURE,
        all: () => [REQUEST, SUCCESS, FAILURE],
    };
};
