import React, {useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Button, Modal} from "react-bootstrap";
import {actionPostComputeRanking} from "../../store/reducers/ranking";


interface RankingProps {
    startRankingCompute(): any,
}

const Ranking = ({startRankingCompute}: RankingProps) => {

    const [showModal, setShowModal] = useState(false);

    const startCompute = async () => {
        startRankingCompute();
    }

    const handleClick = () => {
        startCompute();
        setShowModal(true);
    }

    return (
        <div className="start-page">

            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{`La demande de calcul a bien été prise en compte`}</Modal.Title>
                </Modal.Header>
            </Modal>

            <h1 className="h3 mb-2 text-gray-800">Calcul du classement</h1>
            <div className="alert alert-info">
                Cet écran permet de lancer le calcul des classements des utilisateurs
            </div>
            <Button variant="primary" onClick={() => handleClick()}>
                <i className="fas fas fa-crown"/> Démarrer le calcul
            </Button>
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    startRankingCompute: actionPostComputeRanking,
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Ranking);
