import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {membershipRenew} from "./membership-reducer";

const MembershipRenewal = () => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const startCompute = async () => {
        dispatch(membershipRenew({}));
    }

    const handleClick = () => {
        startCompute();
        setShowModal(true);
    }

    return (
        <div>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{`La demande de calcul a bien été prise en compte`}</Modal.Title>
                </Modal.Header>
            </Modal>

            <h1 className="h3 mb-2 text-gray-800">Problème de renouvelement</h1>
            <div className="alert alert-info">
                Cet écran permet de lancer le renouvelement des membership
            </div>
            <Button variant="primary" onClick={() => handleClick()}>
                <i className="fas fas fa-crown"/> Démarrer le renouvelement
            </Button>
        </div>
    );
};

export default MembershipRenewal;
