import React from 'react';
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import '../styles/backoffice.scss'
import Login from "./login/login";
import store from "../store";
import Routes from "./routes";
import {Redirect, Route, Switch} from "react-router-dom";


const isAuthenticated = authUser => {
    return authUser && authUser.jwt;
}

export const PrivateRoute = ({component: Component, authUser, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated(authUser) ? (
                <Component {...props} />
            ) : (
                <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
            )
        }
    />
);


const App = ({history}): JSX.Element => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Routes/>
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
