import {connectRouter} from "connected-react-router";
import {combineReducers} from "redux";
import {createResponsiveStateReducer} from "redux-responsive";

import authUser from "./authentication";
import retrieveBasicMetricsReport from "./dashboard";
import contributionAllCompetitions from "./competition";
import contributionAllV2Competitions from "./competitionv2";
import userReducers from "./user";
import userDetails from "./userDetails"
import allMatchs from "./match";
import allV2Matchs from "./matchv2"
import bookmakerReducers from "./bookmaker";
import bookmakerV2Reducers from "./bookmakerv2";
import authorization from "./authorization";
import watchingSponsorshipReducers from "./sponsorship";
import contactUsReducers from "./contactus";
import publicationReducers from "./publication";
import campainReducers from "./campain";
import dailyUserCountActions from "./user";
import teamReducers from "./team";
import teamDetailsReducers from "./teamDetails";
import membershipReducers from "./membership";
import lastDeviceInformationReducers from "./lastDeviceInformation";
import oddVariationReducers from "./oddVariation";
import socialStatisticsReducers from "./socialStatistics";
import sportReducers from "./sport";
import {createBrowserHistory} from "history";
import reducerMatchs from "./matchsV2";
import reducerEvents from "./event";
import reducerOddTypes from "./oddtype";
import sportV2Reducer from "./sportv2";
import rankingReducer from "./ranking"
import challengesReducer from "./all-challenges";
import reducerChallenge, {reducerImageForChallenge} from "./challenge";
import reducerTeamsForChallenges from "./team-for-challenge";
import reducerNews from "./news";
import reducerTrophy from "./trophy";
import reducerConnection from "./info-connection";
import {
    addUserPageSlice,
    allRolesPageSlice,
    allUsersPageSlice,
    removeUserRolePageSlice
} from "../../pages/authorization/authorization-reducer";
import {allSocialStatisticsPageSlice} from "../../pages/bestcontributor/socialStatistics-reducer";
import {
    allBookmakersPageSlice,
    updateBookmakerPageSlice,
    uploadBookmakerImagePageSlice
} from "../../pages/contribution/bookmaker/bookmaker-reducer";
import {allEventsPageSlice, searchEventByNamePageSlice} from "../../pages/contribution/competition/competition-reducer";
import {
    allMatchsPageSlice,
    allPendingMatchsPageSlice,
    updateMatchPageSlice
} from "../../pages/contribution/match/match-reducer";
import {allSportsPageSlice, uploadSportImagePageSlice} from "../../pages/contribution/sport/sport-reducer";
import {basicMetricsReportPageSlice} from "../../pages/dashboard/dashboard-reducer";
import {allConnectionPageSlice} from "../../pages/info-connection/info-connection-reducer";
import {
    allChallengesPageSlice,
    deleteChallengePageSlice,
    searchChallengeByTitlePageSlice,
    updateChallengePageSlice
} from "../../pages/challenge/all-challenges/all-challenges-reducer";
import {
    allEventsForChallengePageSlice,
    allMatchesForChallengePageSlice,
    allSportsForChallengePageSlice,
    challengeInfoPageSlice,
    createChallengePageSlice,
    oddTypesPageSlice,
    uploadImageChallengeAdPageSlice,
    uploadImageChallengePageSlice
} from "../../pages/challenge/create-challenge/challenge-reducer";
import {
    addMembershipLevelInUserConfigurationPageSlice,
    addPackConfigurationPageSlice,
    allPackConfigurationPageSlice,
    allPackFeaturesPageSlice,
    allPackInformationPageSlice,
    allPackLevelsPageSlice,
    allProductConfigurationPageSlice,
    allUsersForMemberShipPageSlice,
    deletePackConfigurationPageSlice,
    nbPackBuyPageSlice,
    updatePackInformationPageSlice
} from "../../pages/membership/membership-reducer";
import {
    allNewsPageSlice,
    createNewsPageSlice,
    deleteNewsPageSlice,
    editNewsPageSlice,
    uploadImageNewsPageSlice
} from "../../pages/news/news-reducer";
import {allGeneratedAlertsPageSlice} from "../../pages/oddvariation/oddVariation-reducer";
import {
    allCampaignsPageSlice,
    allUsersForCampaignPageSlice,
    sendCampaignPageSlice
} from "../../pages/publication/campaign/campaign-reducer";
import {
    allEventsBySportPageSlice,
    allMatchByEventPageSlice,
    matchToLinkWithSobetPageSlice,
    unlinkSobetPageSlice
} from "../../pages/unlinksobetmodel/unlinksobet-reducer";
import {allUsersForModerationPageSlice, dailyUserCountSlice, deleteUserPageSlice} from "../../pages/user/user-reducer";
import {
    allTrophiesPageSlice,
    createTrophyPageSlice,
    deleteTrophyPageSlice,
    updateTrophyPageSlice
} from "../../pages/trophy/all-trophies-reducer";
import {allSupportMessagesPageSlice} from "../../pages/support/all-messages/all-messages-reducer";
import {getSupportMessagePageSlice} from "../../pages/support/message-details/message-details-reducer";
import {
    allPublicationsPageSlice,
    allUsersForPublicationsPageSlice,
    deleteUserPublicationPageSlice,
    userPublicationsPageSlice
} from "../../pages/publication/moderation/feed-moderation-reducer";
import {
    allAdminsPageSlice,
    allFeedsForSendFeedPageSlice,
    sendMessagePageSlice
} from "../../pages/publication/feed/send-feed-reducer";
import {
    blockPageSlice,
    getUserAnalysisPageSlice,
    getUserCommentsPageSlice,
    getUserPageSlice,
    getUserPublicationsPageSlice,
    unBlockPageSlice
} from "../../pages/user/user-details-reducer";
import {
    allActiveTrophiesForToolsPageSlice,
    allTrophiesForToolsPageSlice,
    allUsersForToolsPageSlice,
    updateTrophyOfUserPageSlice
} from "../../pages/tester-tools/user-trophies-reducer";
import {
    allFeedsForManageFeedsPageSlice,
    deleteFeedPageSlice,
    updateFeedPageSlice
} from "../../pages/publication/manage-feeds/feeds-reducer";
import {
    allMatchesWithResultPageSlice,
    updateMatchResultPageSlice
} from "../../pages/contribution/prediction/modify-result-reducer";
import {fetchExpertStatSlice, fetchExpertSubscriptionStatSlice} from "../../pages/user/expert-statistics-reducer";
import {fetchAllExpertsSlice} from "../../components/experts/all-experts-table-reducer";
import {expertFollowedByUserSlice, followUserSlice} from "../../pages/membership/subscription-reducer";
import {rankChallPageSlice, rankHistoryPageSlice} from "../../pages/ranking/ranking-reducer";
import {
    allInfluencersPageSlice,
    searchInfluencersByInfluencerNamePageSlice
} from "../../pages/influencers/all-influencers-reducer";
import {createInfluencerPageSlice} from "../../pages/influencers/create-influencer/influencer-reducer";
import {allUsersForGodfatherPageSlice} from "../../pages/user/sponsorship/godsons-reducer";
import {
    allMatchScoresSlice,
    countPendingMatchScoresSlice,
    searchMatchScoresSlice,
    updateMatchScoreSlice
} from "../../pages/contribution/match-scores/match-score-reducer";

export const history = createBrowserHistory({});

const rootReducer = combineReducers({
    router: connectRouter(history),
    browser: createResponsiveStateReducer({
        extraSmall: 500,
        small: 640,
        medium: 1024,
        large: 1280,
        extraLarge: 1400,
    }),
    currentStep: (_state, action) => action.type,
    allUsers: allUsersPageSlice.reducer,
    allUsersForPublications: allUsersForPublicationsPageSlice.reducer,
    allUsersForTools: allUsersForToolsPageSlice.reducer,
    allActiveTrophiesForTools: allActiveTrophiesForToolsPageSlice.reducer,
    allInfluencers: allInfluencersPageSlice.reducer,
    searchInfluencersByInfluencerName: searchInfluencersByInfluencerNamePageSlice.reducer,
    updateTrophyForUser: updateTrophyOfUserPageSlice.reducer,
    userPublications: userPublicationsPageSlice.reducer,
    deleteUserPublication: deleteUserPublicationPageSlice.reducer,
    addRole: addUserPageSlice.reducer,
    removeRole: removeUserRolePageSlice.reducer,
    socialStatistics: allSocialStatisticsPageSlice.reducer,
    allBookmakers: allBookmakersPageSlice.reducer,
    updatedBookmakers: updateBookmakerPageSlice.reducer,
    bookmakerUrl: uploadBookmakerImagePageSlice.reducer,
    allEvents: allEventsPageSlice.reducer,
    searchEventByName: searchEventByNamePageSlice.reducer,
    allMatch: allMatchsPageSlice.reducer,
    allMatchesForChallenge: allMatchesForChallengePageSlice.reducer,
    allPendingMatch: allPendingMatchsPageSlice.reducer,
    allSportsCategories: allSportsPageSlice.reducer,
    urlSportImage: uploadSportImagePageSlice.reducer,
    basicMetricsReport: basicMetricsReportPageSlice.reducer,
    allConnection: allConnectionPageSlice.reducer,
    allChallenges: allChallengesPageSlice.reducer,
    deleteChallenge: deleteChallengePageSlice.reducer,
    challengeInfo: challengeInfoPageSlice.reducer,
    searchChallenge: searchChallengeByTitlePageSlice.reducer,
    allSportsForChallenge: allSportsForChallengePageSlice.reducer,
    allMatchScores: allMatchScoresSlice.reducer,
    updateMatchScore: updateMatchScoreSlice.reducer,
    searchMatchScores: searchMatchScoresSlice.reducer,
    countPendingMatchScores: countPendingMatchScoresSlice.reducer,
    urlChallengeImage: uploadImageChallengePageSlice.reducer,
    urlChallengeAd: uploadImageChallengeAdPageSlice.reducer,
    createChallenge: createChallengePageSlice.reducer,
    createInfluencer: createInfluencerPageSlice.reducer,
    allEventsForChallenge: allEventsForChallengePageSlice.reducer,
    oddTypes: oddTypesPageSlice.reducer,
    updateChallenge: updateChallengePageSlice.reducer,
    packConfiguration: allPackConfigurationPageSlice.reducer,
    users: allUsersForMemberShipPageSlice.reducer,
    allUsersForModeration: allUsersForModerationPageSlice.reducer,
    fetchDailyUserCount : dailyUserCountSlice.reducer,
    allUserForGodfather: allUsersForGodfatherPageSlice.reducer,
    addMembershipLevelInUserConfiguration: addMembershipLevelInUserConfigurationPageSlice.reducer,
    allProductConfiguration: allProductConfigurationPageSlice.reducer,
    allPackInformation: allPackInformationPageSlice.reducer,
    updatePackInformation: updatePackInformationPageSlice.reducer,
    urlNewsImage: uploadImageNewsPageSlice.reducer,
    allNews: allNewsPageSlice.reducer,
    userInfos: getUserPageSlice.reducer,
    userDetailsPublications: getUserPublicationsPageSlice.reducer,
    userDetailsComments: getUserCommentsPageSlice.reducer,
    userDetailsAnalysis: getUserAnalysisPageSlice.reducer,
    expertStatResponse: fetchExpertStatSlice.reducer,
    allExpertsResponse: fetchAllExpertsSlice.reducer,
    followedExpertsByUserResponse: expertFollowedByUserSlice.reducer,
    expertSubscriptionStatResponse: fetchExpertSubscriptionStatSlice.reducer,
    followUserResponse: followUserSlice.reducer,
    allAdmins: allAdminsPageSlice.reducer,
    sendMessage: sendMessagePageSlice.reducer,
    allSupportMessages: allSupportMessagesPageSlice.reducer,
    allPublications: allPublicationsPageSlice.reducer,
    supportMessage: getSupportMessagePageSlice.reducer,
    updateMatch: updateMatchPageSlice.reducer,
    packLevels: allPackLevelsPageSlice.reducer,
    packFeatures: allPackFeaturesPageSlice.reducer,
    addPackConfig: addPackConfigurationPageSlice.reducer,
    nbPackBuyed: nbPackBuyPageSlice.reducer,
    rankingHistory: rankHistoryPageSlice.reducer,
    challHistory: rankChallPageSlice.reducer,
    deletePackConfig: deletePackConfigurationPageSlice.reducer,
    blockUser: blockPageSlice.reducer,
    unBlockUser: unBlockPageSlice.reducer,
    createNews: createNewsPageSlice.reducer,
    allGeneratedAlerts: allGeneratedAlertsPageSlice.reducer,
    allUsersForCampaign: allUsersForCampaignPageSlice.reducer,
    allCampaigns: allCampaignsPageSlice.reducer,
    sendCampaign: sendCampaignPageSlice.reducer,
    unLinkSobetReducer: unlinkSobetPageSlice.reducer,
    matchToLinkWithSobet: matchToLinkWithSobetPageSlice.reducer,
    allEventsBySport: allEventsBySportPageSlice.reducer,
    allMatchByEvent: allMatchByEventPageSlice.reducer,
    isUserDeleted: deleteUserPageSlice.reducer,
    allRoles: allRolesPageSlice.reducer,
    allTrophies: allTrophiesPageSlice.reducer,
    allTrophiesForTools: allTrophiesForToolsPageSlice.reducer,
    deleteTrophy: deleteTrophyPageSlice.reducer,
    updateTrophy: updateTrophyPageSlice.reducer,
    createTrophy: createTrophyPageSlice.reducer,
    deleteNews: deleteNewsPageSlice.reducer,
    editNews: editNewsPageSlice.reducer,
    allFeedsForSendFeed: allFeedsForSendFeedPageSlice.reducer,
    allFeedsForManageFeeds: allFeedsForManageFeedsPageSlice.reducer,
    updateFeed: updateFeedPageSlice.reducer,
    deleteFeed: deleteFeedPageSlice.reducer,
    allMatchesWithResult: allMatchesWithResultPageSlice.reducer,
    updateMatchResult: updateMatchResultPageSlice.reducer,
    authUser,
    retrieveBasicMetricsReport,
    contributionAllCompetitions,
    userReducers,
    allMatchs,
    bookmakerReducers,
    contributionAllV2Competitions,
    allV2Matchs,
    bookmakerV2Reducers,
    authorization,
    userDetails,
    watchingSponsorshipReducers,
    contactUsReducers,
    publicationReducers,
    campainReducers,
    teamReducers,
    teamDetailsReducers,
    membershipReducers,
    lastDeviceInformationReducers,
    oddVariationReducers,
    socialStatisticsReducers,
    sportReducers,
    reducerChallenge,
    reducerMatchs,
    reducerEvents,
    dailyUserCountActions,
    reducerOddTypes,
    sportV2Reducer,
    rankingReducer,
    challengesReducer,
    reducerTeamsForChallenges,
    reducerNews,
    reducerTrophy,
    reducerConnection,
    reducerImageForChallenge,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
