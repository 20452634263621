import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";

import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

const AUTHENTICATED_USER_STORAGE_KEY = "sobet-authenticated-user";

enum AuthenticateActionType {
    AUTHENTICATE = "AUTHENTICATE",
    DISCONNECT = "DISCONNECT",
    RESET = "RESET"
}

export const authenticateActions = generateApiActions(AuthenticateActionType.AUTHENTICATE);
export const actionAuthenticate = (form: any) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/auth/signin")
        .verb(HTTP_VERBS.POST)
        .types(authenticateActions.all())
        .withBody(form)
        .build();

export const actionDisconnect = createAction(AuthenticateActionType.DISCONNECT, () => {
    localStorage.removeItem(AUTHENTICATED_USER_STORAGE_KEY);
});

export const actionReset = createAction(AuthenticateActionType.RESET, () => {

});


const getDefaultState = () => {
    const authUserFromLocalStorage = localStorage.getItem(AUTHENTICATED_USER_STORAGE_KEY);
    try {
        if (authUserFromLocalStorage) {
            return JSON.parse(authUserFromLocalStorage);
        }
    } catch (exception) {
        return {};
    }
    return {};
};

// todo remove quand on aura sauvegardé le store dans le localStorage pour gérer le F5.
const defaultState = getDefaultState();

const reducer = handleActions(
    {
        [combineActions(authenticateActions.SUCCESS, authenticateActions.FAILURE)]: {
            next: (state, {payload}) => (payload ? {...payload.content, isLoading: false} : defaultState),
            throw: (state, {payload}) => (payload ? {errors: payload.errors, isLoading: false} : defaultState)
        },
        [combineActions(authenticateActions.REQUEST)]: {
            next: (state, {payload}) => ({...state, isLoading: true}),
        },
        [combineActions(AuthenticateActionType.DISCONNECT)]: {
            next: () => defaultState,
            throw: () => defaultState
        },
        [combineActions(AuthenticateActionType.RESET)]: {
            next: () => defaultState,
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducer;
