import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {
    AllEventsPageState,
    AllMatchesPageState,
    AllSportsPageState,
    ChallengeInfoPageState,
    fetchAllEventsForChallengePage,
    fetchAllMatchesForChallengePage,
    fetchAllSportsPage,
    fetchChallengeInfoPage,
    fetchOddTypesPage,
    fetchUploadImageChallengePage,
    OddTypesPageState,
    UploadImageChallengePageState
} from "../create-challenge/challenge-reducer";
import {AppState} from "../../../store/reducers";
import {fetchUpdateChallengePage, UpdateChallengePageState} from "../all-challenges/all-challenges-reducer";
import {AllBookmakersPageState, fetchAllBookmakersPage} from "../../contribution/bookmaker/bookmaker-reducer";
import {Challenge} from "../../../typings/api";
import moment from "moment";
import {isDevConfig, isProdConfig} from "../../../services/config";
import './typeahead.scss'
import {AllPackInformationPageState, fetchAllPackInformationPage} from "../../membership/membership-reducer";

const ChallengeDetails = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [showUpdate, setShowUpdate] = useState(false);
    const [showError, setShowError] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [challenge, setChallenge] = useState<Challenge>({
        id: 0,
        title: "",
        urlImage: "",
        presentation: "",
        shortDescription: "",
        nbMinPredictions: 0,
        packInformation: "",
        rules: "",
        startDate: "",
        expirationDisplayDate: "",
        expirationDate: "",
        webUrl: null,
        annoncesLots: "",
        priceCents: 0,
        paid: false,
        lots: [],
        sports: [],
        event: [],
        matchs: [],
        bookmakers: [],
        oddTypes: [],
        nbParticipants: 0,
        advertising: "",
        googlePlayProductName: "",
        totalPredictions: 0,
        version: 0,
        updatedAt: null,
        createdAt: null,
        activeSubscriptionsCount: 0,
        otherCount: 0
    });
    const [loading, setLoading] = useState(true);

    const {allSportsForChallenge} = useSelector<AppState, AllSportsPageState>(state => state.allSportsForChallenge);
    const {allEventsForChallenge} = useSelector<AppState, AllEventsPageState>(state => state.allEventsForChallenge);
    const {allMatchesForChallenge} = useSelector<AppState, AllMatchesPageState>(state => state.allMatchesForChallenge);
    const {allBookmakers} = useSelector<AppState, AllBookmakersPageState>(state => state.allBookmakers);
    const {urlChallengeImage} = useSelector<AppState, UploadImageChallengePageState>(state => state.urlChallengeImage);
    const {oddTypes} = useSelector<AppState, OddTypesPageState>(state => state.oddTypes);
    const {isUpdated, isLoading} = useSelector<AppState, UpdateChallengePageState>(state => state.updateChallenge);
    const {allPackInformation} = useSelector<AppState, AllPackInformationPageState>(state => state.allPackInformation);

    const {challengeInfo} = useSelector<AppState, ChallengeInfoPageState>(state => state.challengeInfo);
    let id: any;
    ({id} = useParams());
    const now_date = Date.now();

    /*useEffect(() => {
        console.log(challenge.paid)
    }, [challenge])*/

    useEffect(() => {
        dispatch(fetchAllSportsPage({}));
        dispatch(fetchAllBookmakersPage({}));
        dispatch(fetchAllEventsForChallengePage({}));
        dispatch(fetchAllMatchesForChallengePage({}));
        dispatch(fetchOddTypesPage({}));
        dispatch(fetchAllPackInformationPage({}));
        dispatch(fetchChallengeInfoPage({challengeId: id}));
    }, []);
    const getWebUrl = () => {
        if (isProdConfig()) {
            return "https://www.sobet.fr/webapp/challenge/sponsored/detail/"
        } else if (isDevConfig()) {
            return "https://localhost:3001/webapp/challenge/sponsored/detail/"
        } else {
            return "https://www-recette1.sobet.fr/webapp/challenge/sponsored/detail/"
        }
    }
    useEffect(() => {
        if (confirmed && !isLoading) {
            setConfirmed(false);
            isUpdated ? history.push('/all-challenges') : setShowError(true);
        }
    }, [isLoading])

    useEffect(() => {
        setChallenge(challengeInfo);
        setIsPaid(challengeInfo.paid);
    }, [challengeInfo])

    useEffect(() => {
        if (challenge.updatedAt) {
            setLoading(!allMatchesForChallenge.length);
        }
    }, [challenge])

    useEffect(() => {
        if (challenge.updatedAt) {
            setLoading(!challenge.updatedAt);
        }
    }, [allMatchesForChallenge])

    useEffect(() => {
        setChallenge({...challenge, urlImage: urlChallengeImage});
    }, [urlChallengeImage]);

    const handleImageChange = async event => {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        await dispatch(fetchUploadImageChallengePage({data: data}));
    };

    const handleChallengeIsPaid = () => {
        setIsPaid(!isPaid);
        if (!isPaid)
            setChallenge({...challenge, ["priceCents"]: 0})
    };
    const handleChallengeItemChange = (key, value) => {
        setChallenge({...challenge, [key]: value});
    };

    const handleDateChange = (key, event) => {
        const date = new Date(event.target.value);
        if (key === "startDate") {
            date.setHours(0);
            date.setMinutes(0);
        } else {
            date.setHours(23);
            date.setMinutes(59);
        }
        console.log(date)
        setChallenge({...challenge, [key]: date});
    };

    const handleUpdateClick = () => {
        setShowUpdate(true);
        setChallenge({...challenge, ["paid"]: isPaid})
    };

    const handleConfirmClick = () => {
        setConfirmed(true);
        setShowUpdate(false);
        console.log(challenge.expirationDisplayDate.toString());
        if (!challenge.paid) setChallenge({...challenge, ["priceCents"]: 0})
        dispatch(fetchUpdateChallengePage({challenge: challenge, challengeId: id}));
    };

    const checkBetweenDate = (date) => {
        if ((Date.parse(challenge.expirationDate) > Date.parse(date)) && (Date.parse(challenge.startDate) < Date.parse(date))) {
            return true;
        }
        return false;
    }

    if (loading)
        return (
            <div className="start-page">
                <header className="page-header page-header-dark">
                    <h1>Chargement en cours...</h1>
                </header>
            </div>
        );

    const selectPack = (key, event) => {
        setChallenge({...challenge, [key]: event.target.value});
        console.log(challenge.packInformation);
    }

    return (
        <div className="start-page w-90 mb-4">
            <header className="page-header page-header-dark row">
                <button className="btn btn-danger ml-3 mr-4 mb-3" onClick={() => history.push('/all-challenges')}>
                    Retour
                </button>
                <h1>{challenge.title.length ? challenge.title : "Sans titre"}</h1>
            </header>
            <div className="card mb-2">
                <div className="card-header">
                    <h4>Informations</h4>
                </div>
                <div className="card-body">
                    <div className="ml-0 row">
                        <div className="w-50">
                            <div className="mb-4">
                                <h5>Image du challenge</h5>
                                <input type="file" name="urlImage" onChange={e => handleImageChange(e)}/>
                            </div>
                            <div className="mb-4">
                                <h5>Titre du challenge</h5>
                                <input className="border rounded" type="text" placeholder="Titre"
                                       value={challenge.title}
                                       onChange={e => handleChallengeItemChange("title", e.target.value)}/>
                            </div>
                            <div className="mb-4">
                                <h5>Duree du challenge</h5>
                                <div className="row">
                                    <div className="ml-3">
                                        <h6>Debut</h6>
                                        <input className="border rounded mb-2" type="date"
                                               value={moment(challenge.startDate).format('YYYY-MM-DD')}
                                               onChange={e => handleDateChange("startDate", e)}/>
                                    </div>
                                    <div className="ml-4">
                                        <h6>Fin</h6>
                                        <input className="border rounded" type="date"
                                               value={moment(challenge.expirationDate).format('YYYY-MM-DD')}
                                               onChange={e => handleDateChange("expirationDate", e)}/>
                                    </div>
                                    <div className="ml-4">
                                        <h6>Fin d'affichage</h6>
                                        <input className="border rounded" type="date"
                                               value={moment(challenge.expirationDisplayDate).format('YYYY-MM-DD')}
                                               onChange={e => handleDateChange("expirationDisplayDate", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-50">
                            <img className="card-img" src={challenge.urlImage} alt="apercu de l'image"/>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h5>Presentation courte du challenge</h5>
                        <textarea className="form-control-plaintext border rounded" placeholder="Presentation courte"
                                  onChange={e => handleChallengeItemChange("shortDescription", e.target.value)}/>
                    </div>
                    <div className="mb-4">
                        <h5>Presentation detaillee du challenge</h5>
                        <textarea className="form-control-plaintext border rounded" placeholder="Presentation detaillee"
                                  value={challenge.presentation}
                                  onChange={e => handleChallengeItemChange("presentation", e.target.value)}/>
                    </div>
                    <div className="w-50">
                        <div className="mb-4">
                            <h5>Minimum amount of predictions</h5>
                            <input type="number" min="0" className="form-control-plaintext border rounded w-25"
                                   placeholder="Minimum"
                                   value={challenge.nbMinPredictions}
                                   onChange={e => handleChallengeItemChange("nbMinPredictions", e.target.value)}/>
                        </div>
                    </div>
                    <div className="w-50">
                        <div className="mb-4">
                            <h5>Membership Access</h5>
                            <select value={challenge.packInformation}
                                    onChange={event => selectPack("packInformation", event)}>
                                <option key={-1} value="PACK" disabled={true}>PACK</option>
                                {
                                    allPackInformation.map((packInformation, index) =>
                                        <option key={index}
                                                value={packInformation.level}>{packInformation.level}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h5>Challenge Payant :</h5>
                        <div onClick={e => {
                            handleChallengeIsPaid()
                        }} className={isPaid ? "green-btn" : "red-btn"}>{isPaid ? "PAYING" : "FREE"}</div>
                        {/*<input className="border rounded" type="checkbox"
                               onChange={e => {
                                   handleChallengeIsPaid("paid", e.target.checked)
                               }}/>*/}
                    </div>
                    {challenge.paid && (
                        <>
                            <div className="mb-4">
                                <h5>Prix en centime (exemple : 10€99 = 1099) :</h5>
                                <input className="border rounded" type="number"
                                       value={challenge.priceCents}
                                       onChange={e => {
                                           handleChallengeItemChange("priceCents", e.target.value)
                                       }}/>
                            </div>
                            <div className="mb-4">
                                <h5>Nom du produit Google Play</h5>
                                <input className="border rounded" type="text"
                                       value={challenge.googlePlayProductName}
                                       onChange={e => {
                                           handleChallengeItemChange("googlePlayProductName", e.target.value)
                                       }}/>
                            </div>
                        </>

                    )}
                    <div className="mb-4">
                        <h5>Rediriger l'utilisateur vers le web (Challenge NetBet)</h5>
                        <input className="border rounded" type="checkbox"
                               onChange={e => {
                                   if (e.target.checked) {
                                       handleChallengeItemChange("webUrl", getWebUrl())
                                   } else {
                                       handleChallengeItemChange("webUrl", null)
                                   }
                               }}/>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-header">
                    <h4>Prix a gagner</h4>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <h5>Annonce des prix</h5>
                        <textarea className="form-control-plaintext border rounded" placeholder="Annonce des prix"
                                  value={challenge.annoncesLots}
                                  onChange={e => handleChallengeItemChange("annoncesLots", e.target.value)}/>
                    </div>
                    <div className="mb-4">
                        <h5>List des prix</h5>
                        <textarea className="form-control-plaintext border rounded" placeholder="Prix"
                                  value={challenge.lots.join('\n')}
                                  onChange={e => handleChallengeItemChange("lots", e.target.value.split('\n'))}/>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-header">
                    <h4>Reglement du challenge</h4>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <h5>Conditions et reglement du challenge</h5>
                        <textarea className="form-control-plaintext border rounded" placeholder="Reglement"
                                  value={challenge.rules}
                                  onChange={e => handleChallengeItemChange("rules", e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-header">
                    <h4>Predictions eligibles</h4>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <h5>Sport(s)</h5>
                        <Typeahead id="sport" label="Sport(s)" placeholder="Selectionnez un sport"
                                   onChange={items => handleChallengeItemChange("sports", items.map(e => e.id))}
                                   multiple={true}
                                   options={allSportsForChallenge.map(e => {
                                       return {id: e.id, label: e.name.items[0].trad};
                                   })}
                                   defaultSelected={allSportsForChallenge.map(e => {
                                       return {id: e.id, label: e.name.items[0].trad};
                                   }).filter(e => challengeInfo.sports.includes(e.id))}/>
                    </div>
                    <div className="mb-4">
                        <h5>Competition(s)</h5>
                        <Typeahead id="event" label="Event(s)" placeholder="Selectionnez une competition"
                                   onChange={items => handleChallengeItemChange("event", items.map(e => e.id))}
                                   multiple={true}
                                   options={allEventsForChallenge.map(e => {
                                       return {id: e.id, sport: e.sportCategoryId, label: e.name.items[0].trad};
                                   })} filterBy={(option, props) => challenge.sports.includes(option.sport)}
                                   defaultSelected={allEventsForChallenge.map(e => {
                                       return {id: e.id, sport: e.sportCategoryId, label: e.name.items[0].trad};
                                   }).filter(e => challengeInfo.event.includes(e.id))}/>
                    </div>
                    <div className="mb-4">
                        <h5>Match(s) M</h5>
                        <Typeahead id="match" label="Match(es)" placeholder="Selectionnez un match"
                                   onChange={items => handleChallengeItemChange("matchs", items.map(e => e.id))}
                                   multiple={true}
                                   options={allMatchesForChallenge.filter(m => {
                                       return Date.parse(m.dateTime.toString()).valueOf() > (now_date.valueOf())
                                   }).map(e => {
                                       return {id: e.id, event: e.eventId, label: e.name, datetime: e.dateTime};
                                   })}
                                   filterBy={(option, props) => challenge.event.includes(option.event) && !challenge.matchs.includes(option.id)}
                                   defaultSelected={allMatchesForChallenge.map(e => {
                                       return {id: e.id, event: e.eventId, label: e.name, date: e.dateTime};
                                   }).filter(e => challengeInfo.matchs.includes(e.id))}/>
                    </div>
                    <div className="mb-4">
                        <h5>Type(s) de pari</h5>
                        <Typeahead id="oddtype" label="Odd Type(s)" placeholder="Selectionnez un type de pari"
                                   onChange={items => handleChallengeItemChange("oddTypes", items.map(e => e.id))}
                                   multiple={true}
                                   options={oddTypes.map(e => {
                                       return {id: e, label: e};
                                   })}
                                   defaultSelected={challengeInfo.oddTypes}/>
                    </div>
                    <div className="mb-4">
                        <h5>Bookmaker(s)</h5>
                        <Typeahead id="bookmaker" label="Bookmaker(s)" placeholder="Selectionnez un bookmaker"
                                   onChange={items => handleChallengeItemChange("bookmakers", items.map(e => e.id))}
                                   multiple={true}
                                   options={allBookmakers.map(e => {
                                       return {id: e.id, label: e.name.items[0].trad};
                                   })}
                                   defaultSelected={allBookmakers.map(e => {
                                       return {id: e.id, label: e.name.items[0].trad};
                                   }).filter(e => challengeInfo.bookmakers.includes(e.id))}/>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary mb-4"
                    onClick={() => handleUpdateClick()}>
                Mettre a jour le challenge
            </button>
            {
                showUpdate &&
                <Modal size="lg" show={showUpdate} onHide={() => setShowUpdate(false)}>
                    <Modal.Header closeButton className="alert alert-info">
                        <Modal.Title>Confirmation de mise a jour</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="ml-0 row">
                            <div className="w-60">
                                <h3>{challenge.title.length ? challenge.title : 'Pas de titre !'}</h3>
                                <div className="mb-2">
                                    Short Presentation: {challenge.shortDescription}
                                </div>
                                <div className="mb-2">
                                    {new Date(challenge.startDate).toDateString() + " - " + new Date(challenge.expirationDate).toDateString()}
                                </div>
                                <div className="mb-2">
                                    {new Date(challenge.expirationDisplayDate).toDateString()}
                                </div>
                                <div className="mb-2">
                                    Presentation: {challenge.presentation}
                                </div>
                                <div className="mb-2">
                                    Prizes announcement: {challenge.annoncesLots}
                                </div>
                                <div className="mb-2">
                                    Prizes: {challenge.lots.join(', ')}
                                </div>
                                <div className="mb-2">
                                    Rules: {challenge.rules}
                                </div>
                                <div className="mb-2">
                                    Payant: {challenge.paid ? "Oui" : "Non"}
                                </div>
                                <div className="mb-2">
                                    Prix: {challenge.priceCents}
                                </div>
                                <div className="mb-2">
                                    Nom du produit Google Play: {challenge.googlePlayProductName}
                                </div>
                                <div className="mb-2">
                                    Sport(s): {allSportsForChallenge.filter(e => challenge.sports.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                </div>
                                <div className="mb-2">
                                    Event(s): {allEventsForChallenge.filter(e => challenge.event.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                </div>
                                <div className="mb-2">
                                    Match(es): {allMatchesForChallenge.filter(e => challenge.matchs.includes(e.id)).map(e => e.name).join(', ')}
                                </div>
                                <div className="mb-2">
                                    Odd Type(s): {challenge.oddTypes.join(', ')}
                                </div>
                                <div className="mb-2">
                                    Bookmaker(s): {allBookmakers.filter(e => challenge.bookmakers.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                </div>
                                {challenge.webUrl && <div className="mb-2">
                                    Rediriger vers le web : {challenge.webUrl}

                                </div>}
                            </div>
                            <div className="w-50">
                                <img className="card-img" src={challenge.urlImage} alt="can't display image"/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="row">
                        <Button variant="danger" onClick={() => setShowUpdate(false)}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={() => handleConfirmClick()}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showError &&
                <Modal size="lg" show={showError} onHide={() => setShowError(false)}>
                    <Modal.Header className="alert alert-danger">
                        <Modal.Title>Encountered error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please verify challenge's infos.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowError(false)}>
                            Modify
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                confirmed && isLoading &&
                <Modal size="sm" show={confirmed && isLoading}>
                    <Modal.Header className="alert alert-warning">
                        <Modal.Title>Loading please wait...</Modal.Title>
                    </Modal.Header>
                </Modal>
            }
        </div>
    );
};

export default ChallengeDetails;
