import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const createNews = generateApiActions("create_post_news");

export const actionCreateNews = (news) => {
    return RsaaBuilder.getInstance()
        .apiEndpoint("/news/")
        .withBody(news)
        .verb(HTTP_VERBS.POST)
        .types(createNews.all())
        .build();
}

export const allNews = generateApiActions("all_get_news");

export const actionGetAllNews = () => {
    return RsaaBuilder.getInstance()
        .apiEndpoint(`/news/`)
        .verb(HTTP_VERBS.GET)
        .types(allNews.all())
        .build();
}

export const deleteNews = generateApiActions("delete_news");

export const actionDeleteNews = (newsId: number) => {
    return RsaaBuilder.getInstance()
        .apiEndpoint(`/news/${newsId}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteNews.all())
        .build();
}

export const updateNewsStatus = generateApiActions("update_news_status");

export const actionUpdateNewsStatus = (newsId: number, status: string) => {
    return RsaaBuilder.getInstance()
        .apiEndpoint(`/news/${newsId}/change-status/${status}`)
        .verb(HTTP_VERBS.POST)
        .types(updateNewsStatus.all())
        .build();
}

export const uploadImageActions = generateApiActions("upload_image_news");

export const actionUploadImage = (data) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .types(uploadImageActions.all())
        .build(true);


const defaultState = {err: "error"}

const reducerNews = handleActions(
    {
        [combineActions(uploadImageActions.FAILURE, uploadImageActions.SUCCESS)]: {
            next: (state, {payload}) => (payload ? {newsImageUrl: payload.content, isLoading: false} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(uploadImageActions.REQUEST)]: {
            next: (state, {payload}) => ({...state, isLoading: true}),
            throw: () => defaultState,
        },

        [combineActions(...allNews.all())]: {
            next: (state, {payload}) => (payload ? {newsList: payload.content.content} : {...state}),
            throw: () => defaultState,
        },

        [combineActions(...deleteNews.all())]: {
            next: (state, {payload}) => (payload ? {newsDeleted: payload.content} : {...state}),
            throw: () => defaultState,
        },

        [combineActions(...updateNewsStatus.all())]: {
            next: (state, {payload}) => (payload ? {newsUpdated: payload.content} : {...state}),
            throw: () => defaultState,
        },

        [combineActions(...createNews.all())]: {
            next: (state, {payload}) => (payload ? {news: payload.content, isCreated: true} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducerNews;
