import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    AllInfluencersPageState,
    fetchAllInfluencersPage,
    fetchSearchInfluencersByInfluencerNamePage,
    SearchInfluencersByInfluencerNamePageState
} from "./all-influencers-reducer";
import {Page} from "../../typings/api";
import {AppState} from "../../store/reducers";
import {useHistory} from "react-router-dom";
import Pagination from "../../uicomponents/pagination/pagination";

const AllInfluencers = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [influencers, setInfluencers] = useState<Page>({} as Page);
    const [search, setSearch] = useState("");

    const {allInfluencers} = useSelector<AppState, AllInfluencersPageState>(state => state.allInfluencers);
    const {searchInfluencer} = useSelector<AppState, SearchInfluencersByInfluencerNamePageState>(state => state.searchInfluencersByInfluencerName);

    useEffect(() => {
        dispatch(fetchAllInfluencersPage({page: 0}));
    }, []);

    useEffect(() => {
        if (search === "")
            setInfluencers(allInfluencers);
        else
            dispatch(fetchSearchInfluencersByInfluencerNamePage({influencerName: search, page: 0}));
    }, [search]);

    const handleCreateClick = () => {
        history.push("/create-influencer");
    };

    useEffect(() => {
        setInfluencers(allInfluencers);
    }, [allInfluencers]);

    useEffect(() => {
        setInfluencers(searchInfluencer);
    }, [searchInfluencer]);

    const handleSearchTextChange = text => {
        setSearch(text.target.value);
    };

    const paginationChange = (event, page) => {
        //if (search === "")
        dispatch(fetchAllInfluencersPage({page: page}))
        //else
        //    dispatch(fetchSe({title: search, page: page}));
    };

    return (
        <div className="start-page">
            <header className="page-header page-header-dark">
                <h1>Influenceurs</h1>
            </header>
            <div className="mb-2">
                <form className="form-inline">
                    <input
                        className="form-control"
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Rechercher"
                        onChange={e => handleSearchTextChange(e)}
                    />
                    <button className="btn btn-primary" onClick={() => handleCreateClick()}>
                        <i className="fas fa-plus"/>
                    </button>
                </form>
            </div>
            <div className="card">
                <div className="card-header">
                    <Pagination page={influencers} action={paginationChange}/>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Influenceur</th>
                        <th>Lien Store</th>
                        <th>Nombre de clicks Lien</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        influencers && influencers.content && influencers.content.map((influencers, index) =>
                            <tr key={index}>
                                <td>{influencers.influencerName}</td>
                                <td><a href={influencers.storeLink} target={"_blank"}>{influencers.storeLink}</a></td>
                                <td>{influencers.nbClickStore}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AllInfluencers;