import {ApiResponse, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";
import {HTTP_VERBS} from "../../../services/rsaa-builder";

type AllUsersForGodfatherApiReturnType = ApiResponse<Page>;
type FetchAllUsersForGodfatherPageArgs = { page: number, godfatherId: number, name: string };

export interface AllUsersForGodfatherPageState extends Loadable {
    allUsers: Page;
}

const initialAllUsersForGodfatherState: AllUsersForGodfatherPageState = {
    allUsers: {} as Page,
    isLoading: false,
};

export const fetchAllUsersForGodfatherPage = createAsyncThunk<AllUsersForGodfatherApiReturnType,
    FetchAllUsersForGodfatherPageArgs,
    ThunkApiArgs<AllUsersForGodfatherApiReturnType>>("AllUsersForGodfatherPage/fetch", async ({
                                                                                                  page,
                                                                                                  godfatherId,
                                                                                                  name,
                                                                                              }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/sponsorship/get-godsons/${godfatherId}/${name}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForGodfatherPageSlice = createSlice({
    name: "allUsersForGodfatherSlice",
    initialState: initialAllUsersForGodfatherState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersForGodfatherPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersForGodfatherPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allUsers = payload.content;
            })
            .addCase(fetchAllUsersForGodfatherPage.rejected, state => {
                state.isLoading = false;
            });
    },
});