import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {compose} from "redux";
import {Button, Form, Table} from "react-bootstrap";
import Popin from "../../../uicomponents/modal/modal";
import {actionUploadBookmakerImage} from "../../../store/reducers/bookmaker";
import I18nField from "../../../uicomponents/i18nfield/I18nField";
import {AppState} from "../../../store/reducers";
import {
    AllBookmakersPageState,
    fetchAllBookmakersPage,
    fetchUpdateBookmakerPage,
    fetchUploadBookmakerImagePage,
    UploadBookmakerImagePageState
} from "./bookmaker-reducer";

const ContributionBookmakerV2 = () => {

    const dispatch = useDispatch();
    const [bookmakerOnEdition, setBookmakerOnEdition] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>({});
    const {allBookmakers} = useSelector<AppState, AllBookmakersPageState>(state => state.allBookmakers);
    const {urlBookmakerImage} = useSelector<AppState, UploadBookmakerImagePageState>(state => state.bookmakerUrl);

    useEffect(() => {
        dispatch(fetchAllBookmakersPage({}));
    }, []);

    const updateBookmaker = () => {
        setShowModal(false);
        dispatch(fetchUpdateBookmakerPage({updateBookmakerRequest: bookmakerOnEdition}));
        // TODO : améliorer mis dans un timer pour attendre que le back se rafraichissent
        setTimeout(() => { //Start the timer
            dispatch(fetchAllBookmakersPage({}));
        }, 1000);
    };

    // TODO: avoir la technique avec redux form pour ne plus faire cette méthode
    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setBookmakerOnEdition({...bookmakerOnEdition, [name]: value});
    };

    const onContributionChange = (items) => {
        setBookmakerOnEdition({...bookmakerOnEdition, name: {items}});
        return items;
    };

    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onClickHandler = () => {
        const data = new FormData();
        data.append("file", selectedFile);
        dispatch(fetchUploadBookmakerImagePage({data: data}));
    };

    const onSelectBookmaker = (bookmaker) => {
        setBookmakerOnEdition(bookmaker);
        setShowModal(true);
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Contribution - Bookmaker</h1>
            <p className="mb-4">Sur les applications mobiles, les bookmakers seront affichés dans l'ordre suivant :
                de la + grande priorité à la - grande priorité

            </p>
            <div className="alert alert-info">
                L'ordre d'affichage des bookmakers dans cette page du backoffice est identique à celui des
                applications
                mobiles
            </div>

            {bookmakerOnEdition && bookmakerOnEdition.name &&
                <Popin
                    title={`Edition du bookmaker #${bookmakerOnEdition.id} - ${bookmakerOnEdition.name.items[0].trad}`}
                    showModal={showModal}
                    cancelAction={() => setShowModal(false)}
                    saveAction={updateBookmaker}>

                    <I18nField onChange={(items) => onContributionChange(items)}
                               contributionItems={bookmakerOnEdition.name.items}/>
                    <Form>

                        <I18nField onChange={(items) => onContributionChange(items)}
                                   contributionItems={bookmakerOnEdition.name.items}/>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>URL icône</Form.Label>
                            <Form.Control type="text" placeholder="Entrer l'url de l'icône ..."
                                          onChange={(event) => handleChange(event)}
                                          name="iconUrl"
                                          value={bookmakerOnEdition.iconUrl ? bookmakerOnEdition.iconUrl : ''}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>URL de l'image de la bannière</Form.Label>
                            <Form.Control type="text" placeholder="Entrer l'url de l'image de la bannière ..."
                                          onChange={(event) => handleChange(event)}
                                          name="bannerUrl"
                                          value={bookmakerOnEdition.bannerUrl ? bookmakerOnEdition.bannerUrl : ''}/>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>URL bookmaker</Form.Label>
                            <Form.Control type="text" placeholder="Entrer l'url du bookmaker ..."
                                          onChange={(event) => handleChange(event)}
                                          name="bookmakerUrl"
                                          value={bookmakerOnEdition.bookmakerUrl ? bookmakerOnEdition.bookmakerUrl : ''}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Priorité d'affichage</Form.Label>
                            <Form.Control type="number" placeholder="Entrer la priorité d'affichage ..."
                                          onChange={(event) => handleChange(event)}
                                          name="priority"
                                          value={bookmakerOnEdition.priority}
                            />
                        </Form.Group>
                    </Form>
                </Popin>
            }

            <div>
                <input type="file" name="file" onChange={(event) => onChangeHandler(event)}/>
                <Button variant="primary" onClick={() => onClickHandler()}><i
                    className="fas fa-upload"/> Telecharger</Button>
            </div>
            <br/>
            {urlBookmakerImage &&
                <div className="alert alert-success">
                    Le lien de l'image que vous venez d'importer :
                    <br/>
                    {urlBookmakerImage}
                </div>
            }
            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Nom</th>
                        <th>URL icône</th>
                        <th>URL de la bannière</th>
                        <th>URL Bookmaker</th>
                        <th>priorité (d'affichage)</th>
                        <th><i className="fas fa-cog"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allBookmakers && allBookmakers.map((bookmaker, index) =>
                        <tr key={index}>
                            <td>{bookmaker.id}</td>
                            <td>{bookmaker.code}</td>
                            <td>{bookmaker.name.items[0].trad}</td>
                            <td>{bookmaker.iconUrl}</td>
                            <td>{bookmaker.bannerUrl}</td>
                            <td>{bookmaker.bookmakerUrl}</td>
                            <td>{bookmaker.priority}</td>
                            <td>
                                <Button variant="primary" onClick={() => onSelectBookmaker(bookmaker)}>
                                    <i className="fas fa-pencil-alt"/>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    bookmakerImageUrlReducer: state.bookmakerReducers
});

const mapDispatchToProps = {
    uploadBookmakerImage: actionUploadBookmakerImage
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ContributionBookmakerV2);