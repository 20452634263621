import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum MembershipLevelActionReset {
    RESET = "RESET"
}

// sport

export const getSportsV2 = generateApiActions("bo_get_all_v2_sports");

export const actionGetSportsV2 = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/bo/sportcategory")
        .verb(HTTP_VERBS.GET)
        .types(getSportsV2.all())
        .build();

export const addSportV2 = generateApiActions("bo_add_v2_sport");

export const actionAddSportV2 = (product) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/sportcategory`)
        .verb(HTTP_VERBS.POST)
        .types(addSportV2.all())
        .withBody(product)
        .build();

export const deleteSportV2 = generateApiActions("bo_delete_v2_sport");

export const actionDeleteSportV2 = (product) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/sportcategory`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteSportV2.all())
        .withBody(product)
        .build();

const defaultState = {};

const reducer = handleActions(
    {

        [combineActions(...getSportsV2.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addSportV2.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...deleteSportV2.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducer;