import React from 'react';

interface PaginationProps {
    page: any,

    action(event: any, clickedPage: number): void;
}

export default class Pagination extends React.Component<PaginationProps> {

    buildPageList(currentPage, totalPages) {
        const nbVisiblePages = 7;

        if (totalPages === 0) {
            return [1];
        }
        var result: Array<any> = [];
        var startPage = Math.max(0, currentPage - nbVisiblePages);
        var endPage = Math.min(totalPages, currentPage + nbVisiblePages)

        if (startPage > 1) {
            result.push("...");
        }
        var pageToPrint: Array<number> = [];
        for (var i: number = startPage; i < endPage; i++) {
            result.push(String(i + 1));
        }
        if (endPage < totalPages) {
            result.push("...");
        }
        return result;
    }

    render() {
        const {page, action} = this.props;
        const pageList = this.buildPageList(page.number, page.totalPages);

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <ul className="pagination pagination-sm justify-content-center">
                            <li className={"page-item" + (page.first ? " disabled" : "")}>
                                <a className="page-link fas fa-arrow-left" href="#"
                                   onClick={(e) => action(e, page.number - 1)}/>
                            </li>
                            {
                                pageList.map((x, i) => (x === "...") ?
                                    <li key={i} className="page-item disabled">
                                        <a className="page-link">...</a>
                                    </li> :
                                    <li key={i}
                                        className={"page-item" + (page.number + 1 == x ? ' active' : '')}>
                                        <a className="page-link" href="#" onClick={(e) => action(e, x - 1)}>{x}</a>
                                    </li>
                                )
                            }
                            <li className={"page-item" + (page.last ? ' disabled' : '')}>
                                <a className="page-link fas fa-arrow-right" href="#"
                                   onClick={(e) => action(e, page.number + 1)}/>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm">
                        <span>Nombre total d'éléments : {page.totalElements}</span>
                    </div>
                </div>
            </div>
        );
    }

}