import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const dashboardActions = generateApiActions("bo_dashboard");

export const actionGetBasicMetricsReport = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/metrics/count-users")
        .verb(HTTP_VERBS.GET)
        .types(dashboardActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...dashboardActions.all())]: {
            next: (state, {payload}) => (payload ? {basicMetricsReport: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

