import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {
    AllPublicationsPageState,
    AllUsersForPublicationsPageState,
    DeleteUserPublicationPageState,
    fetchAllPublicationsPage,
    fetchAllUsersForPublicationsPage,
    fetchDeleteUserPublicationPage,
    fetchUserPublicationsPage,
    UserPublicationsPageState
} from "./feed-moderation-reducer";
import moment from "moment";
import Pagination from "../../../uicomponents/pagination/pagination";
import AutoComplete from "../../../components/AutoComplete";
import Modal from "react-bootstrap/Modal";

const FeedModeration = () => {

    const dispatch = useDispatch();

    const [publications, setPublications] = useState<any>({});
    const [searchedUser, setSearchedUser] = useState<any>({});
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [selectedPublication, setSelectedPublication] = useState<any>({});

    const {allPublications} = useSelector<AppState, AllPublicationsPageState>(state => state.allPublications);
    const {allUsers} = useSelector<AppState, AllUsersForPublicationsPageState>(state => state.allUsersForPublications);
    const {userPublications} = useSelector<AppState, UserPublicationsPageState>(state => state.userPublications);
    const {isLoading} = useSelector<AppState, DeleteUserPublicationPageState>(state => state.deleteUserPublication);

    useEffect(() => {
        dispatch(fetchAllPublicationsPage({page: 0}));
        dispatch(fetchAllUsersForPublicationsPage({page: 0}));
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (searchedUser.length)
                dispatch(fetchUserPublicationsPage({page: 0, userId: searchedUser[0].id}));
            else
                dispatch(fetchAllPublicationsPage({page: 0}));
        }
    }, [isLoading])

    useEffect(() => {
        setPublications(allPublications);
    }, [allPublications]);

    useEffect(() => {
        setPublications(userPublications);
    }, [userPublications]);

    useEffect(() => {
        if (searchedUser.length)
            dispatch(fetchUserPublicationsPage({page: 0, userId: searchedUser[0].id}));
        else
            dispatch(fetchAllPublicationsPage({page: 0}));
    }, [searchedUser])

    const paginationChange = (event, pageClicked) => {
        if (searchedUser.length)
            dispatch(fetchUserPublicationsPage({page: pageClicked, userId: searchedUser[0].id}));
        else
            dispatch(fetchAllPublicationsPage({page: pageClicked}));
    };

    const handleUsersSelected = e => {
        setSearchedUser(e);
    };

    const handleDeleteClick = e => {
        setSelectedPublication(e);
        setShowDelete(true);
    };

    const handleDeletePublication = () => {
        dispatch(fetchDeleteUserPublicationPage({
            userId: selectedPublication.user.id,
            publicationId: selectedPublication.id
        }));
        setShowDelete(false);
    };

    return <div className="start-page w-75 mb-4">
        <header className="page-header page-header-dark">
            <h1>Moderations des publications</h1>
        </header>
        <div className="mb-2">
            <form className="form-inline">
                <AutoComplete users={allUsers} labelKey="nickname"
                              filter={["firstname", "lastname", "email"]} multiple={false}
                              placeHolderText="Choississez l'utilisateur"
                              handleUsersSelected={(event) => handleUsersSelected(event)}/>
            </form>
        </div>
        <div className="card mb-2">
            <div className="card-header">
                {
                    publications &&
                    <Pagination page={publications} action={paginationChange}/>
                }
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Message</th>
                    <th>Email</th>
                    <th>Pseudo</th>
                    <th>Type de publication</th>
                    <th>Feed</th>
                    <th>Supprimer</th>
                </tr>
                </thead>
                <tbody>
                {
                    publications && publications.content && publications.content.map((publication, index) =>
                        <tr key={index}>
                            <td>{publication.id}</td>
                            <td>{moment(publication.publishedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                            <td>{publication.message}</td>
                            <td>{publication.user.email}</td>
                            <td>{publication.user.nickname}</td>
                            <td>{publication.type}</td>
                            <td>{publication.sportType}</td>
                            <td>
                                <button className="ml-4 btn btn-danger" type="button"
                                        onClick={() => handleDeleteClick(publication)}>
                                    <i className="fas fa-trash"/>
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
        {
            showDelete &&
            <Modal size="lg" show={showDelete} onHide={() => setShowDelete(false)}>
                <Modal.Header closeButton className="alert alert-danger">
                    <Modal.Title>Suppression de message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirmez-vous la suppression de la publication ?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => handleDeletePublication()}>
                        Confirm deletion
                    </button>
                </Modal.Footer>
            </Modal>
        }
    </div>
};

export default FeedModeration;