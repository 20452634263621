import React, {useEffect, useState} from "react";
import {
    AllEventsBySportPageState,
    AllMatchByEventPageState,
    deleteMatchToLinkWithSobetPage,
    fetchAllEventsBySportPage,
    fetchAllMatchByEventPage,
    fetchMatchToLinkWithSobetPage,
    fetchUnLinkSobetModelPage,
    matchToLinkWithSobetPageSlice,
    MatchToLinkWithSobetPageState,
    UnlinkSobetPageState
} from "./unlinksobet-reducer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {Button, Form, Modal} from "react-bootstrap";
import {LinkBetweenBookAndSobetModel, ModelPronoType} from "../../typings/api";
import {AllSportsPageState, fetchAllSportsPage} from "../contribution/sport/sport-reducer";
import {JsonToTable} from "react-json-to-table";

const Unlinksobet = () => {

    const listModelProno = [ModelPronoType.MATCH, ModelPronoType.EVENT];

    const dispatch = useDispatch();
    const [modelPronoState, setModelpronoState] = useState(ModelPronoType.MATCH.toString());
    const [matchSobetRef, setMatchSobetRef] = useState("");
    const [linkBetweenBookAndSobetModelId, setLinkBetweenBookAndSobetModelId] = useState(0);
    const [wantDelete, setWantDelete] = useState(false);
    const [wantEdit, setWantEdit] = useState(false);
    const [currentMatch, setCurrentMatch] = useState<LinkBetweenBookAndSobetModel>({} as LinkBetweenBookAndSobetModel);

    const {unlinkSobetObject} = useSelector<AppState, UnlinkSobetPageState>(state => state.unLinkSobetReducer);
    const {allSports} = useSelector<AppState, AllSportsPageState>(state => state.allSportsCategories);
    const {allEventsBySport} = useSelector<AppState, AllEventsBySportPageState>(state => state.allEventsBySport);
    const {isFound} = useSelector<AppState, MatchToLinkWithSobetPageState>(state => state.matchToLinkWithSobet);
    const {allMatchByEvent} = useSelector<AppState, AllMatchByEventPageState>(state => state.allMatchByEvent);

    useEffect(() => {
        dispatch(fetchAllSportsPage({}));
    }, []);

    useEffect(() => {
        dispatch(fetchUnLinkSobetModelPage({modelPronoType: modelPronoState}));
        dispatch(matchToLinkWithSobetPageSlice.actions.setIsFound(false));
    }, [isFound]);


    const searchUnlinkSobet = () => {
        dispatch(fetchUnLinkSobetModelPage({modelPronoType: modelPronoState}));
    }

    const matchToLinkWithSobet = () => {
        dispatch(fetchMatchToLinkWithSobetPage({
            sobetRef: matchSobetRef,
            linkBetweenBookAndSobetModelId: linkBetweenBookAndSobetModelId
        }));
        setWantEdit(false);
    }

    const handleClickEdition = (match) => {
        setCurrentMatch(match);
        setLinkBetweenBookAndSobetModelId(match.id);
        allSports && allSports[0] && dispatch(fetchAllEventsBySportPage({sportId: allSports[0].id}));
        allEventsBySport && allEventsBySport[0] && dispatch(fetchAllMatchByEventPage({eventId: allEventsBySport[0].id}));
        setWantEdit(true);
    };

    const handleClickDeletion = (id) => {
        setLinkBetweenBookAndSobetModelId(id);
        setWantDelete(true);
    };

    const handleDeletion = (id) => {
        dispatch(deleteMatchToLinkWithSobetPage({linkBetweenBookAndSobetModelId: id}));
        setWantDelete(false);
    }

    const onSportChosen = (sportName: string) => {
        const sportId = allSports.filter(sport => sport.name.items[0].trad.trim() === sportName.trim())[0].id; // THESE TRIMS ARE TEMPORARY FIXES
        dispatch(fetchAllEventsBySportPage({sportId: sportId}));
    }

    const onSelectEvent = (eventName: string) => {
        const eventId = allEventsBySport.filter(event => event.name.items[0].trad.trim() === eventName.trim())[0].id; // THESE TRIMS ARE TEMPORARY FIXES
        dispatch(fetchAllMatchByEventPage({eventId: eventId}));
    }

    const onMatchChosen = (matchName: string) => {
        const sobetRef = allMatchByEvent.filter(match => match.name.trim() === matchName.trim())[0].sobetRef; // THESE TRIMS ARE TEMPORARY FIXES
        setMatchSobetRef(sobetRef);
    }

    const renderModal = () => {
        return (
            <Modal size="lg" show={wantEdit}
                   onHide={() => setWantEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edition du match</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="card mb-2">
                        <div className="card-header">
                            <JsonToTable json={currentMatch.bookData}/>
                        </div>
                        <div className="card-body">

                            <div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail4">Sport</label>
                                    <select className="form-control"
                                            onChange={(event) => onSportChosen(event.target.value)}>
                                        {
                                            allSports && allSports.map((sport, index) => (
                                                    <option key={index}>{sport.name.items[0].trad}</option>
                                                )
                                            )
                                        }

                                    </select>
                                </div>
                            </div>

                            <div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail4">Event</label>
                                    <select className="form-control"
                                            onChange={(event) => onSelectEvent(event.target.value)}>
                                        {
                                            allEventsBySport && allEventsBySport.map((event, index) => (
                                                    <option key={index}>{event.name.items[0].trad}</option>
                                                )
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            <div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail4">Match</label>
                                    <select className="form-control"
                                            onChange={(event) => onMatchChosen(event.target.value)}>
                                        {
                                            allMatchByEvent && allMatchByEvent.map((match, index) => (
                                                    <option key={index}>{match.name}</option>
                                                )
                                            )
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setWantEdit(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => matchToLinkWithSobet()}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>

            <h1 className="h3 mb-2 text-gray-800">Search unlink Sobet</h1>

            <div className="alert alert-info">
                Cet écran permet de lier les matchs d'un flux de données avec le modèle Sobet
            </div>

            <br/>

            <select className="col-md-4" onChange={(event) => setModelpronoState(event.target.value)}
                    style={{marginRight: "20px"}}>
                {listModelProno && listModelProno.map((el, index) => <option key={index}>{el}</option>)}
            </select>

            <Button variant="primary" onClick={() => searchUnlinkSobet()}>Search</Button>

            {renderModal()}

            <div className="card">
                {/* utile à la pagination
                <div className="card-header py-3">
                    {allConnection &&
                    <Pagination page={allUnlinkSobet} action={paginationChange}/>
                    }
                  </div>
                */
                }
                <table className="table">
                    <thead>
                    <tr>
                        <th>SobetRef</th>
                        <th>Model Prono Type</th>
                        <th>Book Reference Key</th>
                        <th>Nom du match</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    { /* pour une éventuelle pagination
                     { allUnlinkSobet && allUnlinkSobet.content && allUnlinkSobet.content.map((el, index) =>
                     */
                    }
                    {
                        unlinkSobetObject && unlinkSobetObject.map((match, index) =>
                            <tr key={index}>
                                <td>{match.sobetRef}</td>
                                <td>{match.modelPronoType}</td>
                                <td>{match.bookReferenceKey}</td>
                                <td><JsonToTable json={match.bookData}/></td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => handleClickEdition(match)}>
                                        <i className="fas fa-pencil-alt"/>
                                    </button>
                                    <button className="btn btn-danger ml-2"
                                            onClick={() => handleClickDeletion(match.id)}>
                                        <i className="fas fa-trash-alt"/>
                                    </button>
                                </td>
                            </tr>
                        )}
                    {wantDelete &&
                        <Modal size="lg" show={wantDelete}
                               onHide={() => setWantDelete(false)}>
                            <Modal.Header closeButton className="alert alert-info">
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Label>Voulez vous vraiment supprimer ce match ?</Form.Label>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary"
                                        onClick={() => handleDeletion(linkBetweenBookAndSobetModelId)}>
                                    Confirmer
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default Unlinksobet;