import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum UserPhoneNumberReset {
    RESET = "RESET_USER_PHONE_NUMBER"
}

enum UserExpertReset {
    RESET = "RESET_USER_EXPERT_SUCCESS"
}

export const dailyUserCountActions = generateApiActions("bo_get_daily_user_count");
export const actionGetDailyUserCounts = (startDateTime, endDateTime) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/sobet-api/v1/users/daily-count?start=${encodeURIComponent(startDateTime)}&end=${encodeURIComponent(endDateTime)}`)
        .verb(HTTP_VERBS.GET)
        .types(dailyUserCountActions.all())
        .build();

export const pageOfUsersActions = generateApiActions("bo_get_page_user");

export const actionGetUsers = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfUsersActions.all())
        .build();

export const searchUsersByStatus = generateApiActions("bo_search_user_by_status");

export const actionSearchUsersByStatut = (statut: string) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/search-status/${statut}`)
        .verb(HTTP_VERBS.GET)
        .types(searchUsersByStatus.all())
        .build();

export const searchUsersByNickname = generateApiActions("bo_search_user_by_nickname");

export const actionSearchUsersByNickname = (nickName: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/search/${nickName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchUsersByNickname.all())
        .build();

export const getAllUsers = generateApiActions("bo_get_all_users");

export const actionGetAllUsers = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/users/all")
        .verb(HTTP_VERBS.GET)
        .types(getAllUsers.all())
        .build();

export const addUserPhoneNumber = generateApiActions("bo_add__user_phone_number");

export const actionAddUserPhoneNumber = (sendRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/users/add-phone-without-vaidation")
        .withBody(sendRequest)
        .verb(HTTP_VERBS.POST)
        .types(addUserPhoneNumber.all())
        .build();

export const passUserExpert = generateApiActions("bo_pass_user_expert");

export const actionPassUserExpert = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/pass-expert/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(passUserExpert.all())
        .build();

export const removeUserExpert = generateApiActions("bo_remove_user_expert");

export const actionRemoveUserExpert = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/remove-expert/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(removeUserExpert.all())
        .build();

export const passUserSemiExpert = generateApiActions("bo_pass_user_semi_expert");

export const actionPassUserSemiExpert = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/pass-semi-expert/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(passUserExpert.all())
        .build();

export const removeUserSemiExpert = generateApiActions("bo_remove_user_semi_expert");

export const actionRemoveUserSemiExpert = (userId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/remove-semi-expert/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(removeUserExpert.all())
        .build();

export const actionUserPhoneNumberReset = createAction(UserPhoneNumberReset.RESET, () => {

});

export const userExpertReset = createAction(UserExpertReset.RESET, () => {

});

export const getAllUserExperts = generateApiActions("bo_get_user_experts");

export const actionGetAllUserExperts = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/experts`)
        .verb(HTTP_VERBS.GET)
        .types(getAllUserExperts.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...dailyUserCountActions.all())]: {
            next: (state, { payload }) => {
                return {...state, dailyCounts: payload ? payload.content : []};
            },
            throw: (state, { payload }) => {
                return {...state, errors: payload ? payload.errors : []};
            }
        },
        [combineActions(...pageOfUsersActions.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...searchUsersByStatus.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...searchUsersByNickname.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...getAllUsers.all())]: {
            next: (state, {payload}) => (payload ? {users: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...getAllUserExperts.all())]: {
            next: (state, {payload}) => (payload ? {userExperts: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addUserPhoneNumber.all())]: {
            next: (state, {payload}) => {
                return {...state, addUserPhoneNumberSuccess: payload ? true : false};
            },
            throw: () => defaultState
        },
        [combineActions(...passUserExpert.all())]: {
            next: (state, {payload}) => {
                return {...state, userExpertSuccess: payload ? true : false};
            },
            throw: () => defaultState
        },
        [combineActions(...removeUserExpert.all())]: {
            next: (state, {payload}) => {
                return {...state, userExpertSuccess: payload ? true : false};
            },
            throw: () => defaultState
        },

        [combineActions(...passUserSemiExpert.all())]: {
            next: (state, {payload}) => {
                return {...state, userSemiExpertSuccess: payload ? true : false};
            },
            throw: () => defaultState
        },
        [combineActions(...removeUserSemiExpert.all())]: {
            next: (state, {payload}) => {
                return {...state, userSemiExpertSuccess: payload ? true : false};
            },
            throw: () => defaultState
        },
        [combineActions(UserPhoneNumberReset.RESET)]: {
            next: (state) => ({...state, errors: [], addUserPhoneNumberSuccess: false}),
            throw: () => defaultState
        },
        [combineActions(UserExpertReset.RESET)]: {
            next: (state) => ({...state, errors: [], userExpertSuccess: false}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducer;
