import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {AppState} from "../../store/reducers";
import {
    AllTrophiesPageState,
    DeleteTrophyPageState,
    fetchAllTrophiesPage,
    fetchCreateTrophyPage,
    fetchDeleteTrophyPage,
    fetchUpdateTrophyPage,
    UpdateTrophyPageState
} from "./all-trophies-reducer";
import {DurationType, Trophy} from "../../typings/api";
import Modal from "react-bootstrap/Modal";

const AllTrophies = () => {

    const dispatch = useDispatch();

    const [trophy, setTrophy] = useState<Trophy>({} as Trophy);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const {allTrophies} = useSelector<AppState, AllTrophiesPageState>(state => state.allTrophies);
    const deletion = useSelector<AppState, DeleteTrophyPageState>(state => state.updateTrophy);
    const update = useSelector<AppState, UpdateTrophyPageState>(state => state.deleteTrophy);
    const create = useSelector<AppState, UpdateTrophyPageState>(state => state.createTrophy);

    useEffect(() => {
        dispatch(fetchAllTrophiesPage({}));
    }, []);

    useEffect(() => {
        if (!deletion.isLoading)
            dispatch(fetchAllTrophiesPage({}));
    }, [deletion.isLoading]);

    useEffect(() => {
        if (!update.isLoading)
            dispatch(fetchAllTrophiesPage({}));
    }, [update.isLoading]);

    const handleDeleteChallenge = () => {
        dispatch(fetchDeleteTrophyPage({id: trophy.id}));
        setShowDelete(false);
    };

    const handleEditChallenge = () => {
        dispatch(fetchUpdateTrophyPage({trophy: trophy}));
        setShowEdit(false);
    };

    const handleCreateChallenge = () => {
        dispatch(fetchCreateTrophyPage({trophy: trophy}));
        setShowCreate(false);
    };

    const handleChange = (field, event) => {
        setTrophy({...trophy, [field]: event});
    };

    const onEdition = (trophy) => {
        setTrophy(trophy);
        setShowEdit(true);
    }

    const onDelete = (trophy) => {
        setTrophy(trophy);
        setShowDelete(true);
    }

    const onCreate = () => {
        setTrophy({
            name: "",
            description: "",
            iconEmoji: "",
            durationType: DurationType.WEEK
        } as Trophy);
        setShowCreate(true);
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">
                Configuration des Trophées <Button disabled variant="primary" onClick={() => onCreate()}>
                <i className="fas fa-plus"/> (Unavailable)
            </Button>
            </h1>

            <div className="card shadow mb-4">
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Icone</th>
                        <th>Réference</th>
                        <th>Description</th>
                        <th>Durée</th>
                        <th>Modifier</th>
                        <th>Supprimer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        allTrophies && allTrophies.map((trophy, index) =>
                            <tr key={index}>
                                <td>{trophy.id}</td>
                                <td>{trophy.iconEmoji}</td>
                                <td>{trophy.name}</td>
                                <td>{trophy.description}</td>
                                <td>{trophy.durationType}</td>
                                <td>
                                    <Button variant="primary" onClick={() => onEdition(trophy)}>
                                        <i className="fas fa-pencil-alt"/>
                                    </Button>
                                </td>
                                <td>
                                    <Button disabled variant="danger" onClick={() => onDelete(trophy)}>
                                        <i className="fas fas fa-trash"/> (Disabled)
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
            {
                showDelete &&
                <Modal size="lg" show={showDelete} onHide={() => setShowDelete(false)}>
                    <Modal.Header closeButton className="alert alert-danger">
                        <Modal.Title>Suppression d'un trophée</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Voulez-vous vraiment supprimer {trophy ? trophy.name : 'none'}?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => handleDeleteChallenge()}>
                            Confirmer
                        </button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showEdit &&
                <Modal size="lg" show={showEdit} onHide={() => setShowEdit(false)}>
                    <Modal.Header closeButton className="alert alert-primary">
                        <Modal.Title>Edition de {trophy.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m-1">
                            <h5>Icone :</h5>
                            <input className="border rounded" type="text" placeholder="Icone" value={trophy.iconEmoji}
                                   onChange={e => handleChange("iconEmoji", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Description :</h5>
                            <input className="border rounded" type="text" placeholder="Description"
                                   value={trophy.description}
                                   onChange={e => handleChange("description", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Durée :</h5>
                            <select className="custom-select" value={trophy.durationType}
                                    onChange={e => handleChange("durationType", e.target.value)}>
                                <option value="WEEK">WEEK</option>
                                <option value="MONTH">MONTH</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => handleEditChallenge()}>
                            Modifier
                        </button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showCreate &&
                <Modal size="lg" show={showCreate} onHide={() => setShowCreate(false)}>
                    <Modal.Header closeButton className="alert alert-primary">
                        <Modal.Title>Creation d'un trophée</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m-1">
                            <h5>Réference :</h5>
                            <input className="border rounded" type="text" placeholder="Réference" value={trophy.name}
                                   onChange={e => handleChange("name", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Icone :</h5>
                            <input className="border rounded" type="text" placeholder="Icone" value={trophy.iconEmoji}
                                   onChange={e => handleChange("iconEmoji", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Description :</h5>
                            <input className="border rounded" type="text" placeholder="Description"
                                   value={trophy.description}
                                   onChange={e => handleChange("description", e.target.value)}/>
                        </div>
                        <div className="m-1">
                            <h5>Durée :</h5>
                            <select className="custom-select" value={trophy.durationType}
                                    onChange={e => handleChange("durationType", e.target.value)}>
                                <option value="WEEK">WEEK</option>
                                <option value="MONTH">MONTH</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => handleCreateChallenge()}>
                            Créer
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

export default AllTrophies;
