import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type AllSocialStatisticsApiReturnType = ApiResponse<any>;
type FetchAllSocialStatisticsPageArgs = { nbDays: number };

export interface AllSocialStatisticsPageState extends Loadable {
    socialStatistics: any;
}

const initialState: AllSocialStatisticsPageState = {
    socialStatistics: {},
    isLoading: false,
};

export const fetchAllSocialStatisticsPage = createAsyncThunk<AllSocialStatisticsApiReturnType,
    FetchAllSocialStatisticsPageArgs,
    ThunkApiArgs<AllSocialStatisticsApiReturnType>>("AllSocialStatisticsPage/fetch", async ({nbDays}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/social/statistics/?nbDays=${nbDays}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allSocialStatisticsPageSlice = createSlice({
    name: "allSocialStatisticsSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllSocialStatisticsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllSocialStatisticsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.socialStatistics = payload.content;
            })
            .addCase(fetchAllSocialStatisticsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******
