import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {fetchGetSupportMessagePage, GetSupportMessagePageState} from "./message-details-reducer";
import {useParams} from "react-router-dom";
import moment from "moment";

const MessageDetails = () => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [answer, setAnswer] = useState("");

    let id: any;
    ({id} = useParams());
    const {message, isLoading} = useSelector<AppState, GetSupportMessagePageState>(state => state.supportMessage);

    useEffect(() => {
        dispatch(fetchGetSupportMessagePage({id}));
    }, []);

    useEffect(() => {
        if (loading && !isLoading && message.user)
            setLoading(false);
    }, [isLoading]);

    if (loading)
        return (
            <div className="start-page">
                <header className="page-header page-header-dark">
                    <h1>Loading, please wait...</h1>
                </header>
            </div>
        );

    return <div className="start-page w-75">
        <header className="page-header page-header-dark">
            <h1>Demande de support n°{id}</h1>
        </header>
        <div className="card m-1">
            <div className="card-header h5">
                Utilisateur {
                message.user.userSpecialAttribut.expert &&
                <span className={"badge badge-primary"}>expert</span>
            } {
                message.user.userSpecialAttribut.semiExpert &&
                <span className={"badge badge-primary"}>semi-expert</span>
            }
            </div>
            <div className="card-body">
                <div className="mb-4">
                    Identité
                    : {message.user.civility}. {message.user.firstname} “{message.user.nickname}“ {message.user.lastname}
                </div>
                <div className="mb-4">
                    Pays : {message.user.country}
                </div>
                <div className="mb-4">
                    Email : {message.user.email}
                </div>
                <div className="mb-4">
                    Numéro : {message.user.phoneNumber}
                </div>
                <div className="mb-4">
                    Role(s) : {message.user.roles.map(role => role.name).join(', ')}
                </div>
                <div className="mb-4">
                    Titre
                    : {message.user.userMembershipAttribut.level} {
                    message.user.userMembershipAttribut.level === "PRO" && "jusqu'au " +
                    moment(message.user.userMembershipAttribut.expirationDate).format("DD/MM/YY HH:mm:ss")}
                </div>
            </div>
        </div>
        <div className="card m-1">
            <div className="card-header h5">
                Contenu de la demande
            </div>
            <div className="card-body">
                <div className="mb-4">
                    Date de publication : {moment(message.publishedAt).format("DD/MM/YY HH:mm:ss")}
                </div>
                <div className="mb-4">
                    Message :
                    <br/>
                    <div className="border rounded p-2">{message.message}</div>
                </div>
                {
                    message.documentUrls.length &&
                    <div className="mb-4">
                        Document(s) : (Cliquer pour agrandir)
                        {
                            message.documentUrls.map((url, index) =>
                                <div className="mb-4">
                                    <img className="w-50" src={url}
                                         title="Cliquez pour afficher dans un nouvel onglet"
                                         alt="Impossible d'afficher le document."
                                         onClick={() => window.open(url)}/>
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        </div>
        <div className="card m-1">
            <div className="card-header h5">
                Réponse(s) envoyée(s)
            </div>
            <div className="card-body">
                Cette fonctionnalité n'est pas disponible
            </div>
        </div>
        <div className="card m-1">
            <div className="card-header h5">
                Répondre
            </div>
            <div className="card-body">
                <textarea className="form-control-plaintext border rounded" placeholder="Unavailable" disabled
                          value={answer} onChange={e => setAnswer(e.target.value)}/>
                <br/>
                <input className="m-0" type="checkbox" id="resolved" disabled></input> Changer le statut du message
                comme résolu (Disabled)
            </div>
            <div className="card-footer">
                <button className="btn btn-primary" disabled>Envoyer (Disabled)</button>
            </div>
        </div>
    </div>
        ;
};

export default MessageDetails;