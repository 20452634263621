import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const getBookmakersActions = generateApiActions("bo_get_bookmaker");

export const actionGetAllBookmakers = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint('/referentials/bookmakers/')
        .verb(HTTP_VERBS.GET)
        .types(getBookmakersActions.all())
        .build();


export const getBookmakersV2Actions = generateApiActions("bo_get_bookmaker_V2");

export const actionGetAllBookmakersV2 = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint('/bo/bookmaker/referentials/')
        .verb(HTTP_VERBS.GET)
        .types(getBookmakersV2Actions.all())
        .build();

export const updateBookmakerActions = generateApiActions("bo_update_bookmaker");

export const actionUpdateBookmaker = (updateBookmakerRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/bookmakers/")
        .withBody(updateBookmakerRequest)
        .verb(HTTP_VERBS.POST)
        .types(updateBookmakerActions.all())
        .build();

export const uploadBookmakerImageActions = generateApiActions("bo_upload_bookmaker_image");

export const actionUploadBookmakerImage = (data) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .types(uploadBookmakerImageActions.all())
        .build(true);


const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getBookmakersActions.all())]: {
            next: (state, {payload}) => (payload ? {bookmakers: payload.content} : {...state}),
            throw: () => defaultState,
        },

        [combineActions(...getBookmakersV2Actions.all())]: {
            next: (state, {payload}) => (payload ? {bookmakers: payload.content} : {...state}),
            throw: () => defaultState,
        },

        [combineActions(...updateBookmakerActions.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },

        [combineActions(...uploadBookmakerImageActions.all())]: {
            next: (state, {payload}) => {
                return {...state, imageUrl: payload ? payload.content : null}
            },
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

