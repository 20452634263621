import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse,} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type ExpertFollowedByUserApiReturnType = ApiResponse<FollowedExpertByUser[]>;
type ExpertFollowedByUserArgs = {
    userId: number;
};

type FollowedExpertByUser = {
    userId: number,
    followedExpertUserId: number
}

export interface ExpertFollowedByUserState extends Loadable {
    followedExpertsByUser: FollowedExpertByUser[];
}

const initialAllPackConfigurationState: ExpertFollowedByUserState = {
    followedExpertsByUser: [] as FollowedExpertByUser[],
    isLoading: false,
};

export const fetchExpertFollowedByUserRequest = createAsyncThunk<ExpertFollowedByUserApiReturnType,
    ExpertFollowedByUserArgs,
    ThunkApiArgs<ExpertFollowedByUserApiReturnType>>("Subscription/FollowedExpertByUser", async ({userId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/followed-experts/${userId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const expertFollowedByUserSlice = createSlice({
    name: "expertFollowedByUserSlice",
    initialState: initialAllPackConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchExpertFollowedByUserRequest.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchExpertFollowedByUserRequest.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.followedExpertsByUser = payload.content;
            })
            .addCase(fetchExpertFollowedByUserRequest.rejected, state => {
                state.isLoading = false;
            });
    },
});

// ===================

type FollowUserApiReturnType = ApiResponse<any>;
type FollowUserArgs = {
    userId: number;
    action: "follow" | "no-follow";
    expertId: number;
};

export interface FollowUserState extends Loadable {
    newFollowForUser: boolean
}

const initialFollowUserState: FollowUserState = {
    isLoading: false,
    newFollowForUser: false
};

export const postFollowUserRequest = createAsyncThunk<FollowUserApiReturnType,
    FollowUserArgs,
    ThunkApiArgs<FollowUserApiReturnType>>("Subscription/FollowUser", async ({userId, action, expertId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/user-socials/${userId}/${action}/${expertId}`)
        .verb(action === 'follow' ? HTTP_VERBS.POST : HTTP_VERBS.DELETE)
        .build()
);

export const followUserSlice = createSlice({
    name: "followUserSlice",
    initialState: initialFollowUserState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(postFollowUserRequest.pending, state => {
                state.isLoading = true;
                state.newFollowForUser = false;
            })
            .addCase(postFollowUserRequest.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.newFollowForUser = true;
            })
            .addCase(postFollowUserRequest.rejected, state => {
                state.isLoading = false;
            });
    },
});
