import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";

import {CreateInfluencerPageState, fetchCreateInfluencerPage} from "./influencer-reducer";
import {AppState} from "../../../store/reducers";

const CreateInfluencer = () => {

        const history = useHistory();
        const dispatch = useDispatch();

        const [showCreation, setShowCreation] = useState(false);
        const [showError, setShowError] = useState(false);
        const [confirmed, setConfirmed] = useState(false);
        const [influencer, setInfluencer] = useState("");

        const {isCreated, isLoading} = useSelector<AppState, CreateInfluencerPageState>(state => state.createInfluencer);

        useEffect(() => {
            if (confirmed && !isLoading) {
                setConfirmed(false);
                isCreated ? history.push('/all-influencers') : setShowError(true);
            }
        }, [isLoading])

        const handleChallengeItemChange = text => {
            setInfluencer(text.target.value);
        };

        const handleCreateClick = () => {
            setShowCreation(true);
        };

        const handleConfirmClick = () => {
            setConfirmed(true);
            setShowCreation(false);
            dispatch(fetchCreateInfluencerPage({influencerName: influencer}));
        };

        return (
            <div className="start-page w-75 mb-4">
                <header className="page-header page-header-dark row">
                    <button className="btn btn-danger ml-3 mr-4 mb-3" onClick={() => history.push('/all-influencers')}>
                        Cancel
                    </button>
                    <h1>Nouvel Influenceur</h1>
                </header>
                <div className="card mb-2">
                    <div className="card-header">
                        <h4>General information</h4>
                    </div>
                    <div className="card-body">
                        <div className="ml-0 row">
                            <div className="w-50">
                                <div className="mb-4">
                                    <h5>Nom de l'influenceur</h5>
                                    <input className="border rounded" type="text" placeholder="Title"
                                           onChange={text => handleChallengeItemChange(text)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mb-4"
                        onClick={() => handleCreateClick()}>
                    Create Influencer
                </button>
                {
                    showCreation &&
                    <Modal size="lg" show={showCreation} onHide={() => setShowCreation(false)}>
                        <Modal.Header closeButton className="alert alert-info">
                            <Modal.Title>Creation confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="ml-0 row">
                                <div className="w-50">
                                    <h3>Nom de l'influenceur : {influencer.length ? influencer : 'No title!'}</h3>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="row">
                            <Button variant="danger" onClick={() => setShowCreation(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => handleConfirmClick()}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    showError &&
                    <Modal size="lg" show={showError} onHide={() => setShowError(false)}>
                        <Modal.Header className="alert alert-danger">
                            <Modal.Title>Encountered error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please verify challenge's infos.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowError(false)}>
                                Modify
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    confirmed && isLoading &&
                    <Modal size="sm" show={confirmed && isLoading}>
                        <Modal.Header className="alert alert-warning">
                            <Modal.Title>Loading please wait...</Modal.Title>
                        </Modal.Header>
                    </Modal>
                }
            </div>
        );
    }
;

export default CreateInfluencer;