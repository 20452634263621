import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, Challenge, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllChallengesApiReturnType = ApiResponse<Page>;
type FetchAllChallengePageArgs = { page: number };

export interface AllChallengesPageState extends Loadable {
    allChallenges: Page;
}

const initialAllChallengesState: AllChallengesPageState = {
    allChallenges: {} as Page,
    isLoading: false,
};

export const fetchAllChallengesPage = createAsyncThunk<AllChallengesApiReturnType,
    FetchAllChallengePageArgs,
    ThunkApiArgs<AllChallengesApiReturnType>>("AllChallengesPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/webapp/challenge/all?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allChallengesPageSlice = createSlice({
    name: "allChallengesSlice",
    initialState: initialAllChallengesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllChallengesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllChallengesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allChallenges = payload.content;
            })
            .addCase(fetchAllChallengesPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type SearchChallengeByTitleApiReturnType = ApiResponse<Page>;
type FetchSearchChallengeByTitlePageArgs = { title: string, page: number };

export interface SearchChallengeByTitlePageState extends Loadable {
    searchChallenge: Page;
}

const initialSearchChallengeByTitleState: SearchChallengeByTitlePageState = {
    searchChallenge: {} as Page,
    isLoading: false,
};

export const fetchSearchChallengeByTitlePage = createAsyncThunk<SearchChallengeByTitleApiReturnType,
    FetchSearchChallengeByTitlePageArgs,
    ThunkApiArgs<SearchChallengeByTitleApiReturnType>>("SearchChallengeByTitlePage/fetch", async ({
                                                                                                      title,
                                                                                                      page
                                                                                                  }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/webapp/challenge/challenge/${title}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const searchChallengeByTitlePageSlice = createSlice({
    name: "searchChallengeByTitleSlice",
    initialState: initialSearchChallengeByTitleState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSearchChallengeByTitlePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSearchChallengeByTitlePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.searchChallenge = payload.content;
            })
            .addCase(fetchSearchChallengeByTitlePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UpdateChallengeApiReturnType = ApiResponse<Challenge>;
type FetchUpdateChallengePageArgs = { challenge, challengeId: number };

export interface UpdateChallengePageState extends Loadable {
    challengeUpdated: Challenge;
    error: boolean;
    isUpdated: boolean;
}

const initialUpdateChallengeState: UpdateChallengePageState = {
    challengeUpdated: {} as Challenge,
    error: false,
    isUpdated: false,
    isLoading: false,
};

export const fetchUpdateChallengePage = createAsyncThunk<UpdateChallengeApiReturnType,
    FetchUpdateChallengePageArgs,
    ThunkApiArgs<UpdateChallengeApiReturnType>>("UpdateChallengePage/fetch", async ({
                                                                                        challenge,
                                                                                        challengeId
                                                                                    }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/webapp/challenge/update-challenge/${challengeId}`)
        .withBody(challenge)
        .verb(HTTP_VERBS.PUT)
        .build()
);

export const updateChallengePageSlice = createSlice({
    name: "updtaeChallengeSlice",
    initialState: initialUpdateChallengeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.challengeUpdated = payload.content;
                state.isUpdated = true;
            })
            .addCase(fetchUpdateChallengePage.rejected, state => {
                state.isLoading = false;
                state.error = true;
            });
    },
});

//*******

type DeleteChallengeApiReturnType = ApiResponse<any>;
type FetchDeleteChallengePageArgs = { challengeId: number };

export interface DeleteChallengePageState extends Loadable {
}

const initialDeleteChallengeState: DeleteChallengePageState = {
    isLoading: false,
};

export const fetchDeleteChallengePage = createAsyncThunk<DeleteChallengeApiReturnType,
    FetchDeleteChallengePageArgs,
    ThunkApiArgs<DeleteChallengeApiReturnType>>("DeleteChallengePage/fetch", async ({challengeId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/webapp/challenge/${challengeId}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteChallengePageSlice = createSlice({
    name: "deleteChallengeSlice",
    initialState: initialDeleteChallengeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteChallengePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******