import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, User} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type AllExpertsApiReturnType = ApiResponse<User[]>;
type AllExpertsArgs = {};

export interface AllExpertsState extends Loadable {
    experts: User[];
}

const initialAllExpertsState: AllExpertsState = {
    experts: [] as User[],
    isLoading: false,
};

export const fetchAllExpertsRequest = createAsyncThunk<AllExpertsApiReturnType,
    AllExpertsArgs,
    ThunkApiArgs<AllExpertsApiReturnType>>("AllExpertsTable/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/users/find-all-experts`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const fetchAllExpertsSlice = createSlice({
    name: "allPackConfigurationSlice",
    initialState: initialAllExpertsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllExpertsRequest.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllExpertsRequest.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.experts = payload.content;
            })
            .addCase(fetchAllExpertsRequest.rejected, state => {
                state.isLoading = false;
            });
    },
});