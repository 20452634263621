import React, {Component} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import LoadingOverlay from "react-loading-overlay";

interface LoaderProps {
    isLoading: boolean;
}

interface LoaderState {
}

class Loader extends Component<LoaderProps, LoaderState> {
    render() {
        const {isLoading} = this.props;

        return <LoadingOverlay active={isLoading} spinner={true} text=" Chargement ...">
            {this.props.children}
        </LoadingOverlay>
    }
}

const mapStateToProps = state => ({
    isLoading: state.reducerImageForChallenge.isLoading
        || state.reducerNews.isLoading
        || state.authUser.isLoading
        || state.membershipReducers.isLoading
});

const mapDispatchToProps = {};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Loader);