import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, Challenge, Event, Match, SportCategory} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";


type AllSportsApiReturnType = ApiResponse<SportCategory[]>;
type FetchAllSportsPageArgs = {};

export interface AllSportsPageState extends Loadable {
    allSportsForChallenge: SportCategory[];
}

const initialAllSportsState: AllSportsPageState = {
    allSportsForChallenge: [] as SportCategory[],
    isLoading: false,
};

export const fetchAllSportsPage = createAsyncThunk<AllSportsApiReturnType,
    FetchAllSportsPageArgs,
    ThunkApiArgs<AllSportsApiReturnType>>("AllSportsForChallengePage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v2/pronos/sports")
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allSportsForChallengePageSlice = createSlice({
    name: "allSportsForChallengeSlice",
    initialState: initialAllSportsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllSportsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllSportsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allSportsForChallenge = payload.content;
            })
            .addCase(fetchAllSportsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type CreateChallengeApiReturnType = ApiResponse<any>;
type FetchCreateChallengePageArgs = { challenge: Challenge };

export interface CreateChallengePageState extends Loadable {
    isCreated: boolean;
    error: boolean;
}

const initialCreateChallengeState: CreateChallengePageState = {
    isCreated: false,
    error: false,
    isLoading: false,
};

export const fetchCreateChallengePage = createAsyncThunk<CreateChallengeApiReturnType,
    FetchCreateChallengePageArgs,
    ThunkApiArgs<CreateChallengeApiReturnType>>("CreateChallengePage/fetch", async ({challenge}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/webapp/challenge/")
        .withBody(challenge)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const createChallengePageSlice = createSlice({
    name: "createChallengeSlice",
    initialState: initialCreateChallengeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCreateChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.isCreated = true;
            })
            .addCase(fetchCreateChallengePage.rejected, state => {
                state.isLoading = false;
                state.error = true;
            });
    },
});

//*******

type UploadImageChallengeApiReturnType = ApiResponse<string>;
type FetchUploadImageChallengePageArgs = { data: any };

export interface UploadImageChallengePageState extends Loadable {
    urlChallengeImage: string;
}

const initialUploadImageChallengeState: UploadImageChallengePageState = {
    urlChallengeImage: "",
    isLoading: false,
};

export const fetchUploadImageChallengePage = createAsyncThunk<UploadImageChallengeApiReturnType,
    FetchUploadImageChallengePageArgs,
    ThunkApiArgs<UploadImageChallengeApiReturnType>>("UploadImageChallengePage/fetch", async ({data}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const uploadImageChallengePageSlice = createSlice({
    name: "uploadImageChallengeSlice",
    initialState: initialUploadImageChallengeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUploadImageChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUploadImageChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.urlChallengeImage = payload.content;
            })
            .addCase(fetchUploadImageChallengePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******


type UploadImageChallengeAdApiReturnType = ApiResponse<string>;
type FetchUploadImageChallengeAdPageArgs = { data: any };

export interface UploadImageChallengeAdPageState extends Loadable {
    urlChallengeAd: string;
}

const initialUploadImageChallengeAdState: UploadImageChallengeAdPageState = {
    urlChallengeAd: "",
    isLoading: false,
};

export const fetchUploadImageChallengeAdPage = createAsyncThunk<UploadImageChallengeAdApiReturnType,
    FetchUploadImageChallengeAdPageArgs,
    ThunkApiArgs<UploadImageChallengeAdApiReturnType>>("UploadImageChallengeAdPage/fetch", async ({data}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const uploadImageChallengeAdPageSlice = createSlice({
    name: "uploadImageChallengeAdSlice",
    initialState: initialUploadImageChallengeAdState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUploadImageChallengeAdPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUploadImageChallengeAdPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.urlChallengeAd = payload.content;
            })
            .addCase(fetchUploadImageChallengeAdPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type ChallengeInfoApiReturnType = ApiResponse<Challenge>;
type ChallengeInfoPageArgs = { challengeId: number };

export interface ChallengeInfoPageState extends Loadable {
    challengeInfo: Challenge;
}

const initialChallengeInfoState: ChallengeInfoPageState = {
    challengeInfo: {
        id: 0,
        title: "",
        urlImage: "",
        presentation: "",
        shortDescription: "",
        nbMinPredictions: 0,
        packInformation: "",
        rules: "",
        webUrl: null,
        startDate: "",
        expirationDisplayDate: "",
        expirationDate: "",
        lots: [],
        annoncesLots: "",
        advertising: "",
        paid: false,
        priceCents: 0,
        sports: [],
        event: [],
        matchs: [],
        bookmakers: [],
        oddTypes: [],
        nbParticipants: 0,
        googlePlayProductName: '',
        activeSubscriptionsCount: 0,
        totalPredictions: 0,
        otherCount: 0,
        version: 0,
        updatedAt: null,
        createdAt: null
    } as Challenge,
    isLoading: false,
};

export const fetchChallengeInfoPage = createAsyncThunk<ChallengeInfoApiReturnType,
    ChallengeInfoPageArgs,
    ThunkApiArgs<ChallengeInfoApiReturnType>>("ChallengeInfoPage/fetch", async ({challengeId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/webapp/challenge/info/${challengeId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const challengeInfoPageSlice = createSlice({
    name: "challengeInfoSlice",
    initialState: initialChallengeInfoState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChallengeInfoPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchChallengeInfoPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.challengeInfo = payload.content;
            })
            .addCase(fetchChallengeInfoPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllMatchesForChallengeApiReturnType = ApiResponse<Match[]>;
type AllMatchesForChallengePageArgs = {};

export interface AllMatchesPageState extends Loadable {
    allMatchesForChallenge: Match[];
}

const initialAllMatchesForChallengeState: AllMatchesPageState = {
    allMatchesForChallenge: [] as Match[],
    isLoading: false,
};

export const fetchAllMatchesForChallengePage = createAsyncThunk<AllMatchesForChallengeApiReturnType,
    AllMatchesForChallengePageArgs,
    ThunkApiArgs<AllMatchesForChallengeApiReturnType>>("AllMatchsForChallengePage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/pronos/matchs`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allMatchesForChallengePageSlice = createSlice({
    name: "allMatchesForChallengeSlice",
    initialState: initialAllMatchesForChallengeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllMatchesForChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatchesForChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMatchesForChallenge = payload.content;
            })
            .addCase(fetchAllMatchesForChallengePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllEventsApiReturnType = ApiResponse<Event[]>;
type FetchAllEventsPageArgs = {};

export interface AllEventsPageState extends Loadable {
    allEventsForChallenge: Event[];
}

const initialAllEventsState: AllEventsPageState = {
    allEventsForChallenge: [] as Event[],
    isLoading: false,
};

export const fetchAllEventsForChallengePage = createAsyncThunk<AllEventsApiReturnType,
    FetchAllEventsPageArgs,
    ThunkApiArgs<AllEventsApiReturnType>>("AllEventsForChallengePage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v2/bo/event/list")
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allEventsForChallengePageSlice = createSlice({
    name: "allEventsForChallengeSlice",
    initialState: initialAllEventsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllEventsForChallengePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllEventsForChallengePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allEventsForChallenge = payload.content;
            })
            .addCase(fetchAllEventsForChallengePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******


type OddTypesApiReturnType = ApiResponse<string[]>;
type FetchOddTypesPageArgs = {};

export interface OddTypesPageState extends Loadable {
    oddTypes: string[];
}

const initialOddTypeState: OddTypesPageState = {
    oddTypes: [],
    isLoading: false,
};

export const fetchOddTypesPage = createAsyncThunk<OddTypesApiReturnType,
    FetchOddTypesPageArgs,
    ThunkApiArgs<OddTypesApiReturnType>>("OddTypesPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/referentials/odd-types")
        .verb(HTTP_VERBS.GET)
        .build()
);

export const oddTypesPageSlice = createSlice({
    name: "oddTypesSlice",
    initialState: initialOddTypeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchOddTypesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchOddTypesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.oddTypes = payload.content;
            })
            .addCase(fetchOddTypesPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

