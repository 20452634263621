import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllTrophies = generateApiActions("bo_get_all_trophies");

enum TrophyReset {
    RESET = "RESET_TROPHY_SUCCESS"
}

export const actionGetAllTrophies = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/trophies/")
        .verb(HTTP_VERBS.GET)
        .types(getAllTrophies.all())
        .build();


export const updateTrophy = generateApiActions("bo_update_trophy");

export const actionUpdateTrophy = (trophy) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/trophies/`)
        .verb(HTTP_VERBS.POST)
        .types(updateTrophy.all())
        .withBody(trophy)
        .build();


export const assignTrophy = generateApiActions("bo_assign_trophy");

export const actionAssignTrophyToUser = (trophyId: number, userId: number) =>

    RsaaBuilder.getInstance()
        .apiEndpoint(`/trophies/${trophyId}/assign-to-user/${userId}`)
        .verb(HTTP_VERBS.POST)
        .types(assignTrophy.all())
        .build();


export const getActiveTrophies = generateApiActions("bo_get_active_trophies")

export const actionGetActiveTrophies = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/trophies/active`)
        .verb(HTTP_VERBS.GET)
        .types(getActiveTrophies.all())
        .build();


export const actionTrophyReset = createAction(TrophyReset.RESET, () => {
});

const defaultState = {};

const reducerTrophy = handleActions(
    {

        [combineActions(...getAllTrophies.all())]: {
            next: (state, {payload}) => (payload ? {...state, trophies: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...getActiveTrophies.all())]: {
            next: (state, {payload}) => (payload ? {...state, activeTrophies: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...updateTrophy.all())]: {
            next: (state, {payload}) => (payload ? {trophyUpdated: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...assignTrophy.all())]: {
            next: (state, {payload}) => {
                return {...state, assignTrophyToUserSuccess: !!payload}
            },
            throw: () => defaultState
        },
        [combineActions(TrophyReset.RESET)]: {
            next: (state) => ({...state, error: [], trophySuccess: false}),
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducerTrophy;