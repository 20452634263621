import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getAllTeamsForChallenges = generateApiActions("bo_get_all_page_team_for_challenge");

export const actionGetAllTeamsForChallenges = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/teams-for-play/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllTeamsForChallenges.all())
        .build();

export const searchTeamsForChallengeByName = generateApiActions("bo_search_team_by_name_for_challenge");

export const actionSearchTeamsForChallengeByName = (nickName: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/team/search/${nickName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchTeamsForChallengeByName.all())
        .build();

export const createTeamsForChallenges = generateApiActions("bo_create_team_for_challenge");

export const actionCreateTeamsForChallenges = (form) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/teams-for-play/`)
        .verb(HTTP_VERBS.POST)
        .withBody(form)
        .types(createTeamsForChallenges.all())
        .build();

const defaultState = {};

const reducerTeamsForChallenges = handleActions(
    {
        [combineActions(...getAllTeamsForChallenges.all())]: {
            next: (state, {payload}) => (payload ? {teams: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...searchTeamsForChallengeByName.all())]: {
            next: (state, {payload}) => (payload ? {teams: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...createTeamsForChallenges.all())]: {
            next: (state, {payload}) => (payload ? {teams: payload.content, isCreated: true} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducerTeamsForChallenges;