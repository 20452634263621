import {ApiResponse, Trophy} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";
import {HTTP_VERBS} from "../../services/rsaa-builder";

type DeleteTrophyApiReturnType = ApiResponse<any>;
type FetchDeleteTrophyPageArgs = { id: number };

export interface DeleteTrophyPageState extends Loadable {
}

const initialDeleteTrophyState: DeleteTrophyPageState = {
    isLoading: false,
};

export const fetchDeleteTrophyPage = createAsyncThunk<DeleteTrophyApiReturnType,
    FetchDeleteTrophyPageArgs,
    ThunkApiArgs<DeleteTrophyApiReturnType>>("DeleteTrophyPage/fetch", async ({id}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/${id}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteTrophyPageSlice = createSlice({
    name: "deleteTrophySlice",
    initialState: initialDeleteTrophyState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteTrophyPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteTrophyPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteTrophyPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllTrophiesApiReturnType = ApiResponse<Trophy[]>;
type FetchAllTrophiesPageArgs = {};

export interface AllTrophiesPageState extends Loadable {
    allTrophies: Trophy[]
}

const initialAllTrophiesState: AllTrophiesPageState = {
    allTrophies: [],
    isLoading: false
};

export const fetchAllTrophiesPage = createAsyncThunk<AllTrophiesApiReturnType,
    FetchAllTrophiesPageArgs,
    ThunkApiArgs<AllTrophiesApiReturnType>>("AllTrophiesPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allTrophiesPageSlice = createSlice({
    name: "allTrophiesSlice",
    initialState: initialAllTrophiesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllTrophiesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllTrophiesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allTrophies = payload.content;
            })
            .addCase(fetchAllTrophiesPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UpdateTrophyApiReturnType = ApiResponse<Trophy>;
type FetchUpdateTrophyPageArgs = { trophy: Trophy };

export interface UpdateTrophyPageState extends Loadable {
}

const initialUpdateTrophyState: UpdateTrophyPageState = {
    isLoading: false,
};

export const fetchUpdateTrophyPage = createAsyncThunk<UpdateTrophyApiReturnType,
    FetchUpdateTrophyPageArgs,
    ThunkApiArgs<UpdateTrophyApiReturnType>>("UpdateTrophyPage/fetch", async ({trophy}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/`)
        .verb(HTTP_VERBS.POST)
        .withBody(trophy)
        .build()
);

export const updateTrophyPageSlice = createSlice({
    name: "updateTrophySlice",
    initialState: initialUpdateTrophyState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateTrophyPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateTrophyPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateTrophyPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type CreateTrophyApiReturnType = ApiResponse<any>;
type FetchCreateTrophyPageArgs = { trophy: Trophy };

export interface CreateTrophyPageState extends Loadable {
}

const initialCreateTrophyState: CreateTrophyPageState = {
    isLoading: false,
};

export const fetchCreateTrophyPage = createAsyncThunk<CreateTrophyApiReturnType,
    FetchCreateTrophyPageArgs,
    ThunkApiArgs<CreateTrophyApiReturnType>>("CreateTrophyPage/fetch", async ({trophy}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/`)
        .withBody(trophy)
        .verb(HTTP_VERBS.PUT)
        .build()
);

export const createTrophyPageSlice = createSlice({
    name: "createTrophySlice",
    initialState: initialCreateTrophyState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCreateTrophyPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateTrophyPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchCreateTrophyPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******