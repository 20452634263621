import {HTTP_VERBS} from "../../services/rsaa-builder";
import {
    ApiResponse,
    MembershipMappingFeaturesByLevel,
    MembershipPackPurchasedResponse,
    MembershipPackResponse,
    MembershipRenewResponse,
    Page,
    ProductByPromoCode,
    User
} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type AllPackConfigurationApiReturnType = ApiResponse<MembershipMappingFeaturesByLevel[]>;
type AllPackConfigurationPageArgs = {};

export interface AllPackConfigurationPageState extends Loadable {
    packConfiguration: MembershipMappingFeaturesByLevel[];
}

const initialAllPackConfigurationState: AllPackConfigurationPageState = {
    packConfiguration: [] as MembershipMappingFeaturesByLevel[],
    isLoading: false,
};

export const fetchAllPackConfigurationPage = createAsyncThunk<AllPackConfigurationApiReturnType,
    AllPackConfigurationPageArgs,
    ThunkApiArgs<AllPackConfigurationApiReturnType>>("AllPackConfigurationPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/pack-configuration`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPackConfigurationPageSlice = createSlice({
    name: "allPackConfigurationSlice",
    initialState: initialAllPackConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPackConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPackConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.packConfiguration = payload.content;
            })
            .addCase(fetchAllPackConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllPackLevelsApiReturnType = ApiResponse<string[]>;
type AllPackLevelsPageArgs = {};

export interface AllPackLevelsPageState extends Loadable {
    levels: string[];
}

const initialAllPackLevelsState: AllPackLevelsPageState = {
    levels: [] as string[],
    isLoading: false,
};

export const fetchAllPackLevelsPage = createAsyncThunk<AllPackLevelsApiReturnType,
    AllPackLevelsPageArgs,
    ThunkApiArgs<AllPackLevelsApiReturnType>>("AllPackLevelsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/levels`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPackLevelsPageSlice = createSlice({
    name: "allPackLevelsSlice",
    initialState: initialAllPackLevelsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPackLevelsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPackLevelsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.levels = payload.content;
            })
            .addCase(fetchAllPackLevelsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllPackFeaturesApiReturnType = ApiResponse<string[]>;
type AllPackFeaturesPageArgs = {};

export interface AllPackFeaturesPageState extends Loadable {
    features: string[];
}

const initialAllPackFeaturesState: AllPackFeaturesPageState = {
    features: [] as string[],
    isLoading: false,
};

export const fetchAllPackFeaturesPage = createAsyncThunk<AllPackFeaturesApiReturnType,
    AllPackFeaturesPageArgs,
    ThunkApiArgs<AllPackFeaturesApiReturnType>>("AllPackFeaturesPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/features`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPackFeaturesPageSlice = createSlice({
    name: "allPackFeaturesSlice",
    initialState: initialAllPackFeaturesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPackFeaturesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPackFeaturesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.features = payload.content;
            })
            .addCase(fetchAllPackFeaturesPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AddPackConfigurationApiReturnType = ApiResponse<MembershipMappingFeaturesByLevel[]>;
type AddPackConfigurationPageArgs = MembershipMappingFeaturesByLevel;

export interface AddPackConfigurationPageState extends Loadable {
    packConfigurationAdded: MembershipMappingFeaturesByLevel[];
}

const initialAddPackConfigurationState: AddPackConfigurationPageState = {
    packConfigurationAdded: [] as MembershipMappingFeaturesByLevel[],
    isLoading: false,
};

export const fetchAddPackConfigurationPage = createAsyncThunk<AddPackConfigurationApiReturnType,
    AddPackConfigurationPageArgs,
    ThunkApiArgs<AddPackConfigurationApiReturnType>>("AddPackConfigurationPage/fetch", async ({
                                                                                                  level,
                                                                                                  feature
                                                                                              }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/pack-configuration?level=${level}&feature=${feature}`)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const addPackConfigurationPageSlice = createSlice({
    name: "addPackConfigurationSlice",
    initialState: initialAddPackConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAddPackConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAddPackConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.packConfigurationAdded = payload.content;
            })
            .addCase(fetchAddPackConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type DeletePackConfigurationApiReturnType = ApiResponse<MembershipMappingFeaturesByLevel[]>;
type DeletePackConfigurationPageArgs = MembershipMappingFeaturesByLevel;

export interface DeletePackConfigurationPageState extends Loadable {
    deletePackConfiguration: MembershipMappingFeaturesByLevel[];
}

const initialDeletePackConfigurationState: DeletePackConfigurationPageState = {
    deletePackConfiguration: [] as MembershipMappingFeaturesByLevel[],
    isLoading: false,
};

export const fetchDeletePackConfigurationPage = createAsyncThunk<DeletePackConfigurationApiReturnType,
    DeletePackConfigurationPageArgs,
    ThunkApiArgs<DeletePackConfigurationApiReturnType>>("DeletePackConfigurationPage/fetch", async ({
                                                                                                        level,
                                                                                                        feature
                                                                                                    }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/pack-configuration?level=${level}&feature=${feature}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deletePackConfigurationPageSlice = createSlice({
    name: "deletePackConfigurationSlice",
    initialState: initialDeletePackConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeletePackConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeletePackConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.deletePackConfiguration = payload.content;
            })
            .addCase(fetchDeletePackConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllUsersApiReturnType = ApiResponse<Page>;
type FetchAllUsersPageArgs = { searchText: string, page: number };

export interface AllUsersPageState extends Loadable {
    users: Page;
}

const initialState: AllUsersPageState = {
    users: {} as Page,
    isLoading: false,
};

export const fetchAllUsersPage = createAsyncThunk<AllUsersApiReturnType,
    FetchAllUsersPageArgs,
    ThunkApiArgs<AllUsersApiReturnType>>("AllUsersForMemberShipPage/fetch", async ({searchText, page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/search/${searchText}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForMemberShipPageSlice = createSlice({
    name: "allUsersForMemberShipSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.users = payload.content;
            })
            .addCase(fetchAllUsersPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//************

type AddMembershipLevelInUserConfigurationApiReturnType = ApiResponse<User[]>;
type AddMembershipLevelInUserConfigurationPageArgs = { sendRequest };

export interface AddMembershipLevelInUserConfigurationPageState extends Loadable {
    addMembershipLevelInUserConfiguration: User[];
    modifyUsersMembershipLevelSuccess: boolean;
}

const initialAddMembershipLevelInUserConfigurationState: AddMembershipLevelInUserConfigurationPageState = {
    addMembershipLevelInUserConfiguration: [] as User[],
    modifyUsersMembershipLevelSuccess: false,
    isLoading: false,
};

export const fetchAddMembershipLevelInUserConfigurationPage = createAsyncThunk<AddMembershipLevelInUserConfigurationApiReturnType,
    AddMembershipLevelInUserConfigurationPageArgs,
    ThunkApiArgs<AddMembershipLevelInUserConfigurationApiReturnType>>("AddMembershipLevelInUserConfigurationPage/fetch", async ({sendRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/bo/membership/membership-level-configuration")
        .withBody(sendRequest)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const addMembershipLevelInUserConfigurationPageSlice = createSlice({
    name: "addMembershipLevelInUserConfigurationSlice",
    initialState: initialAddMembershipLevelInUserConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAddMembershipLevelInUserConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAddMembershipLevelInUserConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.addMembershipLevelInUserConfiguration = payload.content;
                state.modifyUsersMembershipLevelSuccess = true;
            })
            .addCase(fetchAddMembershipLevelInUserConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type RemoveMembershipLevelInUserConfigurationApiReturnType = ApiResponse<User[]>;
type RemoveMembershipLevelInUserConfigurationPageArgs = { sendRequest };

export interface RemoveMembershipLevelInUserConfigurationPageState extends Loadable {
    packRemoved: User[];
}

const initialRemoveMembershipLevelInUserConfigurationState: RemoveMembershipLevelInUserConfigurationPageState = {
    packRemoved: [] as User[],
    isLoading: false,
};

export const fetchRemoveMembershipLevelInUserConfigurationPage = createAsyncThunk<RemoveMembershipLevelInUserConfigurationApiReturnType,
    RemoveMembershipLevelInUserConfigurationPageArgs,
    ThunkApiArgs<RemoveMembershipLevelInUserConfigurationApiReturnType>>("RemoveMembershipLevelInUserConfigurationPage/fetch", async ({sendRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/bo/membership/membership-level-configuration")
        .withBody(sendRequest)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const removeMembershipLevelInUserConfigurationPageSlice = createSlice({
    name: "removeMembershipLevelInUserConfigurationSlice",
    initialState: initialRemoveMembershipLevelInUserConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRemoveMembershipLevelInUserConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchRemoveMembershipLevelInUserConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.packRemoved = payload.content;
            })
            .addCase(fetchRemoveMembershipLevelInUserConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********
// product

type AllProductConfigurationApiReturnType = ApiResponse<ProductByPromoCode[]>;
type AllProductConfigurationPageArgs = {};

export interface AllProductConfigurationPageState extends Loadable {
    allProductConfiguration: ProductByPromoCode[];
}

const initialAllProductConfigurationState: AllProductConfigurationPageState = {
    allProductConfiguration: [] as ProductByPromoCode[],
    isLoading: false,
};

export const fetchAllProductConfigurationPage = createAsyncThunk<AllProductConfigurationApiReturnType,
    AllProductConfigurationPageArgs,
    ThunkApiArgs<AllProductConfigurationApiReturnType>>("AllProductConfigurationPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/product-configuration`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allProductConfigurationPageSlice = createSlice({
    name: "allProductConfigurationSlice",
    initialState: initialAllProductConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllProductConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllProductConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allProductConfiguration = payload.content;
            })
            .addCase(fetchAllProductConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AddProductConfigurationApiReturnType = ApiResponse<ProductByPromoCode[]>;
type AddProductConfigurationPageArgs = { product };

export interface AddProductConfigurationPageState extends Loadable {
    addProductConfiguration: ProductByPromoCode[];
}

const initialAddProductConfigurationState: AddProductConfigurationPageState = {
    addProductConfiguration: [] as ProductByPromoCode[],
    isLoading: false,
};

export const fetchAddProductConfigurationPage = createAsyncThunk<AddProductConfigurationApiReturnType,
    AddProductConfigurationPageArgs,
    ThunkApiArgs<AddProductConfigurationApiReturnType>>("AddProductConfigurationPage/fetch", async ({product}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/product-configuration`)
        .verb(HTTP_VERBS.POST)
        .withBody(product)
        .build()
);

export const addProductConfigurationPageSlice = createSlice({
    name: "addProductConfigurationSlice",
    initialState: initialAddProductConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAddProductConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAddProductConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.addProductConfiguration = payload.content;
            })
            .addCase(fetchAddProductConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type DeleteProductConfigurationApiReturnType = ApiResponse<ProductByPromoCode[]>;
type DeleteProductConfigurationPageArgs = { id: number };

export interface DeleteProductConfigurationPageState extends Loadable {
    deleteProductConfiguration: ProductByPromoCode[];
}

const initialDeleteProductConfigurationState: DeleteProductConfigurationPageState = {
    deleteProductConfiguration: [] as ProductByPromoCode[],
    isLoading: false,
};

export const fetchDeleteProductConfigurationPage = createAsyncThunk<DeleteProductConfigurationApiReturnType,
    DeleteProductConfigurationPageArgs,
    ThunkApiArgs<DeleteProductConfigurationApiReturnType>>("DeleteProductConfigurationPage/fetch", async ({id}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/product-configuration/${id}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const allConnectionPageSlice = createSlice({
    name: "seleteProductConfigurationSlice",
    initialState: initialDeleteProductConfigurationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteProductConfigurationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteProductConfigurationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.deleteProductConfiguration = payload.content;
            })
            .addCase(fetchDeleteProductConfigurationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********
// pack information

type AllPackInformationApiReturnType = ApiResponse<MembershipPackResponse[]>;
type AllPackInformationPageArgs = {};

export interface AllPackInformationPageState extends Loadable {
    allPackInformation: MembershipPackResponse[];
}

const initialAllPackInformationState: AllPackInformationPageState = {
    allPackInformation: [] as MembershipPackResponse[],
    isLoading: false,
};

export const fetchAllPackInformationPage = createAsyncThunk<AllPackInformationApiReturnType,
    AllPackInformationPageArgs,
    ThunkApiArgs<AllPackInformationApiReturnType>>("AllPackInformationPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/pack-information`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPackInformationPageSlice = createSlice({
    name: "allPackInformationSlice",
    initialState: initialAllPackInformationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPackInformationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPackInformationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allPackInformation = payload.content;
            })
            .addCase(fetchAllPackInformationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UpdatePackInformationApiReturnType = ApiResponse<MembershipPackResponse[]>;
type UpdatePackInformationPageArgs = { id: number, features };

export interface UpdatePackInformationPageState extends Loadable {
    updatePackInformation: MembershipPackResponse[];
}

const initialUpdatePackInformationState: UpdatePackInformationPageState = {
    updatePackInformation: [] as MembershipPackResponse[],
    isLoading: false,
};

export const fetchUpdatePackInformationPage = createAsyncThunk<UpdatePackInformationApiReturnType,
    UpdatePackInformationPageArgs,
    ThunkApiArgs<UpdatePackInformationApiReturnType>>("UpdatePackInformationPage/fetch", async ({
                                                                                                    id,
                                                                                                    features
                                                                                                }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/pack-information/${id}`)
        .verb(HTTP_VERBS.POST)
        .withBody(features)
        .build()
);

export const updatePackInformationPageSlice = createSlice({
    name: "updatePackInformationSlice",
    initialState: initialUpdatePackInformationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdatePackInformationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdatePackInformationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.updatePackInformation = payload.content;
            })
            .addCase(fetchUpdatePackInformationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type NbPackBuyApiReturnType = ApiResponse<MembershipPackPurchasedResponse[]>;
type NbPackBuyPageArgs = {};

export interface NbPackBuyPageState extends Loadable {
    nbPackBuy: MembershipPackPurchasedResponse[];
}

const InitialNbPackBuyState: NbPackBuyPageState = {
    nbPackBuy: [] as MembershipPackPurchasedResponse[],
    isLoading: false,
};

export const fetchNbPackBuyPage = createAsyncThunk<NbPackBuyApiReturnType,
    NbPackBuyPageArgs,
    ThunkApiArgs<NbPackBuyApiReturnType>>("NbPackBuyPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/membership/stats`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const nbPackBuyPageSlice = createSlice({
    name: "updatePackInformationSlice",
    initialState: InitialNbPackBuyState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNbPackBuyPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchNbPackBuyPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.nbPackBuy = payload.content;
            })
            .addCase(fetchNbPackBuyPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type MembershipRenewApiReturnType = ApiResponse<MembershipRenewResponse[]>;
type MembershipRenewArgs = {};

export interface MembershipRenewState extends Loadable {
    renewal: MembershipRenewResponse[];
}

const MembershipRenewState: MembershipRenewState = {
    renewal: [] as MembershipRenewResponse[],
    isLoading: false,
};

export const membershipRenew = createAsyncThunk<MembershipRenewApiReturnType,
    MembershipRenewArgs,
    ThunkApiArgs<MembershipRenewApiReturnType>>("MembershipRenewPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/memberships/payment/renewal/all_membership`)
        .verb(HTTP_VERBS.PUT)
        .build()
);

export const membershipRenewSlice = createSlice({
    name: "membershipRenewSlice",
    initialState: MembershipRenewState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(membershipRenew.pending, state => {
                state.isLoading = true;
            })
            .addCase(membershipRenew.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.renewal = payload.content;
            })
            .addCase(membershipRenew.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********