import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, BasicMetricsReportResponse} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type BasicMetricsReportApiReturnType = ApiResponse<BasicMetricsReportResponse>;
type FetchBasicMetricsReportPageArgs = {};

export interface BasicMetricsReportPageState extends Loadable {
    basicMetricsReport: BasicMetricsReportResponse;
}

const initialBasicMetricsReportState: BasicMetricsReportPageState = {
    basicMetricsReport: {} as BasicMetricsReportResponse,
    isLoading: false,
};

export const fetchBasicMetricsReportPage = createAsyncThunk<BasicMetricsReportApiReturnType,
    FetchBasicMetricsReportPageArgs,
    ThunkApiArgs<BasicMetricsReportApiReturnType>>("BasicMetricsReportPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/metrics/count-users`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const basicMetricsReportPageSlice = createSlice({
    name: "allSportsSlice",
    initialState: initialBasicMetricsReportState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBasicMetricsReportPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchBasicMetricsReportPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.basicMetricsReport = payload.content;
            })
            .addCase(fetchBasicMetricsReportPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

