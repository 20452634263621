import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const pageOfMatchsV2Actions = generateApiActions("get_all_pending_v2_match");

export const actionGetAllPendingMatchsV2 = (page: number) => {
    console.log("page ")
    return RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/matchs/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfMatchsV2Actions.all())
        .build();
}

export const allMatchsV2Actions = generateApiActions("get_all_v2_match");

export const actionGetAllMatchsV2 = (page: number) => {
    console.log("page ")
    return RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/matchs/all?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(allMatchsV2Actions.all())
        .build();
}

export const updateMatchV2ResultActions = generateApiActions("update_v2_match_result");

export const actionUpdateV2MatchResult = (updateMatchRequest) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/matchs/result/prediction`)
        .withBody(updateMatchRequest)
        .verb(HTTP_VERBS.PUT)
        .types(updateMatchV2ResultActions.all())
        .build();


export const getMatchV2PeriodActions = generateApiActions("get_v2_match_period");

export const actionGetV2MatchPeriod = (matchId: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/matchs/${matchId}`)
        .types(getMatchV2PeriodActions.all())
        .build();

const defaultState = {};


const reducer = handleActions(
    {
        [combineActions(...pageOfMatchsV2Actions.all())]: {
            next: (state, {payload}) => (payload ? {page: {...payload.content}} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...updateMatchV2ResultActions.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },

        [combineActions(...getMatchV2PeriodActions.all())]: {
            next: (state, {payload}) => ({...state, period: payload ? payload.content : null}),
            throw: () => defaultState,
        },
        [combineActions(...allMatchsV2Actions.all())]: {
            next: (state, {payload}) => (payload ? {page: {...payload.content}} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

