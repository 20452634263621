import React from "react";
import {isDevConfig, isProdConfig, isRecConfig} from "../../services/config";

export const EnvBadge: React.FunctionComponent = (props) => {

    return (
        <>
            {isDevConfig() && <span className="badge badge-dark">DEV</span>}
            {isRecConfig() && <span className="badge badge-success">REC</span>}
            {isProdConfig() && <span className="badge badge-danger">PROD</span>}
        </>
    )
}