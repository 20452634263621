import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Table} from "react-bootstrap";
import Pagination from "../../../uicomponents/pagination/pagination";
import ScoreV2 from "../../../components/score/ScoreV2";
import {AppState} from "../../../store/reducers";
import {
    AllPendingMatchsPageState,
    fetchAllPendingMatchsPage,
    fetchUpdateMatchPage,
    UpdateMatchPageState
} from "./match-reducer";
import moment from "moment";

const MatchV2 = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [result, setResult] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [matchOnEdition, setMatchOnEdition] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const {allPendingMatchs} = useSelector<AppState, AllPendingMatchsPageState>(state => state.allPendingMatch);
    const {isLoading} = useSelector<AppState, UpdateMatchPageState>(state => state.updateMatch);

    useEffect(() => {
        dispatch(fetchAllPendingMatchsPage({page: 0}));
    }, []);

    useEffect(() => {
        if (!isLoading)
            dispatch(fetchAllPendingMatchsPage({page: currentPage}));
    }, [isLoading]);

    const handleIsCancel = (event) => {
        setIsCancel(event.target.checked);
    }

    const handleInputTextChange = (result) => {
        setResult(result);
    };

    const handleClickEdition = (match) => {
        setMatchOnEdition(match);
        setResult(false);
        setShowModal(true);
    };

    const paginationChange = (event, pageClicked) => {
        dispatch(fetchAllPendingMatchsPage({page: pageClicked}));
        setCurrentPage(pageClicked);
    };

    const updateMatchResult = () => {
        setShowModal(false);
        const request = {
            cancel: isCancel,
            prediction: matchOnEdition.id,
            result: result,
        }
        dispatch(fetchUpdateMatchPage({updateMatchRequest: request}));
        setIsCancel(false);
    };

    return (
        <div className="start-page">
            {matchOnEdition && matchOnEdition.betDescription && matchOnEdition.betDescription.match &&
                <Modal size="lg" show={showModal}
                       onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{matchOnEdition.betDescription.match.name + " | " + moment(matchOnEdition.betDescription.match.dateTime).format("DD/MM/YY HH:mm:ss")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <ScoreV2 handleInputTextChange={(event) => handleInputTextChange(event)}
                                     matchOnEdition={matchOnEdition}
                                     handleIsCancel={(event) => handleIsCancel(event)}/>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={() => updateMatchResult()}>
                            Enregistrer
                        </Button>
                    </Modal.Footer>
                </Modal>}

            <h1 className="h3 mb-2 text-gray-800">Matchs</h1>

            <div className="alert alert-info">
                Pour l'instant on gère la mise à jour du résultat pour le Football, Basketball et rugby pour (V/N/D
                ; V/N/D -
                HT ; V/N/D - 2HT)
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {allPendingMatchs && allPendingMatchs.content &&
                        <div>
                            <Pagination page={allPendingMatchs} action={paginationChange}/>
                            {"Il y a " + allPendingMatchs.content.length + " pronostic(s) en attente de resultat."}
                        </div>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Utilisateur</th>
                        <th>Heure de publication</th>
                        <th>Heure du match</th>
                        <th>Match</th>
                        <th>Compétition</th>
                        <th>Côte</th>
                        <th>Type</th>
                        <th><i className="fas fa-cog"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allPendingMatchs && allPendingMatchs.content && allPendingMatchs.content.map((item, index) =>
                        <tr key={index}>
                            <td>{item.nickname}</td>
                            <td>{item.betDescription.betChoice.createdAt}</td>
                            <td>{item.betDescription.match.dateTime}</td>
                            <td>{item.betDescription.match.name}</td>
                            <td>{item.betDescription.event.name.items[0].trad}</td>
                            <td>{item.betDescription.betChoice.odd}</td>
                            <td>{item.betDescription.bet.oddType}</td>
                            <td>
                                <Button variant="primary"
                                        onClick={() => handleClickEdition(item)}>
                                    <i className="fas fa-pencil-alt"/>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default MatchV2;
