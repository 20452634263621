import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const getEvents = generateApiActions("v2_get_all_event");

export const actionGetEvents = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/bo/event/list")
        .verb(HTTP_VERBS.GET)
        .types(getEvents.all())
        .build();


const defaultState = {}

const reducerEvents = handleActions(
    {
        [combineActions(...getEvents.all())]: {
            next: (state, {payload}) => (payload ? {events: payload.content} : {...state}),
            throw: () => defaultState
        },
    },
    defaultState
);

export default reducerEvents;