import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ActiveTrophy, ApiResponse, Page, Trophy} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api"

type AllTrophiesForToolsApiReturnType = ApiResponse<Trophy[]>;
type FetchAllTrophiesForToolsPageArgs = {};

export interface AllTrophiesForToolsPageState extends Loadable {
    allTrophies: Trophy[]
}

const initialAllTrophiesForToolsState: AllTrophiesForToolsPageState = {
    allTrophies: [],
    isLoading: false
};

export const fetchAllTrophiesForToolsPage = createAsyncThunk<AllTrophiesForToolsApiReturnType,
    FetchAllTrophiesForToolsPageArgs,
    ThunkApiArgs<AllTrophiesForToolsApiReturnType>>("AllTrophiesForToolsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allTrophiesForToolsPageSlice = createSlice({
    name: "allTrophiesForToolsSlice",
    initialState: initialAllTrophiesForToolsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllTrophiesForToolsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllTrophiesForToolsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allTrophies = payload.content;
            })
            .addCase(fetchAllTrophiesForToolsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllActiveTrophiesForToolsApiReturnType = ApiResponse<ActiveTrophy[]>;
type FetchAllActiveTrophiesForToolsPageArgs = {};

export interface AllActiveTrophiesForToolsPageState extends Loadable {
    allActiveTrophies: ActiveTrophy[]
}

const initialAllActiveTrophiesForToolsState: AllActiveTrophiesForToolsPageState = {
    allActiveTrophies: [],
    isLoading: false
};

export const fetchAllActiveTrophiesForToolsPage = createAsyncThunk<AllActiveTrophiesForToolsApiReturnType,
    FetchAllActiveTrophiesForToolsPageArgs,
    ThunkApiArgs<AllActiveTrophiesForToolsApiReturnType>>("AllActiveTrophiesForToolsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/active`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allActiveTrophiesForToolsPageSlice = createSlice({
    name: "allActiveTrophiesForToolsSlice",
    initialState: initialAllActiveTrophiesForToolsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllActiveTrophiesForToolsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllActiveTrophiesForToolsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allActiveTrophies = payload.content;
            })
            .addCase(fetchAllActiveTrophiesForToolsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllUsersForToolsApiReturnType = ApiResponse<Page>;
type FetchAllUsersForToolsPageArgs = { page: number, nickname: string };

export interface AllUsersForToolsPageState extends Loadable {
    allUsers: Page;
}

const initialState: AllUsersForToolsPageState = {
    allUsers: {} as Page,
    isLoading: false,
};

export const fetchAllUsersForToolsPage = createAsyncThunk<AllUsersForToolsApiReturnType,
    FetchAllUsersForToolsPageArgs,
    ThunkApiArgs<AllUsersForToolsApiReturnType>>("AllUsersForToolsPage/fetch", async ({
                                                                                          page,
                                                                                          nickname
                                                                                      }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/search/${nickname}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForToolsPageSlice = createSlice({
    name: "allUsersForToolsSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersForToolsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersForToolsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allUsers = payload.content;
            })
            .addCase(fetchAllUsersForToolsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UpdateTrophyOfUserApiReturnType = ApiResponse<ActiveTrophy>;
type FetchUpdateTrophyOfUserPageArgs = { trophyId: number, userId: number, remove: boolean };

export interface UpdateTrophyOfUserPageState extends Loadable {
}

const initialUpdateTrophyOfUserState: UpdateTrophyOfUserPageState = {
    isLoading: false,
};

export const fetchUpdateTrophyOfUserPage = createAsyncThunk<UpdateTrophyOfUserApiReturnType,
    FetchUpdateTrophyOfUserPageArgs,
    ThunkApiArgs<UpdateTrophyOfUserApiReturnType>>("UpdateTrophyOfUserPage/fetch", async ({
                                                                                              trophyId,
                                                                                              userId,
                                                                                              remove
                                                                                          }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/trophies/${trophyId}/${remove ? 'remove-from' : 'assign-to'}-user/${userId}`)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const updateTrophyOfUserPageSlice = createSlice({
    name: "updateTrophyOfUserSlice",
    initialState: initialUpdateTrophyOfUserState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateTrophyOfUserPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateTrophyOfUserPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateTrophyOfUserPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******