import {ApiResponse, Page, User} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";
import {HTTP_VERBS} from "../../services/rsaa-builder";

type GetUserApiReturnType = ApiResponse<User>;
type FetchGetUserPageArgs = { userId: number };

export interface GetUserPageState extends Loadable {
    user: User;
}

const initialGetUserState: GetUserPageState = {
    isLoading: false,
    user: {} as User,
};

export const fetchGetUserPage = createAsyncThunk<GetUserApiReturnType,
    FetchGetUserPageArgs,
    ThunkApiArgs<GetUserApiReturnType>>("GetUserPage/fetch", async ({userId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/users/${userId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const getUserPageSlice = createSlice({
    name: "getUserSlice",
    initialState: initialGetUserState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGetUserPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGetUserPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.user = payload.content;
            })
            .addCase(fetchGetUserPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type GetUserPublicationsApiReturnType = ApiResponse<Page>;
type FetchGetUserPublicationsPageArgs = { userId: number, page: number };

export interface GetUserPublicationsPageState extends Loadable {
    publications: Page;
}

const initialGetUserPublicationsState: GetUserPublicationsPageState = {
    isLoading: false,
    publications: {} as Page,
};

export const fetchGetUserPublicationsPage = createAsyncThunk<GetUserPublicationsApiReturnType,
    FetchGetUserPublicationsPageArgs,
    ThunkApiArgs<GetUserPublicationsApiReturnType>>("GetUserPublicationsPage/fetch", async ({userId, page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/publications/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const getUserPublicationsPageSlice = createSlice({
    name: "getUserPublicationsSlice",
    initialState: initialGetUserPublicationsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGetUserPublicationsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGetUserPublicationsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.publications = payload.content;
            })
            .addCase(fetchGetUserPublicationsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type GetUserCommentsApiReturnType = ApiResponse<Page>;
type FetchGetUserCommentsPageArgs = { userId: number, page: number };

export interface GetUserCommentsPageState extends Loadable {
    comments: Page;
}

const initialGetUserCommentsState: GetUserCommentsPageState = {
    isLoading: false,
    comments: {} as Page,
};

export const fetchGetUserCommentsPage = createAsyncThunk<GetUserCommentsApiReturnType,
    FetchGetUserCommentsPageArgs,
    ThunkApiArgs<GetUserCommentsApiReturnType>>("GetUserCommentsPage/fetch", async ({userId, page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/comments/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const getUserCommentsPageSlice = createSlice({
    name: "getUserCommentsSlice",
    initialState: initialGetUserCommentsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGetUserCommentsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGetUserCommentsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.comments = payload.content;
            })
            .addCase(fetchGetUserCommentsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type GetUserAnalysisApiReturnType = ApiResponse<Page>;
type FetchGetUserAnalysisPageArgs = { userId: number, page: number };

export interface GetUserAnalysisPageState extends Loadable {
    analysis: Page;
}

const initialGetUserAnalysisState: GetUserAnalysisPageState = {
    isLoading: false,
    analysis: {} as Page,
};

export const fetchGetUserAnalysisPage = createAsyncThunk<GetUserAnalysisApiReturnType,
    FetchGetUserAnalysisPageArgs,
    ThunkApiArgs<GetUserAnalysisApiReturnType>>("GetUserAnalysisPage/fetch", async ({userId, page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/analysis/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const getUserAnalysisPageSlice = createSlice({
    name: "getUserAnalysisSlice",
    initialState: initialGetUserAnalysisState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchGetUserAnalysisPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchGetUserAnalysisPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.analysis = payload.content;
            })
            .addCase(fetchGetUserAnalysisPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type BlockApiReturnType = ApiResponse<any>;
type FetchBlockPageArgs = { userId: number };

export interface BlockPageState extends Loadable {
}

const initialBlockState: BlockPageState = {
    isLoading: false,
};

export const fetchBlockPage = createAsyncThunk<BlockApiReturnType, FetchBlockPageArgs,
    ThunkApiArgs<BlockApiReturnType>>("BlockPage/fetch", async ({userId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/prevent/${userId}`)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const blockPageSlice = createSlice({
    name: "blockSlice",
    initialState: initialBlockState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBlockPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchBlockPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchBlockPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UnBlockApiReturnType = ApiResponse<any>;
type FetchUnBlockPageArgs = { userId: number };

export interface UnBlockPageState extends Loadable {
}

const initialUnBlockState: UnBlockPageState = {
    isLoading: false,
};

export const fetchUnBlockPage = createAsyncThunk<UnBlockApiReturnType, FetchUnBlockPageArgs,
    ThunkApiArgs<UnBlockApiReturnType>>("UnBlockPage/fetch", async ({userId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/dis-prevent/${userId}`)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const unBlockPageSlice = createSlice({
    name: "unBlockSlice",
    initialState: initialUnBlockState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUnBlockPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUnBlockPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUnBlockPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type ModifyUserPasswordApiReturnType = ApiResponse<any>;
type FetchModifyUserPasswordPageArgs = { userId: number, password: string };

export interface ModifyUserPasswordPageState extends Loadable {
}

const initialModifyUserPasswordState: ModifyUserPasswordPageState = {
    isLoading: false,
};

export const fetchModifyUserPasswordPage = createAsyncThunk<ModifyUserPasswordApiReturnType, FetchModifyUserPasswordPageArgs,
    ThunkApiArgs<ModifyUserPasswordApiReturnType>>("ModifyUserPasswordPage/fetch", async (body, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/change-password`)
        .verb(HTTP_VERBS.POST)
        .withBody(body)
        .build()
);

export const modifyUserPasswordPageSlice = createSlice({
    name: "modifyUserPasswordSlice",
    initialState: initialModifyUserPasswordState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchModifyUserPasswordPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchModifyUserPasswordPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchModifyUserPasswordPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******