import React, {useEffect, useState} from "react";
import {Button, Col, Container, Modal, Row, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    AllMatchesScoresState,
    CountPendingMatchScoresState,
    fetchAllMatchScores,
    fetchCountPendingMatchScores,
    fetchSearchMatchScores,
    SearchMatchScoreState,
    updateMatchScore,
    UpdateMatchScoreState
} from "./match-score-reducer";
import {AppState} from "../../../store/reducers";
import {MatchScoreResponse} from "../../../typings/api";
import Pagination from "../../../uicomponents/pagination/pagination";

const MatchScores = () => {

    const [showModal, setShowModal] = useState(false);
    const [matchOnEdition, setMatchOnEdition] = useState<MatchScoreResponse | undefined>(undefined);
    const [homeScore, setHomeScore] = useState(0);
    const [awayScore, setAwayScore] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [pendingScoresAmount, setPendingScoresAmount] = useState(0);
    const {allMatchScores} = useSelector<AppState, AllMatchesScoresState>(state => state.allMatchScores);
    const [matchScores, setMatchScores] = useState<MatchScoreResponse[]>([])
    const {matchScore} = useSelector<AppState, UpdateMatchScoreState>(state => state.updateMatchScore);
    const {searchMatchScoresResultsPage} = useSelector<AppState, SearchMatchScoreState>(state => state.searchMatchScores);
    const {count} = useSelector<AppState, CountPendingMatchScoresState>(state => state.countPendingMatchScores);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllMatchScores({page: 0}));
    }, []);

    useEffect(() => {
        allMatchScores && allMatchScores.content && setMatchScores(allMatchScores.content);
        dispatch(fetchCountPendingMatchScores({}));
    }, [allMatchScores]);


    useEffect(() => {
        searchMatchScoresResultsPage && searchMatchScoresResultsPage.content && setMatchScores(searchMatchScoresResultsPage.content);
    }, [searchMatchScoresResultsPage]);


    useEffect(() => {
        console.log(matchScores);
        let pending = 0;
        matchScores.forEach(el => {
            if (el.matchScore.pending) {
                pending += 1;
            }
        });
        setPendingScoresAmount(pending);
    }, [matchScores]);


    useEffect(() => {
        if (matchScore && matchOnEdition && matchScore.id === matchOnEdition.matchScore.id) {
            setMatchOnEdition(undefined);
            setShowModal(false);
            dispatch(fetchAllMatchScores({page: 0}));
        }
    }, [matchScore]);

    useEffect(() => {
        searchMatchScores(searchQuery);
    }, [searchQuery]);


    const searchMatchScores = (query: string) => {
        if (query.length === 0) {
            dispatch(fetchAllMatchScores({page: 0}));
        } else {
            dispatch(fetchSearchMatchScores({page: 0, query}));
        }
    }

    const paginationChange = (event, pageClicked) => {
        if (searchQuery.length === 0) {
            dispatch(fetchAllMatchScores({page: pageClicked}));
        } else {
            dispatch(fetchSearchMatchScores({page: pageClicked, query: searchQuery}));
        }
    };

    const handleClickEdition = (matchScore) => {
        setMatchOnEdition(matchScore);
        setHomeScore(matchScore.matchScore.homeScore);
        setAwayScore(matchScore.matchScore.awayScore);
        setShowModal(true);
    };


    const renderModal = () => {
        return (
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="text-center">Équipe à domicile</h3>
                                <div className="form-group">
                                    <br/>
                                    <input type="number" value={homeScore} onChange={(e) => {
                                        setHomeScore(parseInt(e.target.value))
                                    }} className="form-control" placeholder="Entrez le score"/>
                                </div>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center position-relative">
                                <div className="vertical-line position-absolute" style={{zIndex: 1000}}></div>
                                <h4 className="text-center">{matchOnEdition?.match.name}</h4>
                            </Col>
                            <Col>
                                <h3 className="text-center">Équipe à l'extérieur</h3>
                                <div className="form-group">
                                    <br/>
                                    <input type="number" value={awayScore} onChange={(e) => {
                                        setAwayScore(parseInt(e.target.value))
                                    }} className="form-control" placeholder="Entrez le score"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModal(false)
                        setMatchOnEdition(undefined);
                    }}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {
                        dispatch(updateMatchScore({
                            matchScore: {
                                id: matchOnEdition?.matchScore.id ?? 0,
                                matchId: matchOnEdition?.match.id ?? 0,
                                pending: false,
                                homeScore,
                                awayScore
                            }
                        }))
                    }}>Enregistrer</Button>
                </Modal.Footer>
            </Modal>
        );
    };


    return (
        <div className="start-page">
            {renderModal()}
            <h1 className="h3 mb-2 text-gray-800">Scores des matchs</h1>
            <div className="mb-2">
                <input
                    className="form-control"
                    type="search"
                    value={searchQuery}
                    placeholder="Rechercher"
                    aria-label="Rechercher"
                    onChange={e => {
                        setSearchQuery(e.target.value);
                    }}
                />
            </div>
            <div>
                <Pagination page={searchQuery.length === 0 ? allMatchScores : searchMatchScoresResultsPage}
                            action={paginationChange}/>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div>
                        {"Il y a " + count + " match(s) en attente de résultat."}
                    </div>
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Match</th>
                        <th>ID du Match</th>
                        <th>Compétition</th>
                        <th>Date et heure du match</th>
                        <th>Score en attente</th>
                        <th>Score</th>
                        <th><i className="fas fa-cog"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        matchScores.map((el) =>
                            <tr key={el.matchScore.id}>
                                <td>{el.matchScore.id}</td>
                                <td>{el.match.name}</td>
                                <td>{el.match.id}</td>
                                <td>{el.event.name.items[0].trad}</td>
                                <td>{el.match.dateTime}</td>
                                <td className={(el.matchScore.pending ? "text-danger" : "text-success") + " font-weight-bold"}>{el.matchScore.pending ? "Oui" : "Non"}</td>
                                <td>{el.matchScore.homeScore} - {el.matchScore.awayScore}</td>
                                <td><Button variant="primary"
                                            onClick={() => handleClickEdition(el)}>
                                    <i className="fas fa-pencil-alt"/>
                                </Button></td>
                            </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    );

};

export default MatchScores;
