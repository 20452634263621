import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "react-bootstrap/Table";
import {Button, Form} from "react-bootstrap";
import Popin from "../../../uicomponents/modal/modal";
import PopinDelete from "../../../uicomponents/modal/modalDelete";
import I18nField from "../../../uicomponents/i18nfield/I18nField";
import {AppState} from "../../../store/reducers";
import {
    AllSportsPageState,
    fetchAddOrUpdateSportPage,
    fetchAllSportsPage,
    fetchDeleteSportPage,
    fetchUploadSportImagePage,
    UploadSportImagePageState
} from "./sport-reducer";

const SportV2 = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showModalDeletion, setShowModalDeletion] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [sportOnEdition, setSportOnEdition] = useState<any>({});

    const {allSports} = useSelector<AppState, AllSportsPageState>(state => state.allSportsCategories);
    const {urlSportImage} = useSelector<AppState, UploadSportImagePageState>(state => state.urlSportImage);

    useEffect(() => {
        dispatch(fetchAllSportsPage({}));
    }, []);

    const addOrUpdateProduct = () => {
        setShowModal(false);
        dispatch(fetchAddOrUpdateSportPage({product: sportOnEdition}));
    };

    const deleteProduct = () => {
        setShowModalDeletion(false);
        dispatch(fetchDeleteSportPage({product: sportOnEdition}));
    };

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        setSportOnEdition({...sportOnEdition, [name]: value});
    };

    const onContributionChange = (items) => {
        setSportOnEdition({...sportOnEdition, name: {items}});
        return items;
    };

    const onClickHandler = () => {
        const data = new FormData();
        data.append("file", selectedFile);
        dispatch(fetchUploadSportImagePage({data: data}));
    };

    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onEditSport = (sport) => {
        setSportOnEdition(sport);
        setShowModal(true);
    }

    const onDeleteSport = (sport) => {
        setSportOnEdition(sport);
        setShowModalDeletion(true);
    }

    const renderModal = () => {
        return (
            <Popin title={sportOnEdition.id != -1 ? `Edition du sport #${sportOnEdition.id}` : `Nouveau sport`}
                   showModal={showModal}
                   cancelAction={() => setShowModal(false)}
                   saveAction={addOrUpdateProduct}>

                <I18nField onChange={(event) => onContributionChange(event)}
                           contributionItems={sportOnEdition.name && sportOnEdition.name.items}/>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>code link with model v1</Form.Label>
                        <Form.Control type="text" placeholder="code link with model v1"
                                      onChange={(event) => handleChange(event)}
                                      name="codeLinkWithModelv1"
                                      value={sportOnEdition.codeLinkWithModelv1 ? sportOnEdition.codeLinkWithModelv1 : ""}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>icon url</Form.Label>
                        <Form.Control type="text" placeholder="icon url" onChange={(event) => handleChange(event)}
                                      name="iconUrl"
                                      value={sportOnEdition.iconUrl ? sportOnEdition.iconUrl : ""}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control type="text" placeholder="Priority"
                                      onChange={(event) => handleChange(event)}
                                      name="priority"
                                      value={sportOnEdition.priority ? sportOnEdition.priority : ""}/>
                    </Form.Group>

                </Form>
            </Popin>
        );
    }

    const renderModalDeletion = () => {
        return (
            <PopinDelete title="Voulez vous supprimer ce sport"
                         showModal={showModalDeletion}
                         cancelAction={() => setShowModalDeletion(false)}
                         saveAction={deleteProduct}>
            </PopinDelete>
        );
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Configuration des sports</h1>

            <div className="alert alert-info">
                Cet écran permet de configurer les sports
            </div>
            {sportOnEdition && renderModal()}
            {sportOnEdition && renderModalDeletion()}

            <div>
                <input type="file" name="file" onChange={(event) => onChangeHandler(event)}/>
                <Button variant="primary" onClick={() => onClickHandler()}><i
                    className="fas fa-upload"></i> Telecharger</Button>
            </div>

            <br/>
            <br/>

            {urlSportImage && urlSportImage.length > 0 &&
                <div className="alert alert-success">
                    Le lien de l'image que vous venez d'importer : {urlSportImage}
                </div>
            }

            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Icon url</th>
                        <th>priority</th>
                        <th>code link with model v1</th>
                        <th><i className="fas fa-cog"></i></th>
                        <th><i className="fas fa-cog"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allSports && allSports.map((sport, index) =>
                        <tr key={index}>
                            <td>{sport.id}</td>
                            <td>{sport.name.items[0].trad}</td>
                            <td>{sport.iconUrl}</td>
                            <td>{sport.priority}</td>
                            <td>{sport.codeLinkWithModelv1}</td>
                            <td>
                                <Button variant="primary" onClick={() => onEditSport(sport)}>
                                    <i className="fas fa-pencil-alt"></i>
                                </Button>
                            </td>

                            <td>
                                <Button variant="primary" onClick={() => onDeleteSport(sport)}>
                                    <i className="fas fas fa-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

        </div>
    );
}

export default SportV2;
