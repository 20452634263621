import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form} from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import {useHistory} from "react-router-dom";
import Pagination from "../../uicomponents/pagination/pagination";
import {
    AllUsersForModerationPageState,
    DeleteUserPageState,
    fetchAllUsersForModerationPage,
    fetchDeleteUserPage
} from "./user-reducer";
import Modal from "react-bootstrap/Modal";
import {AppState} from "../../store/reducers";

const User = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const {isUserDeleted, isLoading} = useSelector<AppState, DeleteUserPageState>(state => state.isUserDeleted);
    const [userId, setUserId] = useState<number>(-1);
    const {allUsers} = useSelector<AppState, AllUsersForModerationPageState>(state => state.allUsersForModeration);
    const [nickname, setNickname] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [reported, setReported] = useState(false);

    useEffect(() => {
        dispatch(fetchAllUsersForModerationPage({page: 0, nickname: "", reported: false}));
    }, []);

    useEffect(() => {
        if (!isLoading && isUserDeleted) {
            dispatch(fetchAllUsersForModerationPage({page: currentPage, nickname, reported}));
        }
    }, [isLoading]);

    useEffect(() => {
        dispatch(fetchAllUsersForModerationPage({page: 0, nickname, reported}));
    }, [nickname]);

    useEffect(() => {
        dispatch(fetchAllUsersForModerationPage({page: 0, nickname, reported}));
    }, [reported]);

    const handleInputTextChange = (event) => {
        setNickname(event.target.value);
    };

    const goUserDetails = (user) => {
        history.push('/user/' + user.id);
    };

    const paginationChange = (event, pageClicked) => {
        setCurrentPage(pageClicked);
        dispatch(fetchAllUsersForModerationPage({page: pageClicked, nickname, reported}));
    };

    const onDeleteUser = (userId) => {
        setUserId(userId);
        setShowModal(true);
    }

    const handleDeleteUser = () => {
        dispatch(fetchDeleteUserPage({userId: userId}));
        setShowModal(false);
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Utilisateurs</h1>

            <div className="row">
                <div className="col-md-4">

                    <FormControl
                        placeholder="Entrez le pseudo d'un utilisateur"
                        aria-label="Username"
                        aria-describedby="basic-addon"
                        size="lg"
                        onChange={(event) => handleInputTextChange(event)}
                    />
                </div>
                <div className="col-md-4 m-2">
                    <input type="checkbox" className="custom-checkbox"
                           onChange={event => setReported(event.target.checked)}/> Rechercher uniquement les
                    signalements
                </div>
            </div>
            <br/>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {
                        allUsers &&
                        <Pagination page={allUsers} action={paginationChange}/>
                    }
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pseudo</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Numéro</th>
                        <th>Signalé</th>
                        <th>Détails</th>
                        <th>Retirer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        allUsers.content && allUsers.content.map((user, index) =>
                            <tr key={index}>
                                <td>{user.id}</td>
                                <td>{user.nickname}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber === undefined ? "N/A" : user.phoneNumber}</td>
                                <td>{user.report ? "oui" : "non"}</td>
                                <td>
                                    <Button variant="primary" onClick={() => goUserDetails(user)}>
                                        <i className="fas fa-arrow-right"/>
                                    </Button>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => onDeleteUser(user.id)}>
                                        <i className="fas fa-trash"/>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {
                showModal &&
                <Modal size="lg" show={showModal}
                       onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton className="alert alert-success">
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Confirmer vous la suppression de l'utilisateur ?</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleDeleteUser()}>
                            Supprimer
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
}

export default User;
