import React from "react";
import {Link} from "react-router-dom";

type MenuProps = {
    onchangePage: any
}

class Menu extends React.Component<MenuProps, any> {

    menuConfiguration = {
        sections: [
            {
                name: "Utilisateur",
                icon: "fa-user",
                links: [
                    {
                        name: "Sponsoring",
                        redirect: "/sponsorship"
                    },
                    {
                        name: "Les + actifs",
                        redirect: "/best-contributors"
                    },
                    // {
                    //     name: "Attribuer un numéro",
                    //     redirect: "/user-add-phone-without-validation"
                    // },
                    {
                        name: "Passer Expert",
                        redirect: "/user-pass-expert"
                    },
                    {
                        name: "Statistiques des experts",
                        redirect: "/expert-statistics"
                    },
                    {
                        name: "Information connexion",
                        redirect: "/info-connection"
                    },
                    {
                        name: "Modération",
                        redirect: "/users"
                    },
                    {
                        name: "Habilitation des rôles",
                        redirect: "/authorization"
                    },
                ]
            },
            {
                name: "Groupe",
                icon: "fa-users",
                links: [
                    {
                        name: "Groupes",
                        redirect: "/team"
                    }
                ]
            },
            {
                name: "Outils",
                icon: "fa-tools",
                links: [

                    {
                        name: "Classements",
                        redirect: "/ranking"
                    },
                    {
                        name: "Historique classement",
                        redirect: "/ranking/weekly"
                    },
                    {
                        name: "Trophées",
                        redirect: "/trophies"
                    },
                    {
                        name: "Challenges",
                        redirect: "/all-challenges"
                    },
                    {
                        name: "News",
                        redirect: "/all-news"
                    },
                    {

                        name: "Variation de cote",
                        redirect: "/odd-variation/all-generated-alerts"
                    },
                    {
                        "name": "Nombre d'utilisateurs<br/>par période",
                        "redirect": "/stats/get-new-users-in-period"
                    }
                ]
            },
            {
                name: "Publications",
                icon: "fa-cog",
                links: [
                    {
                        name: "Demandes de support",
                        redirect: "/support/all-messages"
                    },
                    {
                        name: "Modération des feed",
                        redirect: "/publications"
                    },
                    {
                        name: "Gestion des feeds",
                        redirect: "/feeds"
                    },
                    {
                        name: "Envoyer des notifications",
                        redirect: "/send-notifications"
                    },
                    {
                        name: "Publier dans le feed",
                        redirect: "/send-in-feed"
                    },
                ]
            },
            {
                name: "Prédictions",
                icon: "fa-futbol",
                links: [
                    {
                        name: "Compétitions",
                        redirect: "/contribution/v2/competition"
                    },
                    {
                        name: "Sports",
                        redirect: "/sport"
                    },
                    {
                        name: "Bookmakers",
                        redirect: "/contribution/v2/bookmaker"
                    },
                    {
                        name: "Résultats",
                        redirect: "/match"
                    },
                    {
                        name: "Correction de résultats",
                        redirect: "/modify-result"
                    },
                    {
                        name: "Scores des matchs",
                        redirect: "/match-scores"
                    },
                ]
            },
            {
                name: "Membership",
                icon: "fa-shopping-bag",
                links: [
                    {
                        name: "Information des packs",
                        redirect: "/membership/pack-information"
                    },
                    // {
                    //     name: "Configuration des packs",
                    //     redirect: "/membership/pack-configuration"
                    // },
                    {
                        name: "Configuration des niveaux",
                        redirect: "/membership/level-configuration"
                    },
                    {
                        name: "Configuration des produits",
                        redirect: "/membership/product-configuration"
                    },
                    {
                        name: "Abonnement",
                        redirect: "/membership/subscription"
                    },
                    {
                        name: "Achat membership",
                        redirect: "/membership/purchased"
                    },
                    {
                        name: "Rétablir membership",
                        redirect: "/membership/renewal"
                    }
                ]
            },
            {
                name: "Influenceurs",
                icon: "fa-user",
                links: [
                    {
                        name: "Influenceurs",
                        redirect: "/all-influencers"
                    }
                ]
            },
            {
                name: "Outils pour testeurs",
                icon: "fa-tools",
                links: [

                    {
                        name: "Résultat",
                        redirect: "/all-matchs"
                    },
                    {
                        name: "Trophées des utilisateurs",
                        redirect: "/tester-tools/user-trophies"
                    }

                ]
            },
            {
                name: "Search Unlink Sobet",
                icon: "fa-tools",
                links: [
                    {
                        name: "Search Unlink",
                        redirect: "/search-unlink-sobetmatch"
                    },
                ]
            },
        ]
    };

    renderLinkName(name) {
        return <span dangerouslySetInnerHTML={{__html: name}} />;
    }

    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* Autres éléments du menu */}
                {this.menuConfiguration.sections.map((section, index) =>
                    <li key={index} className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse"
                           data-target={`#collapse-${index}`}>
                            <i className={`fas fa-fw ${section.icon}`}></i>
                            <span>{section.name}</span>
                        </a>
                        <div id={`collapse-${index}`} className="collapse">
                            <div className="bg-white py-2 collapse-inner rounded">
                                {section.links.map((link, index2) =>
                                    <Link key={index2} className="collapse-item" to={link.redirect}>
                                        {this.renderLinkName(link.name)}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        );
    }
}

export default Menu;
