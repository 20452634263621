import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum MembershipLevelActionReset {
    RESET = "RESET_MEMBERSHIP"
}


export const getAllPackConfiguration = generateApiActions("bo_get_all_pack_configuration");

export const actionGetAllPackConfiguration = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/pack-configuration`)
        .verb(HTTP_VERBS.GET)
        .types(getAllPackConfiguration.all())
        .build();

export const addPackConfiguration = generateApiActions("bo_add_pack_configuration");

export const actionAddPackConfiguration = (level, feature) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/pack-configuration?level=${level}&feature=${feature}`)
        .verb(HTTP_VERBS.POST)
        .types(addPackConfiguration.all())
        .build();

export const deletePackConfiguration = generateApiActions("bo_delete_pack_configuration");

export const actionDeletePackConfiguration = (level, feature) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/pack-configuration?level=${level}&feature=${feature}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deletePackConfiguration.all())
        .build();

export const getAllUsers = generateApiActions("bo_get_all_users");

export const actionGetAllUsers = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/users/all")
        .verb(HTTP_VERBS.GET)
        .types(getAllUsers.all())
        .build();

export const addMembershipLevelInUserConfiguration = generateApiActions("bo_add_membership_level_user_configuration");

export const actionaddMembershipLevelInUserConfiguration = (sendRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/membership/membership-level-configuration")
        .withBody(sendRequest)
        .verb(HTTP_VERBS.POST)
        .types(addMembershipLevelInUserConfiguration.all())
        .build();


export const removeMembershipLevelInUserConfiguration = generateApiActions("bo_remove_membership_level_user_configuration");

export const actionRemoveMembershipLevelInUserConfiguration = (sendRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/membership/membership-level-configuration")
        .withBody(sendRequest)
        .verb(HTTP_VERBS.DELETE)
        .types(removeMembershipLevelInUserConfiguration.all())
        .build();

export const actionMembershipLevelActionReset = createAction(MembershipLevelActionReset.RESET, () => {

});

// product

export const getAllProductConfiguration = generateApiActions("bo_get_all_product_configuration");

export const actionGetAllProductConfiguration = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/product-configuration`)
        .verb(HTTP_VERBS.GET)
        .types(getAllProductConfiguration.all())
        .build();

export const addProductConfiguration = generateApiActions("bo_add_product_configuration");

export const actionAddProductConfiguration = (product) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/product-configuration`)
        .verb(HTTP_VERBS.POST)
        .types(addProductConfiguration.all())
        .withBody(product)
        .build();

export const deleteProductConfiguration = generateApiActions("bo_delete_product_configuration");

export const actionDeleteProductConfiguration = (id) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/product-configuration/${id}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteProductConfiguration.all())
        .build();

// pack information

export const getAllPackInformation = generateApiActions("bo_get_all_pack_information");

export const actionGetAllPackInformation = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/pack-information`)
        .verb(HTTP_VERBS.GET)
        .types(getAllPackInformation.all())
        .build();

export const updatePackInformation = generateApiActions("bo_update_pack_information");

export const actionUpdatePackInformation = (id, features) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/membership/pack-information/${id}`)
        .verb(HTTP_VERBS.POST)
        .withBody(features)
        .types(updatePackInformation.all())
        .build();
const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getAllPackConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {packConfiguration: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addPackConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {packConfiguration: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...deletePackConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {packConfiguration: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...getAllUsers.all())]: {
            next: (state, {payload}) => (payload ? {users: payload.content, ...state} : {...state}),
            throw: () => defaultState
        },

        [combineActions(addMembershipLevelInUserConfiguration.FAILURE, addMembershipLevelInUserConfiguration.SUCCESS)]: {
            next: (state, {payload}) => {
                return {...state, modifyUsersMembershipLevelSuccess: payload ? true : false, isLoading: false};
            },
            throw: () => defaultState
        },
        [combineActions(addMembershipLevelInUserConfiguration.REQUEST)]: {
            next: (state, {payload}) => {
                return {...state, isLoading: true};
            },
            throw: () => defaultState
        },
        [combineActions(removeMembershipLevelInUserConfiguration.FAILURE, removeMembershipLevelInUserConfiguration.SUCCESS)]: {
            next: (state, {payload}) => {
                return {...state, modifyUsersMembershipLevelSuccess: payload ? true : false, isLoading: false};
            },
            throw: () => defaultState
        },
        [combineActions(removeMembershipLevelInUserConfiguration.REQUEST)]: {
            next: (state, {payload}) => {
                return {...state, isLoading: true};
            },
            throw: () => defaultState
        },

        [combineActions(MembershipLevelActionReset.RESET)]: {
            next: (state) => ({...state, errors: [], modifyUsersMembershipLevelSuccess: false}),
            throw: () => defaultState
        },
        [combineActions(...getAllProductConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {productConfiguration: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addProductConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {productConfiguration: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...deleteProductConfiguration.all())]: {
            next: (state, {payload}) => (payload ? {productConfiguration: payload.content, ...state} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...getAllPackInformation.all())]: {
            next: (state, {payload}) => (payload ? {packInformation: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...updatePackInformation.all())]: {
            next: (state, {payload}) => (payload ? {packInformation: payload.content} : {...state}),
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducer;