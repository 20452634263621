import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const computeRankingActions = generateApiActions("bo_post_compute_ranking");

export const actionPostComputeRanking = () =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/ranking`)
        .verb(HTTP_VERBS.PUT)
        .types(computeRankingActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...computeRankingActions.all())]: {
            next: (state, {payload}) => (payload ? {computeDone: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

