import {ApiResponse, SupportMessage} from "../../../typings/api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Loadable} from "../../../typings/store";
import {ApiBuilder, HTTP_VERBS, ThunkApiArgs} from "../../../services/fetch-api";

type AllSupportMessagesApiReturnType = ApiResponse<SupportMessage[]>
type FetchAllSupportMessagesPageArgs = {};

export interface AllSupportMessagesPageState extends Loadable {
    allMessages: SupportMessage[];
}

const initialAllSupportMessagesState: AllSupportMessagesPageState = {
    allMessages: [] as SupportMessage[],
    isLoading: false,
}

export const fetchAllSupportMessagesPage = createAsyncThunk<AllSupportMessagesApiReturnType,
    FetchAllSupportMessagesPageArgs,
    ThunkApiArgs<AllSupportMessagesApiReturnType>>("AllSupportMessagesPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/contact/`)
        .verb(HTTP_VERBS.GET)
        .build()
)

export const allSupportMessagesPageSlice = createSlice({
    name: "allSupportMessagesSlice",
    initialState: initialAllSupportMessagesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllSupportMessagesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllSupportMessagesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMessages = payload.content;
            })
            .addCase(fetchAllSupportMessagesPage.rejected, state => {
                state.isLoading = false;
            })
    }
})