import React from "react";
import {Field, Form} from "react-final-form";

interface Values {
    loginOrEmail?: string;
    password?: string;
}

interface LoginFormProps {
    onSubmit: (values: Values) => void;
}

const LoginForm: React.FC<any> = ({onSubmit}: LoginFormProps) => (
    <Form
        onSubmit={onSubmit}
        render={({handleSubmit}) => (
            <form className="user" onSubmit={handleSubmit}>
                <div className="form-group">
                    <Field<string> component="input" className="form-control form-control-user" placeholder="login"
                                   name="nicknameOrEmail"/>
                </div>
                <div className="form-group">
                    <Field<string>
                        component="input"
                        type="password"
                        className="form-control form-control-user"
                        placeholder="mot de passe"
                        name="password"
                    />
                </div>
                <hr/>

                <button type="submit" className="btn btn-primary btn-user btn-block">
                    Connexion
                </button>
            </form>
        )}
    />
);

export default LoginForm;
