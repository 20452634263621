import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllMatchsApiReturnType = ApiResponse<Page>;
type FetchAllMatchsPageArgs = { page: number };

export interface AllMatchsPageState extends Loadable {
    allMatchs: Page;
}

const initialAllMatchsState: AllMatchsPageState = {
    allMatchs: {} as Page,
    isLoading: false,
};

export const fetchAllMatchsPage = createAsyncThunk<AllMatchsApiReturnType,
    FetchAllMatchsPageArgs,
    ThunkApiArgs<AllMatchsApiReturnType>>("AllMatchsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/matchs/all?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allMatchsPageSlice = createSlice({
    name: "allMatchsSlice",
    initialState: initialAllMatchsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllMatchsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatchsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMatchs = payload.content;
            })
            .addCase(fetchAllMatchsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllPendingMatchsApiReturnType = ApiResponse<Page>;
type FetchAllPendingMatchsPageArgs = { page: number };

export interface AllPendingMatchsPageState extends Loadable {
    allPendingMatchs: Page;
}

const initialAllPendingMatchsState: AllPendingMatchsPageState = {
    allPendingMatchs: {} as Page,
    isLoading: false,
};

export const fetchAllPendingMatchsPage = createAsyncThunk<AllPendingMatchsApiReturnType,
    FetchAllPendingMatchsPageArgs,
    ThunkApiArgs<AllPendingMatchsApiReturnType>>("AllPendingMatchsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/matchs/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPendingMatchsPageSlice = createSlice({
    name: "allPendingMatchsSlice",
    initialState: initialAllPendingMatchsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPendingMatchsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPendingMatchsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allPendingMatchs = payload.content;
            })
            .addCase(fetchAllPendingMatchsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UpdateMatchApiReturnType = ApiResponse<any>;
type FetchUpdateMatchPageArgs = { updateMatchRequest: any };

export interface UpdateMatchPageState extends Loadable {
}

const initialUpdateMatchState: UpdateMatchPageState = {
    isLoading: false,
};

export const fetchUpdateMatchPage = createAsyncThunk<UpdateMatchApiReturnType,
    FetchUpdateMatchPageArgs,
    ThunkApiArgs<UpdateMatchApiReturnType>>("UpdateMatchPage/fetch", async ({updateMatchRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/matchs/result/prediction`)
        .withBody(updateMatchRequest)
        .verb(HTTP_VERBS.PUT)
        .build()
);

export const updateMatchPageSlice = createSlice({
    name: "updateMatchSlice",
    initialState: initialUpdateMatchState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateMatchPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateMatchPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateMatchPage.rejected, state => {
                state.isLoading = false;
            });
    },
});



