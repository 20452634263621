import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    AllChallengesPageState,
    DeleteChallengePageState,
    fetchAllChallengesPage,
    fetchDeleteChallengePage,
    fetchSearchChallengeByTitlePage,
    SearchChallengeByTitlePageState
} from "./all-challenges-reducer";
import {Challenge, Page} from "../../../typings/api";
import {AppState} from "../../../store/reducers";
import {useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Pagination from "../../../uicomponents/pagination/pagination";

const AllChallenges = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [challenges, setChallenges] = useState<Page>({} as Page);
    const [currentChallenge, setCurrentChallenge] = useState<Challenge>();
    const [showDelete, setShowDelete] = useState(false);
    const [search, setSearch] = useState("");

    const {allChallenges} = useSelector<AppState, AllChallengesPageState>(state => state.allChallenges);
    const {searchChallenge} = useSelector<AppState, SearchChallengeByTitlePageState>(state => state.searchChallenge);
    const deleteChallengeState = useSelector<AppState, DeleteChallengePageState>(state => state.deleteChallenge);

    useEffect(() => {
        dispatch(fetchAllChallengesPage({page: 0}));
    }, []);

    useEffect(() => {
        if (search === "")
            setChallenges(allChallenges);
        else
            dispatch(fetchSearchChallengeByTitlePage({title: search, page: 0}));
    }, [search]);

    useEffect(() => {
        setChallenges(allChallenges);
    }, [allChallenges]);

    useEffect(() => {
        setChallenges(searchChallenge);
    }, [searchChallenge]);

    useEffect(() => {
        if (!deleteChallengeState.isLoading) {
            dispatch(fetchAllChallengesPage({page: 0}));
        }
    }, [deleteChallengeState]);

    const handleSearchTextChange = text => {
        setSearch(text.target.value);
    };

    const handleCreateClick = () => {
        history.push("/create-challenge");
    };

    const handleModifyClick = challenge => {
        history.push({
            pathname: '/challenge/' + challenge.id,
            state: {challenge: challenge}
        });
    };

    const handleDeleteClick = challenge => {
        setCurrentChallenge(challenge);
        setShowDelete(true);
    };

    const handleDeleteChallenge = () => {
        if (currentChallenge)
            dispatch(fetchDeleteChallengePage({challengeId: currentChallenge.id}))
        setShowDelete(false);
    };

    const paginationChange = (event, page) => {
        if (search === "")
            dispatch(fetchAllChallengesPage({page: page}))
        else
            dispatch(fetchSearchChallengeByTitlePage({title: search, page: page}));
    }

    return (
        <div className="start-page">
            <header className="page-header page-header-dark">
                <h1>Challenges</h1>
            </header>
            <div className="mb-2">
                <form className="form-inline">
                    <input
                        className="form-control"
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Rechercher"
                        onChange={e => handleSearchTextChange(e)}
                    />
                    <button className="btn btn-primary" onClick={() => handleCreateClick()}>
                        <i className="fas fa-plus"/>
                    </button>
                </form>
            </div>
            <div className="card">
                <div className="card-header">
                    <Pagination page={challenges} action={paginationChange}/>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Présentation courte</th>
                        <th>Total des Prédictions</th>
                        <th>Total des Participants</th>
                        <th>Participants Abonnés</th>
                        <th>Participants Non Abonnés</th>
                        <th>Modifier</th>
                        <th>Supprimer</th>
                        <th>Statut</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        challenges && challenges.content && challenges.content.map((challenge, index) =>
                            <tr key={index}>
                                <td>{challenge.id}</td>
                                <td>{challenge.title}</td>
                                <td>{challenge.shortDescription}</td>
                                <td>{challenge.totalPredictions}</td>
                                <td>{challenge.nbParticipants}</td>
                                <td>{challenge.activeSubscriptionsCount}</td>
                                <td>{challenge.otherCount}</td>
                                <td>
                                    <button className="btn btn-primary" type="button"
                                            onClick={() => handleModifyClick(challenge)}>
                                        <i className="fas fa-pencil-alt"/>
                                    </button>
                                </td>
                                <td>
                                    <button className="ml-4 btn btn-danger" type="button"
                                            onClick={() => handleDeleteClick(challenge)}>
                                        <i className="fas fa-trash"/>
                                    </button>
                                </td>
                                <td>
                                    {
                                        moment(challenge.startDate) > moment(Date.now()) &&
                                        <h2><span className="badge badge-warning">Prévu</span></h2>
                                    }
                                    {
                                        moment(challenge.startDate) < moment(Date.now()) &&
                                        moment(challenge.expirationDate) > moment(Date.now()) &&
                                        <h2><span className="badge badge-success">En Cours</span></h2>
                                    }
                                    {
                                        moment(challenge.expirationDate) < moment(Date.now()) &&
                                        <h2><span className="badge badge-danger">Terminé</span></h2>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
            {
                showDelete &&
                <Modal size="lg" show={showDelete} onHide={() => setShowDelete(false)}>
                    <Modal.Header closeButton className="alert alert-danger">
                        <Modal.Title>Challenge Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you wish to delete {currentChallenge ? currentChallenge.title : 'none'}?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => handleDeleteChallenge()}>
                            Confirm deletion
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

export default AllChallenges;