import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {
    AllFeedsForManageFeedsPageState,
    DeleteFeedPageState,
    fetchAllFeedsForManageFeedsPage,
    fetchDeleteFeedPage,
    fetchUpdateFeedPage,
    UpdateFeedPageState
} from "./feeds-reducer";
import {Sport} from "../../../typings/api";
import {Modal, ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const Feeds = () => {

    const dispatch = useDispatch();

    const defaultFeed = {active: false} as Sport;

    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [showModify, setShowModify] = useState<boolean>(false);
    const [newFeed, setNewFeed] = useState<boolean>(true);
    const [currentFeed, setCurrentFeed] = useState<Sport>({} as Sport);

    const {allFeeds} = useSelector<AppState, AllFeedsForManageFeedsPageState>(state => state.allFeedsForManageFeeds);
    const updateFeed = useSelector<AppState, UpdateFeedPageState>(state => state.updateFeed);
    const deleteFeed = useSelector<AppState, DeleteFeedPageState>(state => state.deleteFeed);

    useEffect(() => {
        dispatch(fetchAllFeedsForManageFeedsPage({}));
    }, []);

    useEffect(() => {
        if (!updateFeed.isLoading)
            dispatch(fetchAllFeedsForManageFeedsPage({}));
    }, [updateFeed.isLoading]);

    useEffect(() => {
        if (!deleteFeed.isLoading)
            dispatch(fetchAllFeedsForManageFeedsPage({}));
    }, [deleteFeed.isLoading]);

    const onModifyClick = feed => {
        setCurrentFeed(feed);
        setNewFeed(!feed.id);
        setShowModify(true);
    };

    const handleModifyButton = () => {
        dispatch(fetchUpdateFeedPage({feed: {...currentFeed, activeForProno: currentFeed.active}}));
        setCurrentFeed(defaultFeed);
        setShowModify(false);
    };

    const handleDeleteButton = () => {
        dispatch(fetchDeleteFeedPage({feedId: currentFeed.id}));
        setCurrentFeed(defaultFeed);
        setShowModify(false);
    };

    const verifyFeed = () => {
        return currentFeed.code != null && currentFeed.name != null && currentFeed.active != null &&
            currentFeed.code !== "" && (!newFeed || !allFeeds.map(f => f.code).includes(currentFeed.code))
    };

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Gestion des Feeds</h1>
        </header>
        <button className="btn btn-primary mb-2" onClick={() => onModifyClick(defaultFeed)}>
            <i className="fas fa-plus"/> Ajouter un Feed
        </button>
        <input type="checkbox" className="custom-checkbox mb-2 ml-2" checked={showInactive}
               onChange={event => setShowInactive(event.target.checked)}/> Afficher les feeds inactifs
        <div className="card mb-2">
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Nom</th>
                    <th>Ordre</th>
                    <th>Visibilité</th>
                    <th>Modifier</th>
                </tr>
                </thead>
                <tbody>
                {
                    allFeeds && (showInactive ? allFeeds : allFeeds.filter(f => f.active)).map(feed =>
                        <tr>
                            <td>{feed.id}</td>
                            <td>{feed.code}</td>
                            <td>{feed.name}</td>
                            <td>{feed.sportOrder}</td>
                            <td>
                                {
                                    feed.active ?
                                        <span className='badge badge-primary'>Actif</span> :
                                        <span className='badge badge-danger'>Inactif</span>
                                }
                            </td>
                            <td>
                                <button className="btn btn-circle btn-secondary" onClick={() => onModifyClick(feed)}>
                                    <i className="fas fa-cog"/>
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
        {
            showModify &&
            <Modal show={showModify} onHide={() => setShowModify(false)}>
                <ModalHeader closeButton className="alert alert-primary">
                    <ModalTitle>Modification de {currentFeed.name}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {
                        newFeed ?
                            <div className="alert alert-danger font-weight-bolder">
                                <i className="fas fa-exclamation-triangle"/> Le code d'un feed doit être unique. Ses
                                messages y seront liés.
                            </div> :
                            <div className="alert alert-danger font-weight-bolder">
                                <i className="fas fa-exclamation-triangle"/> La suppression d'un feed ne supprime pas
                                ses messages. Ils seront cependant inaccessibles jusqu'à la création d'un nouveau feed
                                avec le même code.
                            </div>
                    }
                    <h5>Code</h5>
                    <input type="text" className="form-control mb-2" value={currentFeed.code} disabled={!newFeed}
                           onChange={event => setCurrentFeed({...currentFeed, code: event.target.value})}/>
                    <h5>Nom</h5>
                    <input type="text" className="form-control mb-2" value={currentFeed.name}
                           onChange={event => setCurrentFeed({...currentFeed, name: event.target.value})}/>
                    <h5>Ordre</h5>
                    <input type="number" className="form-control mb-2" value={currentFeed.sportOrder}
                           onChange={event => setCurrentFeed({
                               ...currentFeed,
                               sportOrder: parseInt(event.target.value)
                           })}/>
                    <h5>Actif</h5>
                    <BootstrapSwitchButton checked={currentFeed.active} onlabel='ACTIF' onstyle='primary'
                                           offlabel='INACTIF' offstyle='danger' style='w-100'
                                           onChange={event => setCurrentFeed({...currentFeed, active: event})}/>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger" onClick={handleDeleteButton} disabled={newFeed}>
                        <i className="fas fa-exclamation-triangle"/> Tenter de supprimer
                    </button>
                    <button className="btn btn-primary" onClick={handleModifyButton} disabled={!verifyFeed()}>
                        <i className="fas fa-check"/> Confirmer
                    </button>
                </ModalFooter>
            </Modal>
        }
    </div>
};

export default Feeds;