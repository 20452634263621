import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, CampaignRequest, Page, User} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllCampaignsApiReturnType = ApiResponse<Page>;
type AllCampaignsPageArgs = { page: number };

export interface AllCampaignsPageState extends Loadable {
    allCampaigns: Page;
}

const initialAllCampaignsState: AllCampaignsPageState = {
    allCampaigns: {} as Page,
    isLoading: false,
};

export const fetchAllCampaignsPage = createAsyncThunk<AllCampaignsApiReturnType,
    AllCampaignsPageArgs,
    ThunkApiArgs<AllCampaignsApiReturnType>>("AllCampaignsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/campain/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allCampaignsPageSlice = createSlice({
    name: "allCampaignsSlice",
    initialState: initialAllCampaignsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllCampaignsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllCampaignsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allCampaigns = payload.content;
            })
            .addCase(fetchAllCampaignsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AllUsersApiReturnType = ApiResponse<User[]>;
type FetchAllUsersPageArgs = {};

export interface AllUsersPageState extends Loadable {
    users: User[];
}

const initialAllUsersState: AllUsersPageState = {
    users: [] as User[],
    isLoading: false,
};

export const fetchAllUsersPage = createAsyncThunk<AllUsersApiReturnType,
    FetchAllUsersPageArgs,
    ThunkApiArgs<AllUsersApiReturnType>>("AllUsersForCampaignsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/bo/users/all")
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForCampaignPageSlice = createSlice({
    name: "allUsersForCampaignsSlice",
    initialState: initialAllUsersState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.users = payload.content;
                console.log(payload.content)
            })
            .addCase(fetchAllUsersPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//************

type SendCampaignApiReturnType = ApiResponse<Page>;
type SendCampaignPageArgs = { sendRequest: CampaignRequest };

export interface SendCampaignPageState extends Loadable {
    campaignIsSend: boolean;
}

const initialSendCampaignState: SendCampaignPageState = {
    campaignIsSend: false,
    isLoading: false,
};

export const fetchSendCampaignPage = createAsyncThunk<SendCampaignApiReturnType,
    SendCampaignPageArgs,
    ThunkApiArgs<SendCampaignApiReturnType>>("SendCampaignPage/fetch", async ({sendRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/campain/`)
        .verb(HTTP_VERBS.POST)
        .withBody(sendRequest)
        .build()
);

export const sendCampaignPageSlice = createSlice({
    name: "sendCampaignSlice",
    initialState: initialSendCampaignState,
    reducers: {
        setCampaignIsSend: (state, action) => {
            state.campaignIsSend = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSendCampaignPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSendCampaignPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.campaignIsSend = true;
            })
            .addCase(fetchSendCampaignPage.rejected, state => {
                state.isLoading = false;
            });
    },
});


