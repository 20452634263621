import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, Page} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";

type AllInfluencersApiReturnType = ApiResponse<Page>;
type FetchAllInfluencersPageArgs = { page: number };

export interface AllInfluencersPageState extends Loadable {
    allInfluencers: Page;
}

const initialAllInfluencersState: AllInfluencersPageState = {
    allInfluencers: {} as Page,
    isLoading: false,
}

export const fetchAllInfluencersPage = createAsyncThunk<AllInfluencersApiReturnType,
    FetchAllInfluencersPageArgs,
    ThunkApiArgs<AllInfluencersApiReturnType>>("AllInfluencersPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/influencer-statistics/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allInfluencersPageSlice = createSlice({
    name: "allInfluencersSlice",
    initialState: initialAllInfluencersState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllInfluencersPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllInfluencersPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allInfluencers = payload.content;
            })
            .addCase(fetchAllInfluencersPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type SearchInfluencersByInfluencerNameApiReturnType = ApiResponse<Page>;
type FetchSearchInfluencersByInfluencerNamePageArgs = { influencerName: string, page: number };

export interface SearchInfluencersByInfluencerNamePageState extends Loadable {
    searchInfluencer: Page;
}

const initialSearchInfluencersByInfluencerNameState: SearchInfluencersByInfluencerNamePageState = {
    searchInfluencer: {} as Page,
    isLoading: false,
};

export const fetchSearchInfluencersByInfluencerNamePage = createAsyncThunk<SearchInfluencersByInfluencerNameApiReturnType,
    FetchSearchInfluencersByInfluencerNamePageArgs,
    ThunkApiArgs<SearchInfluencersByInfluencerNameApiReturnType>>("SearchInfluencersByInfluencerNamePage/fetch", async ({
                                                                                                                            influencerName,
                                                                                                                            page
                                                                                                                        }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/influencer-statistics/influencer-statistics/${influencerName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const searchInfluencersByInfluencerNamePageSlice = createSlice({
    name: "searchInfluencersByInfluencerNameSlice",
    initialState: initialSearchInfluencersByInfluencerNameState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSearchInfluencersByInfluencerNamePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSearchInfluencersByInfluencerNamePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.searchInfluencer = payload.content;
            })
            .addCase(fetchSearchInfluencersByInfluencerNamePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

