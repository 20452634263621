import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, SportCategory} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllSportsApiReturnType = ApiResponse<SportCategory[]>;
type FetchAllSportsPageArgs = {};

export interface AllSportsPageState extends Loadable {
    allSports: SportCategory[];
}

const initialSportsState: AllSportsPageState = {
    allSports: [] as SportCategory[],
    isLoading: false,
};

export const fetchAllSportsPage = createAsyncThunk<AllSportsApiReturnType,
    FetchAllSportsPageArgs,
    ThunkApiArgs<AllSportsApiReturnType>>("AllSportsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/sportcategory`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allSportsPageSlice = createSlice({
    name: "allSportsSlice",
    initialState: initialSportsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllSportsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllSportsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allSports = payload.content;
            })
            .addCase(fetchAllSportsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type AddOrUpdateSportApiReturnType = ApiResponse<SportCategory[]>;
type FetchAddOrUpdateSportPageArgs = { product: SportCategory };

export interface AddOrUpdateSportPageState extends Loadable {
    sports: SportCategory[];
}

const initialAddOrUpdateState: AddOrUpdateSportPageState = {
    sports: [] as SportCategory[],
    isLoading: false,
};

export const fetchAddOrUpdateSportPage = createAsyncThunk<AddOrUpdateSportApiReturnType,
    FetchAddOrUpdateSportPageArgs,
    ThunkApiArgs<AddOrUpdateSportApiReturnType>>("AddOrUpdateSportPage/fetch", async ({product}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/sportcategory`)
        .verb(HTTP_VERBS.POST)
        .withBody(product)
        .build()
);

export const addOrUpdateSportPageSlice = createSlice({
    name: "addOrUpdateSportSlice",
    initialState: initialAddOrUpdateState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAddOrUpdateSportPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAddOrUpdateSportPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.sports = payload.content;
            })
            .addCase(fetchAddOrUpdateSportPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********


type DeleteSportApiReturnType = ApiResponse<any>;
type FetchDeleteSportPageArgs = { product: SportCategory };

export interface DeleteSportPageState extends Loadable {
}

const initialDeleteState: DeleteSportPageState = {
    isLoading: false,
};

export const fetchDeleteSportPage = createAsyncThunk<DeleteSportApiReturnType,
    FetchDeleteSportPageArgs,
    ThunkApiArgs<DeleteSportApiReturnType>>("DeleteSportPage/fetch", async ({product}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/sportcategory`)
        .verb(HTTP_VERBS.DELETE)
        .withBody(product)
        .build()
);

export const deleteSportPageSlice = createSlice({
    name: "deleteSportSlice",
    initialState: initialDeleteState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteSportPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteSportPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteSportPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UploadSportImageApiReturnType = ApiResponse<string>;
type FetchUploadSportImagePageArgs = { data: any };

export interface UploadSportImagePageState extends Loadable {
    urlSportImage: string;
}

const initialUploadSportImageState: UploadSportImagePageState = {
    urlSportImage: "",
    isLoading: false,
};

export const fetchUploadSportImagePage = createAsyncThunk<UploadSportImageApiReturnType,
    FetchUploadSportImagePageArgs,
    ThunkApiArgs<UploadSportImageApiReturnType>>("UploadSportImagePage/fetch", async ({data}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/documents/`)
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const uploadSportImagePageSlice = createSlice({
    name: "uploadSportImageSlice",
    initialState: initialUploadSportImageState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUploadSportImagePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUploadSportImagePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.urlSportImage = payload.content;
            })
            .addCase(fetchUploadSportImagePage.rejected, state => {
                state.isLoading = false;
            });
    },
});