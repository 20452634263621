import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDailyUserCount } from '../user/user-reducer';
import { Card, Button, Form, Table } from 'react-bootstrap';
import { AppState } from '../../store/reducers'; // Assurez-vous que ce chemin est correct

const UserStatisticsPeriod = () => {
    const dispatch = useDispatch();
    const dailyUserCount = useSelector((state: AppState) => state.fetchDailyUserCount);

    const [startDate, setStartDate] = useState<string>('');
    const [startTime, setStartTime] = useState<string>('00:00');
    const [endDate, setEndDate] = useState<string>('');
    const [endTime, setEndTime] = useState<string>('23:59');

    const fetchUserStatistics = () => {
        const startDateTime = `${startDate}T${startTime}:00Z`;
        const endDateTime = `${endDate}T${endTime}:00Z`;

        if (new Date(startDateTime) >= new Date(endDateTime)) {
            alert('La date et heure de début doivent être antérieures à la date et heure de fin.');
            return;
        }
        dispatch(fetchDailyUserCount({ start: startDateTime, end: endDateTime }));
    };

    const totalNewUsers = dailyUserCount?.data.reduce((acc, curr) => acc + curr.count, 0) || 0;

    return (
        <div className="container mt-4">
            <h1 className="mb-4 text-gray-800">Statistiques des utilisateurs par période</h1>
            <Card className="shadow mb-4">
                <Card.Header>
                    <Form>
                        <Form.Row className="align-items-center">
                            <Form.Group className="mr-3 mb-0">
                                <Form.Label>Date de début</Form.Label>
                                <Form.Control type="date" value={startDate}
                                    // @ts-ignore
                                              onChange={e => setStartDate(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-0">
                                <Form.Label>Date de fin</Form.Label>
                                <Form.Control type="date" value={endDate}
                                    // @ts-ignore
                                              onChange={e => setEndDate(e.target.value)}/>
                            </Form.Group>
                            <Button className="ml-3" onClick={fetchUserStatistics}>Rechercher</Button>
                        </Form.Row>
                    </Form>
                </Card.Header>
                <Card.Body>
                    {dailyUserCount?.data?.length > 0 ? (
                        <Table responsive="sm" className="table">
                            <thead className="thead-light">
                            <tr>
                                <th>Date</th>
                                <th>Nombre d'inscriptions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dailyUserCount.data.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.date}</td>
                                    <td>{result.count}</td>
                                </tr>
                            ))}
                            <tr>
                                <td style={{textAlign: "right"}}>Total :</td>
                                <td><strong>{totalNewUsers}</strong></td>
                            </tr>
                            </tbody>
                        </Table>
                    ) : (
                        <div>Aucune donnée disponible pour la période sélectionnée.</div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default UserStatisticsPeriod;
