import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse} from "../../typings/api";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Loadable} from "../../typings/store";

type ExpertSubscriptionStatApiReturnType = ApiResponse<any>;

type FetchExpertSubscriptionStatArgs = {};

export interface ExpertSubscriptionStatState extends Loadable {
    expertSubscriptionStatResponse: Array<any>;
}

const initialExpertSubscriptionStatState: ExpertSubscriptionStatState = {
    expertSubscriptionStatResponse: [],
    isLoading: false,
};

export const fetchExpertSubscriptionStat = createAsyncThunk<ExpertSubscriptionStatApiReturnType,
    FetchExpertSubscriptionStatArgs,
    ThunkApiArgs<ExpertSubscriptionStatApiReturnType>>("ExpertSubscriptionStatistics/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint('/v1/bo/experts/subscription-statistics')
        .verb(HTTP_VERBS.GET)
        .build()
);

export const fetchExpertSubscriptionStatSlice = createSlice({
    name: "fetchExpertSubscriptionStatSlice",
    initialState: initialExpertSubscriptionStatState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchExpertSubscriptionStat.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchExpertSubscriptionStat.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.expertSubscriptionStatResponse = payload.content;
            })
            .addCase(fetchExpertSubscriptionStat.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type ExpertStatApiReturnType = ApiResponse<any>;
type FetchExpertStatArgs = {};

export interface ExpertStatState extends Loadable {
    expertStatResponse: Array<any>;
}

const initialExpertStatState: ExpertStatState = {
    expertStatResponse: [],
    isLoading: false,
};

export const fetchExpertStat = createAsyncThunk<ExpertStatApiReturnType,
    FetchExpertStatArgs,
    ThunkApiArgs<ExpertStatApiReturnType>>("ExpertStatistics/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint('/v1/bo/experts/statistics')
        .verb(HTTP_VERBS.GET)
        .build()
);

export const fetchExpertStatSlice = createSlice({
    name: "fetchExpertStatSlice",
    initialState: initialExpertStatState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchExpertStat.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchExpertStat.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.expertStatResponse = payload.content;
            })
            .addCase(fetchExpertStat.rejected, state => {
                state.isLoading = false;
            });
    },
});


