import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import {useHistory, useParams} from "react-router-dom";
import Pagination from "../../../uicomponents/pagination/pagination";
import {AllUsersForGodfatherPageState, fetchAllUsersForGodfatherPage,} from "./godsons-reducer";
import {AppState} from "../../../store/reducers";

const Godsons = () => {

    const {id, name} = useParams<{ id, name }>();

    const dispatch = useDispatch();
    const history = useHistory();
    const {allUsers} = useSelector<AppState, AllUsersForGodfatherPageState>(state => state.allUserForGodfather);
    const [nickname, setNickname] = useState("");
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        dispatch(fetchAllUsersForGodfatherPage({page: 0, godfatherId: id, name: nickname}));
    }, []);

    useEffect(() => {
        dispatch(fetchAllUsersForGodfatherPage({page: 0, godfatherId: id, name: nickname}));
    }, [nickname]);

    const handleInputTextChange = (event) => {
        setNickname(event.target.value);
    };

    const goUserDetails = (user) => {
        history.push('/user/' + user.id);
    };

    const paginationChange = (event, pageClicked) => {
        setCurrentPage(pageClicked);
        dispatch(fetchAllUsersForGodfatherPage({page: pageClicked, godfatherId: id, name: nickname}));
    };

    return (
        <div className="start-page">
            <header className="page-header page-header-dark row">
                <button className="btn btn-danger ml-3 mr-4 mb-3" onClick={() => history.push("/sponsorship")}>
                    Retour
                </button>
                <h1>Filleuls de {name}</h1>
            </header>
            <div className="row">
                <div className="col-md-4">

                    <FormControl
                        placeholder="Entrez le pseudo d'un utilisateur"
                        aria-label="Username"
                        aria-describedby="basic-addon"
                        size="lg"
                        onChange={(event) => handleInputTextChange(event)}
                    />
                </div>
            </div>
            <br/>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {
                        allUsers &&
                        <Pagination page={allUsers} action={paginationChange}/>
                    }
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pseudo</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Numéro</th>
                        <th>Jour d'inscription</th>
                        <th>Détails</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        allUsers.content && allUsers.content.map((user, index) =>
                            <tr key={index}>
                                <td>{user.id}</td>
                                <td>{user.nickname}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber === undefined || user.phoneNumber === null ? "N/A" : "+" + user.phoneNumber.toString().substring(2)}</td>
                                <td>{user.createdAt}</td>
                                <td>
                                    <Button variant="primary" onClick={() => goUserDetails(user)}>
                                        <i className="fas fa-arrow-right"/>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Godsons;