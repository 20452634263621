import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const allWatchingSponsorships = generateApiActions("bo_get_watching_sponsorship");

export const actionGetAllWatchingSponsorships = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint('/bo/users/sponsorship/')
        .verb(HTTP_VERBS.GET)
        .types(allWatchingSponsorships.all())
        .build();


export const createOrUpdateWatchingSponsorships = generateApiActions("bo_create_or_update_watching_sponsorship");

export const actionCreateOrUpdateWatchingSponsorships = (email: string, sponsorshipCode: string) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/sponsorship/${email}/${sponsorshipCode}`)
        .verb(HTTP_VERBS.POST)
        .types(createOrUpdateWatchingSponsorships.all())
        .build();


const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...allWatchingSponsorships.all())]: {
            next: (state, {payload}) => (payload ? {allWatchingSponsorships: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;