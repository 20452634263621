import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllMatchesWithResultApiReturnType = ApiResponse<Page>;
type FetchAllMatchesWithResultPageArgs = { search: string, page: number };

export interface AllMatchesWithResultPageState extends Loadable {
    allMatches: Page;
}

const initialAllMatchesWithResultState: AllMatchesWithResultPageState = {
    allMatches: {} as Page,
    isLoading: false,
};

export const fetchAllMatchesWithResultPage = createAsyncThunk<AllMatchesWithResultApiReturnType,
    FetchAllMatchesWithResultPageArgs,
    ThunkApiArgs<AllMatchesWithResultApiReturnType>>("AllMatchesWithResultPage/fetch", async ({
                                                                                                  search,
                                                                                                  page
                                                                                              }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/matchs/past-matches-with-result/${search}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allMatchesWithResultPageSlice = createSlice({
    name: "allMatchesWithResultSlice",
    initialState: initialAllMatchesWithResultState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllMatchesWithResultPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatchesWithResultPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMatches = payload.content;
            })
            .addCase(fetchAllMatchesWithResultPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*********

type UpdateMatchResultApiReturnType = ApiResponse<any>;
type FetchUpdateMatchResultPageArgs = { request: { cancel: boolean, prediction: number, result: boolean } };

export interface UpdateMatchResultPageState extends Loadable {
}

const initialUpdateMatchResultState: UpdateMatchResultPageState = {
    isLoading: false,
};

export const fetchUpdateMatchResultPage = createAsyncThunk<UpdateMatchResultApiReturnType,
    FetchUpdateMatchResultPageArgs,
    ThunkApiArgs<UpdateMatchResultApiReturnType>>("UpdateMatchResultPage/fetch", async ({request}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/matchs/result/prediction`)
        .withBody(request)
        .verb(HTTP_VERBS.PUT)
        .build()
);

export const updateMatchResultPageSlice = createSlice({
    name: "updateMatchResultSlice",
    initialState: initialUpdateMatchResultState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateMatchResultPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateMatchResultPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateMatchResultPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******