import React, {Component} from "react";
import CustomMatchInputV2 from "../../uicomponents/input/CustomMatchInputV2";

interface ScoreProps {
    handleInputTextChange: any;
    handleIsCancel: any;
    matchOnEdition: any;
}

interface ScoreState {
    results: Array<any>;
}

const FULL_TIME = ["THREE_WAY", "BOTH_TEAMS_TO_SCORE", "DOUBLE_CHANCE", "HANDICAP", "OVER_UNDER", "OVER_UNDER_HOME", "OVER_UNDER_AWAY"];
const HALF_TIME = ["THREE_WAY_HT", "BOTH_TEAMS_TO_SCORE_HT", "THREE_WAY_HT", "TWO_WAY_HT", "OVER_UNDER_HT", ""];
const FULL_TIME_WITH_EXTRA_TIME = ["TWO_WAY"];
const SECOND_HALF_TIME = ["THREE_WAY_2ND_HF", "BOTH_TEAMS_TO_SCORE_2ND_HT", "DOUBLE_CHANCE_2ND_HF", "TWO_WAY_2ND_HF", "OVER_UNDER_2ND_HF"];
const FIRST_QUARTER_TIME = ["THREE_WAY_Q1", "OVER_UNDER_Q1", "TWO_WAY_Q1"];
const SECOND_QUARTER_TIME = ["THREE_WAY_Q2", "OVER_UNDER_Q2", "TWO_WAY_Q2"];
const THIRD_QUARTER_TIME = ["THREE_WAY_Q3", "OVER_UNDER_Q3", "TWO_WAY_Q3"];
const FOURTH_QUARTER_TIME = ["THREE_WAY_Q4", "OVER_UNDER_Q4", "TWO_WAY_Q4"];

class ScoreV2 extends Component<ScoreProps, ScoreState> {


    render() {

        const {matchOnEdition, handleInputTextChange, handleIsCancel} = this.props;
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        {matchOnEdition.betDescription.bet.name.items[0].trad + " | "
                            + matchOnEdition.betDescription.betChoice.name.items[0].trad}
                    </div>
                    <div className="card-body">
                        <CustomMatchInputV2 onInputTextchange={handleInputTextChange} matchOnEdition={matchOnEdition}/>
                        <input className="custom-checkbox m-3" type="checkbox" onChange={handleIsCancel}/>Abandon
                    </div>
                </div>
            </div>
        );
    }

}

export default ScoreV2;