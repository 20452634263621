import {HTTP_VERBS} from "../../../services/rsaa-builder";
import {ApiResponse, Event, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";

type AllEventsApiReturnType = ApiResponse<Page>;
type FetchAllEventsPageArgs = { page: number };

export interface AllEventsPageState extends Loadable {
    allEvents: Page;
}

const initialAllEventsState: AllEventsPageState = {
    allEvents: {} as Page,
    isLoading: false,
};

export const fetchAllEventsPage = createAsyncThunk<AllEventsApiReturnType,
    FetchAllEventsPageArgs,
    ThunkApiArgs<AllEventsApiReturnType>>("AllEventsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/event/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allEventsPageSlice = createSlice({
    name: "allEventsSlice",
    initialState: initialAllEventsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllEventsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllEventsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allEvents = payload.content;
            })
            .addCase(fetchAllEventsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UpdateEventApiReturnType = ApiResponse<any>;
type FetchUpdateEventPageArgs = { updateCompetitionRequest: Event };

export interface UpdateEventPageState extends Loadable {
}

const initialUpdateEventState: UpdateEventPageState = {
    isLoading: false,
};

export const fetchUpdateEventPage = createAsyncThunk<UpdateEventApiReturnType,
    FetchUpdateEventPageArgs,
    ThunkApiArgs<UpdateEventApiReturnType>>("UpdateEventPage/fetch", async ({updateCompetitionRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/event`)
        .withBody(updateCompetitionRequest)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const updateEventPageSlice = createSlice({
    name: "updateEventSlice",
    initialState: initialUpdateEventState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateEventPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateEventPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateEventPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type SearchEventByNameApiReturnType = ApiResponse<Page>;
type FetchSearchEventByNamePageArgs = { name: string };

export interface SearchEventByNamePageState extends Loadable {
    eventsByName: Page;
}

const initialSearchEventByNameState: SearchEventByNamePageState = {
    eventsByName: {} as Page,
    isLoading: false,
};

export const fetchSearchEventByNamePage = createAsyncThunk<SearchEventByNameApiReturnType,
    FetchSearchEventByNamePageArgs,
    ThunkApiArgs<SearchEventByNameApiReturnType>>("SearchEventByNamePage/fetch", async ({name}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v2/bo/event/search/${name}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const searchEventByNamePageSlice = createSlice({
    name: "searchEventByNameSlice",
    initialState: initialSearchEventByNameState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSearchEventByNamePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSearchEventByNamePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.eventsByName = payload.content;
            })
            .addCase(fetchSearchEventByNamePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******



