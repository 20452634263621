export const isDevConfig = () => {
    return process.env.REACT_APP_ENV === "DEV";
}

export const isRecConfig = () => {
    return process.env.REACT_APP_ENV === "REC";
}

export const isProdConfig = () => {
    return process.env.REACT_APP_ENV === "PROD";
}

export const getConfig = () => {

    const devConfig = {
        "environment": "dev",
        "urlHostApi": "http://localhost:8080"
    }

    const recConfig = {
        "environment": "rec",
        "urlHostApi": "https://recette1.app-sobet.com"
    }

    const prodConfig = {
        "environment": "prod",
        "urlHostApi": "https://production1.app-sobet.com"
    }

    if (isRecConfig()) {
        return recConfig;
    } else if (isProdConfig()) {
        return prodConfig;
    } else {
        return devConfig;
    }
}

export default getConfig();