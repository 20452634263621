import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const allContactUsActions = generateApiActions("bo_get_support_message");

export const actionGetAllContactUs = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint('/contact/')
        .verb(HTTP_VERBS.GET)
        .types(allContactUsActions.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...allContactUsActions.all())]: {
            next: (state, {payload}) => (payload ? {allContactUs: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;