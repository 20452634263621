import {ApiResponse, RankingV2} from "../../typings/api";
import {Loadable} from "../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";
import {HTTP_VERBS} from "../../services/rsaa-builder";

type RankHistoryApiReturnType = ApiResponse<RankingV2[]>;
type RankHistoryPageArgs = { rankingType: string, challengeId: number | null };

export interface RankHistoryPageState extends Loadable {
    rank: RankingV2[];
}

const InitialRankHistoryState: RankHistoryPageState = {
    rank: [] as RankingV2[],
    isLoading: false,
};

export interface RankChallPageState extends Loadable {
    chall: RankingV2[];
}

const InitialRankChallState: RankChallPageState = {
    chall: [] as RankingV2[],
    isLoading: false,
};

export const fetchRankWeeklyHistoryPage = createAsyncThunk<RankHistoryApiReturnType,
    RankHistoryPageArgs,
    ThunkApiArgs<RankHistoryApiReturnType>>("rankWeekPage/fetch", async ({rankingType}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/ranking/week?rankingType=${rankingType}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const fetchChallengeRankPage = createAsyncThunk<RankHistoryApiReturnType,
    RankHistoryPageArgs,
    ThunkApiArgs<RankHistoryApiReturnType>>("rankChallPage/fetch", async ({rankingType, challengeId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/ranking/week?rankingType=${rankingType}&challengeId=${challengeId}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const rankHistoryPageSlice = createSlice({
    name: "RankHistoryInformationSlice",
    initialState: InitialRankHistoryState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRankWeeklyHistoryPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchRankWeeklyHistoryPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.rank = payload.content;
            })
            .addCase(fetchRankWeeklyHistoryPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const rankChallPageSlice = createSlice({
    name: "RankChallInformationSlice",
    initialState: InitialRankChallState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchChallengeRankPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchChallengeRankPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.chall = payload.content;
            })
            .addCase(fetchChallengeRankPage.rejected, state => {
                state.isLoading = false;
            });
    },
});