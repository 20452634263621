import {combineActions, createAction, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum CampainActionType {
    RESET = "CAMPAIN_RESET"
}


export const getCampains = generateApiActions("bo_get_campains");

export const actiongetCampains = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/campain/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getCampains.all())
        .build();


export const getAllUsers = generateApiActions("bo_get_all_users");

export const actionGetAllUsers = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/users/all")
        .verb(HTTP_VERBS.GET)
        .types(getAllUsers.all())
        .build();

export const sendCampainActions = generateApiActions("send_campain");

export const actionSendCampain = (sendRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/campain/`)
        .withBody(sendRequest)
        .verb(HTTP_VERBS.POST)
        .types(sendCampainActions.all())
        .build();


export const actionReset = createAction(CampainActionType.RESET, () => {
});

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getCampains.all())]: {
            next: (state, {payload}) => {
                return {...state, page: payload ? payload.content : null}
            },
            throw: () => defaultState,
        },

        [combineActions(...getAllUsers.all())]: {
            next: (state, {payload}) => {
                return {...state, users: payload ? payload.content : []}
            },
            throw: () => defaultState,
        },

        [combineActions(...sendCampainActions.all())]: {
            next: (state, {payload}) => {
                return {...state, campainIsSend: payload ? true : false,}
            },
            throw: () => (state, {payload}) => (payload ? {errors: payload.errors} : defaultState),
        },
        [combineActions(CampainActionType.RESET)]: {
            next: (state) => ({...state, errors: [], campainIsSend: false}),
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducer;