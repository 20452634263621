import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "react-bootstrap/Table";
import {produce} from "immer";
import {Button, Form, Modal} from "react-bootstrap";
import Pagination from "../../uicomponents/pagination/pagination";
import {AppState} from "../../store/reducers";
import {
    AddUserPageState,
    AllRolesPageState,
    AllUsersPageState,
    fetchAddUserPage,
    fetchAllRolesPage,
    fetchAllUsersPage,
    fetchRemoveUserRolePage,
    RemoveUserRolePageState
} from "./authorization-reducer";

const Authorization = () => {

    const dispatch = useDispatch();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [userEdition, setUserEdition] = useState({nickname: "", id: 0});
    const [rolesChoice, setRolesChoice] = useState<Array<string>>([]);
    const [users, setUsers] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchRole, setSearchRole] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);

    const {allUsers} = useSelector<AppState, AllUsersPageState>(state => state.allUsers);
    const {allRoles} = useSelector<AppState, AllRolesPageState>(state => state.allRoles);
    const addRole = useSelector<AppState, AddUserPageState>(state => state.addRole);
    const removeRole = useSelector<AppState, RemoveUserRolePageState>(state => state.removeRole);

    useEffect(() => {
        dispatch(fetchAllUsersPage({role: false, value: "", page: 0}));
        dispatch(fetchAllRolesPage({}));
    }, []);

    useEffect(() => {
        setUsers(allUsers)
    }, [allUsers]);

    useEffect(() => {
        if (!addRole.isLoading)
            dispatch(fetchAllUsersPage({role: searchRole, value: searchValue, page: currentPage}));
    }, [addRole.isLoading]);

    useEffect(() => {
        if (!removeRole.isLoading)
            dispatch(fetchAllUsersPage({role: searchRole, value: searchValue, page: currentPage}));
    }, [removeRole.isLoading]);

    useEffect(() => {
        setCurrentPage(0);
        dispatch(fetchAllUsersPage({role: searchRole, value: searchValue, page: 0}));
    }, [searchValue]);

    const handleInputTextChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleRoleChange = (event) => {
        const value = event.target.value;
        setSearchRole(value !== "ANY");
        setSearchValue(value !== "ANY" ? event.target.value : "");
    };

    const handleChoiceRoles = (event) => {
        const value = event.target.value;

        if (rolesChoice.length === 0) {
            setRolesChoice([value]);
        } else {
            if (rolesChoice.indexOf(value) === -1) {
                setRolesChoice(currentValues =>
                    produce(currentValues, items => {
                        items[rolesChoice.length] = value;
                    })
                );
            }
        }
    };

    const addUserRoles1 = () => {
        const request = {
            userId: userEdition.id,
            roles: rolesChoice
        };
        dispatch(fetchAddUserPage({updateRoleRequest: request}));
        setShowAddModal(false);
        setRolesChoice([]);
    };

    const removeUserRoles1 = () => {
        const request = {
            userId: userEdition.id,
            roles: rolesChoice
        };
        dispatch(fetchRemoveUserRolePage({updateRoleRequest: request}));
        setShowDeletionModal(false);
        setRolesChoice([]);
    };

    const paginationChange = (event, pageClicked) => {
        setCurrentPage(pageClicked);
        dispatch(fetchAllUsersPage({role: searchRole, value: searchValue, page: pageClicked}));
    };

    const cleanUserAndShowModal = (user) => {
        setUserEdition(user);
        setShowAddModal(true);
    }

    const cleanUserAfterDeletionAndShowModal = (user) => {
        setUserEdition(user);
        setShowDeletionModal(true);
    }

    const cleanRolesChoiceAfterAddAndCloseModal = () => {
        setShowAddModal(false)
        setRolesChoice([]);
    }

    const cleanRolesChoiceAfterDeletionAndCloseModal = () => {
        setShowDeletionModal(false)
        setRolesChoice([]);
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Habiliation des rôles</h1>

            <div className="row mb-2">
                <div className="col-md-4">
                    <input className="form-control" placeholder="Utilisateur..." value={searchValue}
                           disabled={searchRole}
                           onChange={(event) => handleInputTextChange(event)}
                    />
                </div>

                <div className="col-md-4">
                    <select className="custom-select" onChange={(event) => handleRoleChange(event)}>
                        <option value="ANY">ANY</option>
                        {
                            allRoles && allRoles.map((role, index) =>
                                <option value={role.name}>{role.name}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-header py-3">
                    {
                        users &&
                        <Pagination page={users} action={(event, page) => paginationChange(event, page)}/>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pseudo</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Ajouter</th>
                        <th>Retirer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users.content && users.content.map((user, index) =>
                            <tr key={index}>
                                <td>{user.id}</td>
                                <td>{user.nickname}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.email}</td>
                                <td>{user.roles.map(role => role.name).join(', ')}</td>
                                <td>
                                    <Button variant="primary" onClick={() => cleanUserAndShowModal(user)}>
                                        <i className="fas fa-plus"/>
                                    </Button>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => cleanUserAfterDeletionAndShowModal(user)}>
                                        <i className="fas fa-trash"/>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <Modal size="lg" show={showAddModal}
                   onHide={() => cleanRolesChoiceAfterAddAndCloseModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{"Ajout d'un rôle pour " + userEdition.nickname}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Veuillez selectionner le role à ajouter</Form.Label>
                        <Form.Control as="select" multiple onChange={(event) => handleChoiceRoles(event)}>
                            {
                                allRoles && allRoles.map((role, index) =>
                                    <option value={role.name.toString()}>{role.name.toString()}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => cleanRolesChoiceAfterAddAndCloseModal()}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => addUserRoles1()}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showDeletionModal}
                   onHide={() => cleanRolesChoiceAfterDeletionAndCloseModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{"Retrait d'un rôle de " + userEdition.nickname}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Veuillez selectionner le role à supprimer</Form.Label>
                        <Form.Control as="select" multiple onChange={(event) => handleChoiceRoles(event)}>
                            {
                                allRoles && allRoles.map((role, index) =>
                                    <option value={role.name.toString()}>{role.name.toString()}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => cleanRolesChoiceAfterDeletionAndCloseModal()}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => removeUserRoles1()}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Authorization;
