import {ApiResponse, MatchScore, Page} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";
import {HTTP_VERBS} from "../../../services/rsaa-builder";

type AllMatchScoresReturnType = ApiResponse<Page>
type AllMatchesScoresPageArgs = { page: number };

export interface AllMatchesScoresState extends Loadable {
    allMatchScores: Page;
}

const initialAllMatchScoresState: AllMatchesScoresState = {
    allMatchScores: {} as Page,
    isLoading: false,
};

export const fetchAllMatchScores = createAsyncThunk<AllMatchScoresReturnType,
    AllMatchesScoresPageArgs,
    ThunkApiArgs<AllMatchesScoresState>>("AllMatchScores/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/match-scores/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allMatchScoresSlice = createSlice({
    name: "allMatchScoresSlice",
    initialState: initialAllMatchScoresState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllMatchScores.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllMatchScores.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allMatchScores = payload.content;
            })
            .addCase(fetchAllMatchScores.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******


type UpdateMatchScoreReturnType = ApiResponse<MatchScore>;
type UpdateMatchScoreArgs = { matchScore: MatchScore };

export interface UpdateMatchScoreState extends Loadable {
    isCreated: boolean;
    matchScore: MatchScore;
    error: boolean;
}

const initialUpdateMatchScoreState: UpdateMatchScoreState = {
    isCreated: false,
    error: false,
    isLoading: false,
    matchScore: {awayScore: 0, homeScore: 0, matchId: 0, pending: true, id: 0}
};

export const updateMatchScore = createAsyncThunk<UpdateMatchScoreReturnType,
    UpdateMatchScoreArgs,
    ThunkApiArgs<UpdateMatchScoreReturnType>>("UpdateMatchScore/fetch", async ({matchScore}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint("/v1/match-scores/")
        .withBody(matchScore)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const updateMatchScoreSlice = createSlice({
    name: "updateMatchScoreSlice",
    initialState: initialUpdateMatchScoreState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(updateMatchScore.pending, state => {
                state.isLoading = true;
            })
            .addCase(updateMatchScore.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.matchScore = payload.content;
                state.isCreated = true;
            })
            .addCase(updateMatchScore.rejected, state => {
                state.isLoading = false;
                state.error = true;
            });
    },
});

//*******


type SearchMatchScoreReturnType = ApiResponse<Page>
type SearchMatchScorePageArgs = { page: number, query: string };

export interface SearchMatchScoreState extends Loadable {
    searchMatchScoresResultsPage: Page;
}

const initialSearchMatchScoreState: SearchMatchScoreState = {
    searchMatchScoresResultsPage: {} as Page,
    isLoading: false,
};

export const fetchSearchMatchScores = createAsyncThunk<SearchMatchScoreReturnType,
    SearchMatchScorePageArgs,
    ThunkApiArgs<SearchMatchScoreState>>("SearchMatchScores/fetch", async ({page, query}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/match-scores/search/${query}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const searchMatchScoresSlice = createSlice({
    name: "searchMatchScoresSlice",
    initialState: initialSearchMatchScoreState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSearchMatchScores.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSearchMatchScores.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.searchMatchScoresResultsPage = payload.content;
            })
            .addCase(fetchSearchMatchScores.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******


type CountPendingMatchScoresReturnType = ApiResponse<number>
type CountPendingMatchScoresArgs = {};

export interface CountPendingMatchScoresState extends Loadable {
    count: number
}

const initialCountPendingMatchScoresState: CountPendingMatchScoresState = {
    count: 0,
    isLoading: false,
};

export const fetchCountPendingMatchScores = createAsyncThunk<CountPendingMatchScoresReturnType,
    CountPendingMatchScoresArgs,
    ThunkApiArgs<CountPendingMatchScoresState>>("CountPendingMatchScores/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/match-scores/count`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const countPendingMatchScoresSlice = createSlice({
    name: "countPendingMatchScores",
    initialState: initialCountPendingMatchScoresState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCountPendingMatchScores.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCountPendingMatchScores.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.count = payload.content;
            })
            .addCase(fetchCountPendingMatchScores.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******
