import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";


export const getAllUsersActions = generateApiActions("authorization_get_page_user");

export const actionGetAllUsers = (page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(getAllUsersActions.all())
        .build();

export const searchUsersByexpression = generateApiActions("bo_search_user_by_expression");

export const actionSearchUsersByExpression = (nickName: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/search/${nickName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchUsersByexpression.all())
        .build();

export const searchUsersByRole = generateApiActions("bo_search_user_by_role");

export const actionSearchUsersByRole = (nickName: string, page: number) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/search-role/${nickName}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(searchUsersByRole.all())
        .build();


export const addUserRoles = generateApiActions("bo_add_user_roles");

export const actionAddUserRoles = (updateRoleRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/add-role`)
        .withBody(updateRoleRequest)
        .verb(HTTP_VERBS.POST)
        .types(addUserRoles.all())
        .build();


export const removeUserRoles = generateApiActions("bo_remove_user_roles");

export const actionRemoveUserRoles = (updateRoleRequest) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/users/remove-role`)
        .withBody(updateRoleRequest)
        .verb(HTTP_VERBS.POST)
        .types(removeUserRoles.all())
        .build();


const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...getAllUsersActions.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...searchUsersByexpression.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...searchUsersByRole.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addUserRoles.all())]: {
            next: state => ({...state}),
            throw: () => defaultState
        },
        [combineActions(...removeUserRoles.all())]: {
            next: state => ({...state}),
            throw: () => defaultState
        }
    },
    defaultState
);

export default reducer;
