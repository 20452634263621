import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {formatPrecisionMin} from "../../services/moment/momentUtil";
import {AppState} from "../../store/reducers";
import {AllGeneratedAlertsPageState, fetchAllGeneratedAlertsPage} from "./oddVariation-reducer";

const AllGeneratedAlerts = () => {

    const dispatch = useDispatch();
    const {allGeneratedAlerts} = useSelector<AppState, AllGeneratedAlertsPageState>(state => state.allGeneratedAlerts);
    useEffect(() => {
        dispatch(fetchAllGeneratedAlertsPage({}));
    }, []);

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Variation de cote générée par le système</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Championnat</th>
                            <th>Match</th>
                            <th>Date du match</th>
                            <th>Ancienne cote</th>
                            <th>Nouvelle cote</th>
                            <th>Nom prono</th>
                            <th>Nom choix du prono</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allGeneratedAlerts && allGeneratedAlerts.map((alert, index) =>
                                <>
                                    <tr key={index}>
                                        <td>{alert.id}</td>
                                        <td colSpan={9}>
                                            <div className="alert alert-info">
                                                {alert.message}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{alert.id}</td>
                                        <td>{formatPrecisionMin(alert.createdAt)}</td>
                                        <td>{alert.championship}</td>
                                        <td>{alert.matchName}</td>
                                        <td>{formatPrecisionMin(alert.matchDate)}</td>
                                        <td>{alert.oldOdd}</td>
                                        <td>{alert.newOdd}</td>
                                        <td>{alert.betName}</td>
                                        <td>{alert.betChoiceName}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}

export default AllGeneratedAlerts;