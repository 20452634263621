import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {compose} from "redux";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Pagination from "../../../uicomponents/pagination/pagination";
import AutoComplete from "../../../components/AutoComplete";
import {AppState} from "../../../store/reducers";
import {
    AllCampaignsPageState,
    AllUsersPageState,
    fetchAllCampaignsPage,
    fetchAllUsersPage,
    fetchSendCampaignPage,
    sendCampaignPageSlice,
    SendCampaignPageState
} from "./campaign-reducer";
import moment from "moment";
import {Modal} from "react-bootstrap";

interface PushNotificationProps {
    admin: any;
}

const Campain = ({admin}: PushNotificationProps) => {

    const dispatch = useDispatch();

    const [notificationTitle, setNotificationTitle] = useState("");
    const [message, setMessage] = useState("");
    const [selected, setSelected] = useState<{ id: number }[]>([]);
    const [allUsers, setAllUsers] = useState(false);
    const [showSent, setShowSent] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [emptyPlan, setEmptyPlan] = useState(true);

    let [sendDate, setSendDate] = useState<{ day: Date }>({day: new Date()});
    const [sendTime, setSendTime] = useState<{ hour: number, minute: number }>({hour: 0, minute: 0});
    const {allCampaigns} = useSelector<AppState, AllCampaignsPageState>(state => state.allCampaigns);
    const {users} = useSelector<AppState, AllUsersPageState>(state => state.allUsersForCampaign);
    const {campaignIsSend, isLoading} = useSelector<AppState, SendCampaignPageState>(state => state.sendCampaign);
    useEffect(() => {
        dispatch(fetchAllUsersPage({}));
        dispatch(fetchAllCampaignsPage({page: 0}));
    }, []);

    useEffect(() => {
        if (!isLoading)
            dispatch(fetchAllCampaignsPage({page: 0}));
    }, [isLoading]);

    const canSend = (allUsers || selected.length > 0) && message.length > 0 && notificationTitle.length > 0;

    const handleDateChange = (key, event) => {
        event.target.value == null ? setEmptyPlan(false) : setEmptyPlan(true);
        var date = new Date(event.target.value);
        if (date != null) {
            setSendDate({...sendDate, [key]: date});
        }
    }

    const handleTimeChange = (key, event) => {
        event.target.value == null ? setEmptyPlan(false) : setEmptyPlan(true);
        const time = event.target.value;
        const hour = Number(time.slice(0, 2));
        const minute = Number(time.slice(3, 5));
        if (event.target.value == null) {
            const nhour = Number(0);
            const nminute = Number(0);
            setSendTime({...sendTime, hour: nhour, minute: nminute});
        } else {

            setSendTime({...sendTime, hour, minute});
        }
    }

    const handleInputTitleChange = (event) => {
        event.preventDefault()
        const notificationTitle = event.target.value;
        setNotificationTitle(notificationTitle);
    };

    const handleInputTextChange = (event) => {
        const message = event.target.value;
        setMessage(message);
    };

    const handleSendMessage = () => {
        if (canSend) {
            let toUserIds = selected.map(function (user) {
                return user.id;
            });
            let request;
            if (showPlans) {
                const timeToSendv2 = new Date(sendDate.day.getFullYear(), sendDate.day.getMonth(), sendDate.day.getDate(), sendTime.hour, sendTime.minute);
                request = {
                    fromUserId: admin.user.id,
                    toUserIds: toUserIds,
                    notificationTitle: notificationTitle,
                    message: message,
                    allUsers: allUsers,
                    timeToSend: timeToSendv2
                };
            } else {
                request = {
                    fromUserId: admin.user.id,
                    toUserIds: toUserIds,
                    notificationTitle: notificationTitle,
                    message: message,
                    allUsers: allUsers
                }
            }

            dispatch(fetchSendCampaignPage({sendRequest: request}));
            setShowSent(true);
        }
    };

    const handleUsersSelected = selected => {
        setSelected(selected);
    };

    const handleAllUsersChecked = event => {
        const allUsers = event.target.checked;
        setAllUsers(allUsers);
    };

    const handlePlanificationChecked = event => {
        const showPlans = event.target.checked;
        event.target.checked ? setEmptyPlan(false) : setEmptyPlan(true);
        setShowPlans(showPlans);
    }

    const paginationChange = (event, pageClicked) => {
        dispatch(fetchAllCampaignsPage({page: pageClicked}));
    };

    const setShow = () => {
        dispatch(fetchAllCampaignsPage({page: 0}));
        dispatch(sendCampaignPageSlice.actions.setCampaignIsSend(false));
    }

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Envoi de notifications</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea2">

                            <Form.Label>Titre de la notification</Form.Label>
                            <Form.Control onChange={(event) => handleInputTitleChange(event)}/>
                            <Form.Label>Message à transmettre</Form.Label>
                            <Form.Control as="textarea" rows="5" onChange={(event) => handleInputTextChange(event)}/>
                        </Form.Group>
                    </Form>

                    <br/>
                    <div className="row">
                        <div className="col-md-9">
                            <AutoComplete users={users} labelKey="nickname"
                                          filter={["firstname", "lastname", "email"]} multiple
                                          placeHolderText="Choississez les utilisateurs"
                                          handleUsersSelected={handleUsersSelected}/>
                        </div>
                        <div className="form-group col-md-3">
                            <Form>
                                <div className="mb-3">
                                    <Form.Group id="formGridCheckbox"
                                                onChange={(event) => handleAllUsersChecked(event)}>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="custom-checkbox"
                                            label="Tous les utilisateurs"
                                        />
                                    </Form.Group>
                                    <Form.Group id="planGridBox"
                                                onChange={(event) => handlePlanificationChecked(event)}>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="Seer-checkbox"
                                            label="Planification"
                                        />
                                    </Form.Group>
                                </div>
                            </Form>
                        </div>
                        {
                            showPlans &&
                            <div style={{
                                width: '100%',
                            }} className="mb-4">
                                <h1
                                    style={{
                                        display: 'flex',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                    }}
                                >Planification</h1>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }} className="row">
                                    <div className="ml-3">
                                        <h6
                                            style={{
                                                display: 'flex',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                            }}>DAY</h6>
                                        <input className="border rounded mb-2" type="date"
                                               onChange={e => handleDateChange("day", e)}/>
                                    </div>
                                    <div className="ml-4">
                                        <h6>TIME</h6>
                                        <input className="border rounded" type="time"
                                               onChange={e => handleTimeChange("time", e)}/>
                                    </div>

                                </div>
                            </div>

                        }


                    </div>

                    <button className="btn btn-primary" onClick={() => handleSendMessage()}
                            disabled={!canSend || !emptyPlan}>
                        Envoyer <i className="far fa-paper-plane"/>
                    </button>


                </div>
            </div>
            {
                //here also check show sent and date to change the message to send
                showSent && !isLoading &&
                <Modal size="lg" show={showSent} onHide={() => setShowSent(false)}>
                    <Modal.Header closeButton className={campaignIsSend ? "alert alert-success" : "alert alert-danger"}>
                        <Modal.Title>{campaignIsSend ? "Succes" : "Echec"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            campaignIsSend ? "Votre notification a bien ete envoyee." : "La requete n'a pas pu aboutir. Veuillez verifier et reessayer votre envoi."
                        }
                    </Modal.Body>
                </Modal>
            }

            {
                allCampaigns &&
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Pagination page={allCampaigns} action={(event, page) => paginationChange(event, page)}/>
                    </div>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Date de publication</th>
                            <th>Titre</th>
                            <th>Message</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allCampaigns.content && allCampaigns.content.map((campain, index) =>
                                <tr key={campain.id}>
                                    <td>{campain.id}</td>
                                    <td>{moment(campain.publishedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>{campain.notificationTitle ? campain.notificationTitle : "Notification"}</td>
                                    <td>{campain.message}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            }
        </div>
    );

}

const mapStateToProps = state => ({
    admin: (state as any).authUser,
});

const mapDispatchToProps = {};


export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Campain);

