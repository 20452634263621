import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

import {
    AllEventsPageState,
    AllMatchesPageState,
    AllSportsPageState,
    CreateChallengePageState,
    fetchAllEventsForChallengePage,
    fetchAllMatchesForChallengePage,
    fetchAllSportsPage,
    fetchCreateChallengePage,
    fetchOddTypesPage,
    fetchUploadImageChallengePage,
    OddTypesPageState
} from "./challenge-reducer";
import {AppState} from "../../../store/reducers";
import {AllBookmakersPageState, fetchAllBookmakersPage} from "../../contribution/bookmaker/bookmaker-reducer";
import {UploadImageChallengePageState} from "../../challenge/create-challenge/challenge-reducer";
import {Challenge} from "../../../typings/api";
import {isDevConfig, isProdConfig} from "../../../services/config";
import {AllPackInformationPageState, fetchAllPackInformationPage} from "../../membership/membership-reducer";

const CreateChallenge = () => {

        const history = useHistory();
        const dispatch = useDispatch();

        const [showCreation, setShowCreation] = useState(false);
        const [showError, setShowError] = useState(false);
        const [confirmed, setConfirmed] = useState(false);
        const [challenge, setChallenge] = useState<Challenge>({
            id: 0,
            title: "",
            urlImage: "",
            presentation: "",
            shortDescription: "",
            nbMinPredictions: 3,
            packInformation: "FREE",
            rules: "",
            startDate: "",
            expirationDisplayDate: "",
            expirationDate: "",
            annoncesLots: "",
            priceCents: 0,
            paid: false,
            webUrl: null,
            lots: [],
            sports: [],
            event: [],
            matchs: [],
            bookmakers: [],
            oddTypes: [],
            nbParticipants: 0,
            advertising: "",
            activeSubscriptionsCount: 0,
            otherCount: 0,
            totalPredictions: 0,
            version: 0,
            googlePlayProductName: "",
            updatedAt: null,
            createdAt: null
        });

        const {allSportsForChallenge} = useSelector<AppState, AllSportsPageState>(state => state.allSportsForChallenge);
        const {allEventsForChallenge} = useSelector<AppState, AllEventsPageState>(state => state.allEventsForChallenge);
        const {allMatchesForChallenge} = useSelector<AppState, AllMatchesPageState>(state => state.allMatchesForChallenge);
        const {allBookmakers} = useSelector<AppState, AllBookmakersPageState>(state => state.allBookmakers);
        const {urlChallengeImage} = useSelector<AppState, UploadImageChallengePageState>(state => state.urlChallengeImage);
        const {oddTypes} = useSelector<AppState, OddTypesPageState>(state => state.oddTypes);
        const {allPackInformation} = useSelector<AppState, AllPackInformationPageState>(state => state.allPackInformation);
        const {isCreated, isLoading} = useSelector<AppState, CreateChallengePageState>(state => state.createChallenge);

        useEffect(() => {
            dispatch(fetchAllSportsPage({}));
            dispatch(fetchAllBookmakersPage({}));
            dispatch(fetchAllEventsForChallengePage({}));
            dispatch(fetchAllMatchesForChallengePage({}));
            dispatch(fetchOddTypesPage({}));
            dispatch(fetchAllPackInformationPage({}));
        }, []);

        useEffect(() => {
            if (confirmed && !isLoading) {
                setConfirmed(false);
                isCreated ? history.push('/all-challenges') : setShowError(true);
            }
        }, [isLoading])

        useEffect(() => {
            setChallenge({...challenge, urlImage: urlChallengeImage});
        }, [urlChallengeImage]);

        const getWebUrl = () => {
            if (isProdConfig()) {
                return "https://www.sobet.fr/webapp/challenge/sponsored/detail/"
            } else if (isDevConfig()) {
                return "https://localhost:3001/webapp/challenge/sponsored/detail/"
            } else {
                return "https://www-recette1.sobet.fr/webapp/challenge/sponsored/detail/"
            }
        }

        const handleImageChange = async event => {
            const data = new FormData();
            data.append("file", event.target.files[0]);
            await dispatch(fetchUploadImageChallengePage({data: data}));
        };

        const handleChallengeIsPaid = (key, value) => {
            setChallenge({...challenge, [key]: value});
        };
        const handleChallengeItemChange = (key, value) => {
            setChallenge({...challenge, [key]: value});
        };

        const handleDateChange = (key, event) => {
            const date = new Date(event.target.value);
            if (key === "startDate") {
                date.setHours(0);
                date.setMinutes(0);
            } else {
                date.setHours(23);
                date.setMinutes(59);
            }
            setChallenge({...challenge, [key]: date});
        };

        const handleCreateClick = () => {
            setShowCreation(true);
        };

        const handleConfirmClick = () => {
            setConfirmed(true);
            setShowCreation(false);
            dispatch(fetchCreateChallengePage({challenge: challenge}));
        };

        const selectPack = (key, event) => {
            setChallenge({...challenge, [key]: event.target.value});
        }

        const checkBetweenDate = (date) => {
            if ((Date.parse(challenge.expirationDate) > Date.parse(date)) && (Date.parse(challenge.startDate) < Date.parse(date))) {
                return true;
            }
            return false;
        }

        useEffect(() => {
            console.log(allMatchesForChallenge);
        }, [allMatchesForChallenge])

        return (
            <div className="start-page w-75 mb-4">
                <header className="page-header page-header-dark row">
                    <button className="btn btn-danger ml-3 mr-4 mb-3" onClick={() => history.push('/all-challenges')}>
                        Cancel
                    </button>
                    <h1>{challenge.title.length ? challenge.title : "New challenge"}</h1>
                </header>
                <div className="card mb-2">
                    <div className="card-header">
                        <h4>General information</h4>
                    </div>
                    <div className="card-body">
                        <div className="ml-0 row">
                            <div className="w-50">
                                <div className="mb-4">
                                    <h5>Challenge image</h5>
                                    <input type="file" name="urlImage" onChange={e => handleImageChange(e)}/>
                                </div>
                                <div className="mb-4">
                                    <h5>Challenge title</h5>
                                    <input className="border rounded" type="text" placeholder="Title"
                                           onChange={e => handleChallengeItemChange("title", e.target.value)}/>
                                </div>
                                <div className="mb-4">
                                    <h5>Challenge duration</h5>
                                    <div className="row">
                                        <div className="ml-3">
                                            <h6>Starting</h6>
                                            <input className="border rounded mb-2" type="date"
                                                   onChange={e => handleDateChange("startDate", e)}/>
                                        </div>
                                        <div className="ml-4">
                                            <h6>Ending</h6>
                                            <input className="border rounded" type="date"
                                                   onChange={e => handleDateChange("expirationDate", e)}/>
                                        </div>
                                        <div className="ml-4">
                                            <h6>Display Ending</h6>
                                            <input className="border rounded" type="date"
                                                   onChange={e => handleDateChange("expirationDisplayDate", e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-50">
                                <img className="card-img" src={challenge.urlImage} alt="selected image"/>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h5>Challenge short presentation</h5>
                            <textarea className="form-control-plaintext border rounded" placeholder="Short description"
                                      onChange={e => handleChallengeItemChange("shortDescription", e.target.value)}/>
                        </div>
                        <div className="mb-4">
                            <h5>Challenge detailed presentation</h5>
                            <textarea className="form-control-plaintext border rounded" placeholder="Presentation"
                                      onChange={e => handleChallengeItemChange("presentation", e.target.value)}/>
                        </div>
                        <div className="w-50">
                            <div className="mb-4">
                                <h5>Minimum amount of predictions</h5>
                                <input type="number" min="0" className="form-control-plaintext border rounded w-25"
                                       placeholder="Minimum"
                                       value={challenge.nbMinPredictions}
                                       onChange={e => handleChallengeItemChange("nbMinPredictions", e.target.value)}/>
                            </div>
                        </div>
                        {/*<div className="w-50">
                            <div className="mb-4">
                                <h5>Membership Access</h5>
                                <select defaultValue="PACK"
                                        onChange={event => selectPack("packInformation", event)}>
                                    <option key={-1} value="PACK" disabled={true}>PACK</option>
                                    {
                                        allPackInformation.map((packInformation, index) =>
                                            <option key={index} value={packInformation.level}>{packInformation.level}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>*/}
                        <div className="mb-4">
                            <h5>Challenge Payant :</h5>
                            <input className="border rounded" type="checkbox"
                                   onChange={e => {
                                       handleChallengeIsPaid("paid", e.target.checked)
                                   }}/>
                        </div>
                        {challenge.paid && (
                            <>
                                <div className="mb-4">
                                    <h5>Prix en centime (exemple : 10€99 = 1099) :</h5>
                                    <input className="border rounded" type="number"
                                           onChange={e => {
                                               handleChallengeItemChange("priceCents", e.target.value)
                                           }}/>
                                </div>
                                <div className="mb-4">
                                    <h5>Nom du produit Google Play</h5>
                                    <input className="border rounded" type="text"
                                           onChange={e => {
                                               handleChallengeItemChange("googlePlayProductName", e.target.value)
                                           }}/>
                                </div>

                            </>
                        )}
                        <div className="mb-4">
                            <h5>Rediriger l'utilisateur vers le web (Challenge NetBet)</h5>
                            <input className="border rounded" type="checkbox"
                                   onChange={e => {
                                       if (e.target.checked) {
                                           handleChallengeItemChange("webUrl", getWebUrl())
                                       } else {
                                           handleChallengeItemChange("webUrl", null)
                                       }
                                   }}/>
                        </div>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-header">
                        <h4>Prizes and awards</h4>
                    </div>
                    <div className="card-body">
                        <div className="mb-4">
                            <h5>Prizes announcement</h5>
                            <textarea className="form-control-plaintext border rounded" placeholder="Announcement"
                                      onChange={e => handleChallengeItemChange("annoncesLots", e.target.value)}/>
                        </div>
                        <div className="mb-4">
                            <h5>Prizes List</h5>
                            <textarea className="form-control-plaintext border rounded" placeholder="Prizes"
                                      onChange={e => handleChallengeItemChange("lots", e.target.value.split('\n'))}/>
                        </div>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-header">
                        <h4>Challenge rules</h4>
                    </div>
                    <div className="card-body">
                        <div className="mb-4">
                            <h5>Rules and conditions winning prizes</h5>
                            <textarea className="form-control-plaintext border rounded" placeholder="Rules"
                                      onChange={e => handleChallengeItemChange("rules", e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-header">
                        <h4>Eligible predictions</h4>
                    </div>
                    <div className="card-body">
                        <div className="mb-4">
                            <h5>Sport(s)</h5>
                            <Typeahead id="sport" label="Sport(s)" placeholder="Select a sport"
                                       onChange={items => handleChallengeItemChange("sports", items.map(e => e.id))}
                                       multiple={true}
                                       options={allSportsForChallenge.map(e => {
                                           return {id: e.id, label: e.name.items[0].trad};
                                       })}/>
                        </div>
                        <div className="mb-4">
                            <h5>Event(s)</h5>
                            <Typeahead id="event" label="Event(s)" placeholder="Select an event"
                                       onChange={items => handleChallengeItemChange("event", items.map(e => e.id))}
                                       multiple={true}
                                       options={allEventsForChallenge.map(e => {
                                           return {id: e.id, sport: e.sportCategoryId, label: e.name.items[0].trad};
                                       })} filterBy={(option, props) => challenge.sports.includes(option.sport)}/>
                        </div>
                        <div className="mb-4">
                            <h5>Match(es)</h5>
                            <Typeahead id="match" label="Match(es)" placeholder="Select a match"
                                       onChange={items => handleChallengeItemChange("matchs", items.map(e => e.id))}
                                       multiple={true}
                                       options={allMatchesForChallenge.map(e => {
                                           return {id: e.id, event: e.eventId, label: e.name, date: e.dateTime};
                                       })}
                                       filterBy={(option, props) => challenge.event.includes(option.event) && !challenge.matchs.includes(option.id)}/>
                        </div>
                        <div className="mb-4">
                            <h5>Odd Type(s)</h5>
                            <Typeahead id="oddtype" label="Odd Type(s)" placeholder="Select an odd type"
                                       onChange={items => handleChallengeItemChange("oddTypes", items.map(e => e.id))}
                                       multiple={true}
                                       options={oddTypes.map(e => {
                                           return {id: e, label: e};
                                       })}/>
                        </div>
                        <div className="mb-4">
                            <h5>Bookmaker(s)</h5>
                            <Typeahead id="bookmaker" label="Bookmaker(s)" placeholder="Select a bookmaker"
                                       onChange={items => handleChallengeItemChange("bookmakers", items.map(e => e.id))}
                                       multiple={true}
                                       options={allBookmakers.map(e => {
                                           return {id: e.id, label: e.name.items[0].trad};
                                       })}/>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mb-4"
                        onClick={() => handleCreateClick()}>
                    Create challenge
                </button>
                {
                    showCreation &&
                    <Modal size="lg" show={showCreation} onHide={() => setShowCreation(false)}>
                        <Modal.Header closeButton className="alert alert-info">
                            <Modal.Title>Creation confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="ml-0 row">
                                <div className="w-50">
                                    <h3>{challenge.title.length ? challenge.title : 'No title!'}</h3>
                                    <div className="mb-2">
                                        {/* Short Presentation */}
                                    </div>
                                    <div className="mb-2">
                                        {new Date(challenge.startDate).toDateString() + " - " + new Date(challenge.expirationDate).toDateString()}
                                    </div>
                                    <div className="mb-2">
                                        Short Presentation: {challenge.shortDescription}
                                    </div>
                                    <div className="mb-2">
                                        Presentation: {challenge.presentation}
                                    </div>
                                    <div className="mb-2">
                                        Prizes announcement: {challenge.annoncesLots}
                                    </div>
                                    <div className="mb-2">
                                        Prizes: {challenge.lots.join(', ')}
                                    </div>
                                    <div className="mb-2">
                                        Rules: {challenge.rules}
                                    </div>
                                    <div className="mb-2">
                                        Payant: {challenge.paid ? "Oui" : "Non"}
                                    </div>
                                    <div className="mb-2">
                                        Prix: {challenge.priceCents}
                                    </div>
                                    <div className="mb-2">
                                        Nom du produit Google Play: {challenge.googlePlayProductName}
                                    </div>
                                    <div className="mb-2">
                                        Sport(s): {allSportsForChallenge.filter(e => challenge.sports.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                    </div>
                                    <div className="mb-2">
                                        Event(s): {allEventsForChallenge.filter(e => challenge.event.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                    </div>
                                    <div className="mb-2">
                                        Match(es): {allMatchesForChallenge.filter(e => challenge.matchs.includes(e.id)).map(e => e.name).join(', ')}
                                    </div>
                                    <div className="mb-2">
                                        Odd Type(s): {challenge.oddTypes.join(', ')}
                                    </div>
                                    <div className="mb-2">
                                        Bookmaker(s): {allBookmakers.filter(e => challenge.bookmakers.includes(e.id)).map(e => e.name.items[0].trad).join(', ')}
                                    </div>
                                    {challenge.webUrl && <div className="mb-2">
                                        Rediriger vers le web : {challenge.webUrl}

                                    </div>}
                                </div>
                                <div className="w-50">
                                    <img className="card-img" src={challenge.urlImage} alt="can't display image"/>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="row">
                            <Button variant="danger" onClick={() => setShowCreation(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => handleConfirmClick()}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    showError &&
                    <Modal size="lg" show={showError} onHide={() => setShowError(false)}>
                        <Modal.Header className="alert alert-danger">
                            <Modal.Title>Encountered error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please verify challenge's infos.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowError(false)}>
                                Modify
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    confirmed && isLoading &&
                    <Modal size="sm" show={confirmed && isLoading}>
                        <Modal.Header className="alert alert-warning">
                            <Modal.Title>Loading please wait...</Modal.Title>
                        </Modal.Header>
                    </Modal>
                }
            </div>
        );
    }
;

export default CreateChallenge;
