import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import {Table} from "react-bootstrap";
import Pagination from "../../uicomponents/pagination/pagination";
import {actionGetTeamAllComments, actionGetTeamAllPublications} from "../../store/reducers/teamDetails";
import {useHistory} from "react-router-dom";

interface TeamDetailsProps {
    teamDetailsReducers: any

    getTeamsPublications(teamId: number, page: number): void;

    getTeamsComments(teamId: number, page: number): void;

    getTeamUsers(teamId: number, page: number): void
}

type TeamDetailsWithRouteProps = TeamDetailsProps & RouteComponentProps;

const TeamDetails = ({
                         getTeamsComments,
                         teamDetailsReducers,
                         getTeamsPublications,
                         getTeamUsers
                     }: TeamDetailsWithRouteProps) => {

    const history = useHistory();
    // @ts-ignore
    const team = history.location.state.team;

    useEffect(() => {
        getTeamsPublications(team.publicationChannel, 0);
        getTeamsComments(team.publicationChannel, 0);
    }, [team]);

    const paginationPublicationChange = (event, pageClicked) => {
        getTeamsPublications(team.publicationChannel, pageClicked);
    };

    const paginationCommentChange = (event, pageClicked) => {
        getTeamsComments(team.publicationChannel, pageClicked);
    };

    return (
        <div className="start-page">
            {team &&
                <h1 className="h3 mb-2 text-gray-800">Details du groupe {team.name}</h1>
            }
            <div className="alert alert-info">
                Cet écran permet de visualiser les détails d'un groupe
            </div>
            <Accordion defaultActiveKey="0">
                {team &&
                    <Card>

                        <Card.Header>
                            Informations générales
                        </Card.Header>

                        <Card.Body>
                            <div className="row">
                                <div className="col-md-2">
                                    Nom :
                                </div>
                                <div className="col-md-4">
                                    {team.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    Visibilité :
                                </div>
                                <div className="col-md-4">
                                    {team.visibility}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    Description :
                                </div>
                                <div className="col-md-10">
                                    {team.description}
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-2">
                                    Membres totals :
                                </div>
                                <div className="col-md-4">
                                    {team.members.length}
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                }

                <br/>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} eventKey="1">
                            Publications dans le Feed du groupe<i className="fas fa-caret-down"></i>
                        </Accordion.Toggle></Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    {teamDetailsReducers.publications &&
                                        <Pagination page={teamDetailsReducers.publications}
                                                    action={paginationPublicationChange}/>
                                    }
                                </div>
                            </div>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Message</th>
                                </tr>
                                </thead>
                                <tbody>
                                {teamDetailsReducers.publications && teamDetailsReducers.publications.content.map((publication, index) =>
                                    <tr key={index}>
                                        <td>{publication.id}</td>
                                        <td>{publication.message}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <br/>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} eventKey="2">
                            Commentaires dans le Feed du groupe<i className="fas fa-caret-down"></i>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    {teamDetailsReducers.comments &&
                                        <Pagination page={teamDetailsReducers.comments}
                                                    action={paginationCommentChange}/>
                                    }
                                </div>
                            </div>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Message</th>
                                </tr>
                                </thead>
                                <tbody>
                                {teamDetailsReducers.comments && teamDetailsReducers.comments.content.map((comment, index) =>
                                    <tr key={index}>
                                        <td>{comment.id}</td>
                                        <td>{comment.comment}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <br/>
                {team &&
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} eventKey="3">
                                Membres du groupe <i className="fas fa-caret-down"></i>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Pseudo</th>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th>Email</th>
                                        <th>Numéro</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {team.members && team.members.map((member, index) =>
                                        <tr key={index}>
                                            <td>{member.id}</td>
                                            <td>{member.nickname}</td>
                                            <td>{member.firstname}</td>
                                            <td>{member.lastname}</td>
                                            <td>{member.email}</td>
                                            <td>{member.phoneNumber === undefined ? "N/A" : member.phoneNumber}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                }
            </Accordion>
        </div>
    );
}

const mapStateToProps = state => ({
    teamDetailsReducers: state.teamDetailsReducers
});

const mapDispatchToProps = {
    getTeamsPublications: actionGetTeamAllPublications,
    getTeamsComments: actionGetTeamAllComments
};

export default withRouter(compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamDetails));
