import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

export const pageOfCompetitionsActionsV2 = generateApiActions("bo_get_page_competition_V2");

export const actionGetAllCompetitionsV2 = (page: number) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/event/?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .types(pageOfCompetitionsActionsV2.all())
        .build();

export const updateCompetitionActionsV2 = generateApiActions("bo_update_competition_V2");

export const actionUpdateCompetitionV2 = (updateCompetitionRequest) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint("/bo/event")
        .withBody(updateCompetitionRequest)
        .verb(HTTP_VERBS.POST)
        .types(updateCompetitionActionsV2.all())
        .build();


export const searchCompetitionByNameActionsV2 = generateApiActions("bo_search_competition_by_name_V2");

export const actionSearchCompetitionByNameV2 = (name: string) =>
    RsaaBuilder.getInstance()
        .apiV2Endpoint(`/bo/event/search/${name}`)
        .verb(HTTP_VERBS.GET)
        .types(searchCompetitionByNameActionsV2.all())
        .build();

const defaultState = {};

const reducer = handleActions(
    {
        [combineActions(...pageOfCompetitionsActionsV2.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState,
        },
        [combineActions(...searchCompetitionByNameActionsV2.all())]: {
            next: (state, {payload}) => (payload ? {page: payload.content} : {...state}),
            throw: () => defaultState,
        },
    },
    {
        [combineActions(...updateCompetitionActionsV2.all())]: {
            next: state => ({...state}),
            throw: () => defaultState,
        },
    },
    defaultState
);

export default reducer;

