import {combineActions, handleActions} from "redux-actions";
import {generateApiActions} from "../../services/default-actions";
import RsaaBuilder, {HTTP_VERBS} from "../../services/rsaa-builder";

enum MembershipLevelActionReset {
    RESET = "RESET"
}

// sport

export const getSports = generateApiActions("bo_get_all_sports");

export const actionGetSports = () =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/bo/sports/")
        .verb(HTTP_VERBS.GET)
        .types(getSports.all())
        .build();

export const addSport = generateApiActions("bo_add_sport");

export const actionAddSport = (product) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/sports/add-update-sport`)
        .verb(HTTP_VERBS.POST)
        .types(addSport.all())
        .withBody(product)
        .build();

export const deleteSport = generateApiActions("bo_delete_sport");

export const actionDeleteSport = (id) =>
    RsaaBuilder.getInstance()
        .apiEndpoint(`/bo/sports/delete-sport/${id}`)
        .verb(HTTP_VERBS.DELETE)
        .types(deleteSport.all())
        .build();

export const uploadSportImageActions = generateApiActions("bo_upload_sport_image");

export const actionUploadSportImage = (data) =>
    RsaaBuilder.getInstance()
        .apiEndpoint("/documents/")
        .withBody(data)
        .verb(HTTP_VERBS.POST)
        .types(uploadSportImageActions.all())
        .build(true);

const defaultState = {};

const reducer = handleActions(
    {

        [combineActions(...getSports.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...addSport.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },
        [combineActions(...deleteSport.all())]: {
            next: (state, {payload}) => (payload ? {sports: payload.content} : {...state}),
            throw: () => defaultState
        },

        [combineActions(...uploadSportImageActions.all())]: {
            next: (state, {payload}) => {
                return {...state, imageUrl: payload ? payload.content : null}
            },
            throw: () => defaultState,
        },

    },
    defaultState
);

export default reducer;