import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import {AppState} from "../../store/reducers";
import {AllSocialStatisticsPageState, fetchAllSocialStatisticsPage} from "./socialStatistics-reducer";

const BestContributor = () => {

    const dispatch = useDispatch();
    const [nbDays, setNbDays] = useState(30);
    const {socialStatistics} = useSelector<AppState, AllSocialStatisticsPageState>(state => state.socialStatistics);

    useEffect(() => {
        dispatch(fetchAllSocialStatisticsPage({nbDays: nbDays}));
    }, [nbDays]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        dispatch(fetchAllSocialStatisticsPage({nbDays: Number(value)}));
        setNbDays(Number(value));
    };

    const renderUserList = (users) => {
        return (
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Rang</th>
                            <th>#</th>
                            <th>Nickname</th>
                            <th>Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && users.map((item, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.user.id}</td>
                                <td>{item.user.nickname}</td>
                                <td>{item.score}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    return (
        <div className="start-page">

            <div className="row">
                <div className="col-md-4">
                    <span>Nombre de jours pour les statistiques</span>
                    <br/>
                    <FormControl
                        type="number"
                        onChange={(event) => handleInputChange(event)}
                        value={nbDays.toString()}
                    />
                </div>
            </div>

            <br/>
            <br/>

            <h1 className="h3 mb-2 text-gray-800">Statistiques des utilisateurs les plus actifs sur
                les {nbDays} derniers jours</h1>

            <h1 className="h5 mb-2 text-gray-800">Messages envoyés</h1>
            {socialStatistics && renderUserList(socialStatistics.RANKING_FOR_MESSAGE)}

            <h1 className="h5 mb-2 text-gray-800">Commentaires envoyés</h1>
            {socialStatistics && renderUserList(socialStatistics.RANKING_FOR_COMMENT)}

            <h1 className="h5 mb-2 text-gray-800">Pronostics envoyés</h1>
            {socialStatistics && renderUserList(socialStatistics.RANKING_FOR_PRONO)}

        </div>
    );
}

export default BestContributor;
