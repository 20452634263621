import {ApiResponse, Page, User} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, ThunkApiArgs} from "../../../services/fetch-api";
import {HTTP_VERBS} from "../../../services/rsaa-builder";

type AllPublicationsApiReturnType = ApiResponse<Page>;
type FetchAllPublicationsPageArgs = { page: number };

export interface AllPublicationsPageState extends Loadable {
    allPublications: Page;
}

const initialAllPublicationsState: AllPublicationsPageState = {
    allPublications: {} as Page,
    isLoading: false,
};

export const fetchAllPublicationsPage = createAsyncThunk<AllPublicationsApiReturnType,
    FetchAllPublicationsPageArgs,
    ThunkApiArgs<AllPublicationsApiReturnType>>("AllPublicationsPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/publications?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allPublicationsPageSlice = createSlice({
    name: "allPublicationsSlice",
    initialState: initialAllPublicationsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPublicationsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllPublicationsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allPublications = payload.content;
            })
            .addCase(fetchAllPublicationsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllUsersForPublicationsApiReturnType = ApiResponse<User[]>;
type FetchAllUsersForPublicationsPageArgs = { page: number };

export interface AllUsersForPublicationsPageState extends Loadable {
    allUsers: User[];
}

const initialAllUsersForPublicationsState: AllUsersForPublicationsPageState = {
    allUsers: [] as User[],
    isLoading: false,
};

export const fetchAllUsersForPublicationsPage = createAsyncThunk<AllUsersForPublicationsApiReturnType,
    FetchAllUsersForPublicationsPageArgs,
    ThunkApiArgs<AllUsersForPublicationsApiReturnType>>("AllUsersPage/fetch", async ({page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/all`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersForPublicationsPageSlice = createSlice({
    name: "allUsersForPublicationsSlice",
    initialState: initialAllUsersForPublicationsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersForPublicationsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersForPublicationsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allUsers = payload.content;
            })
            .addCase(fetchAllUsersForPublicationsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type UserPublicationsApiReturnType = ApiResponse<Page>;
type FetchUserPublicationsPageArgs = { page: number, userId: number };

export interface UserPublicationsPageState extends Loadable {
    userPublications: Page;
}

const initialUserPublicationsState: UserPublicationsPageState = {
    userPublications: {} as Page,
    isLoading: false,
};

export const fetchUserPublicationsPage = createAsyncThunk<UserPublicationsApiReturnType,
    FetchUserPublicationsPageArgs,
    ThunkApiArgs<UserPublicationsApiReturnType>>("UserPublicationsPage/fetch", async ({
                                                                                          page,
                                                                                          userId
                                                                                      }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/publications/${userId}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const userPublicationsPageSlice = createSlice({
    name: "userPublicationsSlice",
    initialState: initialUserPublicationsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUserPublicationsPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserPublicationsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.userPublications = payload.content;
            })
            .addCase(fetchUserPublicationsPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type DeleteUserPublicationApiReturnType = ApiResponse<Page>;
type FetchDeleteUserPublicationPageArgs = { publicationId: number, userId: number };

export interface DeleteUserPublicationPageState extends Loadable {
}

const initialDeleteUserPublicationState: DeleteUserPublicationPageState = {
    isLoading: false,
};

export const fetchDeleteUserPublicationPage = createAsyncThunk<DeleteUserPublicationApiReturnType,
    FetchDeleteUserPublicationPageArgs,
    ThunkApiArgs<DeleteUserPublicationApiReturnType>>("DeleteUserPublicationPage/fetch", async ({
                                                                                                    publicationId,
                                                                                                    userId
                                                                                                }, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/message/publication/${userId}/${publicationId}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteUserPublicationPageSlice = createSlice({
    name: "deleteUserPublicationSlice",
    initialState: initialDeleteUserPublicationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteUserPublicationPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteUserPublicationPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteUserPublicationPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******