import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Table} from "react-bootstrap";
import {formatPrecisionMin} from "../../../services/moment/momentUtil";
import {actionGetAllDeviceInformation} from "../../../store/reducers/lastDeviceInformation";
import Pagination from "../../../uicomponents/pagination/pagination";

interface LastDeviceInformationProps {
    lastDeviceInformationReducersState: any

    getAllDeviceInformation(page: number): void,
}

const LastDeviceInformation = ({
                                   getAllDeviceInformation,
                                   lastDeviceInformationReducersState
                               }: LastDeviceInformationProps) => {

    useEffect(() => {
        getAllDeviceInformation(0);
    }, []);

    const paginationChange = (event, pageClicked) => {
        getAllDeviceInformation(pageClicked);
    };

    console.log(lastDeviceInformationReducersState.pageDeviceInformation)

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Utilisateurs</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {lastDeviceInformationReducersState.pageDeviceInformation &&
                        <Pagination page={lastDeviceInformationReducersState.pageDeviceInformation}
                                    action={paginationChange}/>
                    }
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>user.#</th>
                        <th>nickname</th>
                        <th>nom</th>
                        <th>email</th>
                        <th>Device type</th>
                        <th>Device OS</th>
                        <th>Version de l'application</th>
                        <th>Date de création</th>
                        <th>Date de dernière connexion</th>
                    </tr>
                    </thead>
                    <tbody>
                    {lastDeviceInformationReducersState && lastDeviceInformationReducersState.pageDeviceInformation && lastDeviceInformationReducersState.pageDeviceInformation.map((watchingSponsorships, index) =>
                        <tr key={index}>
                            <td>{watchingSponsorships && watchingSponsorships.id}</td>
                            <td>{watchingSponsorships.user && watchingSponsorships.user.id}</td>
                            <td>{watchingSponsorships.user && watchingSponsorships.user.nickname}</td>
                            <td>{`${watchingSponsorships.user && watchingSponsorships.user.firstname} ${watchingSponsorships.user && watchingSponsorships.user.lastname}`}</td>
                            <td>{watchingSponsorships.user && watchingSponsorships.user.email}</td>
                            <td>{watchingSponsorships.deviceType}</td>
                            <td>{watchingSponsorships.deviceOs}</td>
                            <td>{watchingSponsorships.applicationVersion}</td>
                            <td>{formatPrecisionMin(watchingSponsorships.createdAt)}</td>
                            <td>{formatPrecisionMin(watchingSponsorships.updatedAt)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    lastDeviceInformationReducersState: state.lastDeviceInformationReducers
});

const mapDispatchToProps = {
    getAllDeviceInformation: actionGetAllDeviceInformation,
};

export default compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(LastDeviceInformation);
