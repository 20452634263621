import React, {Component} from "react";

interface I18nFieldProps {
    contributionItems: Array<any>;

    onChange<Array>(items: Array);
}

interface I18nFieldState {
    items: Array<any>;
}

class I18nField extends Component<I18nFieldProps, I18nFieldState> {
    defaultState = {
        items: [{
            lang: 'FR',
            trad: '',
        }],
    }

    state = this.props.contributionItems == null ? this.defaultState : {items: this.props.contributionItems};

    handleInputTextChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const items = [{lang: 'FR', [name]: value}];

        this.setState({
            items,
        });
        this.props.onChange(items);
    }

    render() {
        const {items} = this.state;
        return (
            <div>
                {items.map((item, index) =>
                    <form key={index}>
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label htmlFor="inputEmail4">Langue</label>
                                <select className="form-control" defaultValue={item.lang}>
                                    <option>FR</option>
                                </select>
                            </div>
                            <div className="form-group col-md-10">
                                <label htmlFor="inputEmail4">Traduction</label>
                                <input type="text" className="form-control" name="trad" defaultValue={item.trad}
                                       placeholder="Entrer le nom ..." onChange={this.handleInputTextChange}/>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        )
    }
}

export default I18nField;