import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import FormControl from "react-bootstrap/FormControl";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {
    actionCreateTeamsForChallenges,
    actionGetAllTeamsForChallenges,
    actionSearchTeamsForChallengeByName
} from "../../store/reducers/team-for-challenge";
import AutoComplete from "../../components/AutoComplete";
import {actionGetAllUsers} from "../../store/reducers/user";
import {useHistory} from "react-router-dom";


interface TeamForChallengeProps {
    userReducers: any;
    teamForChallengeReducers: any;
    void;

    getAllTeams(page: number): void;

    getAllUsers(): void;

    createTeamForChallenge(form: any);

    searchTeamByName(name: string, page: number): void;
}

type TeamForChallengeWithRouteProps = TeamForChallengeProps & RouteComponentProps;

const TeamForChallenge = ({
                              userReducers,
                              teamForChallengeReducers,
                              getAllTeams,
                              getAllUsers,
                              createTeamForChallenge,
                              searchTeamByName
                          }: TeamForChallengeWithRouteProps) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selected, setSelected] = useState(0);
    const [teamName, setTeamName] = useState("");

    useEffect(() => {
        getAllTeams(0);
        getAllUsers();
    }, []);

    const handleInputTextChange = (event) => {
        const value = event.target.value;
        if (value !== "") {
            searchTeamByName(value, 0);
        }
    };

    const goTeamDetails = (team) => {
        history.push({
            pathname: '/teams/' + team.id,
            state: {team: team}
        })
    }

    const handleUsersSelected = selected => {
        setSelected(selected);
    };

    const handleTeamName = (e) => {
        setTeamName(e.target.value);
    }

    const buildForm = () => {
        return {
            name: teamName,
            creatorUserId: selected[0].id,
        };
    }

    const handleCreateTeamForChallenge = () => {
        const form = buildForm();
        createTeamForChallenge(form);
        setShowAddModal(false);
        setShowModal(false);
    }

    const handleTeamCreated = () => {
        setShowModal(false);
        window.location.reload();
    }

    const renderModal = () => {
        return (
            <Modal size="lg" show={showAddModal}
                   onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un groupe
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Veuillez remplir les champs suivants : </Form.Label>

                        <Form.Group controlId="formGroupTitre" style={{marginTop: '20px'}}>
                            <Form.Label>Nom du groupe</Form.Label>
                            <Form.Control type="text" placeholder="Nom du groupe"
                                          onChange={(event) => handleTeamName(event)}/>
                        </Form.Group>

                        <AutoComplete users={userReducers.users} labelKey="nickname"
                                      filter={["firstname", "lastname", "email"]}
                                      multiple={false}
                                      placeHolderText="Choississez un (ou des) administrateur(s) pour le groupe"
                                      handleUsersSelected={(event) => handleUsersSelected(event)}/>

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => setShowAddModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => handleCreateTeamForChallenge()}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    return (
        <div className="start-page">
            {renderModal()}
            <h1 className="h3 mb-2 text-gray-800">Groupes</h1>

            <div className="alert alert-info">
                Cet écran permet de gerer les groupes pour les challenges
            </div>
            <div className="row">
                <div className="col-md-4">

                    <FormControl
                        placeholder="Entrez le nom d'un groupe"
                        aria-label="Usernamed"
                        aria-describedby="basic-addon"
                        size="lg"
                        onChange={(event) => handleInputTextChange(event)}
                    />

                    <Button variant="primary" onClick={() => setShowAddModal(true)}
                            style={{marginTop: '50px', marginBottom: '70px', width: "120px"}}>
                        Ajouter
                    </Button>

                </div>
            </div>
            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th>Description</th>
                        <th>Détail</th>
                        <th>Supprimer</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {teamForChallengeReducers && teamForChallengeReducers.teams && teamForChallengeReducers.teams.content && teamForChallengeReducers.teams.content.map((team, index) =>
                        <tr key={index}>
                            <td>{team.id}</td>
                            <td>{team.name}</td>
                            <td>{team.description}</td>
                            <td>
                                <Button variant="primary" onClick={() => {
                                    goTeamDetails(team)
                                }}>
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </td>

                            <td>
                                <Button variant="primary">
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            {
                teamForChallengeReducers && teamForChallengeReducers.err &&
                <Modal size="lg" show={showModal}
                       onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton className="alert alert-danger">
                        <Modal.Title>Erreur</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Une erreur est survenue lors de la creation du groupe</Form.Label>
                    </Modal.Body>
                </Modal>
            }

            {
                teamForChallengeReducers && teamForChallengeReducers.isCreated &&
                <Modal size="lg" show={showModal}
                       onHide={() => setShowModal(false)}>
                    <Modal.Header className="alert alert-info">
                        <Modal.Title>Succès</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>Le groupe est créé avec succès</Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleTeamCreated()}>
                            OK!
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    userReducers: state.userReducers,
    teamForChallengeReducers: state.reducerTeamsForChallenges,
});

const mapDispatchToProps = {
    getAllTeams: actionGetAllTeamsForChallenges,
    createTeamForChallenge: actionCreateTeamsForChallenges,
    getAllUsers: actionGetAllUsers,
    searchTeamByName: actionSearchTeamsForChallengeByName
};

export default withRouter(compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(TeamForChallenge));