import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {compose} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import FormControl from "react-bootstrap/FormControl";
import {Button, Table} from "react-bootstrap";
import {actionGetAllTeams, actionSearchTeamsByName} from "../../store/reducers/team";
import {useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {actionDeleteTeam} from "../../store/reducers/teamDetails";

interface TeamProps {
    teamReducers: any;

    getAllTeams(page: number): void;

    searchTeamByName(name: string, page: number): void;

    deleteTeam(teamId: number): void;
}

type TeamWithRouteProps = TeamProps & RouteComponentProps;

const Team = ({teamReducers, getAllTeams, searchTeamByName, deleteTeam}: TeamWithRouteProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [currentTeam, setCurrentTeam] = useState<any>();
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        getAllTeams(0);
    }, []);

    const handleInputTextChange = (event) => {
        const value = event.target.value;
        if (value !== "") {
            searchTeamByName(value, 0);
        }
    };

    const goTeamDetails = (team) => {
        history.push({
            pathname: '/teams/' + team.id,
            state: {team: team}
        })
    }

    const handleDeleteClick = team => {
        setCurrentTeam(team)
        setShowDelete(true)
    };

    const handleDeleteTeam = () => {
        if (currentTeam) {
            deleteTeam(currentTeam.id)
            window.location.reload()
        }
        setShowDelete(false)
    };

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Groupes</h1>

            <div className="alert alert-info">
                Cet écran permet de gerer les groupes
            </div>
            <div className="row">
                <div className="col-md-4">

                    <FormControl
                        placeholder="Entrez le nom d'un groupe"
                        aria-label="Usernamed"
                        aria-describedby="basic-addon"
                        size="lg"
                        onChange={(event) => handleInputTextChange(event)}
                    />
                </div>
            </div>
            <br/>
            <div className="card shadow mb-4">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th>Visibilité</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {teamReducers.teams && teamReducers.teams.content.map((team, index) =>
                        <tr key={index}>
                            <td>{team.id}</td>
                            <td>{team.name}</td>
                            <td>{team.visibility}</td>
                            <td>{team.description}</td>
                            <td>
                                <button className="ml-4 btn btn-danger" type="button"
                                        onClick={() => handleDeleteClick(team)}>
                                    <i className="fas fa-trash"/>
                                </button>
                            </td>
                            <td>
                                <Button variant="primary" onClick={() => goTeamDetails(team)}>
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
            {
                showDelete &&
                <Modal size="lg" show={showDelete} onHide={() => setShowDelete(false)}>
                    <Modal.Header closeButton className="alert alert-danger">
                        <Modal.Title>Challenge Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you wish to delete {currentTeam ? currentTeam.name : 'none'}?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => handleDeleteTeam()}>
                            Confirm deletion
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    teamReducers: state.teamReducers
});

const mapDispatchToProps = {
    getAllTeams: actionGetAllTeams,
    searchTeamByName: actionSearchTeamsByName,
    deleteTeam: actionDeleteTeam
};

export default withRouter(compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Team));