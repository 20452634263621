import {ApiResponse, Sport} from "../../../typings/api";
import {Loadable} from "../../../typings/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiBuilder, HTTP_VERBS, ThunkApiArgs} from "../../../services/fetch-api";

type AllFeedsForManageFeedsApiReturnType = ApiResponse<Sport[]>;
type FetchAllFeedsForManageFeedsPageArgs = {};

export interface AllFeedsForManageFeedsPageState extends Loadable {
    allFeeds: Sport[]
}

const initialAllFeedsForManageFeedsState: AllFeedsForManageFeedsPageState = {
    allFeeds: [],
    isLoading: false,
};

export const fetchAllFeedsForManageFeedsPage = createAsyncThunk<AllFeedsForManageFeedsApiReturnType,
    FetchAllFeedsForManageFeedsPageArgs,
    ThunkApiArgs<AllFeedsForManageFeedsApiReturnType>>("AllFeedsForManageFeedsPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/sports/`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allFeedsForManageFeedsPageSlice = createSlice({
    name: "allFeedsForManageFeedsSlice",
    initialState: initialAllFeedsForManageFeedsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllFeedsForManageFeedsPage.pending, state => {
                state.isLoading = true
            })
            .addCase(fetchAllFeedsForManageFeedsPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allFeeds = payload.content;
            })
            .addCase(fetchAllFeedsForManageFeedsPage.rejected, state => {
                state.isLoading = false
            })
    }
});

//*******

type UpdateFeedApiReturnType = ApiResponse<Sport>;
type FetchUpdateFeedPageArgs = { feed: Sport };

export interface UpdateFeedPageState extends Loadable {
    newFeed: Sport
}

const initialUpdateFeedState: UpdateFeedPageState = {
    newFeed: {} as Sport,
    isLoading: false,
};

export const fetchUpdateFeedPage = createAsyncThunk<UpdateFeedApiReturnType,
    FetchUpdateFeedPageArgs,
    ThunkApiArgs<UpdateFeedApiReturnType>>("UpdateFeedPage/fetch", async ({feed}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/sports/add-update-sport`)
        .verb(HTTP_VERBS.POST)
        .withBody(feed)
        .build()
);

export const updateFeedPageSlice = createSlice({
    name: "updateFeedSlice",
    initialState: initialUpdateFeedState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateFeedPage.pending, state => {
                state.isLoading = true
            })
            .addCase(fetchUpdateFeedPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.newFeed = payload.content;
            })
            .addCase(fetchUpdateFeedPage.rejected, state => {
                state.isLoading = false
            })
    }
});

//*******

type DeleteFeedApiReturnType = ApiResponse<any>;
type FetchDeleteFeedPageArgs = { feedId: number };

export interface DeleteFeedPageState extends Loadable {
}

const initialDeleteFeedState: DeleteFeedPageState = {
    isLoading: false,
};

export const fetchDeleteFeedPage = createAsyncThunk<DeleteFeedApiReturnType,
    FetchDeleteFeedPageArgs,
    ThunkApiArgs<DeleteFeedApiReturnType>>("DeleteFeedPage/fetch", async ({feedId}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/sports/delete-sport/${feedId}`)
        .verb(HTTP_VERBS.DELETE)
        .build()
);

export const deleteFeedPageSlice = createSlice({
    name: "deleteFeedSlice",
    initialState: initialDeleteFeedState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDeleteFeedPage.pending, state => {
                state.isLoading = true
            })
            .addCase(fetchDeleteFeedPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteFeedPage.rejected, state => {
                state.isLoading = false
            })
    }
});

//*******