import React from "react";
import {connect} from "react-redux";

import {PrivateRoute} from "./app";
import Dashboard from "./dashboard/dashboard";
import BarHandler from "../uicomponents/barHandler/barHandler";
import ContributionCompetition from "./contribution/competition/contributionCompetition"
import ContributionCompetitionV2 from "./contribution/competition/contributionCompetition"
import ContributionBookmakerV2 from "./contribution/bookmaker/contributionBookmaker"
import User from "./user/user";
import NewUserDetails from "./user/user-details";
import Nav from "../uicomponents/nav/nav";
import Authorization from "./authorization/authorization";
import Sponsorship from "./user/sponsorship/sponsorship";
import AllMessages from "./support/all-messages/all-messages";
import Campain from "./publication/campaign/campain";
import Team from "./team/team";
import TeamDetails from "./team/teamDetails";
import PackConfiguration from "./membership/pack-configuration";
import LevelConfiguration from "./membership/level-configuration";
import UserAddPhone from "./user/userAddPhone";
import LastDeviceInformation from "./user/lastDeviceInformation/lastDeviceInformation";
import AllGeneratedAlerts from "./oddvariation/allGeneratedAlerts";
import ProductConfiguration from "./membership/productConfiguration";
import BestContributor from "./bestcontributor/bestContributor";
import PackInformation from "./membership/pack-information";
import SendFeed from "./publication/feed/send-feed";
import UserPassExpert from "./user/userPassExpert";
import ExpertStatistics from "./user/expertStatistics";
import CreateChallenge from "./challenge/create-challenge/create-challenge";
import MatchV2 from "./contribution/match/matchv2";
import SportV2 from "./contribution/sport/sportv2";
import Ranking from "./ranking/ranking";
import RankingHistory from "./ranking/rankinghistory";
import AllChallenges from "./challenge/all-challenges/all-challenges";
import ChallengeDetails from "./challenge/challenge-details/challenge-details";
import TeamForChallenge from "./team/team-for-challenge";
import CreateNews from "./news/create-news/create-news";
import AllNews from "./news/all-news";
import AllTrophies from "./trophy/all-trophies";
import InfoConnection from "./info-connection/info-connection";
import Loader from "../components/loader/loader";
import AllMatchs from "./contribution/match/all-match";
import Unlinksobet from "./unlinksobetmodel/unlinksobet";
import MessageDetails from "./support/message-details/message-details";
import FeedModeration from "./publication/moderation/feed-moderation";
import UserTrophies from "./tester-tools/user-trophies";
import Feeds from "./publication/manage-feeds/feeds";
import ModifyResult from "./contribution/prediction/modify-result";
import Subscription from "./membership/subscription";
import SubscriptionStatistics from "./membership/subscription-statistics";
import MembershipRenewal from "./membership/membership-renewal";
import AllInfluencers from "./influencers/all-influencers";
import CreateInfluencer from "./influencers/create-influencer/create-influencer";
import Godsons from "./user/sponsorship/godsons";
import MatchScores from "./contribution/match-scores/match-scores";
import UserStatisticsPeriod from "./user-statistics-period/UserStatisticsPeriod";


const Routes = ({authUser}) => (
    <>
        <Loader>
            <div id="wrapper">
                <BarHandler/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Nav user={authUser.user}/>
                        <div className="container-fluid">
                            <PrivateRoute authUser={authUser} exact path="/"
                                          component={Dashboard}/>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/sponsorship"
                                              component={Sponsorship}/>
                                <PrivateRoute authUser={authUser} exact path="/best-contributors"
                                              component={BestContributor}/>
                                {/*<PrivateRoute authUser={authUser} exact path="/user-add-phone-without-validation"*/}
                                {/*              component={UserAddPhone}/>*/}
                                <PrivateRoute authUser={authUser} exact path="/user-pass-expert"
                                              component={UserPassExpert}/>
                                <PrivateRoute authUser={authUser} exact path="/expert-statistics"
                                              component={ExpertStatistics}/>
                                <PrivateRoute authUser={authUser} exact path="/info-connection"
                                              component={InfoConnection}/>
                                <PrivateRoute authUser={authUser} exact path="/users"
                                              component={User}/>
                                <PrivateRoute authUser={authUser} exact path="/user/:id"
                                              component={NewUserDetails}/>
                                <PrivateRoute authUser={authUser} exact path="/user/last-device-info"
                                              component={LastDeviceInformation}/>
                                <PrivateRoute authUser={authUser} exact path="/authorization"
                                              component={Authorization}/>
                                <PrivateRoute authUser={authUser} exact path="/godsons/:id/:name"
                                              component={Godsons}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/team"
                                              component={Team}/>
                                <PrivateRoute authUser={authUser} exact path="/teams/:id"
                                              component={TeamDetails}/>
                                <PrivateRoute authUser={authUser} exact path="/team-for-challenges"
                                              component={TeamForChallenge}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/ranking"
                                              component={Ranking}/>
                                <PrivateRoute authUser={authUser} exact path="/ranking/weekly"
                                              component={RankingHistory}/>
                                <PrivateRoute authUser={authUser} exact path="/trophies"
                                              component={AllTrophies}/>
                                <PrivateRoute authUser={authUser} exact path="/all-challenges"
                                              component={AllChallenges}/>
                                <PrivateRoute authUser={authUser} exact path="/create-challenge"
                                              component={CreateChallenge}/>
                                <PrivateRoute authUser={authUser} exact path="/challenge/:id"
                                              component={ChallengeDetails}/>
                                <PrivateRoute authUser={authUser} exact path="/all-news"
                                              component={AllNews}/>
                                <PrivateRoute authUser={authUser} exact path="/create-news"
                                              component={CreateNews}/>
                                <PrivateRoute authUser={authUser} exact path="/odd-variation/all-generated-alerts"
                                              component={AllGeneratedAlerts}/>
                                <PrivateRoute authUser={authUser} exact path="/stats/get-new-users-in-period"
                                              component={UserStatisticsPeriod}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/support/all-messages"
                                              component={AllMessages}/>
                                <PrivateRoute authUser={authUser} exact path="/support/message-details/:id"
                                              component={MessageDetails}/>
                                <PrivateRoute authUser={authUser} exact path="/publications"
                                              component={FeedModeration}/>
                                <PrivateRoute authUser={authUser} exact path="/feeds"
                                              component={Feeds}/>
                                <PrivateRoute authUser={authUser} exact path="/send-notifications"
                                              component={Campain}/>
                                <PrivateRoute authUser={authUser} exact path="/send-in-feed"
                                              component={SendFeed}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/contribution/competition"
                                              component={ContributionCompetition}/>
                                <PrivateRoute authUser={authUser} exact path="/contribution/v2/competition"
                                              component={ContributionCompetitionV2}/>
                                <PrivateRoute authUser={authUser} exact path="/sport"
                                              component={SportV2}/>
                                <PrivateRoute authUser={authUser} exact path="/contribution/v2/bookmaker"
                                              component={ContributionBookmakerV2}/>
                                <PrivateRoute authUser={authUser} exact path="/match"
                                              component={MatchV2}/>
                                <PrivateRoute authUser={authUser} exact path="/modify-result"
                                              component={ModifyResult}/>
                                <PrivateRoute authUser={authUser} exact path="/match-scores"
                                              component={MatchScores}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/membership/pack-information"
                                              component={PackInformation}/>
                                {/*<PrivateRoute authUser={authUser} exact path="/membership/pack-configuration"*/}
                                {/*              component={PackConfiguration}/>*/}
                                <PrivateRoute authUser={authUser} exact path="/membership/level-configuration"
                                              component={LevelConfiguration}/>
                                <PrivateRoute authUser={authUser} exact path="/membership/product-configuration"
                                              component={ProductConfiguration}/>
                                <PrivateRoute authUser={authUser} exact path="/membership/subscription"
                                              component={Subscription}/>
                                <PrivateRoute authUser={authUser} exact path="/membership/purchased"
                                              component={SubscriptionStatistics}/>
                                <PrivateRoute authUser={authUser} exact path="/membership/renewal"
                                              component={MembershipRenewal}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/all-influencers"
                                              component={AllInfluencers}/>
                                <PrivateRoute authUser={authUser} exact path="/create-influencer"
                                              component={CreateInfluencer}/>
                            </>
                            <>
                                <PrivateRoute authUser={authUser} exact path="/all-matchs"
                                              component={AllMatchs}/>
                                <PrivateRoute authUser={authUser} exact path="/tester-tools/user-trophies"
                                              component={UserTrophies}/>
                            </>
                            <PrivateRoute authUser={authUser} exact path="/search-unlink-sobetmatch"
                                          component={Unlinksobet}/>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    </>
);

export default connect(state => ({
    // TODO : fix erreur Typescript
    authUser: (state as any).authUser,
}))(Routes);
