import {AppDispatch} from "../store";
import {AppState} from "../store/reducers";
import {getConfig} from "./config";

export enum HTTP_VERBS {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export enum CONTENT_TYPE_HEADER_TYPE {
    JSON = "application/json",
    MULTIPART_FORM_DATA = "multipart/form-data",
}

export interface ThunkApiArgs<T> {
    getState: AppState;
    dispatch: AppDispatch;
    rejectValue: T & { httpCode: number };
}

export const API_PREFIX = `${getConfig().urlHostApi}/sobet-api`;

export class ApiBuilder {
    _endpoint: string | undefined;
    _verb: HTTP_VERBS;
    _types: string[] | undefined;
    readonly _headers: Headers;
    _body: BodyInit | undefined;
    _options: object | undefined;
    _meta: any;
    _queryParams: string;
    _thunkAPI;

    private constructor() {
        const headers = new Headers();
        headers.append("Content-Type", CONTENT_TYPE_HEADER_TYPE.JSON);
        headers.append("Accept", CONTENT_TYPE_HEADER_TYPE.JSON);
        this._endpoint = undefined;
        this._verb = HTTP_VERBS.GET;
        this._types = undefined;
        this._headers = headers;
        this._body = undefined;
        this._options = undefined;
        this._meta = {} as unknown;
        this._queryParams = "";
    }

    static getInstance(thunkAPI) {
        const instance = new ApiBuilder();
        instance._thunkAPI = thunkAPI;
        return instance;
    }

    endpoint(endpoint: string) {
        this._endpoint = endpoint;
        return this;
    }

    apiEndpoint(endpoint: string) {
        const token = this._thunkAPI.getState().authUser.jwt;
        if (token) {
            this._headers.append("Authorization", `Bearer ${token}`);
        }
        this.endpoint(`${API_PREFIX}${endpoint}`);
        return this;
    }

    verb(verb: HTTP_VERBS) {
        this._verb = verb;
        return this;
    }

    withBody(body) {
        this._body = body;
        return this;
    }

    withQueryParams(queryParams: object) {
        this._queryParams = `?${Object.keys(queryParams)
            .map(key => key + "=" + queryParams[key])
            .join("&")}`;
        return this;
    }

    async build() {
        if (!this._endpoint) {
            throw new Error("Endpoint unknown.");
        }
        const isFormDataBody = this._body instanceof FormData;
        if (isFormDataBody) {
            this._headers.delete("content-type");
            //this._headers.append("Content-Type", CONTENT_TYPE_HEADER_TYPE.MULTIPART_FORM_DATA);
        }
        try {
            const response: Response = await fetch(`${this._endpoint}${this._queryParams}`, {
                method: this._verb,
                headers: this._headers,
                body: isFormDataBody ? this._body : JSON.stringify(this._body),
            });
            const payload = await response.json();
            if (response.status >= 300) {
                return this._thunkAPI.rejectWithValue({...payload, httpCode: response.status});
            }
            return payload;
        } catch (error) {
            return this._thunkAPI.rejectWithValue({httpCode: 418});
        }
    }
}