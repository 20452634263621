import {HTTP_VERBS} from "../../services/rsaa-builder";
import {ApiResponse, Page, Role, User} from "../../typings/api";
import {ApiBuilder, ThunkApiArgs} from "../../services/fetch-api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Loadable} from "../../typings/store";

type AllUsersApiReturnType = ApiResponse<Page>;
type FetchAllUsersPageArgs = { role: boolean; value: string; page: number };

export interface AllUsersPageState extends Loadable {
    allUsers: Page;
}

const initialState: AllUsersPageState = {
    allUsers: {} as Page,
    isLoading: false,
};

export const fetchAllUsersPage = createAsyncThunk<AllUsersApiReturnType,
    FetchAllUsersPageArgs,
    ThunkApiArgs<AllUsersApiReturnType>>("AllUsersPage/fetch", async ({role, value, page}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/search${role ? '-role' : ''}/${value}?page=${page}`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allUsersPageSlice = createSlice({
    name: "allUsersSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsersPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllUsersPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allUsers = payload.content;
            })
            .addCase(fetchAllUsersPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AddUserApiReturnType = ApiResponse<User>;
type FetchAddUserPageArgs = { updateRoleRequest: any };

export interface AddUserPageState extends Loadable {
    user: User;
}

const initialAddUserState: AddUserPageState = {
    user: {} as User,
    isLoading: false,
};

export const fetchAddUserPage = createAsyncThunk<AddUserApiReturnType,
    FetchAddUserPageArgs,
    ThunkApiArgs<AddUserApiReturnType>>("AddUserPage/fetch", async ({updateRoleRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/add-role`)
        .withBody(updateRoleRequest)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const addUserPageSlice = createSlice({
    name: "addUserSlice",
    initialState: initialAddUserState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAddUserPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAddUserPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.user = payload.content;
            })
            .addCase(fetchAddUserPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type RemoveUserRoleApiReturnType = ApiResponse<User>;
type FetchRemoveUserRolePageArgs = { updateRoleRequest: any };

export interface RemoveUserRolePageState extends Loadable {
    user: User;
}

const initialRemoveUserRoleState: RemoveUserRolePageState = {
    user: {} as User,
    isLoading: false,
};

export const fetchRemoveUserRolePage = createAsyncThunk<RemoveUserRoleApiReturnType,
    FetchRemoveUserRolePageArgs,
    ThunkApiArgs<RemoveUserRoleApiReturnType>>("RemoveUserRolePage/fetch", async ({updateRoleRequest}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/remove-role`)
        .withBody(updateRoleRequest)
        .verb(HTTP_VERBS.POST)
        .build()
);

export const removeUserRolePageSlice = createSlice({
    name: "removeUserRoleSlice",
    initialState: initialRemoveUserRoleState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRemoveUserRolePage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchRemoveUserRolePage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.user = payload.content;
            })
            .addCase(fetchRemoveUserRolePage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******

type AllRolesApiReturnType = ApiResponse<Role[]>;
type FetchAllRolesPageArgs = {};

export interface AllRolesPageState extends Loadable {
    allRoles: Role[];
}

const initialAllRolesState: AllRolesPageState = {
    allRoles: [],
    isLoading: false,
};

export const fetchAllRolesPage = createAsyncThunk<AllRolesApiReturnType,
    FetchAllRolesPageArgs,
    ThunkApiArgs<AllRolesApiReturnType>>("AllRolesPage/fetch", async ({}, thunkAPI) =>
    ApiBuilder.getInstance(thunkAPI)
        .apiEndpoint(`/v1/bo/users/roles`)
        .verb(HTTP_VERBS.GET)
        .build()
);

export const allRolesPageSlice = createSlice({
    name: "allRolesSlice",
    initialState: initialAllRolesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllRolesPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAllRolesPage.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.allRoles = payload.content;
            })
            .addCase(fetchAllRolesPage.rejected, state => {
                state.isLoading = false;
            });
    },
});

//*******