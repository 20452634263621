import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    AllActiveTrophiesForToolsPageState,
    AllTrophiesForToolsPageState,
    AllUsersForToolsPageState,
    fetchAllActiveTrophiesForToolsPage,
    fetchAllTrophiesForToolsPage,
    fetchAllUsersForToolsPage,
    fetchUpdateTrophyOfUserPage,
    UpdateTrophyOfUserPageState
} from "./user-trophies-reducer";
import Pagination from "../../uicomponents/pagination/pagination";
import moment, {now} from "moment";
import {User} from "../../typings/api";
import {Modal, ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

const UserTrophies = () => {

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentUser, setCurrentUser] = useState<User>({} as User);
    const [currentUserTrophiesId, setCurrentUserTrophiesId] = useState<number[]>([]);
    const [currentTrophy, setCurrentTrophy] = useState<string>("");
    const [showRemove, setShowRemove] = useState<boolean>(false);
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [nickname, setNickname] = useState<string>("");

    const {allTrophies} = useSelector<AppState, AllTrophiesForToolsPageState>(state => state.allTrophiesForTools);
    const {allActiveTrophies} = useSelector<AppState, AllActiveTrophiesForToolsPageState>(state => state.allActiveTrophiesForTools);
    const {allUsers} = useSelector<AppState, AllUsersForToolsPageState>(state => state.allUsersForTools);
    const {isLoading} = useSelector<AppState, UpdateTrophyOfUserPageState>(state => state.updateTrophyForUser);

    useEffect(() => {
        dispatch(fetchAllTrophiesForToolsPage({}));
        dispatch(fetchAllActiveTrophiesForToolsPage({}));
        dispatch(fetchAllUsersForToolsPage({nickname: "", page: 0}));
    }, []);

    useEffect(() => {
        setCurrentPage(0);
        dispatch(fetchAllUsersForToolsPage({nickname: nickname, page: 0}));
    }, [nickname]);

    useEffect(() => {
        setCurrentUserTrophiesId(activeTrophies(currentUser).map(a => a.trophyId));
    }, [currentUser]);

    useEffect(() => {
        if (!isLoading)
            dispatch(fetchAllActiveTrophiesForToolsPage({}));
    }, [isLoading]);

    const paginationChange = (event, pageClicked) => {
        setCurrentPage(pageClicked);
        dispatch(fetchAllUsersForToolsPage({nickname: nickname, page: pageClicked}));
    };

    const activeTrophies = user => {
        return allActiveTrophies
            .filter(active => moment(active.activeUntil).isAfter(now()) && active.userId === user.id);
    };

    const formatActiveTrophies = user => {
        return activeTrophies(user)
            .map(active => {
                const trophy = allTrophies.find(trophy => trophy.id === active.trophyId);
                return trophy ? trophy.iconEmoji + trophy.name : 'unknown trophy';
            })
            .join('; ');
    };

    const handleAddButton = () => {
        dispatch(fetchUpdateTrophyOfUserPage({
            trophyId: parseInt(currentTrophy),
            userId: currentUser.id,
            remove: false
        }));
        setShowAdd(false);
        setCurrentTrophy("");
        setCurrentUser({} as User);
    };

    const handleRemoveButton = () => {
        dispatch(fetchUpdateTrophyOfUserPage({
            trophyId: parseInt(currentTrophy),
            userId: currentUser.id,
            remove: true
        }));
        setShowRemove(false);
        setCurrentTrophy("");
        setCurrentUser({} as User);
    };

    return <div className="start-page">
        <header className="page-header page-header-dark">
            <h1>Trophées des utilisateurs</h1>
        </header>
        <input className="form-control mb-2" placeholder="Utilisateur..."
               onChange={e => setNickname(e.target.value)}/>
        <div className="card">
            <div className="card-header">
                {
                    allUsers &&
                    <Pagination page={allUsers} action={paginationChange}/>
                }
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Pseudo</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Trophées</th>
                    <th>Ajouter</th>
                    <th>Retirer</th>
                </tr>
                </thead>
                <tbody>
                {
                    allUsers && allUsers.content && allUsers.content.map((user, index) =>
                        <tr key={index}>
                            <td>{user.id}</td>
                            <td>{user.nickname}</td>
                            <td>{user.lastname}</td>
                            <td>{user.firstname}</td>
                            <td>{formatActiveTrophies(user)}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => {
                                    setCurrentUser(user);
                                    setShowAdd(true);
                                }}>
                                    <i className="fas fa-plus"/>
                                </button>
                            </td>
                            <td>
                                <button className="btn btn-danger" onClick={() => {
                                    setCurrentUser(user)
                                    setShowRemove(true);
                                }}>
                                    <i className="fas fa-minus"/>
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        {
            showAdd &&
            <Modal show={showAdd} onHide={() => setShowAdd(false)}>
                <ModalHeader closeButton className="alert alert-primary">
                    <ModalTitle>Ajouter un trophée a {currentUser.nickname}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <h5>Trophée</h5>
                    <select onChange={event => setCurrentTrophy(event.target.value)} className="custom-select"
                            value={currentTrophy}>
                        <option key={-1} value={""} disabled>Trophée...</option>
                        {
                            allTrophies && allTrophies
                                .filter(trophy => !currentUserTrophiesId.includes(trophy.id))
                                .map((trophy, index) => (
                                    <option key={index} value={trophy.id}>{trophy.name} {trophy.iconEmoji}</option>
                                ))};
                    </select>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={handleAddButton}>
                        <i className="fas fa-check"/> Confirmer
                    </button>
                </ModalFooter>
            </Modal>
        }
        {
            showRemove &&
            <Modal show={showRemove} onHide={() => setShowRemove(false)}>
                <ModalHeader closeButton className="alert alert-danger">
                    <ModalTitle>Retirer un trophée a {currentUser.nickname}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <h5>Trophée</h5>
                    <select onChange={event => setCurrentTrophy(event.target.value)} className="custom-select"
                            value={currentTrophy}>
                        <option key={-1} value={""} disabled>Trophée...</option>
                        {
                            allTrophies && allTrophies
                                .filter(trophy => currentUserTrophiesId.includes(trophy.id))
                                .map((trophy, index) => (
                                    <option key={index} value={trophy.id}>{trophy.name} {trophy.iconEmoji}</option>
                                ))};
                    </select>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger" onClick={handleRemoveButton}>
                        <i className="fas fa-check"/> Confirmer
                    </button>
                </ModalFooter>
            </Modal>
        }
    </div>
};

export default UserTrophies;