import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    AllAdminsPageState,
    AllFeedsForSendFeedPageState,
    fetchAllAdminsPage,
    fetchAllFeedsForSendFeedPage,
    fetchSendMessagePage,
    SendMessagePageState
} from "./send-feed-reducer";
import {AppState} from "../../../store/reducers";

const SendFeed = () => {

    const dispatch = useDispatch();

    const [message, setMessage] = useState<any>({
        channel: "",
        userId: -1,
        message: "",
        documentUrl: ""
    });
    const [showSendInfos, setShowSendInfos] = useState(false);
    const [validInfos, setValidInfos] = useState(false);

    const {allAdmins} = useSelector<AppState, AllAdminsPageState>(state => state.allAdmins);
    const {isLoading} = useSelector<AppState, SendMessagePageState>(state => state.sendMessage);
    const {allFeeds} = useSelector<AppState, AllFeedsForSendFeedPageState>(state => state.allFeedsForSendFeed);

    useEffect(() => {
        dispatch(fetchAllAdminsPage({}));
        dispatch(fetchAllFeedsForSendFeedPage({}));
    }, []);

    useEffect(() => {
        setShowSendInfos(false);
        setValidInfos(message.channel.length > 0 && message.userId != -1);
    }, [message]);

    const onMessageChange = (field, value) => {
        setMessage({...message, [field]: value});
    }

    const sendMessage = () => {
        setShowSendInfos(true);
        if (validInfos)
            dispatch(fetchSendMessagePage({message}));
    };

    return (
        <div className="start-page">
            <header className="page-header page-header-dark">
                <h1>Envoi de message(s) dans un feed</h1>
            </header>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row m-0">
                        <div className="mr-4">
                            <h5>Auteur :</h5>
                            <select className="custom-select" onChange={e => onMessageChange("userId", e.target.value)}
                                    value={message.userId}>
                                <option key={-1} value={-1} disabled>Auteur</option>
                                {
                                    allAdmins && allAdmins.map((admin, index) =>
                                        <option key={index} value={admin.id}>{admin.nickname}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div>
                            <h5>Feed :</h5>
                            <select className="custom-select" onChange={e => onMessageChange("channel", e.target.value)}
                                    value={message.channel}>
                                <option key={-1} value="" disabled>Feed</option>
                                {
                                    allFeeds.filter(feed => feed.active).map((channel, index) =>
                                        <option key={index} value={channel.code}>{channel.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h5>Message :</h5>
                        <textarea className="w-75 border rounded"
                                  onChange={e => onMessageChange("message", e.target.value)} value={message.message}/>
                    </div>
                    <div className="mt-4">
                        <h5>Lien GIF</h5>
                        <input className="w-75 border rounded" type="text"
                               onChange={e => onMessageChange("documentUrl", e.target.value)}
                               value={message.documentUrl}/>
                    </div>
                    <div className="row m-0 mt-4">
                        <div className="mr-2">
                            <button className="btn btn-primary" onClick={sendMessage}>
                                Envoyer <i className="far fa-paper-plane"/>
                            </button>
                        </div>
                        {
                            showSendInfos &&
                            <h3>
                            <span
                                className={"badge " + (validInfos ? isLoading ? 'badge-warning' : 'badge-success' : 'badge-danger')}>
                                {
                                    validInfos ?
                                        (isLoading ?
                                            "Traitement en cours" :
                                            "Traité") :
                                        "Information(s) manquante(s)"
                                }
                            </span>
                            </h3>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SendFeed;