import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import AutoComplete from "../../components/AutoComplete";
import {
    actionGetAllUsers,
    actionPassUserExpert,
    actionPassUserSemiExpert,
    actionRemoveUserExpert,
    actionRemoveUserSemiExpert,
    userExpertReset
} from "../../store/reducers/user";
import Button from "react-bootstrap/Button";
import {User} from "../../typings/api";

interface UserAddPhoneProps {
    userReducers: any;
    userExpertSuccess: boolean;
    userSemiExpertSuccess: boolean;

    getAllUsers(): void;

    passUserExpert(userId: number): void;

    removeUserExpert(userId: number): void;

    passUserSemiExpert(userId: number): void;

    removeUserSemiExpert(userId: number): void

    userExpertReset(): void
}

const UserPassExpert = ({
                            userReducers,
                            getAllUsers,
                            passUserExpert,
                            removeUserExpert,
                            passUserSemiExpert,
                            removeUserSemiExpert
                        }: UserAddPhoneProps) => {

    const [selected, setSelected] = useState<User[]>([]);
    const [currentStatus, setCurrentStatus] = useState({
        expert: false,
        semi: false
    })

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        if (selected.length)
            setCurrentStatus({
                expert: selected[0].userSpecialAttribut.expert,
                semi: selected[0].userSpecialAttribut.semiExpert
            });
    }, [selected])

    const handleUsersSelected = selected => {
        setSelected(selected);
    };

    const passExpert = () => {
        let user = selected[0];
        // @ts-ignore
        let userId = user.id;
        passUserExpert(userId);
        currentStatus.expert = true;
    };

    const disableExpert = () => {
        let user = selected[0];
        // @ts-ignore
        let userId = user.id;
        removeUserExpert(userId);
        currentStatus.expert = false;
    };

    const passSemiExpert = () => {
        let user = selected[0];
        // @ts-ignore
        let userId = user.id;
        passUserSemiExpert(userId);
        currentStatus.semi = true;
    };

    const disableSemiExpert = () => {
        let user = selected[0];
        // @ts-ignore
        let userId = user.id;
        removeUserSemiExpert(userId);
        currentStatus.semi = false;
    };

    return (
        <div className="start-page">
            <h1 className="h3 mb-2 text-gray-800">Ajout / Suppression expert pour un utilisateur</h1>

            <div className="row">
                <div className="col-md-6">
                    <AutoComplete users={userReducers.users} labelKey="nickname"
                                  filter={["firstname", "lastname", "email"]}
                                  multiple={false}
                                  placeHolderText="Selectionnez un utilisateur"
                                  handleUsersSelected={(event) => handleUsersSelected(event)}/>
                </div>
            </div>
            {
                selected.length != 0 &&
                <div className="mt-4 card">
                    <div className="card-header">
                        <h4>
                            <div className="row">
                                <div className="col-md-auto">
                                    Status de {selected[0].nickname}
                                </div>
                                <div className="col-md-auto">
                                <span className={currentStatus.expert ? "badge badge-success" : "badge badge-danger"}>
                                    expert
                                </span>
                                </div>
                                <div className="col-md-auto">
                                <span className={currentStatus.semi ? "badge badge-success" : "badge badge-danger"}>
                                    semi-expert
                                </span>
                                </div>
                            </div>
                        </h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-auto">
                                {
                                    currentStatus.expert ?
                                        <Button variant="outline-primary" onClick={() => disableExpert()}>
                                            Retirer expert <i className="fas fa-trash"/>
                                        </Button> :
                                        <Button variant="outline-primary" onClick={() => passExpert()}>
                                            Attribuer expert <i className="fas fa-star"/>
                                        </Button>
                                }
                            </div>
                            <div className="col-md-auto">
                                {
                                    currentStatus.semi ?
                                        <Button variant="outline-primary" onClick={() => disableSemiExpert()}>
                                            Retirer semi-expert <i className="fas fa-trash"/>
                                        </Button> :
                                        <Button variant="outline-primary" onClick={() => passSemiExpert()}>
                                            Attribuer semi-expert <i className="fas fa-star"/>
                                        </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    userReducers: state.userReducers,
    addUserPhoneNumberSuccess: state.userReducers.addUserPhoneNumberSuccess,
    userExpertSuccess: state.userReducers.userExpertSuccess
});

const mapDispatchToProps = {
    getAllUsers: actionGetAllUsers,
    removeUserExpert: actionRemoveUserExpert,
    passUserExpert: actionPassUserExpert,
    passUserSemiExpert: actionPassUserSemiExpert,
    removeUserSemiExpert: actionRemoveUserSemiExpert,
    userExpertReset: userExpertReset
};

export default withRouter(compose<any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UserPassExpert));